import React from 'react';

export const PhoneRebrand = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      {...props}
    >
      <path
        d="M0.858398 5.06636C0.876894 4.07615 1.07892 3.01196 1.6096 2.02174C2.03072 1.25205 2.58132 0.62748 3.35101 0.206355C3.53454 0.0968052 3.71808 0.0228238 3.93717 0.00432847C4.3768 -0.0326622 4.70687 0.169364 4.92739 0.536426C5.33145 1.21506 5.73408 1.8937 6.13813 2.59083C6.41272 3.08594 6.70722 3.58104 6.96331 4.07615C7.18383 4.4802 7.12835 4.79178 6.76128 5.08486C6.43121 5.35944 6.08264 5.57997 5.64302 5.65395C5.58754 5.67244 5.51498 5.67244 5.45949 5.67244C5.25746 5.69094 5.14791 5.78199 5.11092 5.96552C5.07393 6.22161 5.11092 6.46063 5.1849 6.68115C5.36844 7.3043 5.64302 7.91038 5.9546 8.47805C6.37573 9.21217 6.83527 9.92637 7.42143 10.5325C7.54947 10.6605 7.67752 10.7715 7.80699 10.881C7.89804 10.955 8.00901 11.0091 8.11856 11.0461C8.3021 11.12 8.43014 11.083 8.53969 10.8995C8.81428 10.4229 9.27382 10.1654 9.786 9.98328C10.1531 9.85524 10.4461 9.96479 10.6482 10.2764C11.0522 10.881 11.4364 11.5056 11.8219 12.1287C12.152 12.6793 12.4821 13.21 12.8121 13.7606C13.1422 14.3112 13.0327 14.8604 12.5191 15.2459C12.0424 15.5945 11.4918 15.7965 10.9057 15.9061C9.76892 16.1266 8.66916 15.9431 7.58646 15.5205C6.63324 15.1534 5.80806 14.5858 5.05544 13.8886C4.04672 12.9539 3.24004 11.8527 2.5614 10.679C1.94109 9.61338 1.42749 8.49512 1.13441 7.30288C0.969371 6.60717 0.858398 5.91004 0.858398 5.06636Z"
        fill={props.color || 'white'}
      />
    </svg>
  );
};
