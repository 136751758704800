import React from 'react';

export const Wifi = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        d="M31.5249 13.7071L33.8749 11.8187C34.2087 11.5429 34.5847 11.3267 34.9838 11.1699C30.6512 7.93455 25.4229 6.17535 19.9189 6.17535C13.1782 6.17535 6.83811 8.79964 2.06853 13.5649C1.27311 14.3588 1.27311 15.6463 2.06708 16.4417C2.46478 16.8394 2.98587 17.0383 3.50695 17.0383C4.02658 17.0383 4.54766 16.8394 4.94537 16.4431C8.94566 12.4458 14.2639 10.2453 19.9203 10.2453C24.1064 10.2439 28.1052 11.4588 31.5249 13.7071Z"
        fill="#0096FA"
      />
      <path
        d="M25.9468 18.1864L29.4057 15.4082C26.5637 13.7158 23.3124 12.8057 19.9188 12.8057C14.9475 12.8057 10.2752 14.7435 6.76258 18.2604C5.96862 19.0558 5.97007 20.3433 6.76403 21.1372C7.16174 21.5335 7.68137 21.7324 8.201 21.7324C8.72208 21.7324 9.24316 21.5335 9.64087 21.1358C12.3856 18.3881 16.0361 16.8742 19.9188 16.8742C22.0351 16.8742 24.0788 17.3329 25.9468 18.1864Z"
        fill="#0096FA"
      />
      <path
        d="M18.8694 23.8718L23.3894 20.2417C22.2804 19.8991 21.1134 19.722 19.9203 19.722C16.7982 19.722 13.8604 20.9355 11.6498 23.1403C10.8543 23.9328 10.8514 25.2217 11.6454 26.0171C12.4379 26.8125 13.7268 26.8154 14.5222 26.0215C15.7125 24.8327 17.2293 24.0954 18.8694 23.8718Z"
        fill="#0096FA"
      />
      <path
        d="M38.5282 15.0178C38.5282 13.9031 37.6239 12.9988 36.5092 12.9988C36.0186 12.9988 35.5686 13.1744 35.2174 13.4662L17.7966 27.4585C16.9969 28.1174 16.4874 29.1146 16.4874 30.2322C16.4874 32.2164 18.0957 33.8247 20.0798 33.8247C21.0451 33.8247 21.9203 33.4415 22.5662 32.8217L22.5677 32.8231L22.5909 32.7985C22.6475 32.7419 22.7012 32.6838 22.7549 32.6243L37.8779 16.4983C38.2771 16.1296 38.5282 15.6042 38.5282 15.0178Z"
        fill="#0096FA"
      />
      <path
        d="M36.5077 12.9988C37.6224 12.9988 38.5267 13.9031 38.5267 15.0178C38.5267 15.6042 38.2756 16.1296 37.8779 16.4983L22.7563 32.6243C22.7026 32.6838 22.6489 32.7433 22.5923 32.7985L22.5691 32.8231L22.5676 32.8217C21.9217 33.4415 21.0465 33.8247 20.0812 33.8247C18.0971 33.8247 16.4888 32.2164 16.4888 30.2322C16.4888 29.1161 16.9983 28.1174 17.7981 27.4585L35.2188 13.4662C35.5671 13.1744 36.0171 12.9988 36.5077 12.9988Z"
        fill="#DE3700"
      />
    </svg>
  );
};
