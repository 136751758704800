import React from 'react';

export const DataUsageArrow = (props) => {
  return (
    <svg width="64" {...props}>
      <defs>
        <path d="M30 11h29v1H30z" />
        <mask x="0" y="0" width="29" height="1" fill="#fff">
          <use xlinkHref="#abc" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#0096CB"
          d="M23.969.098A17.653 17.653 0 0 0 22 0c-.75 0-1.414.033-1.992.098-.281.033-.52.16-.715.38a1.164 1.164 0 0 0-.293.799V20.82c0 .328.11.606.328.835.219.23.484.344.797.344h3.75c.313 0 .578-.115.797-.344.219-.229.328-.507.328-.835V1.277a1.21 1.21 0 0 0-.293-.81 1.007 1.007 0 0 0-.738-.369zM14.797 5.101A23.297 23.297 0 0 0 12.5 5c-.875 0-1.65.034-2.324.101a1.338 1.338 0 0 0-.834.392A1.12 1.12 0 0 0 9 6.315v14.47c0 .338.128.625.383.86.255.237.565.355.93.355h4.374c.365 0 .675-.118.93-.354s.383-.523.383-.86V6.316c0-.321-.114-.6-.342-.835a1.235 1.235 0 0 0-.861-.38zM4.969 11.098A17.653 17.653 0 0 0 3 11c-.75 0-1.414.033-1.992.098-.281.033-.52.16-.715.38a1.164 1.164 0 0 0-.293.799v8.544c0 .328.11.606.328.835.219.23.484.344.797.344h3.75c.313 0 .578-.115.797-.344.219-.229.328-.507.328-.835v-8.544a1.21 1.21 0 0 0-.293-.81 1.007 1.007 0 0 0-.738-.369z"
        />
        <path fill="#0096CB" stroke="#0096CB" d="M64 11.5L59 14V9z" />
        <use
          stroke="#0096CB"
          mask="url(#bbc)"
          strokeWidth="4"
          strokeDasharray="3"
          xlinkHref="#abc"
        />
      </g>
    </svg>
  );
};
