import React from 'react';

export const Account = (props) => {
  return (
    <svg width="8" viewBox="0 0 8 12" {...props}>
      <path
        d="M6.875 5.233L6.032 5A2.954 2.954 0 0 1 1.97 5l-.843.233C.23 5.494-.062 5.886.01 6.397l.67 4.439A1.167 1.167 0 0 0 1.798 12H6.2a1.167 1.167 0 0 0 1.118-1.164l.67-4.439c.077-.511-.216-.903-1.113-1.164m-2.507-.237A2.5 2.5 0 1 0 2.001 2.5a2.44 2.44 0 0 0 2.367 2.497"
        fill="#757575"
        fillRule="evenodd"
      />
    </svg>
  );
};
