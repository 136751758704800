export const fireTagular = (type, webElement) => {
  tagular('beam', {
    '@type': type,
    webElement,
  });
};

export const fireEventTagular = (
  event,
  type,
  webElement,
  actionOutcome = ''
) => {
  tagular('beam', event, {
    '@type': type,
    webElement,
    actionOutcome,
  });
};

export const fireElementViewedEvent = (
  elementType,
  location,
  position,
  message
) => {
  fireTagular('redventures.usertracking.v3.ElementViewed', {
    elementType: elementType,
    location: location,
    position: position,
    htmlId: '',
    text: message,
  });
};
