import React, { useContext } from 'react';
import { FuseContext } from '~/providers/FuseProvider';

export default function PhoneNumber({ dashes = false }) {
  const { fuseNumber, defaultFuseNumber } = useContext(FuseContext);

  function formatPhone(phone) {
    if (dashes) {
      return `1-${phone.substring(0, 3)}-${phone.substring(
        3,
        6
      )}-${phone.substring(6, 10)}`;
    } else {
      return `1.${phone.substring(0, 3)}.${phone.substring(
        3,
        6
      )}.${phone.substring(6, 10)}`;
    }
  }
  function renderPhoneNumber() {
    return (
      <>
        {fuseNumber ? formatPhone(fuseNumber) : formatPhone(defaultFuseNumber)}
      </>
    );
  }

  return renderPhoneNumber();
}
