import * as React from 'react';

export const SummerSavings = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      {...props}
    >
      <path
        d="M30.5 60C47.0685 60 60.5 46.5685 60.5 30C60.5 13.4315 47.0685 0 30.5 0C13.9315 0 0.5 13.4315 0.5 30C0.5 46.5685 13.9315 60 30.5 60Z"
        fill="white"
      />
      <mask
        id="mask0_1572_105"
        maskType="luminance"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="55"
        height="54"
      >
        <path
          d="M30.4706 56.9183C45.3579 56.9183 57.4265 44.8498 57.4265 29.9625C57.4265 15.0751 45.3579 3.00659 30.4706 3.00659C15.5833 3.00659 3.51474 15.0751 3.51474 29.9625C3.51474 44.8498 15.5833 56.9183 30.4706 56.9183Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1572_105)">
        <path
          d="M60.8433 3.00659H-0.153961V35.799H60.8433V3.00659Z"
          fill="url(#paint0_linear_1572_105)"
        />
        <path
          d="M30.4559 41.2561C33.3425 41.2561 35.6826 38.9161 35.6826 36.0294C35.6826 33.1428 33.3425 30.8027 30.4559 30.8027C27.5693 30.8027 25.2292 33.1428 25.2292 36.0294C25.2292 38.9161 27.5693 41.2561 30.4559 41.2561Z"
          fill="white"
        />
        <path
          d="M-0.153858 56.928L60.8434 56.928V35.8005L-0.153858 35.8005V56.928Z"
          fill="url(#paint1_linear_1572_105)"
        />
        <path
          d="M57.3333 52.9062H3.35458V54.2142H57.3333V52.9062Z"
          fill="white"
        />
        <path
          d="M57.3709 47.7939H3.31699V48.837H57.3709V47.7939Z"
          fill="white"
        />
        <path
          d="M57.4085 43.0447H3.27939V43.8621H57.4085V43.0447Z"
          fill="white"
        />
        <path
          d="M57.4462 39.4578H3.24179V40.1117H57.4462V39.4578Z"
          fill="white"
        />
        <path
          d="M57.4462 36.9775H3.24179V37.4876H57.4462V36.9775Z"
          fill="white"
        />
        <path
          d="M57.4838 35.7041H3.20419V35.9902H57.4838V35.7041Z"
          fill="white"
        />
        <path
          d="M57.4838 34.9358H3.20419V35.181H57.4838V34.9358Z"
          fill="white"
        />
        <path
          d="M46.1132 23.0175C45.4429 23.0175 44.7726 22.7608 44.2625 22.2491C40.5758 18.5559 35.6728 16.5238 30.4559 16.5238C25.239 16.5238 20.3573 18.5477 16.6756 22.2246C15.6538 23.2448 13.9976 23.2431 12.9758 22.2213C11.9557 21.1995 11.9573 19.5434 12.9791 18.5216C17.6499 13.8589 23.8559 11.2905 30.4559 11.2905C37.0559 11.2905 43.2897 13.8687 47.9638 18.5527C48.984 19.5745 48.9824 21.2306 47.9606 22.2524C47.4505 22.7625 46.7818 23.0175 46.1132 23.0175Z"
          fill="white"
        />
        <path
          d="M39.3088 29.8218C38.6385 29.8218 37.9666 29.5651 37.4565 29.0534C35.5879 27.1798 33.1028 26.1466 30.456 26.1466C27.8091 26.1466 25.3437 27.1716 23.4783 29.0305C22.4549 30.0507 20.7987 30.0474 19.7786 29.024C18.7584 28.0005 18.7617 26.3444 19.7851 25.3242C22.638 22.4795 26.4276 20.9133 30.456 20.9133C34.4843 20.9133 38.3034 22.491 41.1611 25.3586C42.1813 26.382 42.178 28.0381 41.1546 29.0583C40.6445 29.5667 39.9759 29.8218 39.3072 29.8218H39.3088Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1572_105"
          x1="30.3447"
          y1="35.799"
          x2="30.3447"
          y2="3.00659"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FCB116" />
          <stop offset="0.88" stop-color="#DD3801" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1572_105"
          x1="30.3464"
          y1="35.8005"
          x2="30.3464"
          y2="56.928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0096FA" />
          <stop offset="1" stop-color="#005DAC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
