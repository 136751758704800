import React from 'react';

export const PageRight = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M6.89172 14.9573L12.4855 8.22292V7.81667L6.89172 1.06667L4.26672 2.53542L8.67297 8.00417L4.26672 13.4417L6.89172 14.9573Z"
        fill="black"
      />
    </svg>
  );
};
