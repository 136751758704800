import React from 'react';

export const PlayRebrand = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clipPath="url(#clip0_4917_23532)">
        <path
          d="M28.3614 9.92871H6.89122L3.78241 10.8808C3.70469 10.8808 2.46117 10.9779 2.34458 10.9585C2.07256 10.9002 2.15028 9.77327 1.8394 10.0259C1.72282 10.1036 1.56738 10.2979 1.56738 10.6476L1.74225 13.3678C1.74225 13.8924 2.05313 14.3393 2.50003 14.5531V26.9883C2.50003 28.6399 3.8407 29.9805 5.49225 29.9805H25.9327C27.5842 29.9805 28.9249 28.6399 28.9249 26.9883V14.5531C29.3718 14.3393 29.6827 13.873 29.6827 13.3484V11.2694C29.7215 10.531 29.1192 9.92871 28.3614 9.92871ZM19.8705 13.5233L21.3472 11.0945H24.9223L23.4456 13.5233H19.8705ZM6.56091 13.5233L8.03759 11.0945H11.6322L10.1555 13.5233H6.56091ZM14.7021 11.0945H18.2772L16.8005 13.5233H13.2254L14.7021 11.0945ZM2.90806 13.3484L2.77205 11.0557C2.8692 11.0751 2.98578 11.0945 3.08293 11.0945H4.96764L3.49096 13.5233H3.08293C2.98578 13.5233 2.90806 13.4455 2.90806 13.3484ZM27.7785 26.9883C27.7785 27.9987 26.9625 28.8147 25.9521 28.8147H5.49225C4.48189 28.8147 3.66583 27.9987 3.66583 26.9883V14.6891H27.7785V26.9883ZM28.5557 13.3484C28.5557 13.4455 28.478 13.5233 28.3809 13.5233H26.5544L28.0311 11.0945H28.3809C28.478 11.0945 28.5557 11.1722 28.5557 11.2694V13.3484Z"
          fill="#0096FA"
        />
        <path
          d="M28.4584 2.9729L27.8949 0.971605C27.7977 0.621864 27.5646 0.349843 27.2537 0.174973C26.9428 0.000102278 26.5737 -0.0387578 26.2239 0.0583924L1.91692 7.01435C1.56718 7.1115 1.29516 7.34466 1.12029 7.65554C0.945415 7.96642 0.906555 8.3356 1.0037 8.68534L1.56718 10.6866C1.74205 11.2695 1.95578 11.0752 2.53868 11.0752C2.65526 11.0752 4.01536 11.0169 4.15137 10.9781L6.54127 10.6672L27.5452 4.66332C27.8949 4.56616 28.1669 4.333 28.3418 4.02212C28.5167 3.67238 28.5555 3.32264 28.4584 2.9729ZM26.5542 1.16591C26.5737 1.16591 26.5931 1.16591 26.6125 1.16591C26.6514 1.16591 26.6708 1.18534 26.6902 1.18534C26.7097 1.20477 26.768 1.2242 26.768 1.30192L27.3314 3.30321C27.3509 3.3615 27.3314 3.40036 27.312 3.43922C27.2926 3.45865 27.2731 3.51694 27.1954 3.51694L26.8651 3.61409L24.7667 1.69052L26.5542 1.16591ZM11.9817 5.34337L15.4208 4.35243L17.5193 6.27601L14.0801 7.26694L11.9817 5.34337ZM11.1268 8.12186L7.68764 9.1128L5.5892 7.16979L9.02832 6.17886L11.1268 8.12186ZM18.3936 3.51694L21.8327 2.52601L23.9312 4.44958L20.4726 5.44052L18.3936 3.51694ZM2.90785 10.4729C2.8107 10.4923 2.71355 10.4535 2.69412 10.3563L2.11122 8.35503C2.09179 8.29673 2.11122 8.25787 2.13065 8.21902C2.15008 8.18015 2.16951 8.16072 2.24723 8.14129L2.63583 8.02471L4.73428 9.96772L2.90785 10.4729Z"
          fill="#0096FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_4917_23532">
          <rect
            width="28.7759"
            height="30"
            fill="white"
            transform="translate(0.945312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
