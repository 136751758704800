// DO NOT EDIT THIS FILE. THIS FILE IS GENERATED IN NEXT.CONFIG.JS
import { lazy } from "react";

const listOfPreampAssets = {
  ['ACP - Hero - Rebrand V1']: lazy(() => import('./assets/ACP - Hero - Rebrand V1')),
  ['Business - Banner - MiR - V3']: lazy(() => import('./assets/Business - Banner - MiR - V3')),
  ['Cart - Index - MiR Banner Legal']: lazy(() => import('./assets/Cart - Index - MiR Banner Legal')),
  ['Home - ACP Banner - Rebrand V1']: lazy(() => import('./assets/Home - ACP Banner - Rebrand V1')),
  ['Home - Competitor Comparison Rebrand V1']: lazy(() => import('./assets/Home - Competitor Comparison Rebrand V1')),
  ['Home - Competitor Comparison Rebrand V2']: lazy(() => import('./assets/Home - Competitor Comparison Rebrand V2')),
  ['Home - Edu Module']: lazy(() => import('./assets/Home - Edu Module')),
  ['Home - Edu Module V2']: lazy(() => import('./assets/Home - Edu Module V2')),
  ['Home - Education Module - Rebrand V1']: lazy(() => import('./assets/Home - Education Module - Rebrand V1')),
  ['Home - Empty']: lazy(() => import('./assets/Home - Empty')),
  ['Home - FAQs - Rebrand V1']: lazy(() => import('./assets/Home - FAQs - Rebrand V1')),
  ['Home - Final EPQ - Rebrand V1']: lazy(() => import('./assets/Home - Final EPQ - Rebrand V1')),
  ['Home - Footer - Rebrand V1']: lazy(() => import('./assets/Home - Footer - Rebrand V1')),
  ['Home - Fusion Edu Module - Rebrand V1']: lazy(() => import('./assets/Home - Fusion Edu Module - Rebrand V1')),
  ['Home - Hero Flex Full']: lazy(() => import('./assets/Home - Hero Flex Full')),
  ['Home - Hero Flex Split']: lazy(() => import('./assets/Home - Hero Flex Split')),
  ['Home - Hero MiR - Rebrand V1']: lazy(() => import('./assets/Home - Hero MiR - Rebrand V1')),
  ['Home - Hero RTB - Rebrand V1']: lazy(() => import('./assets/Home - Hero RTB - Rebrand V1')),
  ['Home - Hero Standard Split']: lazy(() => import('./assets/Home - Hero Standard Split')),
  ['Home - Homepage Reviews - Rebrand V1']: lazy(() => import('./assets/Home - Homepage Reviews - Rebrand V1')),
  ['Home - Inactivity Modal']: lazy(() => import('./assets/Home - Inactivity Modal')),
  ['Home - Jupiter Banner']: lazy(() => import('./assets/Home - Jupiter Banner')),
  ['Home - Modal - ReturnVisitor Rebrand V1']: lazy(() => import('./assets/Home - Modal - ReturnVisitor Rebrand V1')),
  ['Home - Nav Header - Rebrand V1']: lazy(() => import('./assets/Home - Nav Header - Rebrand V1')),
  ['Home - Offer Hero - HN Rebrand V1']: lazy(() => import('./assets/Home - Offer Hero - HN Rebrand V1')),
  ['Home - Rural US News']: lazy(() => import('./assets/Home - Rural US News')),
  ['Home - Self Service Portal']: lazy(() => import('./assets/Home - Self Service Portal')),
  ['Home - US News - Rebrand V1']: lazy(() => import('./assets/Home - US News - Rebrand V1')),
  ['Home - US News - V2']: lazy(() => import('./assets/Home - US News - V2')),
  ['Home - Value Props - Rebrand V1']: lazy(() => import('./assets/Home - Value Props - Rebrand V1')),
  ['Home - WNAH']: lazy(() => import('./assets/Home - WNAH')),
  ['Home - Whole Home WiFi - Rebrand V1']: lazy(() => import('./assets/Home - Whole Home WiFi - Rebrand V1')),
};

export default listOfPreampAssets;
