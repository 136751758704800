import React from 'react';

export const ThumbsUp = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
    >
      <path
        d="M17.0387 34.6892H29.7961C29.8043 34.6892 29.8153 34.6892 29.8235 34.6892C31.2615 34.6892 32.6857 34.2191 33.835 33.3613C34.9952 32.4979 35.8586 31.2607 36.2655 29.875L38.5283 22.3333C39.1332 20.3454 38.7757 18.1734 37.5715 16.488L37.4725 16.356C36.2105 14.6431 34.1842 13.6203 32.0588 13.6368H27.0466L27.3188 12.647C27.9622 10.3017 27.3683 7.82722 25.7296 6.02908C25.045 5.27024 24.1735 4.7066 23.2029 4.39317C22.2323 4.07973 21.1958 4.03024 20.2005 4.2447C19.9888 4.29144 19.7936 4.40142 19.6424 4.56363C19.4912 4.72585 19.3922 4.92656 19.3564 5.14376C19.3427 5.2235 17.9955 13.5461 13.1454 17.2523L12.9337 17.4145H3.84685C3.55815 17.4145 3.27221 17.5327 3.06875 17.7362C2.8598 17.9452 2.74707 18.2201 2.74707 18.5143V31.7089C2.74707 32.003 2.86255 32.2807 3.06875 32.4869C3.27221 32.6932 3.55815 32.8086 3.84685 32.8086H13.1812L13.4149 33.0698C14.336 34.0981 15.6557 34.6865 17.0332 34.6865C17.0359 34.6892 17.0359 34.6892 17.0387 34.6892ZM29.8125 32.4842C29.8071 32.4842 29.8016 32.4842 29.7961 32.4842H17.0414C16.3293 32.4842 15.6584 32.2065 15.1553 31.7034C14.6521 31.2002 14.3744 30.5293 14.3744 29.8172V19.0257L14.6659 18.792C18.8423 15.4322 20.6459 9.40265 21.2261 6.96664L21.4075 6.21055L22.1664 6.38651C22.928 6.56248 23.6098 6.96389 24.1322 7.54403C25.2732 8.81977 25.6609 10.6234 25.1468 12.2511L24.4896 14.4341C24.3934 14.7613 24.4594 15.1242 24.6656 15.4019C24.8718 15.6796 25.2017 15.8446 25.5482 15.8446H32.0643C33.4968 15.8446 34.8605 16.5319 35.7156 17.6812C36.5707 18.8305 36.8346 20.3344 36.4222 21.7064L34.1594 29.2454C33.8845 30.1774 33.3016 31.0105 32.5207 31.5934C31.7399 32.168 30.7804 32.4842 29.8125 32.4842ZM12.1694 30.6008H4.94662V19.6086H12.1694V30.6008Z"
        fill="#0096FA"
      />
    </svg>
  );
};
