import React from 'react';

export const ConnectRebrand = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        d="M16.8061 30.2662C16.753 29.9707 16.5779 29.7049 16.3246 29.5361L5.72402 22.3559L6.82301 20.7239L8.23153 21.604C8.75991 21.912 9.41337 21.7635 9.75573 21.2491C10.0637 20.7208 9.91518 20.0673 9.40555 19.7281L7.08251 18.243C6.56507 17.9412 5.91318 18.0726 5.56457 18.5509L3.20714 22.0105C3.04299 22.2559 2.98828 22.517 3.03205 22.8312L3.0383 22.8562C3.10553 23.0579 3.22746 23.3471 3.51198 23.5362L15.0224 31.3229C15.1819 31.4433 15.3898 31.5027 15.6399 31.5027C15.9557 31.5027 16.2902 31.3589 16.606 31.0884L16.6279 31.065C16.7936 30.8368 16.8577 30.5522 16.8061 30.2662Z"
        fill="#DE3700"
      />
      <path
        d="M36.9053 7.31091C36.9037 7.31717 36.9021 7.32342 36.9021 7.32967C36.7958 6.37451 36.4707 5.5788 35.9407 4.991C35.2122 4.17653 34.1945 3.76852 33.0096 3.80916C31.1508 3.84981 28.7809 4.91284 26.1546 6.88258C23.558 8.81948 20.8816 11.4708 18.4148 14.5442C15.7431 17.8146 13.8265 20.7676 12.3868 23.8332C12.2367 24.1318 12.2179 24.4539 12.3289 24.7884C12.4243 25.0729 12.6463 25.3371 12.9386 25.5122L12.9621 25.5247C13.2606 25.6748 13.5827 25.6936 13.9157 25.5826C14.2002 25.4872 14.4644 25.2652 14.6395 24.9729L14.6551 24.9447C16.0292 22.0886 17.8442 19.2888 20.3689 16.1325C22.7451 13.1904 25.2729 10.7017 27.6788 8.93516C29.8861 7.28903 31.7949 6.38232 33.0533 6.38232C33.5567 6.38232 33.824 6.473 34.046 6.72312C34.2539 6.95292 34.4931 7.49069 34.4494 8.74288C34.3759 9.87001 34.0132 11.266 33.3707 12.8918C31.9809 16.4061 29.5969 20.3284 26.6595 23.9349C24.2833 26.877 21.7555 29.3657 19.3496 31.1322C17.1423 32.7783 15.2335 33.685 13.9751 33.685C13.5139 33.685 13.1825 33.5725 12.9855 33.3474C12.7479 33.0801 12.5806 32.8362 12.5806 32.1093C12.5806 31.423 12.0006 30.843 11.3144 30.843C10.6281 30.843 10.0481 31.423 10.0481 32.1202C10.0872 33.3599 10.3983 34.2197 11.0877 34.9951C11.7708 35.7627 12.7323 36.1707 13.8719 36.1769C13.8562 36.1816 13.8422 36.1879 13.8281 36.1926C18.382 36.0769 22.484 34.5886 26.3078 31.3902V35.0232C26.3078 35.6782 26.8221 36.1926 27.4771 36.1926C28.1321 36.1926 28.6465 35.6782 28.6465 35.0232V29.1812C29.364 28.4246 30.0722 27.6054 30.7725 26.7128C35.331 20.8974 37.4305 13.006 36.9053 7.31091Z"
        fill="#0096FA"
      />
    </svg>
  );
};
