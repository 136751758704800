import React from 'react';

export const StreamRebrand = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
    >
      <path
        d="M20.3814 1.42065C10.1638 1.42065 1.85059 9.73392 1.85059 19.9515C1.85059 30.1691 10.1638 38.4823 20.3814 38.4823C30.599 38.4823 38.9122 30.1691 38.9122 19.9515C38.9122 9.73392 30.599 1.42065 20.3814 1.42065ZM20.3814 35.2115C11.9677 35.2115 5.12314 28.3669 5.12314 19.9532C5.12314 11.5395 11.9677 4.69491 20.3814 4.69491C28.7951 4.69491 35.6397 11.5395 35.6397 19.9532C35.6397 28.3652 28.7951 35.2115 20.3814 35.2115Z"
        fill="#0096FA"
      />
      <path
        d="M27.9289 18.2446L17.4169 12.1743C17.0442 11.9582 16.663 11.8492 16.2852 11.8492C15.3764 11.8492 14.4575 12.547 14.4575 13.8829V26.0218C14.4575 27.356 15.3764 28.0537 16.2852 28.0537C16.663 28.0537 17.0442 27.9448 17.4169 27.7287L27.9289 21.6601C28.6215 21.2602 29.0197 20.6373 29.0197 19.9515C29.0197 19.2674 28.6215 18.6445 27.9289 18.2446Z"
        fill="#DE3700"
      />
    </svg>
  );
};
