import * as React from 'react';

export const CyberWeek = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="60"
      viewBox="0 0 58 60"
      fill="none"
      {...props}
    >
      <path
        d="M18.0237 17.0313L15.5269 17.144C15.6461 18.2211 15.3642 19.3182 14.6226 20.0976C13.4998 21.2775 11.7318 21.3417 10.5098 20.1783C9.25642 18.9849 9.23287 17.1961 10.3556 16.0163C11.1079 15.2261 12.1608 14.9635 13.1893 14.9799L13.0658 12.4731C11.4984 12.3061 9.71324 12.9157 8.34922 14.3482C6.25358 16.5495 6.18292 19.9356 8.58405 22.2218C10.9645 24.488 14.24 24.2525 16.3756 22.0091C17.8096 20.5031 18.2756 18.5573 18.0223 17.0313H18.0237Z"
        fill="white"
      />
      <path
        d="M20.8752 18.2376L23.2664 16.9728L21.5797 13.7844L21.5704 5.71088L18.7546 7.20052L18.9695 11.743L15.387 8.98209L12.5583 10.4782L19.1886 15.0492L20.8752 18.2376Z"
        fill="white"
      />
      <path
        d="M30.192 14.982C32.252 14.6037 33.3498 13.0127 33.0236 11.239C32.7952 9.9949 31.9465 8.99705 30.5275 8.91782C31.3276 8.4453 31.7488 7.43674 31.5782 6.50741C31.3419 5.21976 30.0821 4.0763 28.0079 4.45674L23.0586 5.36537L24.9986 15.9356L30.1906 14.9827L30.192 14.982ZM30.2384 11.5288C30.3277 12.0149 30.1014 12.633 29.1999 12.7986L27.1978 13.1662L26.8116 11.0634L28.8716 10.6851C29.6867 10.5352 30.1414 10.9992 30.2384 11.5281V11.5288ZM27.923 6.80862C28.5525 6.69299 28.9743 7.05916 29.0586 7.51668C29.1371 7.94566 28.9379 8.47028 28.1371 8.61732L26.4205 8.93209L26.0921 7.14409L27.923 6.80791V6.80862Z"
        fill="white"
      />
      <path
        d="M41.9742 13.3732L37.0328 12.5067L37.314 10.9022L41.8978 11.7059L42.3297 9.24192L37.7458 8.43821L38.0199 6.87719L42.9764 7.74585L43.411 5.26834L35.7615 3.92645L33.905 14.5124L41.5402 15.8514L41.9742 13.3732Z"
        fill="white"
      />
      <path
        d="M51.3838 9.03352L47.5159 6.77515L42.0969 16.0563L44.4452 17.4275L46.2418 14.3504L47.159 14.8857L46.9948 18.9164L49.6829 20.4853V15.8707C51.2182 16.0092 52.3531 15.1905 52.9912 14.0977C53.9662 12.4275 53.72 10.3975 51.3845 9.03352H51.3838ZM50.543 12.753C50.2425 13.2676 49.6351 13.5867 48.8435 13.1249L47.4367 12.3033L48.6101 10.294L50.1047 11.167C50.7707 11.5553 50.8578 12.2134 50.543 12.753Z"
        fill="white"
      />
      <path
        d="M22.2899 22.1968L23.9494 26.1347L20.2385 23.8856L19.235 24.7115L20.7367 28.78L17.1836 26.4002L15.6226 27.685L21.5804 31.2653L22.938 30.1475L21.4812 26.4102L24.8688 28.5587L26.2192 27.4473L23.8516 20.912L22.2899 22.1968Z"
        fill="white"
      />
      <path
        d="M32.1972 25.5765L31.7953 24.0961L28.5426 24.9791L28.2828 24.0205L31.2992 23.2025L30.9002 21.7307L27.8831 22.5494L27.6304 21.6165L30.8924 20.7314L30.4905 19.2518L25.4563 20.6179L27.1715 26.9398L32.1972 25.5765Z"
        fill="white"
      />
      <path
        d="M38.1187 23.5323L38.2722 22.0156L35.1616 21.7001L35.2586 20.7394L38.6219 21.0798L38.7761 19.5538L33.5856 19.0284L32.926 25.5459L38.1066 26.0705L38.2615 24.5445L34.9082 24.2054L35.0081 23.2176L38.1187 23.5323Z"
        fill="white"
      />
      <path
        d="M42.2378 23.7536L43.5447 21.3967L41.9472 20.5109L38.7695 26.2389L40.367 27.1255L41.0936 25.815L42.1942 25.4424L42.4512 28.2818L44.3991 29.3624L43.873 24.8935L47.4661 23.5723L45.5354 22.5009L42.2378 23.7536Z"
        fill="white"
      />
      <path
        d="M35.4576 38.2886C37.1462 36.6 37.1462 33.8622 35.4576 32.1735C33.7689 30.4849 31.0311 30.4849 29.3425 32.1735C27.6538 33.8622 27.6538 36.6 29.3425 38.2886C31.0311 39.9773 33.7689 39.9773 35.4576 38.2886Z"
        fill="white"
      />
      <path
        d="M9.51199 30.5916C7.13371 30.5916 4.8425 29.6515 3.02452 27.9206C1.11161 26.0998 0.0380955 23.7008 0.000979354 21.1655C-0.0347093 18.6987 0.906043 16.3297 2.65193 14.4967C4.77397 12.2669 7.75611 11.1184 10.6276 11.4246C12.5505 11.6295 14.0373 13.2041 14.1322 15.1348L14.1751 16.0013L14.9909 15.9642C16.9759 15.8736 18.7154 17.2819 19.0409 19.2426C19.5298 22.184 18.5291 25.2975 16.3649 27.5709C14.609 29.416 12.3043 30.4859 9.87602 30.5844C9.75396 30.5894 9.63191 30.5916 9.51057 30.5916H9.51199Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.0238 25.0191C16.6155 25.0191 15.2544 24.2597 14.5506 22.9285L13.3136 20.5909L7.47569 16.5659C6.34579 15.7872 5.70482 14.4745 5.78476 13.1041C5.86471 11.7337 6.65485 10.5053 7.86827 9.86358L10.6962 8.36751C11.2979 8.04917 11.9575 7.90071 12.6106 7.91284C12.9675 7.36466 13.4628 6.90428 14.0638 6.58594L16.8789 5.09629C18.0952 4.45318 19.5591 4.49316 20.7383 5.20193C21.9174 5.91071 22.6391 7.18551 22.6405 8.56166L22.6491 15.663L23.881 17.992C24.8946 19.9085 24.163 22.2839 22.2465 23.2975L19.8554 24.5623C19.2701 24.8721 18.6419 25.0184 18.0231 25.0184L18.0238 25.0191Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.145 22.7165C21.3541 22.7165 20.5761 22.4774 19.9159 22.022C19.0586 21.431 18.4719 20.5231 18.2835 19.4995L16.3434 8.92927C15.9523 6.79652 17.3634 4.75085 19.4962 4.35899L24.4455 3.45036C26.3919 3.09276 28.292 3.44322 29.7938 4.43679C31.2534 5.40181 32.2706 6.93856 32.5853 8.65305C32.6874 9.20836 32.7131 9.76582 32.6681 10.3111C33.3519 11.1827 33.8187 12.2312 34.0307 13.3854C34.3883 15.3332 33.9543 17.2626 32.8087 18.8172C31.7067 20.3139 30.015 21.3368 28.0465 21.6979L22.8545 22.6508C22.6189 22.6944 22.382 22.7151 22.1457 22.7151L22.145 22.7165Z"
        fill="#C8C9CA"
      />
      <path
        d="M38.6898 22.633C38.465 22.633 38.2366 22.6137 38.0074 22.573L30.3722 21.234C29.3465 21.0541 28.4343 20.4745 27.8369 19.6223C27.2388 18.77 27.0039 17.7151 27.1838 16.6894L29.0403 6.10343C29.4151 3.96783 31.45 2.53957 33.5849 2.91501L41.2344 4.25619C43.37 4.63092 44.7976 6.66517 44.4228 8.80078L43.9881 11.279C43.8839 11.8721 43.652 12.411 43.325 12.8714L42.9139 15.2155C43.0567 15.7508 43.0888 16.3247 42.9867 16.9057L42.552 19.3839C42.218 21.2904 40.5606 22.6323 38.6898 22.6323V22.633Z"
        fill="#C8C9CA"
      />
      <path
        d="M46.8273 27.2661C46.1435 27.2661 45.459 27.0876 44.848 26.7307L42.1606 25.1619C41.7159 24.9021 41.3348 24.563 41.0307 24.169C40.5346 24.0976 40.0521 23.9313 39.6103 23.6737L37.262 22.3025C35.3898 21.209 34.7581 18.805 35.8516 16.9328L41.2705 7.6516C41.7952 6.75225 42.656 6.09843 43.6631 5.83434C44.6702 5.56953 45.7409 5.71585 46.6402 6.24119L50.5082 8.49956C53.0499 9.9835 54.0128 11.9371 54.3725 13.3147C54.8579 15.1726 54.5574 17.1676 53.526 18.9335C52.8501 20.0912 51.9057 21.0455 50.7965 21.6929C50.7823 21.7015 50.768 21.7093 50.753 21.7179V23.341C50.753 24.7472 50.0014 26.0455 48.7823 26.7457C48.177 27.0933 47.5018 27.2668 46.8273 27.2668V27.2661Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.7247 38.0462C18.0281 38.0462 17.3286 37.8613 16.7033 37.4852L10.7455 33.9049C9.65268 33.2482 8.94605 32.1012 8.85254 30.8293C8.75832 29.5573 9.28794 28.3189 10.2729 27.5088L11.834 26.224C12.1909 25.9299 12.5884 25.7079 13.0067 25.558C13.2337 25.1762 13.5285 24.8286 13.8854 24.5352L14.8882 23.7094C15.2451 23.4153 15.6427 23.1933 16.0609 23.0434C16.2879 22.6616 16.5827 22.3147 16.9396 22.0206L18.5006 20.7358C19.4856 19.9257 20.8025 19.6437 22.0323 19.9806C23.2622 20.3175 24.2522 21.2304 24.6869 22.4296L27.0545 28.9649C27.6205 30.5273 27.1415 32.2768 25.8582 33.3332L24.5077 34.4445C24.173 34.72 23.8025 34.9327 23.4128 35.0812C23.1922 35.4352 22.9117 35.7579 22.577 36.0334L21.2194 37.1511C20.4999 37.7436 19.6141 38.0455 18.724 38.0455L18.7247 38.0462Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.3163 33.7206C23.6375 33.7206 22.9637 33.5443 22.3642 33.201C21.4605 32.6835 20.8003 31.8277 20.5276 30.8227L18.8124 24.5001C18.245 22.4081 19.4805 20.251 21.5733 19.6836L26.6083 18.3174C27.6133 18.0448 28.6853 18.1825 29.5883 18.7C30.4912 19.2175 31.1521 20.0733 31.4248 21.0783L31.8267 22.5587C31.9551 23.0319 31.9915 23.508 31.9458 23.9691L32.1364 24.6721C32.4041 25.0476 32.6061 25.4701 32.7289 25.9234L33.1307 27.4037C33.4034 28.4087 33.2656 29.4808 32.7481 30.3837C32.2306 31.2874 31.3748 31.9476 30.3706 32.2203L25.3456 33.5836C25.0073 33.6757 24.6618 33.7206 24.3178 33.7206H24.3163Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.2561 32.8513C35.1241 32.8513 34.9906 32.8449 34.8564 32.8313L29.6759 32.3067C27.5188 32.0883 25.9471 30.1626 26.1655 28.0055L26.8251 21.4881C26.93 20.4524 27.4418 19.5002 28.2483 18.8421C29.0549 18.184 30.0899 17.8728 31.1263 17.9777L36.3168 18.5031C38.4738 18.7215 40.0455 20.6473 39.8271 22.8043L39.673 24.3303C39.6244 24.8071 39.4902 25.2661 39.2804 25.6872L39.2069 26.4145C39.3225 26.8606 39.3596 27.3282 39.3125 27.7957L39.1583 29.3217C38.9535 31.3446 37.2476 32.852 35.2576 32.852L35.2561 32.8513Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.5441 36.1433C40.8896 36.1433 40.2336 35.9798 39.6398 35.6501L37.6919 34.5694C37.3343 34.371 37.0145 34.1205 36.7404 33.8307C36.3529 33.7529 35.971 33.6151 35.607 33.4131L34.0096 32.5266C33.0988 32.0213 32.4264 31.1754 32.1402 30.1747C31.8532 29.174 31.976 28.0998 32.4814 27.1897L35.6598 21.4617C36.7119 19.5659 39.1016 18.8814 40.9974 19.9335L42.5948 20.82C42.9417 21.012 43.2479 21.249 43.5099 21.5202C43.8825 21.6009 44.2443 21.7358 44.5848 21.9249L46.5156 22.9963C47.8582 23.7415 48.6433 25.2004 48.5255 26.7315C48.4071 28.2625 47.4078 29.5837 45.9667 30.1141L45.2793 30.3667L45.4435 31.76C45.6155 33.2232 44.9545 34.6594 43.7311 35.4795C43.0716 35.9213 42.3093 36.1447 41.5441 36.1447V36.1433Z"
        fill="#C8C9CA"
      />
      <path
        d="M29.5398 46.3359C25.5284 46.3359 22.0009 43.3966 21.3871 39.3117C21.0594 37.1326 21.6005 34.9563 22.9095 33.184C24.2186 31.4117 26.1401 30.2554 28.3185 29.9277C32.8167 29.2511 37.0265 32.361 37.7025 36.8592C38.0301 39.0383 37.4891 41.2146 36.18 42.9869C34.8709 44.7592 32.9495 45.9155 30.771 46.2431C30.3578 46.3052 29.9459 46.3352 29.5398 46.3352V46.3359ZM29.5448 37.6879C29.5255 37.6879 29.5055 37.6893 29.4855 37.6922C29.2685 37.725 29.1186 37.9277 29.1515 38.1447C29.1843 38.3617 29.387 38.5123 29.604 38.4787C29.821 38.4459 29.9709 38.2432 29.9381 38.0262C29.9081 37.8292 29.7382 37.6872 29.5448 37.6872V37.6879Z"
        fill="#C8C9CA"
      />
      <path
        d="M9.58328 30.5202C7.20499 30.5202 4.91379 29.5801 3.09581 27.8492C1.1829 26.0284 0.109385 23.6294 0.0722684 21.0941C0.0365798 18.6273 0.977332 16.2583 2.72322 14.4253C4.84598 12.1955 7.82669 11.047 10.6989 11.3532C12.6218 11.5581 14.1086 13.1327 14.2035 15.0634L14.2464 15.9299L15.0622 15.8928C17.0472 15.8022 18.7867 17.2104 19.1121 19.1712C19.6011 22.1126 18.6004 25.2261 16.4362 27.4995C14.6803 29.3446 12.3756 30.4145 9.94731 30.513C9.82525 30.518 9.7032 30.5202 9.58186 30.5202H9.58328Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.0949 24.9478C16.6866 24.9478 15.3254 24.1883 14.6216 22.8571L13.3847 20.5195L7.54674 16.4946C6.41684 15.7158 5.77587 14.4032 5.85581 13.0328C5.93575 11.6623 6.7259 10.4339 7.93931 9.79223L10.7673 8.29616C11.369 7.97782 12.0285 7.82864 12.6816 7.84149C13.0385 7.29331 13.5339 6.83293 14.1349 6.51459L16.95 5.02494C18.1662 4.38183 19.6302 4.42181 20.8093 5.13058C21.9885 5.83936 22.7101 7.11416 22.7115 8.49031L22.7201 15.5916L23.9521 17.9207C24.9656 19.8371 24.234 22.2126 22.3175 23.2261L19.9264 24.4909C19.3411 24.8007 18.713 24.947 18.0942 24.947L18.0949 24.9478Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.2165 22.6451C21.4257 22.6451 20.6476 22.406 19.9874 21.9506C19.1302 21.3596 18.5434 20.4517 18.355 19.4281L16.415 8.85786C16.0238 6.72511 17.435 4.67944 19.5677 4.28758L24.517 3.37895C26.4635 3.02135 28.3635 3.37181 29.8653 4.36538C31.325 5.3304 32.3421 6.86715 32.6569 8.58163C32.7589 9.13695 32.7846 9.69441 32.7397 10.2397C33.4235 11.1112 33.8903 12.1598 34.1023 13.3139C34.4599 15.2618 34.0259 17.1912 32.8803 18.7458C31.7782 20.2425 30.0866 21.2654 28.118 21.6265L22.926 22.5794C22.6905 22.623 22.4535 22.6437 22.2172 22.6437L22.2165 22.6451Z"
        fill="#C8C9CA"
      />
      <path
        d="M38.7611 22.5616C38.5363 22.5616 38.3079 22.5423 38.0787 22.5016L30.4435 21.1626C29.4178 20.9827 28.5056 20.4031 27.9082 19.5509C27.3101 18.6986 27.0752 17.6437 27.2551 16.618L29.1116 6.03202C29.4863 3.89642 31.5213 2.46816 33.6562 2.8436L41.3057 4.18478C43.4413 4.55951 44.8689 6.59376 44.4941 8.72937L44.0594 11.2076C43.9552 11.8007 43.7232 12.3396 43.3963 12.8L42.9852 15.144C43.128 15.6794 43.1601 16.2532 43.058 16.8343L42.6233 19.3125C42.2893 21.219 40.6319 22.5608 38.7611 22.5608V22.5616Z"
        fill="#C8C9CA"
      />
      <path
        d="M46.8988 27.1947C46.215 27.1947 45.5305 27.0163 44.9195 26.6594L42.2321 25.0905C41.7875 24.8307 41.4063 24.4917 41.1022 24.0977C40.6062 24.0263 40.1237 23.86 39.6818 23.6023L37.3335 22.2311C35.4613 21.1376 34.8296 18.7337 35.9231 16.8614L41.3421 7.58025C41.8667 6.6809 42.7275 6.02708 43.7346 5.76299C44.7418 5.49818 45.8124 5.6445 46.7118 6.16984L50.5797 8.42821C53.1215 9.91215 54.0843 11.8657 54.4441 13.2433C54.9294 15.1013 54.6289 17.0963 53.5975 18.8621C52.9216 20.0199 51.9773 20.9742 50.8681 21.6216C50.8538 21.6301 50.8395 21.638 50.8245 21.6466V23.2697C50.8245 24.6758 50.0729 25.9742 48.8538 26.6744C48.2485 27.022 47.5733 27.1954 46.8988 27.1954V27.1947Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.796 37.9748C18.0994 37.9748 17.3999 37.7899 16.7746 37.4138L10.8168 33.8335C9.72397 33.1768 9.01734 32.0298 8.92383 30.7578C8.82961 29.4859 9.35923 28.2475 10.3442 27.4374L11.9053 26.1526C12.2621 25.8585 12.6597 25.6365 13.078 25.4866C13.305 25.1048 13.5998 24.7572 13.9566 24.4638L14.9595 23.638C15.3164 23.3439 15.7139 23.1219 16.1322 22.972C16.3592 22.5901 16.654 22.2433 17.0109 21.9492L18.5719 20.6644C19.5569 19.8543 20.8738 19.5723 22.1036 19.9092C23.3335 20.2461 24.3235 21.159 24.7582 22.3582L27.1257 28.8935C27.6918 30.4559 27.2128 32.2054 25.9295 33.2618L24.579 34.3731C24.2442 34.6486 23.8738 34.8613 23.4841 35.0098C23.2635 35.3638 22.983 35.6864 22.6482 35.962L21.2907 37.0797C20.5712 37.6722 19.6854 37.9741 18.7953 37.9741L18.796 37.9748Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.3879 33.6493C23.7091 33.6493 23.0353 33.473 22.4357 33.1297C21.5321 32.6122 20.8718 31.7564 20.5992 30.7514L18.884 24.4288C18.3165 22.3368 19.5521 20.1797 21.6448 19.6123L26.6798 18.2461C27.6848 17.9735 28.7569 18.1112 29.6598 18.6287C30.5634 19.1462 31.2237 20.002 31.4963 21.007L31.8982 22.4874C32.0267 22.9606 32.0631 23.4367 32.0174 23.8978L32.208 24.6008C32.4756 24.9763 32.6776 25.3988 32.8004 25.8521L33.2022 27.3325C33.4749 28.3374 33.3371 29.4095 32.8197 30.3125C32.3022 31.2161 31.4464 31.8763 30.4421 32.149L25.4171 33.5123C25.0788 33.6044 24.7333 33.6493 24.3893 33.6493H24.3879Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.3277 32.78C35.1956 32.78 35.0621 32.7735 34.928 32.76L29.7474 32.2354C27.5904 32.0169 26.0187 30.0912 26.2371 27.9342L26.8966 21.4167C27.0015 20.381 27.5133 19.4288 28.3199 18.7708C29.1264 18.1127 30.1614 17.8022 31.1978 17.9064L36.3883 18.4317C38.5454 18.6501 40.1171 20.5759 39.8987 22.7329L39.7445 24.2589C39.696 24.7357 39.5618 25.1947 39.3519 25.6158L39.2784 26.3432C39.394 26.7893 39.4311 27.2568 39.384 27.7243L39.2299 29.2504C39.025 31.2732 37.3191 32.7807 35.3291 32.7807L35.3277 32.78Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.6152 36.0719C40.9607 36.0719 40.3047 35.9084 39.7108 35.5787L37.7629 34.498C37.4053 34.2996 37.0856 34.049 36.8115 33.7593C36.4239 33.6815 36.042 33.5437 35.678 33.3417L34.0806 32.4552C33.1698 31.9498 32.4975 31.104 32.2112 30.1033C31.9243 29.1026 32.0471 28.0284 32.5524 27.1183L35.7308 21.3903C36.7829 19.4945 39.1719 18.81 41.0684 19.8621L42.6659 20.7486C43.0127 20.9406 43.319 21.1776 43.5809 21.4488C43.9535 21.5295 44.3154 21.6644 44.6558 21.8535L46.5866 22.9249C47.9292 23.6701 48.7144 25.129 48.5966 26.6601C48.4781 28.1911 47.4788 29.5123 46.0377 30.0426L45.3503 30.2953L45.5145 31.6886C45.6865 33.1518 45.0256 34.5879 43.8022 35.4081C43.1427 35.8499 42.3803 36.0733 41.6152 36.0733V36.0719Z"
        fill="#C8C9CA"
      />
      <path
        d="M29.6111 46.2645C25.5997 46.2645 22.0722 43.3252 21.4584 39.2403C21.1307 37.0611 21.6718 34.8849 22.9808 33.1126C24.2899 31.3403 26.2114 30.184 28.3898 29.8563C32.888 29.1797 37.0978 32.2896 37.7738 36.7878C38.1014 38.9669 37.5603 41.1432 36.2513 42.9155C34.9422 44.6878 33.0207 45.8441 30.8423 46.1717C30.429 46.2338 30.0172 46.2638 29.6111 46.2638V46.2645ZM29.6161 37.6165C29.5968 37.6165 29.5768 37.6179 29.5568 37.6207C29.3398 37.6536 29.1899 37.8563 29.2228 38.0733C29.2556 38.2903 29.459 38.4409 29.6753 38.4073C29.8923 38.3745 30.0422 38.1718 30.0093 37.9548C29.9794 37.7578 29.8095 37.6157 29.6161 37.6157V37.6165Z"
        fill="#C8C9CA"
      />
      <path
        d="M9.65459 30.4488C7.2763 30.4488 4.98509 29.5088 3.16712 27.7779C1.25492 25.9578 0.181406 23.5588 0.14429 21.0235C0.108601 18.5567 1.04935 16.1877 2.79524 14.3547C4.91729 12.1249 7.89943 10.9764 10.7709 11.2826C12.6938 11.4875 14.1806 13.062 14.2756 14.9928L14.3184 15.8593L15.1342 15.8222C17.1192 15.7337 18.8587 17.1398 19.1842 19.1006C19.6731 22.042 18.6724 25.1555 16.5082 27.4289C14.7524 29.274 12.4476 30.3439 10.0193 30.4424C9.89727 30.4474 9.77522 30.4495 9.65388 30.4495L9.65459 30.4488Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.1664 24.8763C16.7581 24.8763 15.397 24.1169 14.6932 22.7857L13.4562 20.4481L7.61827 16.4231C6.48837 15.6444 5.8474 14.3318 5.92734 12.9613C6.00729 11.5909 6.79743 10.3625 8.01084 9.72082L10.8388 8.22475C11.4405 7.90641 12.1 7.75794 12.7531 7.77008C13.11 7.2219 13.6054 6.76152 14.2064 6.44318L17.0215 4.95353C18.2378 4.31042 19.7017 4.35039 20.8809 5.05917C22.06 5.76795 22.7816 7.04274 22.7831 8.4189L22.7916 15.5202L24.0236 17.8493C25.0372 19.7657 24.3056 22.1412 22.3891 23.1547L19.9979 24.4195C19.4126 24.7293 18.7845 24.8756 18.1657 24.8756L18.1664 24.8763Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.2876 22.5737C21.4967 22.5737 20.7187 22.3346 20.0584 21.8792C19.2012 21.2882 18.6145 20.3803 18.4261 19.3567L16.486 8.78645C16.0949 6.6537 17.506 4.60803 19.6388 4.21617L24.588 3.30754C26.5345 2.94994 28.4346 3.3004 29.9363 4.29397C31.396 5.25899 32.4131 6.79574 32.7279 8.51022C32.83 9.06554 32.8557 9.62299 32.8107 10.1683C33.4945 11.0398 33.9613 12.0884 34.1733 13.2425C34.5309 15.1904 34.0969 17.1197 32.9513 18.6743C31.8493 20.1711 30.1576 21.194 28.189 21.5551L22.997 22.508C22.7615 22.5515 22.5245 22.5722 22.2883 22.5722L22.2876 22.5737Z"
        fill="#C8C9CA"
      />
      <path
        d="M38.8324 22.4902C38.6076 22.4902 38.3791 22.4709 38.15 22.4302L30.5148 21.0912C29.4891 20.9113 28.5769 20.3317 27.9795 19.4795C27.3813 18.6272 27.1465 17.5723 27.3264 16.5466L29.1829 5.96061C29.5576 3.82501 31.5926 2.39675 33.7275 2.77219L41.377 4.11337C43.5126 4.4881 44.9401 6.52235 44.5654 8.65796L44.1307 11.1362C44.0265 11.7293 43.7945 12.2682 43.4676 12.7286L43.0565 15.0726C43.1992 15.608 43.2314 16.1818 43.1293 16.7628L42.6946 19.2411C42.3606 21.1475 40.7032 22.4894 38.8324 22.4894V22.4902Z"
        fill="#C8C9CA"
      />
      <path
        d="M46.9703 27.1233C46.2865 27.1233 45.602 26.9449 44.991 26.588L42.3037 25.0191C41.859 24.7593 41.4778 24.4202 41.1738 24.0262C40.6777 23.9549 40.1952 23.7886 39.7534 23.5309L37.4051 22.1597C35.5328 21.0662 34.9011 18.6622 35.9946 16.79L41.4136 7.50884C41.9382 6.60949 42.799 5.95567 43.8062 5.69158C44.8133 5.42677 45.884 5.57309 46.7833 6.09843L50.6512 8.3568C53.193 9.84074 54.1559 11.7943 54.5156 13.1719C55.001 15.0299 54.7005 17.0249 53.6691 18.7907C52.9931 19.9485 52.0488 20.9028 50.9396 21.5502C50.9253 21.5587 50.9111 21.5666 50.8961 21.5752V23.1983C50.8961 24.6044 50.1445 25.9028 48.9253 26.603C48.3201 26.9506 47.6448 27.124 46.9703 27.124V27.1233Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.8673 37.9034C18.1707 37.9034 17.4712 37.7185 16.8459 37.3424L10.888 33.7621C9.79526 33.1054 9.08863 31.9584 8.99512 30.6864C8.9009 29.4145 9.43052 28.1761 10.4155 27.366L11.9765 26.0812C12.3334 25.7871 12.731 25.5651 13.1493 25.4152C13.3763 25.0334 13.671 24.6857 14.0279 24.3924L15.0308 23.5666C15.3877 23.2725 15.7852 23.0505 16.2035 22.9006C16.4305 22.5187 16.7253 22.1718 17.0822 21.8778L18.6432 20.593C19.6275 19.7828 20.9451 19.5009 22.1749 19.8378C23.4048 20.1747 24.3948 21.0876 24.8294 22.2868L27.197 28.8221C27.7631 30.3845 27.2841 32.134 26.0007 33.1903L24.6503 34.3017C24.3155 34.5772 23.9451 34.7899 23.5554 34.9384C23.3348 35.2924 23.0543 35.615 22.7195 35.8905L21.3619 37.0083C20.6425 37.6007 19.7567 37.9027 18.8666 37.9027L18.8673 37.9034Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.4589 33.578C23.7801 33.578 23.1063 33.4017 22.5067 33.0584C21.6031 32.5409 20.9429 31.6851 20.6702 30.6801L18.955 24.3575C18.3876 22.2654 19.6231 20.1084 21.7159 19.5409L26.7508 18.1748C27.7558 17.9021 28.8279 18.0399 29.7308 18.5574C30.6345 19.0748 31.2947 19.9307 31.5674 20.9357L31.9692 22.416C32.0977 22.8892 32.1341 23.3653 32.0884 23.8264L32.279 24.5295C32.5467 24.9049 32.7487 25.3275 32.8714 25.7807L33.2733 27.2611C33.546 28.2661 33.4082 29.3382 32.8907 30.2411C32.3732 31.1447 31.5174 31.805 30.5131 32.0776L25.4882 33.4409C25.1498 33.533 24.8044 33.578 24.4603 33.578H24.4589Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.399 32.7086C35.2669 32.7086 35.1334 32.7022 34.9992 32.6886L29.8187 32.164C27.6617 31.9456 26.0899 30.0198 26.3084 27.8628L26.9679 21.3453C27.0728 20.3096 27.5846 19.3575 28.3911 18.6994C29.1977 18.0413 30.2327 17.7301 31.2691 17.835L36.4596 18.3603C38.6166 18.5787 40.1884 20.5045 39.97 22.6615L39.8158 24.1876C39.7672 24.6644 39.6331 25.1233 39.4232 25.5444L39.3497 26.2718C39.4653 26.7179 39.5024 27.1854 39.4553 27.6529L39.3012 29.179C39.0963 31.2018 37.3904 32.7093 35.4004 32.7093L35.399 32.7086Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.6867 36.0005C41.0322 36.0005 40.3762 35.837 39.7824 35.5072L37.8345 34.4266C37.4769 34.2282 37.1571 33.9776 36.883 33.6878C36.4954 33.61 36.1136 33.4723 35.7496 33.2703L34.1521 32.3838C33.2414 31.8784 32.569 31.0326 32.2828 30.0319C31.9958 29.0312 32.1186 27.957 32.6239 27.0469L35.8024 21.3189C36.8545 19.4231 39.2442 18.7386 41.14 19.7907L42.7374 20.6772C43.0843 20.8692 43.3905 21.1062 43.6524 21.3774C44.025 21.4581 44.3869 21.593 44.7274 21.7821L46.6581 22.8535C48.0007 23.5987 48.7859 25.0576 48.6681 26.5887C48.5496 28.1197 47.5504 29.4409 46.1092 29.9712L45.4219 30.2239L45.586 31.6172C45.7581 33.0804 45.0971 34.5165 43.8737 35.3367C43.2142 35.7785 42.4519 36.0019 41.6867 36.0019V36.0005Z"
        fill="#C8C9CA"
      />
      <path
        d="M29.6821 46.1932C25.6707 46.1932 22.1432 43.2539 21.5294 39.1689C21.2018 36.9898 21.7428 34.8135 23.0519 33.0412C24.3609 31.2689 26.2824 30.1126 28.4608 29.785C32.959 29.1083 37.1689 32.2182 37.8448 36.7164C38.1724 38.8956 37.6314 41.0719 36.3223 42.8442C35.0133 44.6165 33.0918 45.7728 30.9134 46.1004C30.5001 46.1625 30.0882 46.1925 29.6821 46.1925V46.1932ZM29.6871 37.5451C29.6678 37.5451 29.6478 37.5465 29.6279 37.5494C29.4109 37.5822 29.261 37.7849 29.2938 38.0019C29.3266 38.2189 29.5301 38.3695 29.7463 38.336C29.9633 38.3031 30.1132 38.1004 30.0804 37.8834C30.0504 37.6864 29.8805 37.5444 29.6871 37.5444V37.5451Z"
        fill="#C8C9CA"
      />
      <path
        d="M9.72637 30.3774C7.34808 30.3774 5.05687 29.4374 3.23889 27.7065C1.3267 25.8864 0.253183 23.4874 0.216067 20.9521C0.180379 18.4853 1.12113 16.1162 2.86702 14.2833C4.98978 12.0535 7.97049 10.9057 10.8427 11.2112C12.7656 11.4161 14.2524 12.9906 14.3473 14.9214L14.3902 15.7879L15.206 15.7508C17.1917 15.6623 18.9305 17.0684 19.2559 19.0291C19.7449 21.9706 18.7442 25.0841 16.58 27.3574C14.8241 29.2025 12.5194 30.2725 10.0911 30.371C9.96905 30.376 9.847 30.3781 9.72566 30.3781L9.72637 30.3774Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.2377 24.805C16.8294 24.805 15.4683 24.0455 14.7645 22.7144L13.5275 20.3768L7.68956 16.3518C6.55966 15.5731 5.91869 14.2604 5.99863 12.89C6.07857 11.5196 6.86872 10.2911 8.08213 9.64947L10.9101 8.1534C11.5118 7.83506 12.1713 7.68588 12.8244 7.69873C13.1813 7.15055 13.6767 6.69017 14.2777 6.37182L17.0928 4.88218C18.3091 4.23907 19.773 4.27904 20.9522 4.98782C22.1313 5.6966 22.8529 6.97139 22.8544 8.34755L22.8629 15.4489L24.0949 17.7779C25.1085 19.6944 24.3768 22.0698 22.4604 23.0834L20.0692 24.3482C19.4839 24.658 18.8558 24.8043 18.237 24.8043L18.2377 24.805Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.3593 22.5023C21.5685 22.5023 20.7905 22.2632 20.1302 21.8078C19.273 21.2168 18.6863 20.3089 18.4978 19.2854L16.5578 8.7151C16.1666 6.58235 17.5778 4.53668 19.7105 4.14482L24.6598 3.23619C26.6063 2.87859 28.5063 3.22905 30.0081 4.22262C31.4678 5.18764 32.4849 6.72439 32.7997 8.43887C32.9018 8.99419 32.9275 9.55164 32.8825 10.097C33.5663 10.9685 34.0331 12.017 34.2451 13.1712C34.6027 15.1191 34.1687 17.0484 33.0231 18.603C31.921 20.0998 30.2294 21.1226 28.2608 21.4838L23.0688 22.4367C22.8333 22.4802 22.5963 22.5009 22.3601 22.5009L22.3593 22.5023Z"
        fill="#C8C9CA"
      />
      <path
        d="M38.9037 22.4189C38.6788 22.4189 38.4504 22.3996 38.2213 22.3589L30.5861 21.0199C29.5604 20.84 28.6482 20.2604 28.0508 19.4082C27.4526 18.5559 27.2178 17.501 27.3977 16.4753L29.2542 5.88932C29.6289 3.75372 31.6639 2.32546 33.7988 2.7009L41.4483 4.04208C43.5839 4.41681 45.0114 6.45106 44.6367 8.58667L44.202 11.0649C44.0978 11.658 43.8658 12.1969 43.5389 12.6573L43.1278 15.0013C43.2705 15.5367 43.3027 16.1105 43.2006 16.6916L42.7659 19.1698C42.4319 21.0763 40.7745 22.4181 38.9037 22.4181V22.4189Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.0414 27.0519C46.3576 27.0519 45.6731 26.8735 45.0621 26.5166L42.3747 24.9477C41.93 24.6879 41.5489 24.3488 41.2448 23.9548C40.7488 23.8835 40.2662 23.7171 39.8244 23.4595L37.4761 22.0883C35.6039 20.9948 34.9722 18.5908 36.0657 16.7186L41.4846 7.43743C42.0093 6.53808 42.8701 5.88426 43.8772 5.62017C44.8843 5.35536 45.955 5.50168 46.8544 6.02702L50.7223 8.28539C53.264 9.76932 54.2269 11.7229 54.5867 13.1005C55.072 14.9584 54.7715 16.9534 53.7401 18.7193C53.0642 19.8771 52.1199 20.8314 51.0107 21.4788C50.9964 21.4873 50.9821 21.4952 50.9671 21.5037V23.1269C50.9671 24.533 50.2155 25.8313 48.9964 26.5316C48.3911 26.8792 47.7159 27.0526 47.0414 27.0526V27.0519Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.9386 37.8321C18.242 37.8321 17.5425 37.6472 16.9172 37.2711L10.9593 33.6908C9.86655 33.0341 9.15991 31.8871 9.06641 30.6151C8.97219 29.3432 9.50181 28.1048 10.4868 27.2947L12.0478 26.0099C12.4047 25.7158 12.8023 25.4938 13.2206 25.3439C13.4475 24.9621 13.7423 24.6145 14.0992 24.3211L15.1021 23.4953C15.459 23.2012 15.8565 22.9792 16.2748 22.8293C16.5018 22.4474 16.7966 22.1006 17.1535 21.8065L18.7145 20.5217C19.6995 19.7116 21.0164 19.4296 22.2462 19.7665C23.476 20.1034 24.466 21.0163 24.9007 22.2155L27.2683 28.7508C27.8343 30.3132 27.3554 32.0627 26.072 33.1191L24.7216 34.2304C24.3868 34.5059 24.0164 34.7186 23.6267 34.8671C23.4061 35.2211 23.1256 35.5437 22.7908 35.8193L21.4332 36.937C20.7137 37.5295 19.828 37.8314 18.9379 37.8314L18.9386 37.8321Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.5304 33.5066C23.8517 33.5066 23.1779 33.3303 22.5783 32.987C21.6746 32.4695 21.0144 31.6137 20.7417 30.6087L19.0265 24.2861C18.4591 22.194 19.6946 20.037 21.7874 19.4695L26.8224 18.1034C27.8274 17.8307 28.8994 17.9685 29.8024 18.486C30.706 19.0034 31.3662 19.8593 31.6389 20.8642L32.0408 22.3446C32.1692 22.8178 32.2056 23.2939 32.16 23.755L32.3505 24.4581C32.6182 24.8335 32.8202 25.2561 32.943 25.7093L33.3448 27.1897C33.6175 28.1947 33.4797 29.2668 32.9622 30.1697C32.4448 31.0733 31.5889 31.7336 30.5847 32.0062L25.5597 33.3695C25.2214 33.4616 24.8759 33.5066 24.5319 33.5066H24.5304Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.4705 32.6372C35.3384 32.6372 35.205 32.6308 35.0708 32.6172L29.8902 32.0926C27.7332 31.8742 26.1615 29.9484 26.3799 27.7914L27.0394 21.2739C27.1443 20.2383 27.6561 19.2861 28.4627 18.628C29.2692 17.9699 30.3042 17.6594 31.3406 17.7636L36.5312 18.2889C38.6882 18.5074 40.2599 20.4331 40.0415 22.5901L39.8873 24.1162C39.8388 24.593 39.7046 25.0519 39.4947 25.4731L39.4212 26.2004C39.5369 26.6465 39.574 27.114 39.5269 27.5816L39.3727 29.1076C39.1678 31.1304 37.4619 32.6379 35.4719 32.6379L35.4705 32.6372Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.7582 35.9292C41.1037 35.9292 40.4478 35.7657 39.8539 35.436L37.906 34.3553C37.5484 34.1569 37.2286 33.9063 36.9546 33.6166C36.567 33.5388 36.1851 33.401 35.8211 33.199L34.2237 32.3125C33.3129 31.8071 32.6405 30.9613 32.3543 29.9606C32.0674 28.9599 32.1901 27.8857 32.6955 26.9756L35.8739 21.2476C36.926 19.3518 39.3157 18.6673 41.2115 19.7194L42.8089 20.6059C43.1558 20.7979 43.462 21.0349 43.724 21.3061C44.0966 21.3868 44.4584 21.5217 44.7989 21.7108L46.7297 22.7822C48.0723 23.5274 48.8574 24.9863 48.7397 26.5174C48.6212 28.0484 47.6219 29.3696 46.1808 29.8999L45.4934 30.1526L45.6576 31.5459C45.8296 33.0091 45.1686 34.4452 43.9452 35.2654C43.2857 35.7072 42.5234 35.9306 41.7582 35.9306V35.9292Z"
        fill="#C8C9CA"
      />
      <path
        d="M29.7539 46.1218C25.7425 46.1218 22.215 43.1825 21.6012 39.0975C21.2736 36.9184 21.8146 34.7421 23.1236 32.9698C24.4327 31.1975 26.3542 30.0412 28.5326 29.7136C33.0308 29.0369 37.2406 32.1468 37.9166 36.645C38.2442 38.8242 37.7032 41.0004 36.3941 42.7727C35.085 44.545 33.1636 45.7014 30.9851 46.029C30.5719 46.0911 30.16 46.121 29.7539 46.121V46.1218ZM29.7589 37.4737C29.7396 37.4737 29.7196 37.4751 29.6996 37.478C29.4826 37.5108 29.3328 37.7135 29.3656 37.9305C29.3984 38.1475 29.6018 38.2981 29.8181 38.2646C30.0351 38.2317 30.185 38.029 30.1522 37.812C30.1222 37.615 29.9523 37.473 29.7589 37.473V37.4737Z"
        fill="#C8C9CA"
      />
      <path
        d="M9.79766 30.306C7.41937 30.306 5.12816 29.366 3.31018 27.6351C1.39799 25.815 0.324472 23.416 0.287356 20.8806C0.251668 18.4138 1.19242 16.0448 2.93831 14.2119C5.06035 11.982 8.04249 10.8336 10.914 11.1398C12.8369 11.3446 14.3237 12.9192 14.4186 14.85L14.4614 15.7165L15.2773 15.6794C17.263 15.5902 19.0018 16.997 19.3272 18.9577C19.8162 21.8992 18.8155 25.0127 16.6513 27.286C14.8954 29.1311 12.5906 30.2011 10.1624 30.2996C10.0403 30.3046 9.91829 30.3067 9.79694 30.3067L9.79766 30.306Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.3095 24.7336C16.9012 24.7336 15.54 23.9741 14.8362 22.6429L13.5993 20.3053L7.76134 16.2804C6.63143 15.5017 5.99047 14.189 6.07041 12.8186C6.15035 11.4481 6.9405 10.2197 8.15391 9.57806L10.9819 8.08199C11.5836 7.76365 12.2431 7.61518 12.8962 7.62732C13.2531 7.07914 13.7485 6.61876 14.3495 6.30041L17.1646 4.81077C18.3808 4.16766 19.8448 4.20763 21.0239 4.91641C22.2031 5.62519 22.9247 6.89998 22.9261 8.27614L22.9347 15.3775L24.1667 17.7065C25.1802 19.623 24.4486 21.9984 22.5321 23.012L20.141 24.2768C19.5557 24.5865 18.9276 24.7329 18.3088 24.7329L18.3095 24.7336Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.4306 22.431C21.6398 22.431 20.8618 22.1919 20.2015 21.7365C19.3443 21.1455 18.7576 20.2376 18.5691 19.214L16.6291 8.64375C16.2379 6.511 17.6491 4.46533 19.7818 4.07347L24.7311 3.16484C26.6776 2.80724 28.5776 3.1577 30.0794 4.15127C31.5391 5.11629 32.5562 6.65304 32.871 8.36752C32.973 8.92284 32.9987 9.48029 32.9538 10.0256C33.6376 10.8971 34.1044 11.9457 34.3164 13.0998C34.674 15.0477 34.24 16.977 33.0944 18.5316C31.9923 20.0284 30.3007 21.0513 28.3321 21.4124L23.1401 22.3653C22.9046 22.4088 22.6676 22.4295 22.4313 22.4295L22.4306 22.431Z"
        fill="#C8C9CA"
      />
      <path
        d="M38.975 22.3475C38.7501 22.3475 38.5217 22.3282 38.2926 22.2875L30.6574 20.9485C29.6317 20.7686 28.7195 20.189 28.1221 19.3368C27.5239 18.4845 27.2891 17.4296 27.469 16.4039L29.3255 5.81791C29.7002 3.6823 31.7352 2.25405 33.8701 2.62949L41.5196 3.97067C43.6552 4.3454 45.0827 6.37965 44.708 8.51526L44.2733 10.9935C44.1691 11.5866 43.9371 12.1255 43.6102 12.5859L43.1991 14.9299C43.3418 15.4653 43.3739 16.0391 43.2719 16.6201L42.8372 19.0984C42.5031 21.0048 40.8458 22.3467 38.975 22.3467V22.3475Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.1129 26.9805C46.4291 26.9805 45.7446 26.8021 45.1336 26.4452L42.4463 24.8763C42.0016 24.6165 41.6204 24.2775 41.3164 23.8835C40.8203 23.8121 40.3378 23.6458 39.8959 23.3881L37.5476 22.017C35.6754 20.9235 35.0437 18.5195 36.1372 16.6473L41.5562 7.36608C42.0808 6.46673 42.9416 5.81291 43.9487 5.54882C44.9559 5.28401 46.0265 5.43033 46.9259 5.95567L50.7938 8.21404C53.3356 9.69797 54.2984 11.6516 54.6582 13.0291C55.1436 14.8871 54.8431 16.8821 53.8117 18.648C53.1357 19.8057 52.1914 20.76 51.0822 21.4074C51.0679 21.416 51.0536 21.4238 51.0386 21.4324V23.0555C51.0386 24.4616 50.287 25.76 49.0679 26.4602C48.4626 26.8078 47.7874 26.9813 47.1129 26.9813V26.9805Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.0101 37.7607C18.3135 37.7607 17.614 37.5758 16.9887 37.1997L11.0309 33.6194C9.93808 32.9627 9.23145 31.8157 9.13794 30.5437C9.04373 29.2718 9.57334 28.0334 10.5583 27.2233L12.1194 25.9385C12.4763 25.6444 12.8738 25.4224 13.2921 25.2725C13.5191 24.8907 13.8139 24.543 14.1708 24.2497L15.1736 23.4239C15.5305 23.1298 15.9281 22.9078 16.3463 22.7579C16.5733 22.376 16.8681 22.0291 17.225 21.7351L18.786 20.4503C19.771 19.6401 21.0879 19.3582 22.3177 19.6951C23.5476 20.032 24.5376 20.9449 24.9723 22.1441L27.3399 28.6794C27.9059 30.2418 27.4269 31.9913 26.1436 33.0476L24.7931 34.159C24.4584 34.4345 24.0879 34.6472 23.6982 34.7957C23.4776 35.1497 23.1971 35.4723 22.8624 35.7478L21.5048 36.8656C20.7853 37.458 19.8995 37.76 19.0094 37.76L19.0101 37.7607Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.6017 33.4352C23.9229 33.4352 23.2491 33.2589 22.6496 32.9156C21.7459 32.3981 21.0857 31.5423 20.813 30.5373L19.0978 24.2147C18.5304 22.1227 19.7659 19.9656 21.8587 19.3982L26.8937 18.032C27.8987 17.7594 28.9707 17.8971 29.8737 18.4146C30.7773 18.9321 31.4375 19.7879 31.7102 20.7929L32.1121 22.2733C32.2405 22.7465 32.2769 23.2226 32.2312 23.6837L32.4218 24.3867C32.6895 24.7622 32.8915 25.1847 33.0143 25.638L33.4161 27.1183C33.6888 28.1233 33.551 29.1954 33.0335 30.0983C32.516 31.002 31.6602 31.6622 30.656 31.9349L25.631 33.2982C25.2927 33.3903 24.9472 33.4352 24.6032 33.4352H24.6017Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.5418 32.5659C35.4097 32.5659 35.2763 32.5595 35.1421 32.5459L29.9615 32.0213C27.8045 31.8029 26.2328 29.8771 26.4512 27.7201L27.1107 21.2026C27.2156 20.1669 27.7274 19.2148 28.534 18.5567C29.3405 17.8986 30.3755 17.5874 31.4119 17.6923L36.6024 18.2176C38.7595 18.436 40.3312 20.3618 40.1128 22.5188L39.9586 24.0449C39.9101 24.5217 39.7759 24.9806 39.566 25.4017L39.4925 26.1291C39.6081 26.5752 39.6453 27.0427 39.5982 27.5102L39.444 29.0363C39.2391 31.0591 37.5332 32.5666 35.5432 32.5666L35.5418 32.5659Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.8295 35.8578C41.175 35.8578 40.519 35.6943 39.9252 35.3645L37.9773 34.2839C37.6197 34.0855 37.2999 33.8349 37.0258 33.5451C36.6383 33.4673 36.2564 33.3296 35.8924 33.1276L34.295 32.2411C33.3842 31.7357 32.7118 30.8899 32.4256 29.8892C32.1386 28.8885 32.2614 27.8143 32.7668 26.9042L35.9452 21.1762C36.9973 19.2804 39.387 18.5959 41.2828 19.648L42.8802 20.5345C43.2271 20.7265 43.5333 20.9635 43.7953 21.2347C44.1679 21.3154 44.5297 21.4503 44.8702 21.6394L46.801 22.7108C48.1436 23.456 48.9287 24.9149 48.8109 26.446C48.6925 27.977 47.6932 29.2982 46.2521 29.8285L45.5647 30.0812L45.7289 31.4745C45.9009 32.9377 45.2399 34.3738 44.0165 35.194C43.357 35.6358 42.5947 35.8592 41.8295 35.8592V35.8578Z"
        fill="#C8C9CA"
      />
      <path
        d="M29.8254 46.0504C25.814 46.0504 22.2865 43.1111 21.6727 39.0262C21.3451 36.847 21.8861 34.6707 23.1952 32.8984C24.5042 31.1262 26.4257 29.9698 28.6041 29.6422C33.1023 28.9656 37.3122 32.0755 37.9881 36.5737C38.3157 38.7528 37.7747 40.9291 36.4656 42.7014C35.1566 44.4737 33.2351 45.63 31.0567 45.9576C30.6434 46.0197 30.2315 46.0497 29.8254 46.0497V46.0504ZM29.8304 37.4024C29.8111 37.4024 29.7912 37.4038 29.7712 37.4066C29.5542 37.4395 29.4043 37.6422 29.4371 37.8592C29.47 38.0762 29.6727 38.226 29.8897 38.1932C30.1066 38.1604 30.2565 37.9577 30.2237 37.7407C30.1937 37.5437 30.0238 37.4016 29.8304 37.4016V37.4024Z"
        fill="#C8C9CA"
      />
      <path
        d="M9.86895 30.2346C7.49066 30.2346 5.19945 29.2946 3.38147 27.5637C1.46927 25.7435 0.395762 23.3446 0.358645 20.8092C0.322957 18.3424 1.26371 15.9734 3.0096 14.1405C5.13235 11.9106 8.11307 10.7622 10.9853 11.0684C12.9082 11.2732 14.395 12.8478 14.4899 14.7786L14.5327 15.6451L15.3486 15.608C17.3343 15.5187 19.073 16.9256 19.3985 18.8863C19.8875 21.8278 18.8867 24.9413 16.7226 27.2146C14.9667 29.0597 12.6619 30.1297 10.2337 30.2282C10.1116 30.2332 9.98957 30.2353 9.86823 30.2353L9.86895 30.2346Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.3805 24.6622C16.9722 24.6622 15.6111 23.9028 14.9073 22.5716L13.6703 20.234L7.83238 16.209C6.70248 15.4303 6.06151 14.1177 6.14145 12.7472C6.2214 11.3768 7.01154 10.1484 8.22496 9.50671L11.0529 8.01064C11.6546 7.6923 12.3142 7.54312 12.9673 7.55597C13.3241 7.00779 13.8195 6.54741 14.4205 6.22906L17.2356 4.73942C18.4519 4.09631 19.9158 4.13628 21.095 4.84506C22.2741 5.55384 22.9958 6.82863 22.9972 8.20479L23.0058 15.3061L24.2377 17.6351C25.2513 19.5516 24.5197 21.9271 22.6032 22.9406L20.212 24.2054C19.6268 24.5152 18.9986 24.6615 18.3798 24.6615L18.3805 24.6622Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.5022 22.3596C21.7113 22.3596 20.9333 22.1205 20.273 21.6651C19.4158 21.0741 18.8291 20.1661 18.6407 19.1426L16.7006 8.57234C16.3095 6.43959 17.7206 4.39392 19.8534 4.00206L24.8026 3.09343C26.7498 2.73583 28.6492 3.08629 30.1509 4.07986C31.6106 5.04488 32.6277 6.58163 32.9425 8.29611C33.0446 8.85143 33.0703 9.40888 33.0253 9.9542C33.7091 10.8257 34.1759 11.8743 34.3879 13.0284C34.7455 14.9763 34.3115 16.9056 33.1659 18.4602C32.0639 19.957 30.3722 20.9798 28.4036 21.341L23.2116 22.2939C22.9761 22.3374 22.7391 22.3581 22.5029 22.3581L22.5022 22.3596Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.0463 22.276C38.8214 22.276 38.593 22.2568 38.3639 22.2161L30.7287 20.877C29.703 20.6972 28.7908 20.1176 28.1933 19.2653C27.5952 18.4131 27.3604 17.3581 27.5402 16.3325L29.3968 5.7465C29.7715 3.61089 31.8065 2.18264 33.9414 2.55808L41.5909 3.89926C43.7265 4.27399 45.154 6.30824 44.7793 8.44385L44.3446 10.9221C44.2404 11.5152 44.0084 12.0541 43.6815 12.5145L43.2704 14.8585C43.4131 15.3938 43.4452 15.9677 43.3432 16.5487L42.9085 19.0269C42.5744 20.9334 40.9171 22.2753 39.0463 22.2753V22.276Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.1839 26.9092C46.5001 26.9092 45.8156 26.7308 45.2047 26.3739L42.5173 24.805C42.0726 24.5452 41.6915 24.2061 41.3874 23.8121C40.8913 23.7408 40.4088 23.5744 39.967 23.3168L37.6187 21.9456C35.7465 20.8521 35.1148 18.4481 36.2083 16.5759L41.6272 7.29473C42.1518 6.39538 43.0127 5.74156 44.0198 5.47747C45.0269 5.21266 46.0976 5.35898 46.9969 5.88432L50.8649 8.14269C53.4066 9.62662 54.3695 11.5802 54.7292 12.9578C55.2146 14.8157 54.9141 16.8107 53.8827 18.5766C53.2068 19.7344 52.2624 20.6887 51.1532 21.3361C51.139 21.3446 51.1247 21.3525 51.1097 21.361V22.9842C51.1097 24.3903 50.3581 25.6886 49.139 26.3889C48.5337 26.7365 47.8585 26.9099 47.1839 26.9099V26.9092Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.0817 37.6893C18.385 37.6893 17.6855 37.5044 17.0603 37.1282L11.1024 33.548C10.0096 32.8913 9.30298 31.7443 9.20948 30.4723C9.11526 29.2004 9.64488 27.962 10.6299 27.1519L12.1909 25.8671C12.5478 25.573 12.9454 25.351 13.3636 25.2011C13.5906 24.8192 13.8854 24.4716 14.2423 24.1783L15.2451 23.3524C15.602 23.0584 15.9996 22.8364 16.4179 22.6865C16.6448 22.3046 16.9396 21.9577 17.2965 21.6637L18.8575 20.3789C19.8425 19.5687 21.1595 19.2868 22.3893 19.6237C23.6191 19.9606 24.6091 20.8735 25.0438 22.0726L27.4114 28.6079C27.9774 30.1704 27.4985 31.9199 26.2151 32.9762L24.8646 34.0876C24.5299 34.3631 24.1594 34.5758 23.7697 34.7243C23.5492 35.0783 23.2686 35.4009 22.9339 35.6764L21.5763 36.7942C20.8568 37.3866 19.971 37.6886 19.0809 37.6886L19.0817 37.6893Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.6735 33.3638C23.9947 33.3638 23.3209 33.1875 22.7213 32.8442C21.8177 32.3267 21.1575 31.4709 20.8848 30.4659L19.1696 24.1433C18.6022 22.0512 19.8377 19.8942 21.9305 19.3268L26.9654 17.9606C27.9704 17.688 29.0425 17.8257 29.9454 18.3432C30.8491 18.8607 31.5093 19.7165 31.782 20.7215L32.1838 22.2018C32.3123 22.6751 32.3487 23.1512 32.303 23.6123L32.4936 24.3153C32.7613 24.6908 32.9633 25.1133 33.086 25.5666L33.4879 27.0469C33.7606 28.0519 33.6228 29.124 33.1053 30.0269C32.5878 30.9306 31.732 31.5908 30.7277 31.8635L25.7028 33.2268C25.3644 33.3188 25.019 33.3638 24.6749 33.3638H24.6735Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.6133 32.4945C35.4813 32.4945 35.3478 32.488 35.2136 32.4745L30.033 31.9499C27.876 31.7314 26.3043 29.8057 26.5227 27.6487L27.1822 21.1312C27.2872 20.0955 27.7989 19.1434 28.6055 18.4853C29.4121 17.8272 30.447 17.5159 31.4834 17.6209L36.674 18.1462C38.831 18.3646 40.4027 20.2904 40.1843 22.4474L40.0301 23.9734C39.9816 24.4502 39.8474 24.9092 39.6376 25.3303L39.564 26.0577C39.6797 26.5038 39.7168 26.9713 39.6697 27.4388L39.5155 28.9649C39.3107 30.9877 37.6047 32.4952 35.6147 32.4952L35.6133 32.4945Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.9008 35.7864C41.2463 35.7864 40.5903 35.6229 39.9965 35.2931L38.0486 34.2125C37.691 34.0141 37.3712 33.7635 37.0971 33.4737C36.7096 33.3959 36.3277 33.2582 35.9637 33.0562L34.3662 32.1697C33.4555 31.6643 32.7831 30.8185 32.4969 29.8178C32.2099 28.8171 32.3327 27.7429 32.8381 26.8328L36.0165 21.1048C37.0686 19.209 39.4583 18.5245 41.3541 19.5766L42.9515 20.4631C43.2984 20.6551 43.6046 20.8921 43.8666 21.1633C44.2391 21.244 44.601 21.3789 44.9415 21.568L46.8722 22.6394C48.2149 23.3846 49 24.8435 48.8822 26.3746C48.7637 27.9056 47.7645 29.2268 46.3234 29.7571L45.636 30.0098L45.8002 31.4031C45.9722 32.8663 45.3112 34.3024 44.0878 35.1225C43.4283 35.5644 42.666 35.7878 41.9008 35.7878V35.7864Z"
        fill="#C8C9CA"
      />
      <path
        d="M29.8967 45.979C25.8853 45.979 22.3578 43.0397 21.744 38.9548C21.4164 36.7756 21.9574 34.5994 23.2665 32.8271C24.5755 31.0548 26.497 29.8985 28.6754 29.5708C33.1736 28.8949 37.3835 32.0041 38.0594 36.5023C38.387 38.6814 37.846 40.8577 36.5369 42.63C35.2279 44.4023 33.3064 45.5586 31.128 45.8862C30.7147 45.9483 30.3028 45.9783 29.8967 45.9783V45.979ZM29.9017 37.331C29.8824 37.331 29.8624 37.3324 29.8425 37.3352C29.6255 37.3681 29.4756 37.5708 29.5084 37.7878C29.5412 38.0048 29.7447 38.1547 29.9609 38.1218C30.1779 38.089 30.3278 37.8863 30.295 37.6693C30.265 37.4723 30.0951 37.3302 29.9017 37.3302V37.331Z"
        fill="#C8C9CA"
      />
      <path
        d="M9.94024 30.1632C7.56195 30.1632 5.27074 29.2232 3.45276 27.4923C1.54056 25.6722 0.467051 23.2732 0.429934 20.7379C0.394246 18.2711 1.335 15.9021 3.08088 14.0691C5.20293 11.8393 8.18507 10.6908 11.0566 10.997C12.9795 11.2019 14.4663 12.7765 14.5612 14.7072L14.604 15.5737L15.4199 15.5366C17.4056 15.4474 19.1443 16.8542 19.4698 18.815C19.9587 21.7564 18.958 24.8699 16.7939 27.1433C15.038 28.9884 12.7332 30.0583 10.305 30.1568C10.1829 30.1618 10.0609 30.164 9.93952 30.164L9.94024 30.1632Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.452 24.5908C17.0438 24.5908 15.6826 23.8314 14.9788 22.5002L13.7419 20.1626L7.90391 16.1376C6.77401 15.3589 6.13305 14.0463 6.21299 12.6758C6.29293 11.3054 7.08308 10.077 8.29649 9.4353L11.1245 7.93923C11.7262 7.62089 12.3857 7.47242 13.0388 7.48456C13.3957 6.93638 13.891 6.476 14.492 6.15765L17.3072 4.66801C18.5234 4.0249 19.9874 4.06487 21.1665 4.77365C22.3457 5.48242 23.0673 6.75722 23.0687 8.13337L23.0773 15.2347L24.3093 17.5637C25.3228 19.4802 24.5912 21.8556 22.6747 22.8692L20.2836 24.134C19.6983 24.4438 19.0702 24.5901 18.4513 24.5901L18.452 24.5908Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.5732 22.2882C21.7823 22.2882 21.0043 22.049 20.3441 21.5937C19.4869 21.0027 18.9001 20.0947 18.7117 19.0712L16.7717 8.50093C16.3805 6.36818 17.7916 4.32251 19.9244 3.93065L24.8737 3.02201C26.8202 2.66441 28.7202 3.01488 30.222 4.00845C31.6817 4.97347 32.6988 6.51022 33.0136 8.2247C33.1156 8.78002 33.1413 9.33747 33.0963 9.88279C33.7801 10.7543 34.247 11.8028 34.4589 12.957C34.8165 14.9049 34.3826 16.8342 33.237 18.3888C32.1349 19.8856 30.4433 20.9084 28.4747 21.2696L23.2827 22.2225C23.0471 22.266 22.8102 22.2867 22.5739 22.2867L22.5732 22.2882Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.118 22.2047C38.8932 22.2047 38.6648 22.1854 38.4357 22.1447L30.8004 20.8057C29.7748 20.6258 28.8626 20.0462 28.2651 19.194C27.667 18.3418 27.4322 17.2868 27.612 16.2611L29.4685 5.67515C29.8433 3.53954 31.8782 2.11129 34.0131 2.48673L41.6626 3.82791C43.7982 4.20264 45.2258 6.23689 44.8511 8.3725L44.4164 10.8507C44.3122 11.4439 44.0802 11.9828 43.7533 12.4431L43.3421 14.7872C43.4849 15.3225 43.517 15.8964 43.4149 16.4774L42.9803 18.9556C42.6462 20.8621 40.9888 22.204 39.118 22.204V22.2047Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.2555 26.8378C46.5717 26.8378 45.8872 26.6593 45.2762 26.3025L42.5888 24.7336C42.1442 24.4738 41.763 24.1347 41.4589 23.7407C40.9629 23.6693 40.4804 23.503 40.0385 23.2454L37.6902 21.8742C35.818 20.7807 35.1863 18.3767 36.2798 16.5045L41.6988 7.22332C42.2234 6.32397 43.0842 5.67015 44.0913 5.40605C45.0985 5.14124 46.1691 5.28757 47.0685 5.8129L50.9364 8.07128C53.4781 9.55521 54.441 11.5088 54.8008 12.8864C55.2861 14.7443 54.9856 16.7393 53.9542 18.5052C53.2783 19.6629 52.334 20.6173 51.2248 21.2646C51.2105 21.2732 51.1962 21.2811 51.1812 21.2896V22.9127C51.1812 24.3189 50.4296 25.6172 49.2105 26.3174C48.6052 26.6651 47.93 26.8385 47.2555 26.8385V26.8378Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.1529 37.6179C18.4563 37.6179 17.7568 37.433 17.1315 37.0568L11.1737 33.4766C10.0809 32.8199 9.37427 31.6729 9.28077 30.4009C9.18655 29.129 9.71617 27.8906 10.7012 27.0804L12.2622 25.7957C12.6191 25.5016 13.0166 25.2796 13.4349 25.1297C13.6619 24.7478 13.9567 24.4002 14.3136 24.1069L15.3164 23.281C15.6733 22.987 16.0709 22.765 16.4892 22.6151C16.7161 22.2332 17.0109 21.8863 17.3678 21.5922L18.9288 20.3075C19.9138 19.4973 21.2307 19.2154 22.4606 19.5523C23.6904 19.8892 24.6804 20.8021 25.1151 22.0012L27.4827 28.5365C28.0487 30.099 27.5698 31.8484 26.2864 32.9048L24.9359 34.0162C24.6012 34.2917 24.2307 34.5044 23.841 34.6529C23.6205 35.0069 23.3399 35.3295 23.0052 35.605L21.6476 36.7228C20.9281 37.3152 20.0423 37.6172 19.1522 37.6172L19.1529 37.6179Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.7446 33.2925C24.0658 33.2925 23.392 33.1162 22.7924 32.7728C21.8888 32.2554 21.2285 31.3995 20.9559 30.3945L19.2407 24.072C18.6732 21.9799 19.9088 19.8229 22.0015 19.2554L27.0365 17.8893C28.0415 17.6166 29.1136 17.7544 30.0165 18.2718C30.9201 18.7893 31.5804 19.6451 31.853 20.6501L32.2549 22.1305C32.3834 22.6037 32.4198 23.0798 32.3741 23.5409L32.5647 24.244C32.8323 24.6194 33.0343 25.042 33.1571 25.4952L33.5589 26.9756C33.8316 27.9806 33.6938 29.0527 33.1764 29.9556C32.6589 30.8592 31.8031 31.5195 30.7988 31.7921L25.7738 33.1554C25.4355 33.2475 25.09 33.2925 24.746 33.2925H24.7446Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.6846 32.4231C35.5526 32.4231 35.4191 32.4166 35.2849 32.4031L30.1043 31.8784C27.9473 31.66 26.3756 29.7343 26.594 27.5773L27.2535 21.0598C27.3585 20.0241 27.8702 19.0719 28.6768 18.4138C29.4834 17.7557 30.5183 17.4445 31.5547 17.5495L36.7453 18.0748C38.9023 18.2932 40.474 20.219 40.2556 22.376L40.1014 23.902C40.0529 24.3788 39.9187 24.8378 39.7089 25.2589L39.6353 25.9863C39.751 26.4324 39.7881 26.8999 39.741 27.3674L39.5868 28.8934C39.3819 30.9163 37.676 32.4238 35.686 32.4238L35.6846 32.4231Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.9724 35.7149C41.3178 35.7149 40.6619 35.5515 40.068 35.2217L38.1201 34.1411C37.7625 33.9426 37.4428 33.6921 37.1687 33.4023C36.7811 33.3245 36.3992 33.1868 36.0352 32.9848L34.4378 32.0983C33.527 31.5929 32.8546 30.7471 32.5684 29.7464C32.2815 28.7457 32.4042 27.6714 32.9096 26.7614L36.088 21.0334C37.1401 19.1376 39.5298 18.4531 41.4256 19.5052L43.023 20.3917C43.3699 20.5837 43.6761 20.8207 43.9381 21.0919C44.3107 21.1725 44.6726 21.3074 45.013 21.4966L46.9438 22.568C48.2864 23.3131 49.0715 24.7721 48.9538 26.3031C48.8353 27.8342 47.836 29.1554 46.3949 29.6857L45.7075 29.9384L45.8717 31.3317C46.0437 32.7949 45.3828 34.231 44.1594 35.0511C43.4998 35.493 42.7375 35.7164 41.9724 35.7164V35.7149Z"
        fill="#C8C9CA"
      />
      <path
        d="M29.9677 45.9076C25.9563 45.9076 22.4289 42.9683 21.815 38.8834C21.4874 36.7042 22.0285 34.5279 23.3375 32.7556C24.6466 30.9834 26.568 29.827 28.7465 29.4994C33.2447 28.8235 37.4545 31.9327 38.1304 36.4309C38.4581 38.61 37.917 40.7863 36.608 42.5586C35.2989 44.3309 33.3774 45.4872 31.199 45.8148C30.7857 45.8769 30.3739 45.9069 29.9677 45.9069V45.9076ZM29.9727 37.2596C29.9535 37.2596 29.9335 37.261 29.9135 37.2638C29.6965 37.2967 29.5466 37.4994 29.5795 37.7164C29.6123 37.9333 29.8157 38.0832 30.032 38.0504C30.249 38.0176 30.3989 37.8149 30.366 37.5979C30.3361 37.4009 30.1662 37.2588 29.9727 37.2588V37.2596Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.0115 30.0919C7.63324 30.0919 5.34203 29.1519 3.52405 27.421C1.61185 25.6008 0.53834 23.2018 0.501223 20.6665C0.465535 18.1997 1.40629 15.8307 3.15217 13.9978C5.27493 11.7679 8.25564 10.6202 11.1279 10.9257C13.0508 11.1305 14.5376 12.7051 14.6325 14.6359L14.6753 15.5024L15.4912 15.4653C17.4769 15.376 19.2156 16.7829 19.5411 18.7436C20.03 21.6851 19.0293 24.7986 16.8652 27.0719C15.1093 28.917 12.8045 29.987 10.3763 30.0855C10.2542 30.0905 10.1322 30.0926 10.0108 30.0926L10.0115 30.0919Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.5233 24.5195C17.1151 24.5195 15.7539 23.76 15.0501 22.4288L13.8131 20.0912L7.9752 16.0663C6.8453 15.2875 6.20433 13.9749 6.28428 12.6045C6.36422 11.234 7.15437 10.0056 8.36778 9.36395L11.1957 7.86788C11.7975 7.54954 12.457 7.40036 13.1101 7.41321C13.467 6.86503 13.9623 6.40464 14.5633 6.0863L17.3784 4.59666C18.5947 3.95355 20.0587 3.99352 21.2378 4.7023C22.417 5.41107 23.1386 6.68587 23.14 8.06202L23.1486 15.1633L24.3805 17.4924C25.3941 19.4089 24.6625 21.7843 22.746 22.7979L20.3549 24.0627C19.7696 24.3724 19.1415 24.5188 18.5226 24.5188L18.5233 24.5195Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.6445 22.2168C21.8536 22.2168 21.0756 21.9777 20.4154 21.5223C19.5581 20.9313 18.9714 20.0234 18.783 18.9998L16.843 8.42958C16.4518 6.29683 17.8629 4.25116 19.9957 3.8593L24.945 2.95066C26.8914 2.59306 28.7915 2.94353 30.2933 3.9371C31.7529 4.90212 32.7701 6.43887 33.0848 8.15335C33.1869 8.70867 33.2126 9.26612 33.1676 9.81144C33.8514 10.683 34.3182 11.7315 34.5302 12.8857C34.8878 14.8335 34.4539 16.7629 33.3083 18.3175C32.2062 19.8142 30.5145 20.8371 28.546 21.1983L23.354 22.1511C23.1184 22.1947 22.8815 22.2154 22.6452 22.2154L22.6445 22.2168Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.1893 22.1333C38.9645 22.1333 38.7361 22.114 38.507 22.0733L30.8717 20.7343C29.846 20.5544 28.9338 19.9748 28.3364 19.1226C27.7383 18.2703 27.5034 17.2154 27.6833 16.1897L29.5398 5.60374C29.9146 3.46813 31.9495 2.03987 34.0844 2.41532L41.7339 3.7565C43.8695 4.13123 45.2971 6.16548 44.9223 8.30108L44.4877 10.7793C44.3834 11.3724 44.1515 11.9113 43.8246 12.3717L43.4134 14.7158C43.5562 15.2511 43.5883 15.825 43.4862 16.406L43.0515 18.8842C42.7175 20.7907 41.0601 22.1326 39.1893 22.1326V22.1333Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.327 26.7664C46.6432 26.7664 45.9587 26.588 45.3477 26.2311L42.6604 24.6622C42.2157 24.4024 41.8345 24.0634 41.5305 23.6694C41.0344 23.598 40.5519 23.4317 40.1101 23.174L37.7617 21.8029C35.8895 20.7094 35.2578 18.3054 36.3513 16.4332L41.7703 7.15197C42.2949 6.25262 43.1557 5.5988 44.1629 5.3347C45.17 5.06989 46.2406 5.21622 47.14 5.74155L51.0079 7.99993C53.5497 9.48386 54.5126 11.4375 54.8723 12.815C55.3577 14.673 55.0572 16.668 54.0258 18.4339C53.3498 19.5916 52.4055 20.5459 51.2963 21.1933C51.282 21.2019 51.2677 21.2097 51.2528 21.2183V22.8414C51.2528 24.2475 50.5012 25.5459 49.282 26.2461C48.6768 26.5937 48.0015 26.7671 47.327 26.7671V26.7664Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.2242 37.5466C18.5276 37.5466 17.8281 37.3617 17.2028 36.9855L11.245 33.4053C10.1522 32.7486 9.44556 31.6016 9.35205 30.3296C9.25784 29.0577 9.78746 27.8193 10.7725 27.0092L12.3335 25.7244C12.6904 25.4303 13.0879 25.2083 13.5062 25.0584C13.7332 24.6765 14.028 24.3289 14.3849 24.0356L15.3877 23.2097C15.7446 22.9157 16.1422 22.6937 16.5604 22.5438C16.7874 22.1619 17.0822 21.815 17.4391 21.521L19.0001 20.2362C19.9851 19.426 21.302 19.1441 22.5319 19.481C23.7617 19.8179 24.7517 20.7308 25.1864 21.9299L27.554 28.4652C28.12 30.0277 27.641 31.7772 26.3577 32.8335L25.0072 33.9449C24.6725 34.2204 24.302 34.4331 23.9123 34.5816C23.6917 34.9356 23.4112 35.2582 23.0765 35.5337L21.7189 36.6515C20.9994 37.2439 20.1136 37.5459 19.2235 37.5459L19.2242 37.5466Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.8161 33.2211C24.1373 33.2211 23.4635 33.0448 22.8639 32.7015C21.9603 32.184 21.3001 31.3282 21.0274 30.3232L19.3122 24.0006C18.7447 21.9085 19.9803 19.7515 22.0731 19.1841L27.108 17.8179C28.113 17.5452 29.1851 17.683 30.088 18.2005C30.9917 18.718 31.6519 19.5738 31.9246 20.5788L32.3264 22.0591C32.4549 22.5324 32.4913 23.0085 32.4456 23.4696L32.6362 24.1726C32.9038 24.5481 33.1058 24.9706 33.2286 25.4239L33.6305 26.9042C33.9031 27.9092 33.7654 28.9813 33.2479 29.8842C32.7304 30.7879 31.8746 31.4481 30.8703 31.7208L25.8454 33.0841C25.507 33.1761 25.1616 33.2211 24.8175 33.2211H24.8161Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.7559 32.3518C35.6238 32.3518 35.4904 32.3453 35.3562 32.3318L30.1756 31.8072C28.0186 31.5887 26.4469 29.663 26.6653 27.506L27.3248 20.9885C27.4297 19.9528 27.9415 19.0007 28.7481 18.3426C29.5546 17.6845 30.5896 17.3732 31.626 17.4782L36.8166 18.0035C38.9736 18.2219 40.5453 20.1477 40.3269 22.3047L40.1727 23.8307C40.1242 24.3075 39.99 24.7665 39.7801 25.1876L39.7066 25.915C39.8223 26.3611 39.8594 26.8286 39.8123 27.2961L39.6581 28.8222C39.4532 30.845 37.7473 32.3525 35.7573 32.3525L35.7559 32.3518Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.0436 35.6437C41.3891 35.6437 40.7332 35.4802 40.1393 35.1504L38.1914 34.0698C37.8338 33.8714 37.514 33.6208 37.24 33.331C36.8524 33.2532 36.4705 33.1155 36.1065 32.9135L34.5091 32.027C33.5983 31.5216 32.9259 30.6758 32.6397 29.6751C32.3528 28.6744 32.4755 27.6002 32.9809 26.6901L36.1593 20.9621C37.2114 19.0663 39.6011 18.3818 41.4969 19.4339L43.0943 20.3204C43.4412 20.5124 43.7474 20.7494 44.0094 21.0206C44.382 21.1013 44.7438 21.2362 45.0843 21.4253L47.0151 22.4967C48.3577 23.2419 49.1428 24.7008 49.0251 26.2319C48.9066 27.7629 47.9073 29.0841 46.4662 29.6144L45.7788 29.8671L45.943 31.2604C46.115 32.7236 45.454 34.1597 44.2306 34.9798C43.5711 35.4217 42.8088 35.6451 42.0436 35.6451V35.6437Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.0393 45.8362C26.0279 45.8362 22.5004 42.8969 21.8866 38.812C21.559 36.6329 22.1 34.4566 23.409 32.6843C24.7181 30.912 26.6396 29.7557 28.818 29.428C33.3162 28.7514 37.526 31.8613 38.202 36.3595C38.5296 38.5386 37.9886 40.7149 36.6795 42.4872C35.3704 44.2595 33.449 45.4158 31.2705 45.7435C30.8573 45.8055 30.4454 45.8355 30.0393 45.8355V45.8362ZM30.0443 37.1882C30.025 37.1882 30.005 37.1896 29.985 37.1925C29.768 37.2253 29.6182 37.428 29.651 37.645C29.6838 37.862 29.8872 38.0119 30.1035 37.979C30.3205 37.9462 30.4704 37.7435 30.4376 37.5265C30.4076 37.3295 30.2377 37.1875 30.0443 37.1875V37.1882Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.0831 30.0205C7.70477 30.0205 5.41356 29.0804 3.59558 27.3495C1.68339 25.5294 0.609873 23.1304 0.572757 20.5951C0.537068 18.1283 1.47782 15.7593 3.22371 13.9263C5.34575 11.6965 8.32789 10.5481 11.1994 10.8543C13.1223 11.0591 14.6091 12.6337 14.704 14.5645L14.7468 15.431L15.5627 15.3939C17.5477 15.3046 19.2872 16.7115 19.6126 18.6722C20.1016 21.6137 19.1009 24.7271 16.9367 27.0005C15.1808 28.8456 12.876 29.9156 10.4478 30.0141C10.3257 30.0191 10.2037 30.0212 10.0823 30.0212L10.0831 30.0205Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.5946 24.4481C17.1863 24.4481 15.8252 23.6887 15.1214 22.3575L13.8844 20.0199L8.04649 15.9949C6.91659 15.2162 6.27562 13.9036 6.35557 12.5331C6.43551 11.1627 7.22565 9.93428 8.43907 9.2926L11.267 7.79653C11.8687 7.47819 12.5283 7.32972 13.1814 7.34186C13.5383 6.79368 14.0336 6.33329 14.6346 6.01495L17.4497 4.52531C18.666 3.8822 20.1299 3.92217 21.3091 4.63095C22.4882 5.33972 23.2099 6.61452 23.2113 7.99067L23.2199 15.092L24.4518 17.421C25.4654 19.3375 24.7338 21.7129 22.8173 22.7265L20.4262 23.9913C19.8409 24.3011 19.2127 24.4474 18.5939 24.4474L18.5946 24.4481Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.716 22.1455C21.9252 22.1455 21.1472 21.9064 20.4869 21.451C19.6297 20.86 19.043 19.9521 18.8545 18.9285L16.9145 8.35829C16.5233 6.22554 17.9345 4.17987 20.0672 3.78801L25.0165 2.87938C26.963 2.52178 28.863 2.87224 30.3648 3.86581C31.8245 4.83083 32.8416 6.36758 33.1564 8.08206C33.2584 8.63738 33.2841 9.19483 33.2392 9.74015C33.923 10.6117 34.3898 11.6602 34.6018 12.8144C34.9594 14.7623 34.5254 16.6916 33.3798 18.2462C32.2777 19.743 30.5861 20.7658 28.6175 21.127L23.4255 22.0798C23.19 22.1234 22.953 22.1441 22.7167 22.1441L22.716 22.1455Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.2609 22.0619C39.036 22.0619 38.8076 22.0426 38.5785 22.0019L30.9433 20.6629C29.9176 20.483 29.0054 19.9034 28.4079 19.0512C27.8098 18.1989 27.575 17.144 27.7548 16.1183L29.6114 5.53233C29.9861 3.39672 32.0211 1.96846 34.156 2.34391L41.8055 3.68509C43.9411 4.05982 45.3686 6.09407 44.9939 8.22967L44.5592 10.7079C44.455 11.301 44.223 11.8399 43.8961 12.3003L43.485 14.6443C43.6277 15.1797 43.6598 15.7535 43.5578 16.3346L43.1231 18.8128C42.789 20.7193 41.1317 22.0612 39.2609 22.0612V22.0619Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.3985 26.695C46.7147 26.695 46.0302 26.5166 45.4193 26.1597L42.7319 24.5908C42.2872 24.331 41.9061 23.992 41.602 23.598C41.1059 23.5266 40.6234 23.3603 40.1816 23.1026L37.8333 21.7314C35.9611 20.6379 35.3294 18.234 36.4229 16.3617L41.8418 7.08056C42.3664 6.1812 43.2273 5.52739 44.2344 5.26329C45.2415 4.99848 46.3122 5.14481 47.2115 5.67014L51.0795 7.92852C53.6212 9.41245 54.5841 11.366 54.9438 12.7436C55.4292 14.6016 55.1287 16.5966 54.0973 18.3624C53.4214 19.5202 52.477 20.4745 51.3678 21.1219C51.3536 21.1305 51.3393 21.1383 51.3243 21.1469V22.77C51.3243 24.1761 50.5727 25.4745 49.3536 26.1747C48.7483 26.5223 48.0731 26.6957 47.3985 26.6957V26.695Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.2958 37.4752C18.5991 37.4752 17.8996 37.2903 17.2744 36.9141L11.3165 33.3339C10.2237 32.6772 9.51709 31.5302 9.42359 30.2582C9.32937 28.9863 9.85899 27.7479 10.844 26.9377L12.405 25.653C12.7619 25.3589 13.1595 25.1369 13.5777 24.987C13.8047 24.6051 14.0995 24.2575 14.4564 23.9642L15.4592 23.1383C15.8161 22.8443 16.2137 22.6223 16.632 22.4724C16.859 22.0905 17.1537 21.7436 17.5106 21.4495L19.0716 20.1648C20.0567 19.3546 21.3736 19.0727 22.6034 19.4096C23.8332 19.7465 24.8232 20.6594 25.2579 21.8585L27.6255 28.3938C28.1915 29.9563 27.7126 31.7057 26.4292 32.7621L25.0788 33.8735C24.744 34.149 24.3736 34.3617 23.9838 34.5102C23.7633 34.8642 23.4828 35.1868 23.148 35.4623L21.7904 36.5801C21.0709 37.1725 20.1851 37.4745 19.2951 37.4745L19.2958 37.4752Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.8871 33.1497C24.2083 33.1497 23.5345 32.9734 22.935 32.6301C22.0313 32.1126 21.3711 31.2568 21.0984 30.2518L19.3832 23.9292C18.8158 21.8371 20.0513 19.6801 22.1441 19.1127L27.1791 17.7465C28.1841 17.4738 29.2561 17.6116 30.1591 18.1291C31.0627 18.6466 31.7229 19.5024 31.9956 20.5074L32.3975 21.9877C32.5259 22.461 32.5623 22.9371 32.5167 23.3981L32.7072 24.1012C32.9749 24.4767 33.1769 24.8992 33.2997 25.3525L33.7015 26.8328C33.9742 27.8378 33.8364 28.9099 33.3189 29.8128C32.8014 30.7165 31.9456 31.3767 30.9414 31.6494L25.9164 33.0127C25.5781 33.1047 25.2326 33.1497 24.8886 33.1497H24.8871Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.8272 32.2803C35.6951 32.2803 35.5617 32.2739 35.4275 32.2603L30.2469 31.7357C28.0899 31.5173 26.5182 29.5915 26.7366 27.4345L27.3961 20.917C27.501 19.8814 28.0128 18.9292 28.8194 18.2711C29.6259 17.613 30.6609 17.3018 31.6973 17.4067L36.8878 17.932C39.0449 18.1505 40.6166 20.0762 40.3982 22.2332L40.244 23.7593C40.1955 24.2361 40.0613 24.695 39.8514 25.1162L39.7779 25.8435C39.8935 26.2896 39.9307 26.7571 39.8836 27.2246L39.7294 28.7507C39.5245 30.7735 37.8186 32.281 35.8286 32.281L35.8272 32.2803Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.1149 35.5722C41.4604 35.5722 40.8044 35.4088 40.2106 35.079L38.2627 33.9984C37.9051 33.7999 37.5853 33.5494 37.3112 33.2596C36.9237 33.1818 36.5418 33.0441 36.1778 32.8421L34.5804 31.9556C33.6696 31.4502 32.9972 30.6044 32.711 29.6037C32.424 28.603 32.5468 27.5287 33.0522 26.6187L36.2306 20.8907C37.2827 18.9949 39.6724 18.3104 41.5682 19.3625L43.1656 20.249C43.5125 20.441 43.8187 20.678 44.0807 20.9492C44.4533 21.0298 44.8151 21.1647 45.1556 21.3539L47.0864 22.4253C48.429 23.1704 49.2141 24.6294 49.0963 26.1604C48.9779 27.6915 47.9786 29.0127 46.5375 29.543L45.8501 29.7957L46.0143 31.189C46.1863 32.6522 45.5253 34.0883 44.3019 34.9084C43.6424 35.3503 42.8801 35.5737 42.1149 35.5737V35.5722Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.1108 45.7649C26.0994 45.7649 22.572 42.8256 21.9581 38.7407C21.6305 36.5615 22.1715 34.3852 23.4806 32.6129C24.7896 30.8406 26.7111 29.6843 28.8895 29.3567C33.3877 28.68 37.5976 31.7899 38.2735 36.2881C38.6011 38.4673 38.0601 40.6436 36.751 42.4159C35.442 44.1882 33.5205 45.3445 31.3421 45.6721C30.9288 45.7342 30.5169 45.7642 30.1108 45.7642V45.7649ZM30.1158 37.1168C30.0965 37.1168 30.0766 37.1183 30.0566 37.1211C29.8396 37.1539 29.6897 37.3567 29.7225 37.5736C29.7554 37.7906 29.9581 37.9412 30.1751 37.9077C30.392 37.8749 30.5419 37.6721 30.5091 37.4552C30.4791 37.2582 30.3092 37.1161 30.1158 37.1161V37.1168Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.1546 29.9491C7.7763 29.9491 5.48509 29.0091 3.66712 27.2782C1.75492 25.4581 0.681406 23.0591 0.64429 20.5238C0.608601 18.057 1.54935 15.688 3.29524 13.855C5.418 11.6252 8.39871 10.4767 11.2709 10.7829C13.1938 10.9878 14.6806 12.5624 14.7756 14.4931L14.8184 15.3596L15.6342 15.3225C17.6192 15.2326 19.3587 16.6401 19.6842 18.6009C20.1731 21.5423 19.1724 24.6558 17.0082 26.9292C15.2524 28.7743 12.9476 29.8442 10.5193 29.9427C10.3973 29.9477 10.2752 29.9498 10.1539 29.9498L10.1546 29.9491Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.6659 24.3767C17.2576 24.3767 15.8965 23.6173 15.1927 22.2861L13.9557 19.9485L8.11778 15.9235C6.98788 15.1448 6.34691 13.8322 6.42685 12.4617C6.5068 11.0913 7.29694 9.86287 8.51036 9.22118L11.3383 7.72512C11.94 7.40677 12.5996 7.2576 13.2527 7.27044C13.6095 6.72227 14.1049 6.26188 14.7059 5.94354L17.521 4.4539C18.7373 3.81079 20.2012 3.85076 21.3804 4.55954C22.5595 5.26831 23.2812 6.54311 23.2826 7.91926L23.2912 15.0206L24.5231 17.3496C25.5367 19.2661 24.8051 21.6415 22.8886 22.6551L20.4974 23.9199C19.9122 24.2297 19.284 24.376 18.6652 24.376L18.6659 24.3767Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.7873 22.0741C21.9965 22.0741 21.2184 21.835 20.5582 21.3796C19.701 20.7886 19.1142 19.8807 18.9258 18.8571L16.9858 8.28688C16.5946 6.15413 18.0058 4.10846 20.1385 3.7166L25.0878 2.80796C27.0343 2.45036 28.9343 2.80083 30.4361 3.7944C31.8958 4.75942 32.9129 6.29617 33.2277 8.01065C33.3297 8.56597 33.3554 9.12342 33.3105 9.66874C33.9943 10.5403 34.4611 11.5888 34.6731 12.743C35.0307 14.6908 34.5967 16.6202 33.4511 18.1748C32.349 19.6715 30.6574 20.6944 28.6888 21.0556L23.4968 22.0084C23.2613 22.052 23.0243 22.0727 22.788 22.0727L22.7873 22.0741Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.3319 21.9906C39.1071 21.9906 38.8787 21.9714 38.6495 21.9307L31.0143 20.5916C29.9886 20.4118 29.0764 19.8322 28.479 18.9799C27.8809 18.1277 27.646 17.0727 27.8259 16.0471L29.6824 5.4611C30.0571 3.32549 32.0921 1.89723 34.227 2.27268L41.8765 3.61386C44.0121 3.98859 45.4397 6.02284 45.0649 8.15845L44.6302 10.6367C44.526 11.2298 44.294 11.7687 43.9671 12.2291L43.556 14.5731C43.6988 15.1084 43.7309 15.6823 43.6288 16.2633L43.1941 18.7415C42.8601 20.648 41.2027 21.9899 39.3319 21.9899V21.9906Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.4696 26.6237C46.7858 26.6237 46.1013 26.4452 45.4903 26.0883L42.8029 24.5195C42.3583 24.2597 41.9771 23.9206 41.673 23.5266C41.177 23.4552 40.6945 23.2889 40.2526 23.0313L37.9043 21.6601C36.0321 20.5666 35.4004 18.1626 36.4939 16.2904L41.9129 7.00921C42.4375 6.10985 43.2983 5.45604 44.3054 5.19194C45.3126 4.92713 46.3832 5.07346 47.2826 5.59879L51.1505 7.85717C53.6923 9.3411 54.6551 11.2947 55.0149 12.6723C55.5002 14.5302 55.1997 16.5252 54.1683 18.2911C53.4924 19.4488 52.5481 20.4031 51.4389 21.0505C51.4246 21.0591 51.4103 21.067 51.3953 21.0755V22.6986C51.3953 24.1048 50.6437 25.4031 49.4246 26.1033C48.8193 26.4509 48.1441 26.6244 47.4696 26.6244V26.6237Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.3668 37.4038C18.6702 37.4038 17.9707 37.2189 17.3454 36.8427L11.3876 33.2624C10.2948 32.6058 9.58814 31.4587 9.49463 30.1868C9.40041 28.9149 9.93003 27.6765 10.915 26.8663L12.4761 25.5815C12.8329 25.2875 13.2305 25.0655 13.6488 24.9156C13.8758 24.5337 14.1706 24.1861 14.5274 23.8928L15.5303 23.0669C15.8872 22.7728 16.2847 22.5509 16.703 22.401C16.93 22.0191 17.2248 21.6722 17.5817 21.3781L19.1427 20.0933C20.127 19.2832 21.4446 19.0013 22.6744 19.3382C23.9043 19.6751 24.8943 20.588 25.329 21.7871L27.6965 28.3224C28.2626 29.8849 27.7836 31.6343 26.5003 32.6907L25.1498 33.8021C24.815 34.0776 24.4446 34.2903 24.0549 34.4387C23.8343 34.7928 23.5538 35.1154 23.219 35.3909L21.8615 36.5087C21.142 37.1011 20.2562 37.403 19.3661 37.403L19.3668 37.4038Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.9587 33.0783C24.2799 33.0783 23.6061 32.902 23.0065 32.5587C22.1029 32.0412 21.4426 31.1854 21.17 30.1804L19.4548 23.8578C18.8873 21.7657 20.1229 19.6087 22.2156 19.0412L27.2506 17.6751C28.2556 17.4024 29.3277 17.5402 30.2306 18.0577C31.1342 18.5752 31.7945 19.431 32.0671 20.436L32.469 21.9163C32.5975 22.3896 32.6339 22.8656 32.5882 23.3267L32.7788 24.0298C33.0464 24.4052 33.2484 24.8278 33.3712 25.281L33.773 26.7614C34.0457 27.7664 33.9079 28.8385 33.3905 29.7414C32.873 30.645 32.0172 31.3053 31.0129 31.5779L25.9879 32.9412C25.6496 33.0333 25.3041 33.0783 24.9601 33.0783H24.9587Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.899 32.2089C35.7669 32.2089 35.6334 32.2025 35.4992 32.189L30.3187 31.6643C28.1617 31.4459 26.5899 29.5202 26.8084 27.3631L27.4679 20.8457C27.5728 19.81 28.0846 18.8578 28.8911 18.1997C29.6977 17.5416 30.7327 17.2304 31.7691 17.3354L36.9596 17.8607C39.1166 18.0791 40.6884 20.0049 40.47 22.1619L40.3158 23.6879C40.2672 24.1647 40.1331 24.6237 39.9232 25.0448L39.8497 25.7721C39.9653 26.2182 40.0024 26.6858 39.9553 27.1533L39.8012 28.6793C39.5963 30.7022 37.8904 32.2097 35.9004 32.2097L35.899 32.2089Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.1865 35.5008C41.5319 35.5008 40.876 35.3374 40.2821 35.0076L38.3342 33.927C37.9766 33.7285 37.6569 33.478 37.3828 33.1882C36.9952 33.1104 36.6133 32.9726 36.2493 32.7707L34.6519 31.8841C33.7411 31.3788 33.0687 30.533 32.7825 29.5323C32.4956 28.5316 32.6183 27.4573 33.1237 26.5473L36.3021 20.8192C37.3542 18.9235 39.7439 18.239 41.6397 19.2911L43.2371 20.1776C43.584 20.3696 43.8902 20.6065 44.1522 20.8778C44.5248 20.9584 44.8867 21.0933 45.2271 21.2825L47.1579 22.3539C48.5005 23.099 49.2856 24.558 49.1679 26.089C49.0494 27.6201 48.0501 28.9413 46.609 29.4716L45.9216 29.7243L46.0858 31.1176C46.2578 32.5808 45.5969 34.0169 44.3735 34.837C43.7139 35.2788 42.9516 35.5023 42.1865 35.5023V35.5008Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.1821 45.6935C26.1707 45.6935 22.6432 42.7542 22.0294 38.6692C21.7018 36.4901 22.2428 34.3138 23.5519 32.5415C24.8609 30.7692 26.7824 29.6129 28.9608 29.2853C33.459 28.6086 37.6689 31.7185 38.3448 36.2167C38.6724 38.3959 38.1314 40.5722 36.8223 42.3445C35.5133 44.1168 33.5918 45.2731 31.4134 45.6007C31.0001 45.6628 30.5882 45.6928 30.1821 45.6928V45.6935ZM30.1871 37.0454C30.1678 37.0454 30.1478 37.0468 30.1279 37.0497C29.9109 37.0825 29.761 37.2852 29.7938 37.5022C29.8266 37.7192 30.0301 37.8698 30.2463 37.8363C30.4633 37.8034 30.6132 37.6007 30.5804 37.3837C30.5504 37.1867 30.3805 37.0447 30.1871 37.0447V37.0454Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.2259 29.8777C7.84759 29.8777 5.55638 28.9377 3.7384 27.2068C1.82621 25.3867 0.752695 22.9877 0.715579 20.4524C0.67989 17.9856 1.62064 15.6165 3.36653 13.7836C5.48857 11.5538 8.47071 10.4053 11.3422 10.7115C13.2651 10.9164 14.7519 12.4909 14.8468 14.4217L14.8897 15.2882L15.7055 15.2511C17.6905 15.1612 19.43 16.5687 19.7555 18.5295C20.2444 21.4709 19.2437 24.5844 17.0795 26.8577C15.3236 28.7029 13.0189 29.7728 10.5906 29.8713C10.4686 29.8763 10.3465 29.8784 10.2252 29.8784L10.2259 29.8777Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.7377 24.3053C17.3294 24.3053 15.9683 23.5458 15.2645 22.2147L14.0275 19.8771L8.18956 15.8521C7.05966 15.0734 6.41869 13.7607 6.49863 12.3903C6.57857 11.0199 7.36872 9.79145 8.58213 9.14977L11.4101 7.65371C12.0118 7.33536 12.6713 7.18761 13.3244 7.19903C13.6813 6.65086 14.1767 6.19047 14.7777 5.87213L17.5928 4.38249C18.8091 3.73938 20.273 3.77935 21.4522 4.48813C22.6313 5.1969 23.3529 6.4717 23.3544 7.84785L23.3629 14.9492L24.5949 17.2782C25.6085 19.1947 24.8768 21.5701 22.9604 22.5837L20.5692 23.8485C19.9839 24.1583 19.3558 24.3046 18.737 24.3046L18.7377 24.3053Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.8588 22.0027C22.068 22.0027 21.29 21.7636 20.6297 21.3082C19.7725 20.7172 19.1858 19.8093 18.9973 18.7857L17.0573 8.21547C16.6662 6.08272 18.0773 4.03705 20.21 3.64518L25.1593 2.73655C27.1058 2.37895 29.0059 2.72942 30.5076 3.72299C31.9673 4.68801 32.9844 6.22476 33.2992 7.93924C33.4013 8.49455 33.427 9.05201 33.382 9.59733C34.0658 10.4688 34.5326 11.5174 34.7446 12.6715C35.1022 14.6194 34.6682 16.5488 33.5226 18.1034C32.4205 19.6001 30.7289 20.623 28.7603 20.9841L23.5683 21.937C23.3328 21.9806 23.0958 22.0013 22.8596 22.0013L22.8588 22.0027Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.4034 21.9192C39.1786 21.9192 38.9502 21.9 38.7211 21.8593L31.0858 20.5202C30.0602 20.3404 29.148 19.7608 28.5505 18.9085C27.9524 18.0563 27.7176 17.0013 27.8974 15.9756L29.7539 5.38969C30.1287 3.25408 32.1636 1.82582 34.2985 2.20127L41.948 3.54245C44.0836 3.91718 45.5112 5.95143 45.1365 8.08703L44.7018 10.5653C44.5976 11.1584 44.3656 11.6973 44.0387 12.1577L43.6275 14.5017C43.7703 15.037 43.8024 15.6109 43.7003 16.1919L43.2657 18.6701C42.9316 20.5766 41.2742 21.9185 39.4034 21.9185V21.9192Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.5411 26.5523C46.8573 26.5523 46.1728 26.3738 45.5618 26.0169L42.8745 24.4481C42.4298 24.1882 42.0486 23.8492 41.7446 23.4552C41.2485 23.3838 40.766 23.2175 40.3242 22.9598L37.9759 21.5887C36.1036 20.4952 35.4719 18.0912 36.5654 16.219L41.9844 6.9378C42.509 6.03844 43.3698 5.38463 44.377 5.12053C45.3841 4.85572 46.4548 5.00205 47.3541 5.52738L51.222 7.78576C53.7638 9.26969 54.7267 11.2233 55.0864 12.6009C55.5718 14.4588 55.2713 16.4538 54.2399 18.2197C53.5639 19.3774 52.6196 20.3317 51.5104 20.9791C51.4961 20.9877 51.4819 20.9955 51.4669 21.0041V22.6272C51.4669 24.0334 50.7153 25.3317 49.4961 26.0319C48.8909 26.3795 48.2156 26.553 47.5411 26.553V26.5523Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.4383 37.3323C18.7417 37.3323 18.0422 37.1475 17.4169 36.7713L11.4591 33.191C10.3663 32.5344 9.65967 31.3873 9.56617 30.1154C9.47195 28.8434 10.0016 27.6051 10.9866 26.7949L12.5476 25.5101C12.9045 25.2161 13.302 24.9941 13.7203 24.8442C13.9473 24.4623 14.2421 24.1147 14.599 23.8213L15.6018 22.9955C15.9587 22.7014 16.3563 22.4795 16.7746 22.3296C17.0015 21.9477 17.2963 21.6008 17.6532 21.3067L19.2142 20.0219C20.1992 19.2118 21.5161 18.9299 22.746 19.2668C23.9758 19.6037 24.9658 20.5166 25.4005 21.7157L27.7681 28.251C28.3341 29.8135 27.8552 31.5629 26.5718 32.6193L25.2213 33.7306C24.8866 34.0062 24.5161 34.2189 24.1264 34.3673C23.9059 34.7214 23.6253 35.044 23.2906 35.3195L21.933 36.4373C21.2135 37.0297 20.3277 37.3316 19.4376 37.3316L19.4383 37.3323Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.03 33.0069C24.3512 33.0069 23.6774 32.8306 23.0778 32.4873C22.1742 31.9698 21.5139 31.114 21.2413 30.109L19.5261 23.7864C18.9586 21.6944 20.1942 19.5373 22.2869 18.9699L27.3219 17.6037C28.3269 17.3311 29.399 17.4688 30.3019 17.9863C31.2055 18.5038 31.8658 19.3596 32.1384 20.3646L32.5403 21.845C32.6688 22.3182 32.7052 22.7943 32.6595 23.2554L32.8501 23.9585C33.1177 24.3339 33.3197 24.7565 33.4425 25.2097L33.8443 26.6901C34.117 27.6951 33.9792 28.7671 33.4618 29.6701C32.9443 30.5737 32.0885 31.2339 31.0842 31.5066L26.0592 32.8699C25.7209 32.962 25.3754 33.0069 25.0314 33.0069H25.03Z"
        fill="#C8C9CA"
      />
      <path
        d="M35.97 32.1375C35.838 32.1375 35.7045 32.1311 35.5703 32.1175L30.3897 31.5929C28.2327 31.3745 26.661 29.4487 26.8794 27.2917L27.5389 20.7743C27.6439 19.7386 28.1556 18.7864 28.9622 18.1283C29.7688 17.4702 30.8037 17.159 31.8401 17.2639L37.0307 17.7893C39.1877 18.0077 40.7594 19.9334 40.541 22.0905L40.3868 23.6165C40.3383 24.0933 40.2041 24.5523 39.9943 24.9734L39.9207 25.7007C40.0364 26.1468 40.0735 26.6144 40.0264 27.0819L39.8722 28.6079C39.6673 30.6308 37.9614 32.1382 35.9714 32.1382L35.97 32.1375Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.2578 35.4295C41.6032 35.4295 40.9473 35.266 40.3534 34.9363L38.4055 33.8556C38.0479 33.6572 37.7282 33.4067 37.4541 33.1169C37.0665 33.0391 36.6846 32.9013 36.3206 32.6993L34.7232 31.8128C33.8124 31.3074 33.14 30.4616 32.8538 29.4609C32.5669 28.4602 32.6896 27.386 33.195 26.4759L36.3734 20.7479C37.4255 18.8521 39.8145 18.1676 41.711 19.2197L43.3084 20.1062C43.6553 20.2982 43.9615 20.5352 44.2235 20.8064C44.5961 20.8871 44.958 21.022 45.2984 21.2111L47.2292 22.2825C48.5718 23.0277 49.3569 24.4866 49.2392 26.0177C49.1207 27.5487 48.1214 28.8699 46.6803 29.4002L45.9929 29.6529L46.1571 31.0462C46.3291 32.5094 45.6682 33.9455 44.4448 34.7657C43.7852 35.2075 43.0229 35.4309 42.2578 35.4309V35.4295Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.2534 45.6221C26.242 45.6221 22.7145 42.6828 22.1007 38.5978C21.7731 36.4187 22.3141 34.2424 23.6232 32.4701C24.9322 30.6978 26.8537 29.5415 29.0321 29.2139C33.5303 28.5372 37.7402 31.6471 38.4161 36.1453C38.7437 38.3245 38.2027 40.5008 36.8936 42.2731C35.5846 44.0453 33.6631 45.2017 31.4846 45.5293C31.0714 45.5914 30.6595 45.6214 30.2534 45.6214V45.6221ZM30.2584 36.974C30.2391 36.974 30.2191 36.9754 30.1991 36.9783C29.9822 37.0111 29.8323 37.2138 29.8651 37.4308C29.8979 37.6478 30.1014 37.7984 30.3176 37.7649C30.5346 37.732 30.6845 37.5293 30.6517 37.3123C30.6217 37.1153 30.4518 36.9733 30.2584 36.9733V36.974Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.2969 29.8064C7.91864 29.8064 5.62743 28.8663 3.80945 27.1354C1.89725 25.3153 0.82374 22.9163 0.786624 20.381C0.750935 17.9142 1.69169 15.5452 3.43757 13.7122C5.56033 11.4824 8.54105 10.3347 11.4133 10.6402C13.3362 10.845 14.823 12.4196 14.9179 14.3503L14.9607 15.2169L15.7766 15.1797C17.7623 15.0898 19.501 16.4974 19.8265 18.4581C20.3154 21.3996 19.3147 24.513 17.1506 26.7864C15.3947 28.6315 13.0899 29.7014 10.6617 29.7999C10.5396 29.8049 10.4176 29.8071 10.2962 29.8071L10.2969 29.8064Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.809 24.2339C17.4007 24.2339 16.0395 23.4744 15.3358 22.1433L14.0988 19.8056L8.26085 15.7807C7.13095 15.002 6.48998 13.6893 6.56992 12.3189C6.64986 10.9484 7.44001 9.72004 8.65342 9.07836L11.4814 7.5823C12.0831 7.26395 12.7426 7.11477 13.3957 7.12762C13.7526 6.57944 14.248 6.11906 14.849 5.80072L17.6641 4.31108C18.8804 3.66797 20.3443 3.70794 21.5235 4.41671C22.7026 5.12549 23.4242 6.40029 23.4257 7.77644L23.4342 14.8778L24.6662 17.2068C25.6797 19.1233 24.9481 21.4987 23.0317 22.5123L20.6405 23.7771C20.0552 24.0869 19.4271 24.2332 18.8083 24.2332L18.809 24.2339Z"
        fill="#C8C9CA"
      />
      <path
        d="M22.9301 21.9313C22.1393 21.9313 21.3613 21.6922 20.701 21.2368C19.8438 20.6458 19.2571 19.7379 19.0686 18.7144L17.1286 8.14412C16.7375 6.01137 18.1486 3.9657 20.2813 3.57383L25.2306 2.6652C27.1771 2.3076 29.0771 2.65807 30.5789 3.65164C32.0386 4.61666 33.0557 6.15341 33.3705 7.86789C33.4726 8.4232 33.4983 8.98066 33.4533 9.52598C34.1371 10.3975 34.6039 11.446 34.8159 12.6002C35.1735 14.5481 34.7395 16.4774 33.5939 18.032C32.4918 19.5288 30.8002 20.5516 28.8316 20.9128L23.6396 21.8657C23.4041 21.9092 23.1671 21.9299 22.9309 21.9299L22.9301 21.9313Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.4745 21.8478C39.2496 21.8478 39.0212 21.8285 38.7921 21.7879L31.1569 20.4488C30.1312 20.269 29.219 19.6894 28.6216 18.8371C28.0234 17.9849 27.7886 16.9299 27.9685 15.9042L29.825 5.31828C30.1997 3.18267 32.2347 1.75441 34.3696 2.12986L42.0191 3.47104C44.1547 3.84577 45.5822 5.88002 45.2075 8.01562L44.7728 10.4938C44.6686 11.087 44.4366 11.6259 44.1097 12.0863L43.6986 14.4303C43.8413 14.9656 43.8735 15.5395 43.7714 16.1205L43.3367 18.5987C43.0027 20.5052 41.3453 21.8471 39.4745 21.8471V21.8478Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.6124 26.4809C46.9286 26.4809 46.2441 26.3025 45.6331 25.9456L42.9458 24.3767C42.5011 24.1169 42.1199 23.7779 41.8159 23.3839C41.3198 23.3125 40.8373 23.1462 40.3955 22.8885L38.0471 21.5173C36.1749 20.4238 35.5432 18.0199 36.6367 16.1476L42.0557 6.86645C42.5803 5.96709 43.4411 5.31328 44.4483 5.04918C45.4554 4.78437 46.526 4.9307 47.4254 5.45603L51.2933 7.71441C53.8351 9.19834 54.798 11.1519 55.1577 12.5295C55.6431 14.3875 55.3426 16.3825 54.3112 18.1483C53.6352 19.3061 52.6909 20.2604 51.5817 20.9078C51.5674 20.9163 51.5531 20.9242 51.5382 20.9328V22.5559C51.5382 23.962 50.7866 25.2604 49.5674 25.9606C48.9622 26.3082 48.2869 26.4816 47.6124 26.4816V26.4809Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.5099 37.261C18.8132 37.261 18.1137 37.0761 17.4885 36.7L11.5306 33.1197C10.4378 32.463 9.7312 31.316 9.6377 30.044C9.54348 28.7721 10.0731 27.5337 11.0581 26.7236L12.6191 25.4388C12.976 25.1447 13.3736 24.9227 13.7919 24.7728C14.0188 24.391 14.3136 24.0434 14.6705 23.75L15.6734 22.9242C16.0302 22.6301 16.4278 22.4081 16.8461 22.2582C17.0731 21.8763 17.3679 21.5294 17.7247 21.2354L19.2858 19.9506C20.2708 19.1405 21.5877 18.8585 22.8175 19.1954C24.0473 19.5323 25.0373 20.4452 25.472 21.6444L27.8396 28.1797C28.4056 29.7421 27.9267 31.4916 26.6433 32.548L25.2929 33.6593C24.9581 33.9348 24.5877 34.1475 24.1979 34.296C23.9774 34.65 23.6969 34.9726 23.3621 35.2482L22.0045 36.3659C21.285 36.9584 20.3992 37.2603 19.5092 37.2603L19.5099 37.261Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.1017 32.9355C24.4229 32.9355 23.7491 32.7592 23.1496 32.4159C22.2459 31.8984 21.5857 31.0426 21.313 30.0376L19.5978 23.715C19.0304 21.623 20.2659 19.4659 22.3587 18.8985L27.3937 17.5323C28.3987 17.2597 29.4707 17.3974 30.3737 17.9149C31.2766 18.4324 31.9375 19.2882 32.2102 20.2932L32.6121 21.7736C32.7405 22.2468 32.7769 22.7229 32.7312 23.184L32.9218 23.887C33.1895 24.2625 33.3915 24.685 33.5143 25.1383L33.9161 26.6186C34.1888 27.6236 34.051 28.6957 33.5335 29.5986C33.016 30.5023 32.1602 31.1625 31.156 31.4352L26.131 32.7985C25.7927 32.8906 25.4472 32.9355 25.1032 32.9355H25.1017Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.0415 32.0662C35.9095 32.0662 35.776 32.0598 35.6418 32.0462L30.4613 31.5216C28.3042 31.3032 26.7325 29.3774 26.9509 27.2204L27.6105 20.7029C27.7154 19.6672 28.2272 18.7151 29.0337 18.057C29.8403 17.3989 30.8753 17.0877 31.9117 17.1926L37.1022 17.7179C39.2592 17.9363 40.8309 19.8621 40.6125 22.0191L40.4584 23.5452C40.4098 24.022 40.2756 24.4809 40.0658 24.902L39.9923 25.6294C40.1079 26.0755 40.145 26.543 40.0979 27.0105L39.9437 28.5366C39.7389 30.5594 38.033 32.0669 36.043 32.0669L36.0415 32.0662Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.329 35.3581C41.6745 35.3581 41.0186 35.1946 40.4247 34.8649L38.4768 33.7842C38.1192 33.5858 37.7994 33.3352 37.5254 33.0454C37.1378 32.9676 36.7559 32.8299 36.3919 32.6279L34.7945 31.7414C33.8837 31.236 33.2113 30.3902 32.9251 29.3895C32.6382 28.3888 32.7609 27.3146 33.2663 26.4045L36.4447 20.6765C37.4968 18.7807 39.8865 18.0962 41.7823 19.1483L43.3797 20.0348C43.7266 20.2268 44.0328 20.4638 44.2948 20.735C44.6674 20.8157 45.0292 20.9506 45.3697 21.1397L47.3005 22.2111C48.6431 22.9563 49.4282 24.4152 49.3105 25.9463C49.192 27.4773 48.1927 28.7985 46.7516 29.3288L46.0642 29.5815L46.2284 30.9748C46.4004 32.438 45.7394 33.8741 44.516 34.6943C43.8565 35.1361 43.0942 35.3595 42.329 35.3595V35.3581Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.3249 45.5507C26.3135 45.5507 22.7861 42.6114 22.1722 38.5265C21.8446 36.3473 22.3856 34.1711 23.6947 32.3988C25.0038 30.6265 26.9252 29.4701 29.1037 29.1425C33.6019 28.4659 37.8117 31.5758 38.4876 36.074C38.8152 38.2531 38.2742 40.4294 36.9651 42.2017C35.6561 43.974 33.7346 45.1303 31.5562 45.4579C31.1429 45.52 30.7311 45.55 30.3249 45.55V45.5507ZM30.3299 36.9027C30.3106 36.9027 30.2907 36.9041 30.2707 36.9069C30.0537 36.9398 29.9038 37.1425 29.9366 37.3595C29.9695 37.5765 30.1729 37.7271 30.3892 37.6935C30.6062 37.6607 30.756 37.458 30.7232 37.241C30.6932 37.044 30.5234 36.9019 30.3299 36.9019V36.9027Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.3685 29.735C7.99017 29.735 5.69896 28.7949 3.88098 27.064C1.96879 25.2439 0.895273 22.8449 0.858157 20.3096C0.822468 17.8428 1.76322 15.4738 3.50911 13.6408C5.63115 11.411 8.61329 10.2625 11.4848 10.5687C13.4077 10.7736 14.8945 12.3482 14.9894 14.2789L15.0322 15.1455L15.8481 15.1083C17.8338 15.0184 19.5726 16.426 19.898 18.3867C20.387 21.3281 19.3863 24.4416 17.2221 26.715C15.4662 28.5601 13.1614 29.63 10.7332 29.7285C10.6111 29.7335 10.4891 29.7357 10.3677 29.7357L10.3685 29.735Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.8803 24.1625C17.472 24.1625 16.1108 23.4031 15.407 22.0719L14.1701 19.7343L8.33214 15.7093C7.20224 14.9306 6.56127 13.618 6.64121 12.2475C6.72115 10.8771 7.5113 9.64869 8.72471 9.00701L11.5527 7.51095C12.1544 7.1926 12.8132 7.04271 13.467 7.05627C13.8239 6.50809 14.3193 6.04771 14.9203 5.72937L17.7354 4.23973C18.9516 3.59662 20.4156 3.63659 21.5947 4.34536C22.7739 5.05414 23.4955 6.32894 23.4969 7.70509L23.5055 14.8064L24.7375 17.1355C25.751 19.0519 25.0194 21.4274 23.1029 22.4409L20.7118 23.7057C20.1265 24.0155 19.4984 24.1618 18.8796 24.1618L18.8803 24.1625Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.0017 21.86C22.2108 21.86 21.4328 21.6209 20.7726 21.1655C19.9153 20.5745 19.3286 19.6666 19.1402 18.643L17.2001 8.07277C16.809 5.94002 18.2201 3.89435 20.3529 3.50248L25.3022 2.59385C27.2486 2.23625 29.1487 2.58671 30.6505 3.58029C32.1101 4.54531 33.1272 6.08206 33.442 7.79654C33.5441 8.35185 33.5698 8.90931 33.5248 9.45463C34.2086 10.3261 34.6754 11.3747 34.8874 12.5288C35.245 14.4767 34.811 16.4061 33.6654 17.9607C32.5634 19.4574 30.8717 20.4803 28.9031 20.8414L23.7112 21.7943C23.4756 21.8379 23.2386 21.8586 23.0024 21.8586L23.0017 21.86Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.5463 21.7764C39.3214 21.7764 39.093 21.7571 38.8639 21.7164L31.2287 20.3774C30.203 20.1975 29.2908 19.618 28.6933 18.7657C28.0952 17.9135 27.8604 16.8585 28.0402 15.8328L29.8968 5.24687C30.2715 3.11126 32.3065 1.683 34.4414 2.05845L42.0909 3.39962C44.2265 3.77435 45.654 5.80861 45.2793 7.94421L44.8446 10.4224C44.7404 11.0156 44.5084 11.5545 44.1815 12.0149L43.7704 14.3589C43.9131 14.8942 43.9452 15.4681 43.8432 16.0491L43.4085 18.5273C43.0744 20.4338 41.4171 21.7757 39.5463 21.7757V21.7764Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.6837 26.4095C46.9999 26.4095 46.3154 26.2311 45.7044 25.8742L43.0171 24.3053C42.5724 24.0455 42.1912 23.7064 41.8872 23.3124C41.3911 23.2411 40.9086 23.0748 40.4667 22.8171L38.1184 21.4459C36.2462 20.3524 35.6145 17.9484 36.708 16.0762L42.127 6.79504C42.6516 5.89568 43.5124 5.24187 44.5195 4.97777C45.5267 4.71296 46.5973 4.85928 47.4967 5.38462L51.3646 7.643C53.9064 9.12693 54.8692 11.0805 55.229 12.4581C55.7144 14.3161 55.4139 16.311 54.3825 18.0769C53.7065 19.2347 52.7622 20.189 51.653 20.8364C51.6387 20.8449 51.6244 20.8528 51.6094 20.8613V22.4845C51.6094 23.8906 50.8578 25.1889 49.6387 25.8892C49.0334 26.2368 48.3582 26.4102 47.6837 26.4102V26.4095Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.5814 37.1896C18.8848 37.1896 18.1853 37.0048 17.56 36.6286L11.6022 33.0483C10.5094 32.3917 9.80274 31.2446 9.70923 29.9727C9.61501 28.7007 10.1446 27.4624 11.1296 26.6522L12.6907 25.3674C13.0475 25.0734 13.4451 24.8514 13.8634 24.7015C14.0904 24.3196 14.3852 23.972 14.742 23.6786L15.7449 22.8528C16.1018 22.5587 16.4993 22.3368 16.9176 22.1869C17.1446 21.805 17.4394 21.4581 17.7963 21.164L19.3573 19.8792C20.3423 19.0691 21.6592 18.7872 22.889 19.1241C24.1189 19.461 25.1089 20.3739 25.5436 21.573L27.9111 28.1083C28.4772 29.6708 27.9982 31.4202 26.7149 32.4766L25.3644 33.5879C25.0296 33.8635 24.6592 34.0762 24.2695 34.2246C24.0489 34.5787 23.7684 34.9013 23.4336 35.1768L22.0761 36.2946C21.3566 36.887 20.4708 37.1889 19.5807 37.1889L19.5814 37.1896Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.173 32.8642C24.4942 32.8642 23.8204 32.6879 23.2209 32.3446C22.3172 31.8271 21.657 30.9713 21.3843 29.9663L19.6691 23.6437C19.1017 21.5516 20.3372 19.3946 22.43 18.8271L27.4649 17.461C28.4699 17.1883 29.542 17.3261 30.4449 17.8436C31.3486 18.361 32.0088 19.2169 32.2815 20.2218L32.6833 21.7022C32.8118 22.1754 32.8482 22.6515 32.8025 23.1126L32.9931 23.8157C33.2608 24.1911 33.4628 24.6137 33.5855 25.0669L33.9874 26.5473C34.2601 27.5523 34.1223 28.6244 33.6048 29.5273C33.0873 30.4309 32.2315 31.0912 31.2272 31.3638L26.2023 32.7271C25.864 32.8192 25.5185 32.8642 25.1745 32.8642H25.173Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.1128 31.9948C35.9808 31.9948 35.8473 31.9883 35.7131 31.9748L30.5326 31.4502C28.3755 31.2317 26.8038 29.306 27.0222 27.149L27.6817 20.6315C27.7867 19.5958 28.2984 18.6437 29.105 17.9856C29.9116 17.3275 30.9465 17.0163 31.9829 17.1212L37.1735 17.6465C39.3305 17.8649 40.9022 19.7907 40.6838 21.9477L40.5297 23.4738C40.4811 23.9506 40.3469 24.4095 40.1371 24.8306L40.0636 25.558C40.1792 26.0041 40.2163 26.4716 40.1692 26.9391L40.015 28.4652C39.8102 30.488 38.1043 31.9955 36.1143 31.9955L36.1128 31.9948Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.4006 35.2867C41.7461 35.2867 41.0901 35.1233 40.4962 34.7935L38.5483 33.7129C38.1907 33.5144 37.871 33.2639 37.5969 32.9741C37.2093 32.8963 36.8274 32.7585 36.4634 32.5565L34.866 31.67C33.9552 31.1647 33.2829 30.3189 32.9966 29.3182C32.7097 28.3174 32.8325 27.2432 33.3378 26.3332L36.5162 20.6051C37.5683 18.7094 39.958 18.0248 41.8538 19.077L43.4513 19.9635C43.7981 20.1555 44.1044 20.3924 44.3663 20.6637C44.7389 20.7443 45.1008 20.8792 45.4412 21.0684L47.372 22.1397C48.7146 22.8849 49.4998 24.3439 49.382 25.8749C49.2635 27.406 48.2642 28.7272 46.8231 29.2575L46.1357 29.5102L46.2999 30.9034C46.4719 32.3667 45.811 33.8028 44.5876 34.6229C43.9281 35.0647 43.1657 35.2881 42.4006 35.2881V35.2867Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.3962 45.4794C26.3848 45.4794 22.8574 42.5401 22.2435 38.4552C21.9159 36.2761 22.4569 34.0998 23.766 32.3275C25.075 30.5552 26.9965 29.3989 29.1749 29.0712C33.6731 28.3946 37.883 31.5045 38.5589 36.0027C38.8865 38.1818 38.3455 40.3581 37.0364 42.1304C35.7274 43.9027 33.8059 45.059 31.6275 45.3866C31.2142 45.4487 30.8024 45.4787 30.3962 45.4787V45.4794ZM30.4012 36.8314C30.3819 36.8314 30.362 36.8328 30.342 36.8357C30.125 36.8685 29.9751 37.0712 30.0079 37.2882C30.0408 37.5052 30.2435 37.6551 30.4605 37.6222C30.6774 37.5894 30.8273 37.3867 30.7945 37.1697C30.7645 36.9727 30.5946 36.8307 30.4012 36.8307V36.8314Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.4397 29.6636C8.06146 29.6636 5.77025 28.7236 3.95227 26.9927C2.04008 25.1726 0.966562 22.7736 0.929446 20.2382C0.893758 17.7714 1.83451 15.4024 3.5804 13.5695C5.70316 11.3396 8.68387 10.1912 11.5561 10.4974C13.479 10.7022 14.9658 12.2768 15.0607 14.2076L15.1035 15.0741L15.9194 15.037C17.9051 14.9471 19.6438 16.3546 19.9693 18.3153C20.4583 21.2568 19.4575 24.3703 17.2934 26.6436C15.5375 28.4887 13.2327 29.5587 10.8045 29.6572C10.6824 29.6622 10.5604 29.6643 10.439 29.6643L10.4397 29.6636Z"
        fill="#C8C9CA"
      />
      <path
        d="M18.9516 24.0912C17.5433 24.0912 16.1821 23.3317 15.4783 22.0006L14.2414 19.6629L8.40343 15.638C7.27352 14.8593 6.63256 13.5466 6.7125 12.1762C6.79244 10.8057 7.58259 9.57734 8.796 8.93566L11.624 7.43959C12.2257 7.12125 12.8859 6.97279 13.5383 6.98492C13.8952 6.43674 14.3905 5.97636 14.9915 5.65802L17.8067 4.16838C19.0229 3.52527 20.4869 3.56524 21.666 4.27401C22.8452 4.98279 23.5668 6.25759 23.5682 7.63374L23.5768 14.7351L24.8088 17.0641C25.8223 18.9806 25.0907 21.356 23.1742 22.3696L20.7831 23.6344C20.1978 23.9442 19.5697 24.0905 18.9508 24.0905L18.9516 24.0912Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.0727 21.7886C22.2819 21.7886 21.5038 21.5495 20.8436 21.0941C19.9864 20.5031 19.3996 19.5952 19.2112 18.5716L17.2712 8.00136C16.88 5.86861 18.2912 3.82293 20.4239 3.43107L25.3732 2.52244C27.3197 2.16484 29.2197 2.5153 30.7215 3.50887C32.1812 4.4739 33.1983 6.01065 33.5131 7.72513C33.6151 8.28044 33.6408 8.8379 33.5959 9.38322C34.2797 10.2547 34.7465 11.3033 34.9585 12.4574C35.3161 14.4053 34.8821 16.3346 33.7365 17.8892C32.6344 19.386 30.9428 20.4089 28.9742 20.77L23.7822 21.7229C23.5467 21.7665 23.3097 21.7872 23.0734 21.7872L23.0727 21.7886Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.6175 21.7051C39.3927 21.7051 39.1643 21.6858 38.9352 21.6452L31.3 20.3061C30.2743 20.1263 29.3621 19.5467 28.7646 18.6944C28.1665 17.8422 27.9317 16.7872 28.1115 15.7615L29.9681 5.17558C30.3428 3.03997 32.3778 1.61171 34.5126 1.98716L42.1621 3.32834C44.2978 3.70307 45.7253 5.73732 45.3506 7.87292L44.9159 10.3511C44.8117 10.9443 44.5797 11.4832 44.2528 11.9436L43.8417 14.2876C43.9844 14.8229 44.0165 15.3968 43.9145 15.9778L43.4798 18.456C43.1457 20.3625 41.4883 21.7044 39.6175 21.7044V21.7051Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.7552 26.3382C47.0714 26.3382 46.3869 26.1597 45.7759 25.8028L43.0886 24.2339C42.6439 23.9741 42.2628 23.6351 41.9587 23.2411C41.4626 23.1697 40.9801 23.0034 40.5383 22.7457L38.19 21.3746C36.3177 20.2811 35.6861 17.8771 36.7796 16.0049L42.1985 6.72369C42.7231 5.82433 43.5839 5.17052 44.5911 4.90642C45.5982 4.64161 46.6689 4.78793 47.5682 5.31327L51.4362 7.57165C53.9779 9.05558 54.9408 11.0092 55.3005 12.3868C55.7859 14.2447 55.4854 16.2397 54.454 18.0056C53.778 19.1633 52.8337 20.1176 51.7245 20.765C51.7102 20.7736 51.696 20.7814 51.681 20.79V22.4131C51.681 23.8192 50.9294 25.1176 49.7103 25.8178C49.105 26.1654 48.4297 26.3389 47.7552 26.3389V26.3382Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.6525 37.1182C18.9558 37.1182 18.2563 36.9334 17.6311 36.5572L11.6732 32.9769C10.5804 32.3203 9.87378 31.1732 9.78028 29.9013C9.68606 28.6293 10.2157 27.3909 11.2007 26.5808L12.7617 25.296C13.1186 25.0019 13.5162 24.78 13.9344 24.6301C14.1614 24.2482 14.4562 23.9006 14.8131 23.6072L15.8159 22.7814C16.1728 22.4873 16.5704 22.2653 16.9887 22.1154C17.2156 21.7336 17.5104 21.3867 17.8673 21.0926L19.4283 19.8078C20.4133 18.9977 21.7303 18.7158 22.9601 19.0527C24.1899 19.3896 25.1799 20.3025 25.6146 21.5016L27.9822 28.0369C28.5482 29.5994 28.0693 31.3488 26.7859 32.4052L25.4354 33.5165C25.1007 33.7921 24.7302 34.0048 24.3405 34.1532C24.12 34.5072 23.8394 34.8299 23.5047 35.1054L22.1471 36.2232C21.4276 36.8156 20.5418 37.1175 19.6517 37.1175L19.6525 37.1182Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.2443 32.7928C24.5655 32.7928 23.8917 32.6165 23.2921 32.2731C22.3885 31.7557 21.7283 30.8998 21.4556 29.8949L19.7404 23.5723C19.173 21.4802 20.4085 19.3232 22.5013 18.7557L27.5362 17.3896C28.5412 17.1169 29.6133 17.2547 30.5162 17.7721C31.4199 18.2896 32.0801 19.1454 32.3528 20.1504L32.7546 21.6308C32.8831 22.104 32.9195 22.5801 32.8738 23.0412L33.0644 23.7443C33.3321 24.1197 33.5341 24.5423 33.6568 24.9955L34.0587 26.4759C34.3314 27.4809 34.1936 28.553 33.6761 29.4559C33.1586 30.3595 32.3028 31.0198 31.2985 31.2924L26.2736 32.6557C25.9352 32.7478 25.5898 32.7928 25.2457 32.7928H25.2443Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.1844 31.9234C36.0523 31.9234 35.9188 31.917 35.7846 31.9034L30.6041 31.3788C28.4471 31.1604 26.8753 29.2346 27.0938 27.0776L27.7533 20.5602C27.8582 19.5245 28.37 18.5723 29.1765 17.9142C29.9831 17.2561 31.0181 16.9449 32.0545 17.0498L37.245 17.5752C39.402 17.7936 40.9738 19.7193 40.7554 21.8764L40.6012 23.4024C40.5526 23.8792 40.4185 24.3382 40.2086 24.7593L40.1351 25.4866C40.2507 25.9327 40.2878 26.4002 40.2407 26.8678L40.0866 28.3938C39.8817 30.4166 38.1758 31.9241 36.1858 31.9241L36.1844 31.9234Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.4719 35.2153C41.8173 35.2153 41.1614 35.0519 40.5675 34.7221L38.6196 33.6414C38.262 33.443 37.9423 33.1925 37.6682 32.9027C37.2806 32.8249 36.8987 32.6871 36.5347 32.4851L34.9373 31.5986C34.0265 31.0933 33.3541 30.2475 33.0679 29.2467C32.781 28.246 32.9037 27.1718 33.4091 26.2617L36.5875 20.5337C37.6396 18.6379 40.0293 17.9534 41.9251 19.0055L43.5225 19.892C43.8694 20.084 44.1756 20.321 44.4376 20.5923C44.8102 20.6729 45.1721 20.8078 45.5125 20.997L47.4433 22.0683C48.7859 22.8135 49.571 24.2725 49.4533 25.8035C49.3348 27.3345 48.3355 28.6557 46.8944 29.1861L46.207 29.4388L46.3712 30.832C46.5432 32.2953 45.8823 33.7314 44.6589 34.5515C43.9993 34.9933 43.237 35.2167 42.4719 35.2167V35.2153Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.4675 45.408C26.4561 45.408 22.9286 42.4687 22.3148 38.3837C21.9872 36.2046 22.5282 34.0283 23.8373 32.256C25.1463 30.4837 27.0678 29.3274 29.2462 28.9998C33.7444 28.3238 37.9543 31.433 38.6302 35.9312C38.9578 38.1104 38.4168 40.2867 37.1077 42.059C35.7987 43.8313 33.8772 44.9876 31.6988 45.3152C31.2855 45.3773 30.8736 45.4073 30.4675 45.4073V45.408ZM30.4725 36.7599C30.4532 36.7599 30.4332 36.7613 30.4133 36.7642C30.1963 36.797 30.0464 36.9997 30.0792 37.2167C30.112 37.4337 30.3155 37.5836 30.5317 37.5508C30.7487 37.5179 30.8986 37.3152 30.8658 37.0982C30.8358 36.9012 30.6659 36.7592 30.4725 36.7592V36.7599Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.5113 29.5922C8.13299 29.5922 5.84178 28.6522 4.02381 26.9213C2.11161 25.1011 1.0381 22.7022 1.00098 20.1668C0.965291 17.7 1.90604 15.331 3.65193 13.4981C5.77397 11.2682 8.75611 10.1198 11.6276 10.426C13.5505 10.6308 15.0373 12.2054 15.1322 14.1362L15.1751 15.0027L15.9909 14.9656C17.9766 14.8749 19.7154 16.2832 20.0409 18.2439C20.5298 21.1854 19.5291 24.2989 17.3649 26.5722C15.609 28.4173 13.3043 29.4873 10.876 29.5858C10.754 29.5908 10.6319 29.5929 10.5106 29.5929L10.5113 29.5922Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.0231 24.0198C17.6148 24.0198 16.2537 23.2603 15.5499 21.9291L14.3129 19.5915L8.47496 15.5666C7.34506 14.7878 6.70409 13.4752 6.78403 12.1048C6.86398 10.7343 7.65412 9.50593 8.86753 8.86425L11.6955 7.36818C12.2972 7.04984 12.9567 6.89995 13.6098 6.91351C13.9667 6.36533 14.4621 5.90495 15.0631 5.58661L17.8782 4.09697C19.0945 3.45386 20.5584 3.49383 21.7376 4.2026C22.9167 4.91138 23.6383 6.18618 23.6398 7.56233L23.6483 14.6637L24.8803 16.9927C25.8939 18.9092 25.1622 21.2846 23.2458 22.2982L20.8546 23.563C20.2693 23.8727 19.6412 24.0191 19.0224 24.0191L19.0231 24.0198Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.1445 21.7172C22.3536 21.7172 21.5756 21.4781 20.9154 21.0227C20.0581 20.4317 19.4714 19.5238 19.283 18.5003L17.343 7.93001C16.9518 5.79726 18.3629 3.75158 20.4957 3.35972L25.445 2.45109C27.3914 2.09349 29.2915 2.44395 30.7933 3.43752C32.2529 4.40254 33.2701 5.9393 33.5848 7.65378C33.6869 8.20909 33.7126 8.76655 33.6676 9.31187C34.3514 10.1834 34.8182 11.2319 35.0302 12.3861C35.3878 14.334 34.9539 16.2633 33.8083 17.8179C32.7062 19.3147 31.0145 20.3375 29.046 20.6987L23.854 21.6516C23.6184 21.6951 23.3815 21.7158 23.1452 21.7158L23.1445 21.7172Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.6891 21.6337C39.4642 21.6337 39.2358 21.6144 39.0067 21.5737L31.3715 20.2347C30.3458 20.0548 29.4336 19.4753 28.8362 18.623C28.238 17.7708 28.0032 16.7158 28.1831 15.6901L30.0396 5.10417C30.4143 2.96856 32.4493 1.5403 34.5842 1.91575L42.2337 3.25692C44.3693 3.63165 45.7968 5.66591 45.4221 7.80151L44.9874 10.2797C44.8832 10.8729 44.6512 11.4118 44.3243 11.8722L43.9132 14.2162C44.0559 14.7515 44.0881 15.3254 43.986 15.9064L43.5513 18.3846C43.2173 20.2911 41.5599 21.633 39.6891 21.633V21.6337Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.8268 26.2669C47.143 26.2669 46.4585 26.0884 45.8475 25.7315L43.1601 24.1627C42.7154 23.9028 42.3343 23.5638 42.0302 23.1698C41.5341 23.0984 41.0516 22.9321 40.6098 22.6744L38.2615 21.3033C36.3893 20.2098 35.7576 17.8058 36.8511 15.9336L42.27 6.6524C42.7947 5.75304 43.6555 5.09923 44.6626 4.83513C45.6697 4.57032 46.7404 4.71665 47.6398 5.24198L51.5077 7.50036C54.0494 8.98429 55.0123 10.9379 55.3721 12.3155C55.8574 14.1734 55.5569 16.1684 54.5255 17.9343C53.8496 19.092 52.9053 20.0463 51.7961 20.6937C51.7818 20.7023 51.7675 20.7101 51.7525 20.7187V22.3418C51.7525 23.748 51.0009 25.0463 49.7818 25.7465C49.1765 26.0941 48.5013 26.2676 47.8268 26.2676V26.2669Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.724 37.0469C19.0274 37.0469 18.3279 36.862 17.7026 36.4859L11.7447 32.9056C10.6519 32.2489 9.94531 31.1019 9.85181 29.8299C9.75759 28.558 10.2872 27.3196 11.2722 26.5095L12.8332 25.2247C13.1901 24.9306 13.5877 24.7086 14.006 24.5587C14.2329 24.1769 14.5277 23.8292 14.8846 23.5359L15.8875 22.71C16.2444 22.416 16.6419 22.194 17.0602 22.0441C17.2872 21.6622 17.582 21.3153 17.9389 21.0213L19.4999 19.7365C20.4849 18.9263 21.8018 18.6444 23.0316 18.9813C24.2614 19.3182 25.2514 20.2311 25.6861 21.4303L28.0537 27.9656C28.6197 29.528 28.1408 31.2775 26.8574 32.3338L25.507 33.4452C25.1722 33.7207 24.8018 33.9334 24.4121 34.0819C24.1915 34.4359 23.911 34.7585 23.5762 35.034L22.2186 36.1518C21.4991 36.7442 20.6134 37.0462 19.7233 37.0462L19.724 37.0469Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.3156 32.7215C24.6368 32.7215 23.963 32.5452 23.3634 32.2019C22.4598 31.6844 21.7996 30.8286 21.5269 29.8236L19.8117 23.501C19.2443 21.4089 20.4798 19.2519 22.5726 18.6844L27.6075 17.3183C28.6125 17.0456 29.6846 17.1834 30.5875 17.7009C31.4912 18.2183 32.1514 19.0742 32.4241 20.0791L32.8259 21.5595C32.9544 22.0327 32.9908 22.5088 32.9451 22.9699L33.1357 23.673C33.4034 24.0484 33.6054 24.471 33.7281 24.9242L34.13 26.4046C34.4026 27.4096 34.2649 28.4817 33.7474 29.3846C33.2299 30.2882 32.3741 30.9485 31.3698 31.2211L26.3449 32.5844C26.0065 32.6765 25.6611 32.7215 25.317 32.7215H25.3156Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.2554 31.852C36.1234 31.852 35.9899 31.8456 35.8557 31.8321L30.6751 31.3074C28.5181 31.089 26.9464 29.1633 27.1648 27.0062L27.8243 20.4888C27.9293 19.4531 28.441 18.5009 29.2476 17.8428C30.0542 17.1847 31.0891 16.8742 32.1255 16.9785L37.3161 17.5038C39.4731 17.7222 41.0448 19.648 40.8264 21.805L40.6722 23.331C40.6237 23.8078 40.4895 24.2668 40.2797 24.6879L40.2061 25.4152C40.3218 25.8614 40.3589 26.3289 40.3118 26.7964L40.1576 28.3224C39.9527 30.3453 38.2468 31.8528 36.2568 31.8528L36.2554 31.852Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.5432 35.144C41.8886 35.144 41.2327 34.9805 40.6388 34.6507L38.6909 33.5701C38.3333 33.3717 38.0136 33.1211 37.7395 32.8313C37.3519 32.7535 36.97 32.6158 36.606 32.4138L35.0086 31.5273C34.0978 31.0219 33.4254 30.1761 33.1392 29.1754C32.8523 28.1747 32.975 27.1005 33.4804 26.1904L36.6588 20.4624C37.7109 18.5666 40.1006 17.8821 41.9964 18.9342L43.5938 19.8207C43.9407 20.0127 44.2469 20.2497 44.5089 20.5209C44.8815 20.6016 45.2434 20.7365 45.5838 20.9256L47.5146 21.997C48.8572 22.7422 49.6423 24.2011 49.5246 25.7322C49.4061 27.2632 48.4068 28.5844 46.9657 29.1147L46.2783 29.3674L46.4425 30.7607C46.6145 32.2239 45.9536 33.66 44.7302 34.4802C44.0706 34.922 43.3083 35.1454 42.5432 35.1454V35.144Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.539 45.3367C26.5276 45.3367 23.0002 42.3974 22.3863 38.3125C22.0587 36.1333 22.5997 33.957 23.9088 32.1847C25.2179 30.4124 27.1393 29.2561 29.3178 28.9285C33.816 28.2526 38.0258 31.3617 38.7017 35.8599C39.0294 38.0391 38.4883 40.2154 37.1793 41.9877C35.8702 43.76 33.9487 44.9163 31.7703 45.2439C31.357 45.306 30.9452 45.336 30.539 45.336V45.3367ZM30.544 36.6886C30.5248 36.6886 30.5048 36.6901 30.4848 36.6929C30.2678 36.7257 30.1179 36.9285 30.1507 37.1454C30.1836 37.3624 30.387 37.5123 30.6033 37.4795C30.8203 37.4467 30.9702 37.2439 30.9373 37.027C30.9073 36.83 30.7375 36.6879 30.544 36.6879V36.6886Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.5826 29.5208C8.20428 29.5208 5.91307 28.5808 4.09509 26.8499C2.1829 25.0298 1.10938 22.6308 1.07227 20.0955C1.03658 17.6287 1.97733 15.2597 3.72322 13.4267C5.84598 11.1969 8.82669 10.0491 11.6989 10.3546C13.6218 10.5595 15.1086 12.1341 15.2035 14.0648L15.2464 14.9313L16.0622 14.8942C18.0479 14.8036 19.7867 16.2119 20.1121 18.1726C20.6011 21.114 19.6004 24.2275 17.4362 26.5009C15.6803 28.346 13.3756 29.4159 10.9473 29.5144C10.8253 29.5194 10.7032 29.5216 10.5819 29.5216L10.5826 29.5208Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.0941 23.9484C17.6859 23.9484 16.3247 23.189 15.6209 21.8578L14.3839 19.5202L8.546 15.4952C7.4161 14.7165 6.77513 13.4039 6.85508 12.0334C6.93502 10.663 7.72517 9.43458 8.93858 8.7929L11.7665 7.29683C12.3683 6.97849 13.0278 6.83003 13.6809 6.84216C14.0378 6.29398 14.5331 5.8336 15.1341 5.51526L17.9492 4.02562C19.1655 3.38251 20.6295 3.42248 21.8086 4.13125C22.9878 4.84003 23.7094 6.11483 23.7108 7.49098L23.7194 14.5923L24.9513 16.9213C25.9649 18.8378 25.2333 21.2133 23.3168 22.2268L20.9257 23.4916C20.3404 23.8014 19.7123 23.9477 19.0934 23.9477L19.0941 23.9484Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.2158 21.6458C22.4249 21.6458 21.6469 21.4067 20.9867 20.9513C20.1294 20.3603 19.5427 19.4524 19.3543 18.4289L17.4142 7.8586C17.0231 5.72584 18.4342 3.68017 20.567 3.28831L25.5163 2.37968C27.4627 2.02208 29.3628 2.37254 30.8646 3.36611C32.3242 4.33113 33.3414 5.86789 33.6561 7.58237C33.7582 8.13768 33.7839 8.69514 33.7389 9.24046C34.4227 10.112 34.8895 11.1605 35.1015 12.3147C35.4591 14.2626 35.0251 16.1919 33.8795 17.7465C32.7775 19.2433 31.0858 20.2661 29.1173 20.6273L23.9253 21.5802C23.6897 21.6237 23.4528 21.6444 23.2165 21.6444L23.2158 21.6458Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.7601 21.5623C39.5353 21.5623 39.3069 21.543 39.0778 21.5023L31.4425 20.1633C30.4168 19.9834 29.5046 19.4038 28.9072 18.5516C28.3091 17.6994 28.0742 16.6444 28.2541 15.6187L30.1106 5.03276C30.4854 2.89715 32.5203 1.46889 34.6552 1.84433L42.3047 3.18551C44.4403 3.56024 45.8679 5.59449 45.4931 7.7301L45.0585 10.2083C44.9542 10.8015 44.7223 11.3404 44.3954 11.8007L43.9842 14.1448C44.127 14.6801 44.1591 15.254 44.057 15.835L43.6223 18.3132C43.2883 20.2197 41.6309 21.5616 39.7601 21.5616V21.5623Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.8978 26.1954C47.214 26.1954 46.5295 26.017 45.9185 25.6601L43.2312 24.0912C42.7865 23.8314 42.4053 23.4924 42.1013 23.0984C41.6052 23.027 41.1227 22.8607 40.6809 22.603L38.3325 21.2319C36.4603 20.1384 35.8286 17.7344 36.9221 15.8622L42.3411 6.58098C42.8657 5.68163 43.7265 5.02782 44.7337 4.76372C45.7408 4.49891 46.8114 4.64523 47.7108 5.17057L51.5787 7.42895C54.1205 8.91288 55.0834 10.8665 55.4431 12.2441C55.9285 14.102 55.628 16.097 54.5966 17.8629C53.9206 19.0206 52.9763 19.9749 51.8671 20.6223C51.8528 20.6309 51.8385 20.6387 51.8236 20.6473V22.2704C51.8236 23.6765 51.072 24.9749 49.8528 25.6751C49.2476 26.0227 48.5723 26.1962 47.8978 26.1962V26.1954Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.795 36.9755C19.0984 36.9755 18.3989 36.7906 17.7736 36.4144L11.8158 32.8342C10.723 32.1775 10.0164 31.0305 9.92286 29.7585C9.82864 28.4866 10.3583 27.2482 11.3433 26.438L12.9043 25.1533C13.2612 24.8592 13.6587 24.6372 14.077 24.4873C14.304 24.1054 14.5988 23.7578 14.9557 23.4645L15.9585 22.6386C16.3154 22.3446 16.713 22.1226 17.1312 21.9727C17.3582 21.5908 17.653 21.2439 18.0099 20.9499L19.5709 19.6651C20.5559 18.8549 21.8728 18.573 23.1027 18.9099C24.3325 19.2468 25.3225 20.1597 25.7572 21.3588L28.1248 27.8941C28.6908 29.4566 28.2118 31.206 26.9285 32.2624L25.578 33.3738C25.2433 33.6493 24.8728 33.862 24.4831 34.0105C24.2625 34.3645 23.982 34.6871 23.6473 34.9626L22.2897 36.0804C21.5702 36.6728 20.6844 36.9748 19.7943 36.9748L19.795 36.9755Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.3869 32.65C24.7081 32.65 24.0343 32.4737 23.4347 32.1304C22.5311 31.6129 21.8709 30.7571 21.5982 29.7521L19.883 23.4295C19.3155 21.3374 20.5511 19.1804 22.6439 18.613L27.6788 17.2468C28.6838 16.9741 29.7559 17.1119 30.6588 17.6294C31.5625 18.1469 32.2227 19.0027 32.4954 20.0077L32.8972 21.488C33.0257 21.9613 33.0621 22.4374 33.0164 22.8985L33.207 23.6015C33.4746 23.977 33.6766 24.3995 33.7994 24.8528L34.2013 26.3331C34.4739 27.3381 34.3362 28.4102 33.8187 29.3131C33.3012 30.2168 32.4454 30.877 31.4411 31.1497L26.4162 32.513C26.0778 32.605 25.7324 32.65 25.3883 32.65H25.3869Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.3272 31.7807C36.1951 31.7807 36.0617 31.7742 35.9275 31.7607L30.7469 31.236C28.5899 31.0176 27.0182 29.0919 27.2366 26.9349L27.8961 20.4174C28.001 19.3817 28.5128 18.4295 29.3194 17.7714C30.1259 17.1133 31.1609 16.8021 32.1973 16.9071L37.3878 17.4324C39.5449 17.6508 41.1166 19.5766 40.8982 21.7336L40.744 23.2596C40.6955 23.7364 40.5613 24.1954 40.3514 24.6165L40.2779 25.3439C40.3935 25.79 40.4307 26.2575 40.3836 26.725L40.2294 28.2511C40.0245 30.2739 38.3186 31.7814 36.3286 31.7814L36.3272 31.7807Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.6147 35.0725C41.9602 35.0725 41.3042 34.9091 40.7103 34.5793L38.7625 33.4987C38.4049 33.3003 38.0851 33.0497 37.811 32.7599C37.4234 32.6821 37.0416 32.5444 36.6775 32.3424L35.0801 31.4559C34.1693 30.9505 33.497 30.1047 33.2107 29.104C32.9238 28.1033 33.0466 27.029 33.5519 26.119L36.7304 20.391C37.7825 18.4952 40.1722 17.8107 42.0679 18.8628L43.6654 19.7493C44.0123 19.9413 44.3185 20.1783 44.5804 20.4495C44.953 20.5302 45.3149 20.6651 45.6554 20.8542L47.5861 21.9256C48.9287 22.6708 49.7139 24.1297 49.5961 25.6607C49.4776 27.1918 48.4783 28.513 47.0372 29.0433L46.3499 29.296L46.514 30.6893C46.686 32.1525 46.0251 33.5886 44.8017 34.4087C44.1422 34.8506 43.3799 35.074 42.6147 35.074V35.0725Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.6106 45.2653C26.5992 45.2653 23.0717 42.3259 22.4579 38.241C22.1302 36.0619 22.6713 33.8856 23.9803 32.1133C25.2894 30.341 27.2109 29.1847 29.3893 28.8571C33.8875 28.1804 38.0973 31.2903 38.7733 35.7885C39.1009 37.9677 38.5599 40.1439 37.2508 41.9162C35.9417 43.6885 34.0203 44.8448 31.8418 45.1725C31.4286 45.2346 31.0167 45.2645 30.6106 45.2645V45.2653ZM30.6156 36.6172C30.5963 36.6172 30.5763 36.6186 30.5563 36.6215C30.3393 36.6543 30.1894 36.857 30.2223 37.074C30.2551 37.291 30.4585 37.4409 30.6748 37.4081C30.8918 37.3752 31.0417 37.1725 31.0089 36.9555C30.9789 36.7585 30.809 36.6165 30.6156 36.6165V36.6172Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.6541 29.4496C8.27581 29.4496 5.98461 28.5095 4.16663 26.7786C2.25443 24.9585 1.18092 22.5595 1.1438 20.0242C1.10811 17.5574 2.04887 15.1884 3.79475 13.3554C5.9168 11.1256 8.89894 9.97714 11.7704 10.2833C13.6933 10.4882 15.1801 12.0628 15.2751 13.9935L15.3179 14.8601L16.1337 14.8229C18.1187 14.7323 19.8582 16.1406 20.1837 18.1013C20.6726 21.0427 19.6719 24.1562 17.5077 26.4296C15.7519 28.2747 13.4471 29.3446 11.0188 29.4431C10.8968 29.4481 10.7747 29.4503 10.6534 29.4503L10.6541 29.4496Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.1659 23.877C17.7576 23.877 16.3965 23.1176 15.6927 21.7864L14.4557 19.4488L8.61778 15.4238C7.48788 14.6451 6.84691 13.3325 6.92685 11.962C7.0068 10.5916 7.79694 9.36317 9.01036 8.72149L11.8383 7.22542C12.44 6.90708 13.0996 6.7579 13.7527 6.77075C14.1095 6.22257 14.6049 5.76219 15.2059 5.44385L18.021 3.9542C19.2373 3.3111 20.7012 3.35107 21.8804 4.05984C23.0595 4.76862 23.7812 6.04342 23.7826 7.41957L23.7912 14.5209L25.0231 16.8499C26.0367 18.7664 25.3051 21.1418 23.3886 22.1554L20.9974 23.4202C20.4122 23.73 19.784 23.8763 19.1652 23.8763L19.1659 23.877Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.2871 21.5744C22.4962 21.5744 21.7182 21.3353 21.058 20.8799C20.2007 20.2889 19.614 19.381 19.4256 18.3574L17.4855 7.78718C17.0944 5.65443 18.5055 3.60876 20.6383 3.2169L25.5876 2.30827C27.534 1.95067 29.4341 2.30113 30.9359 3.2947C32.3955 4.25972 33.4126 5.79647 33.7274 7.51096C33.8295 8.06627 33.8552 8.62373 33.8102 9.16905C34.494 10.0406 34.9608 11.0891 35.1728 12.2433C35.5304 14.1911 35.0964 16.1205 33.9508 17.6751C32.8488 19.1719 31.1571 20.1947 29.1885 20.5559L23.9966 21.5087C23.761 21.5523 23.524 21.573 23.2878 21.573L23.2871 21.5744Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.8317 21.4909C39.6068 21.4909 39.3784 21.4717 39.1493 21.431L31.5141 20.092C30.4884 19.9121 29.5762 19.3325 28.9787 18.4803C28.3806 17.628 28.1458 16.5731 28.3256 15.5474L30.1822 4.96141C30.5569 2.8258 32.5919 1.39754 34.7268 1.77298L42.3763 3.11416C44.5119 3.48889 45.9394 5.52314 45.5647 7.65875L45.13 10.137C45.0258 10.7301 44.7938 11.269 44.4669 11.7294L44.0558 14.0734C44.1985 14.6088 44.2306 15.1826 44.1286 15.7636L43.6939 18.2419C43.3598 20.1483 41.7025 21.4902 39.8317 21.4902V21.4909Z"
        fill="#C8C9CA"
      />
      <path
        d="M47.9693 26.124C47.2855 26.124 46.601 25.9456 45.9901 25.5887L43.3027 24.0198C42.858 23.76 42.4769 23.421 42.1728 23.027C41.6767 22.9556 41.1942 22.7893 40.7524 22.5316L38.4041 21.1605C36.5319 20.067 35.9002 17.663 36.9937 15.7908L42.4126 6.50957C42.9372 5.61022 43.7981 4.95641 44.8052 4.69231C45.8123 4.4275 46.883 4.57382 47.7823 5.09916L51.6503 7.35754C54.192 8.84147 55.1549 10.7951 55.5146 12.1726C56 14.0306 55.6995 16.0256 54.6681 17.7915C53.9922 18.9492 53.0478 19.9035 51.9386 20.5509C51.9244 20.5595 51.9101 20.5673 51.8951 20.5759V22.199C51.8951 23.6051 51.1435 24.9035 49.9244 25.6037C49.3191 25.9513 48.6439 26.1248 47.9693 26.1248V26.124Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.8668 36.9041C19.1702 36.9041 18.4707 36.7193 17.8454 36.3431L11.8876 32.7628C10.7948 32.1061 10.0881 30.9591 9.99463 29.6872C9.90041 28.4152 10.43 27.1768 11.415 26.3667L12.9761 25.0819C13.3329 24.7878 13.7305 24.5658 14.1488 24.416C14.3758 24.0341 14.6706 23.6865 15.0274 23.3931L16.0303 22.5673C16.3872 22.2732 16.7847 22.0512 17.203 21.9013C17.43 21.5195 17.7248 21.1726 18.0817 20.8785L19.6427 19.5937C20.627 18.7836 21.9446 18.5016 23.1744 18.8385C24.4043 19.1754 25.3943 20.0884 25.829 21.2875L28.1965 27.8228C28.7626 29.3852 28.2836 31.1347 27.0003 32.1911L25.6498 33.3024C25.315 33.5779 24.9446 33.7906 24.5549 33.9391C24.3343 34.2931 24.0538 34.6158 23.719 34.8913L22.3615 36.009C21.642 36.6015 20.7562 36.9034 19.8661 36.9034L19.8668 36.9041Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.4584 32.5787C24.7796 32.5787 24.1058 32.4024 23.5063 32.059C22.6026 31.5415 21.9424 30.6857 21.6697 29.6807L19.9545 23.3582C19.3871 21.2661 20.6226 19.1091 22.7154 18.5416L27.7503 17.1755C28.7553 16.9028 29.8274 17.0406 30.7303 17.558C31.634 18.0755 32.2942 18.9313 32.5669 19.9363L32.9687 21.4167C33.0972 21.8899 33.1336 22.366 33.0879 22.8271L33.2785 23.5302C33.5462 23.9056 33.7482 24.3282 33.8709 24.7814L34.2728 26.2618C34.5455 27.2668 34.4077 28.3389 33.8902 29.2418C33.3727 30.1454 32.5169 30.8056 31.5126 31.0783L26.4877 32.4416C26.1494 32.5337 25.8039 32.5787 25.4599 32.5787H25.4584Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.3985 31.7093C36.2664 31.7093 36.1329 31.7029 35.9988 31.6893L30.8182 31.1647C28.6612 30.9463 27.0895 29.0205 27.3079 26.8635L27.9674 20.346C28.0723 19.3103 28.5841 18.3582 29.3907 17.7001C30.1972 17.042 31.2322 16.7315 32.2686 16.8357L37.4591 17.361C39.6162 17.5794 41.1879 19.5052 40.9695 21.6622L40.8153 23.1883C40.7668 23.6651 40.6326 24.124 40.4227 24.5451L40.3492 25.2725C40.4648 25.7186 40.502 26.1861 40.4548 26.6536L40.3007 28.1797C40.0958 30.2025 38.3899 31.71 36.3999 31.71L36.3985 31.7093Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.686 35.0011C42.0315 35.0011 41.3755 34.8377 40.7816 34.5079L38.8338 33.4273C38.4762 33.2288 38.1564 32.9783 37.8823 32.6885C37.4947 32.6107 37.1128 32.473 36.7488 32.271L35.1514 31.3845C34.2406 30.8791 33.5683 30.0333 33.282 29.0326C32.9951 28.0319 33.1179 26.9576 33.6232 26.0476L36.8016 20.3196C37.8537 18.4238 40.2435 17.7393 42.1392 18.7914L43.7367 19.6779C44.0835 19.8699 44.3898 20.1068 44.6517 20.3781C45.0243 20.4587 45.3862 20.5936 45.7267 20.7828L47.6574 21.8542C49 22.5993 49.7852 24.0583 49.6674 25.5893C49.5489 27.1204 48.5496 28.4416 47.1085 28.9719L46.4211 29.2246L46.5853 30.6179C46.7573 32.0811 46.0964 33.5172 44.873 34.3373C44.2135 34.7792 43.4511 35.0026 42.686 35.0026V35.0011Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.6816 45.1939C26.6702 45.1939 23.1428 42.2546 22.5289 38.1697C22.2013 35.9905 22.7423 33.8142 24.0514 32.0419C25.3604 30.2696 27.2819 29.1133 29.4603 28.7857C33.9585 28.1091 38.1684 31.219 38.8443 35.7172C39.1719 37.8963 38.6309 40.0726 37.3218 41.8449C36.0128 43.6172 34.0913 44.7735 31.9129 45.1011C31.4996 45.1632 31.0878 45.1932 30.6816 45.1932V45.1939ZM30.6866 36.5458C30.6673 36.5458 30.6474 36.5473 30.6274 36.5501C30.4104 36.583 30.2605 36.7857 30.2933 37.0027C30.3262 37.2196 30.5289 37.3703 30.7459 37.3367C30.9628 37.3039 31.1127 37.1012 31.0799 36.8842C31.0499 36.6872 30.88 36.5451 30.6866 36.5451V36.5458Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.7251 29.3781C8.34686 29.3781 6.05565 28.4381 4.23767 26.7072C2.32548 24.8871 1.25196 22.4881 1.21485 19.9528C1.17916 17.486 2.11991 15.117 3.8658 13.284C5.98856 11.0542 8.96927 9.90573 11.8415 10.2119C13.7644 10.4168 15.2512 11.9914 15.3461 13.9221L15.3889 14.7886L16.2048 14.7515C18.1898 14.6609 19.9292 16.0691 20.2547 18.0299C20.7437 20.9713 19.7429 24.0848 17.5788 26.3582C15.8229 28.2033 13.5181 29.2732 11.0899 29.3717C10.9678 29.3767 10.8458 29.3789 10.7244 29.3789L10.7251 29.3781Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.2372 23.8057C17.8289 23.8057 16.4678 23.0463 15.764 21.7151L14.527 19.3775L8.68907 15.3525C7.55917 14.5738 6.9182 13.2612 6.99814 11.8907C7.07809 10.5203 7.86823 9.29188 9.08165 8.6502L11.9096 7.15413C12.5113 6.83579 13.1708 6.68733 13.8239 6.69946C14.1808 6.15128 14.6762 5.6909 15.2772 5.37256L18.0923 3.88291C19.3086 3.23981 20.7725 3.27978 21.9517 3.98855C23.1308 4.69733 23.8524 5.97213 23.8539 7.34828L23.8624 14.4496L25.0944 16.7786C26.108 18.6951 25.3764 21.0706 23.4599 22.0841L21.0687 23.3489C20.4834 23.6587 19.8553 23.805 19.2365 23.805L19.2372 23.8057Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.3584 21.503C22.5675 21.503 21.7895 21.2639 21.1292 20.8085C20.272 20.2175 19.6853 19.3096 19.4969 18.286L17.5568 7.71577C17.1657 5.58302 18.5768 3.53735 20.7096 3.14549L25.6588 2.23686C27.6053 1.87926 29.5054 2.22972 31.0071 3.22329C32.4668 4.18831 33.4839 5.72506 33.7987 7.43954C33.9008 7.99486 33.9265 8.55231 33.8815 9.09764C34.5653 9.96915 35.0321 11.0177 35.2441 12.1719C35.6017 14.1197 35.1677 16.0491 34.0221 17.6037C32.9201 19.1004 31.2284 20.1233 29.2598 20.4844L24.0679 21.4373C23.8323 21.4809 23.5953 21.5016 23.3591 21.5016L23.3584 21.503Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.9032 21.4196C39.6784 21.4196 39.4499 21.4003 39.2208 21.3596L31.5856 20.0206C30.5599 19.8407 29.6477 19.2611 29.0503 18.4089C28.4521 17.5567 28.2173 16.5017 28.3972 15.476L30.2537 4.89006C30.6284 2.75445 32.6634 1.32619 34.7983 1.70163L42.4478 3.04281C44.5834 3.41754 46.0109 5.45179 45.6362 7.5874L45.2015 10.0656C45.0973 10.6588 44.8653 11.1977 44.5384 11.658L44.1273 14.0021C44.27 14.5374 44.3022 15.1113 44.2001 15.6923L43.7654 18.1705C43.4314 20.077 41.774 21.4189 39.9032 21.4189V21.4196Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.0406 26.0526C47.3568 26.0526 46.6723 25.8742 46.0613 25.5173L43.374 23.9484C42.9293 23.6886 42.5482 23.3496 42.2441 22.9556C41.748 22.8842 41.2655 22.7179 40.8237 22.4602L38.4754 21.0891C36.6031 19.9956 35.9715 17.5916 37.065 15.7193L42.4839 6.43816C43.0085 5.53881 43.8693 4.88499 44.8765 4.6209C45.8836 4.35609 46.9543 4.50241 47.8536 5.02775L51.7216 7.28612C54.2633 8.77006 55.2262 10.7237 55.5859 12.1012C56.0713 13.9592 55.7708 15.9542 54.7394 17.72C54.0634 18.8778 53.1191 19.8321 52.0099 20.4795C51.9956 20.4881 51.9814 20.4959 51.9664 20.5045V22.1276C51.9664 23.5337 51.2148 24.8321 49.9957 25.5323C49.3904 25.8799 48.7151 26.0533 48.0406 26.0533V26.0526Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.9381 36.8328C19.2415 36.8328 18.542 36.648 17.9167 36.2718L11.9588 32.6915C10.8661 32.0349 10.1594 30.8878 10.0659 29.6159C9.9717 28.3439 10.5013 27.1055 11.4863 26.2954L13.0473 25.0106C13.4042 24.7165 13.8018 24.4946 14.2201 24.3447C14.4471 23.9628 14.7418 23.6152 15.0987 23.3218L16.1016 22.496C16.4585 22.2019 16.856 21.9799 17.2743 21.83C17.5013 21.4482 17.7961 21.1013 18.153 20.8072L19.714 19.5224C20.699 18.7123 22.0159 18.4304 23.2457 18.7673C24.4756 19.1042 25.4656 20.0171 25.9002 21.2162L28.2678 27.7515C28.8339 29.314 28.3549 31.0634 27.0715 32.1198L25.7211 33.2311C25.3863 33.5067 25.0159 33.7194 24.6262 33.8678C24.4056 34.2218 24.1251 34.5445 23.7903 34.82L22.4327 35.9378C21.7133 36.5302 20.8275 36.8321 19.9374 36.8321L19.9381 36.8328Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.53 32.5072C24.8512 32.5072 24.1774 32.3309 23.5778 31.9876C22.6742 31.4701 22.0139 30.6143 21.7413 29.6093L20.0261 23.2867C19.4586 21.1947 20.6942 19.0377 22.7869 18.4702L27.8219 17.104C28.8269 16.8314 29.899 16.9691 30.8019 17.4866C31.7055 18.0041 32.3658 18.8599 32.6384 19.8649L33.0403 21.3453C33.1688 21.8185 33.2052 22.2946 33.1595 22.7557L33.3501 23.4588C33.6177 23.8342 33.8197 24.2568 33.9425 24.71L34.3443 26.1904C34.617 27.1954 34.4792 28.2674 33.9618 29.1704C33.4443 30.074 32.5885 30.7342 31.5842 31.0069L26.5592 32.3702C26.2209 32.4623 25.8754 32.5072 25.5314 32.5072H25.53Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.4698 31.6379C36.3377 31.6379 36.2042 31.6315 36.07 31.6179L30.8895 31.0933C28.7325 30.8749 27.1607 28.9491 27.3792 26.7921L28.0387 20.2746C28.1436 19.239 28.6554 18.2868 29.4619 17.6287C30.2685 16.9706 31.3035 16.6594 32.3399 16.7643L37.5304 17.2896C39.6874 17.5081 41.2592 19.4338 41.0408 21.5908L40.8866 23.1169C40.838 23.5937 40.7039 24.0526 40.494 24.4738L40.4205 25.2011C40.5361 25.6472 40.5732 26.1147 40.5261 26.5822L40.372 28.1083C40.1671 30.1311 38.4612 31.6386 36.4712 31.6386L36.4698 31.6379Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.7573 34.9299C42.1027 34.9299 41.4468 34.7665 40.8529 34.4367L38.905 33.356C38.5474 33.1576 38.2277 32.9071 37.9536 32.6173C37.566 32.5395 37.1841 32.4017 36.8201 32.1997L35.2227 31.3132C34.3119 30.8079 33.6395 29.9621 33.3533 28.9613C33.0664 27.9606 33.1892 26.8864 33.6945 25.9763L36.8729 20.2483C37.925 18.3525 40.3147 17.668 42.2105 18.7201L43.8079 19.6066C44.1548 19.7986 44.461 20.0356 44.723 20.3069C45.0956 20.3875 45.4575 20.5224 45.7979 20.7116L47.7287 21.7829C49.0713 22.5281 49.8564 23.9871 49.7387 25.5181C49.6202 27.0491 48.6209 28.3703 47.1798 28.9007L46.4924 29.1533L46.6566 30.5466C46.8286 32.0099 46.1677 33.446 44.9443 34.2661C44.2847 34.7079 43.5224 34.9313 42.7573 34.9313V34.9299Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.7531 45.1226C26.7417 45.1226 23.2143 42.1832 22.6004 38.0983C22.2728 35.9192 22.8139 33.7429 24.1229 31.9706C25.432 30.1983 27.3534 29.042 29.5319 28.7144C34.0301 28.0377 38.2399 31.1476 38.9158 35.6458C39.2435 37.825 38.7024 40.0012 37.3934 41.7735C36.0843 43.5458 34.1628 44.7021 31.9844 45.0298C31.5711 45.0919 31.1593 45.1218 30.7531 45.1218V45.1226ZM30.7581 36.4745C30.7389 36.4745 30.7189 36.4759 30.6989 36.4788C30.4819 36.5116 30.332 36.7143 30.3649 36.9313C30.3977 37.1483 30.6011 37.2989 30.8174 37.2654C31.0344 37.2325 31.1843 37.0298 31.1514 36.8128C31.1215 36.6158 30.9516 36.4738 30.7581 36.4738V36.4745Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.7967 29.3067C8.41839 29.3067 6.12718 28.3667 4.30921 26.6358C2.39701 24.8157 1.3235 22.4167 1.28638 19.8814C1.25069 17.4146 2.19144 15.0456 3.93733 13.2126C6.05938 10.9828 9.04152 9.83431 11.913 10.1405C13.8359 10.3454 15.3227 11.92 15.4176 13.8507L15.4605 14.7172L16.2763 14.6801C18.2613 14.5916 20.0008 15.9977 20.3263 17.9585C20.8152 20.8999 19.8145 24.0134 17.6503 26.2868C15.8944 28.1319 13.5897 29.2018 11.1614 29.3003C11.0394 29.3053 10.9173 29.3075 10.796 29.3075L10.7967 29.3067Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.3085 23.7344C17.9002 23.7344 16.5391 22.9749 15.8353 21.6437L14.5983 19.3061L8.76036 15.2812C7.63046 14.5024 6.98949 13.1898 7.06943 11.8194C7.14938 10.4489 7.93952 9.22053 9.15293 8.57885L11.9809 7.08278C12.5826 6.76444 13.2421 6.61526 13.8952 6.62811C14.2521 6.07993 14.7475 5.61955 15.3485 5.30121L18.1636 3.81156C19.3799 3.16846 20.8438 3.20843 22.023 3.9172C23.2021 4.62598 23.9237 5.90078 23.9252 7.27693L23.9337 14.3783L25.1657 16.7073C26.1793 18.6238 25.4476 20.9992 23.5312 22.0128L21.14 23.2776C20.5547 23.5873 19.9266 23.7337 19.3078 23.7337L19.3085 23.7344Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.4299 21.4317C22.639 21.4317 21.861 21.1926 21.2008 20.7372C20.3435 20.1462 19.7568 19.2383 19.5684 18.2147L17.6284 7.64448C17.2372 5.51173 18.6483 3.46606 20.7811 3.0742L25.7304 2.16557C27.6768 1.80797 29.5769 2.15843 31.0787 3.152C32.5383 4.11702 33.5555 5.65377 33.8702 7.36826C33.9723 7.92357 33.998 8.48103 33.953 9.02635C34.6368 9.89786 35.1036 10.9464 35.3156 12.1006C35.6732 14.0484 35.2393 15.9778 34.0937 17.5324C32.9916 19.0292 31.2999 20.052 29.3314 20.4132L24.1394 21.366C23.9038 21.4096 23.6669 21.4303 23.4306 21.4303L23.4299 21.4317Z"
        fill="#C8C9CA"
      />
      <path
        d="M39.9742 21.3482C39.7494 21.3482 39.521 21.3289 39.2919 21.2882L31.6566 19.9492C30.631 19.7693 29.7188 19.1897 29.1213 18.3375C28.5232 17.4852 28.2884 16.4303 28.4682 15.4046L30.3247 4.81864C30.6995 2.68304 32.7344 1.25478 34.8693 1.63022L42.5188 2.9714C44.6544 3.34613 46.082 5.38038 45.7073 7.51599L45.2726 9.99421C45.1684 10.5874 44.9364 11.1263 44.6095 11.5866L44.1983 13.9307C44.3411 14.466 44.3732 15.0399 44.2711 15.6209L43.8365 18.0991C43.5024 20.0056 41.845 21.3475 39.9742 21.3475V21.3482Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.1124 25.9813C47.4286 25.9813 46.7441 25.8028 46.1331 25.4459L43.4458 23.8771C43.0011 23.6173 42.6199 23.2782 42.3159 22.8842C41.8198 22.8128 41.3373 22.6465 40.8955 22.3889L38.5471 21.0177C36.6749 19.9242 36.0432 17.5202 37.1367 15.648L42.5557 6.36681C43.0803 5.46746 43.9411 4.81364 44.9483 4.54955C45.9554 4.28474 47.026 4.43106 47.9254 4.9564L51.7933 7.21477C54.3351 8.69871 55.298 10.6523 55.6577 12.0299C56.1431 13.8878 55.8426 15.8828 54.8112 17.6487C54.1352 18.8064 53.1909 19.7608 52.0817 20.4081C52.0674 20.4167 52.0531 20.4246 52.0382 20.4331V22.0562C52.0382 23.4624 51.2866 24.7607 50.0674 25.4609C49.4622 25.8085 48.7869 25.982 48.1124 25.982V25.9813Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.0096 36.7614C19.313 36.7614 18.6135 36.5766 17.9882 36.2004L12.0304 32.6201C10.9376 31.9634 10.231 30.8164 10.1375 29.5445C10.0432 28.2725 10.5729 27.0341 11.5579 26.224L13.1189 24.9392C13.4758 24.6451 13.8733 24.4231 14.2916 24.2733C14.5186 23.8914 14.8134 23.5438 15.1703 23.2504L16.1731 22.4246C16.53 22.1305 16.9276 21.9085 17.3458 21.7586C17.5728 21.3768 17.8676 21.0299 18.2245 20.7358L19.7855 19.451C20.7705 18.6409 22.0874 18.3589 23.3173 18.6958C24.5471 19.0327 25.5371 19.9457 25.9718 21.1448L28.3394 27.6801C28.9054 29.2425 28.4264 30.992 27.1431 32.0484L25.7926 33.1597C25.4579 33.4352 25.0874 33.6479 24.6977 33.7964C24.4771 34.1504 24.1966 34.4731 23.8619 34.7486L22.5043 35.8663C21.7848 36.4588 20.899 36.7607 20.0089 36.7607L20.0096 36.7614Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.6013 32.4359C24.9225 32.4359 24.2487 32.2596 23.6491 31.9163C22.7454 31.3988 22.0852 30.543 21.8125 29.538L20.0974 23.2154C19.5299 21.1233 20.7654 18.9663 22.8582 18.3989L27.8932 17.0327C28.8982 16.76 29.9703 16.8978 30.8732 17.4153C31.7768 17.9328 32.437 18.7886 32.7097 19.7936L33.1116 21.2739C33.24 21.7472 33.2764 22.2232 33.2308 22.6843L33.4213 23.3874C33.689 23.7629 33.891 24.1854 34.0138 24.6387L34.4156 26.119C34.6883 27.124 34.5505 28.1961 34.033 29.099C33.5156 30.0026 32.6597 30.6629 31.6555 30.9355L26.6305 32.2989C26.2922 32.3909 25.9467 32.4359 25.6027 32.4359H25.6013Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.541 31.5666C36.409 31.5666 36.2755 31.5602 36.1413 31.5466L30.9608 31.022C28.8038 30.8036 27.232 28.8778 27.4504 26.7208L28.11 20.2033C28.2149 19.1676 28.7267 18.2155 29.5332 17.5574C30.3398 16.8993 31.3748 16.5888 32.4112 16.693L37.6017 17.2183C39.7587 17.4367 41.3305 19.3625 41.112 21.5195L40.9579 23.0456C40.9093 23.5224 40.7751 23.9813 40.5653 24.4024L40.4918 25.1298C40.6074 25.5759 40.6445 26.0434 40.5974 26.5109L40.4432 28.037C40.2384 30.0598 38.5325 31.5673 36.5425 31.5673L36.541 31.5666Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.8286 34.8585C42.174 34.8585 41.5181 34.695 40.9242 34.3653L38.9763 33.2846C38.6187 33.0862 38.299 32.8357 38.0249 32.5459C37.6373 32.4681 37.2554 32.3303 36.8914 32.1283L35.294 31.2418C34.3832 30.7365 33.7108 29.8906 33.4246 28.8899C33.1377 27.8892 33.2604 26.815 33.7658 25.9049L36.9442 20.1769C37.9963 18.2811 40.386 17.5966 42.2818 18.6487L43.8792 19.5352C44.2261 19.7272 44.5323 19.9642 44.7943 20.2354C45.1669 20.3161 45.5288 20.451 45.8692 20.6402L47.8 21.7115C49.1426 22.4567 49.9277 23.9157 49.81 25.4467C49.6915 26.9777 48.6922 28.2989 47.2511 28.8293L46.5637 29.0819L46.7279 30.4752C46.8999 31.9385 46.239 33.3746 45.0156 34.1947C44.356 34.6365 43.5937 34.8599 42.8286 34.8599V34.8585Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.8244 45.0511C26.813 45.0511 23.2856 42.1118 22.6717 38.0269C22.3441 35.8478 22.8851 33.6715 24.1942 31.8992C25.5033 30.1269 27.4247 28.9706 29.6032 28.643C34.1014 27.9663 38.3112 31.0762 38.9871 35.5744C39.3148 37.7535 38.7737 39.9298 37.4647 41.7021C36.1556 43.4744 34.2341 44.6307 32.0557 44.9584C31.6424 45.0205 31.2306 45.0504 30.8244 45.0504V45.0511ZM30.8294 36.4031C30.8102 36.4031 30.7902 36.4045 30.7702 36.4074C30.5532 36.4402 30.4033 36.6429 30.4361 36.8599C30.469 37.0769 30.6724 37.2275 30.8887 37.1939C31.1057 37.1611 31.2556 36.9584 31.2227 36.7414C31.1927 36.5444 31.0229 36.4024 30.8294 36.4024V36.4031Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.868 29.2354C8.48968 29.2354 6.19847 28.2953 4.38049 26.5645C2.4683 24.7443 1.39478 22.3453 1.35767 19.81C1.32198 17.3432 2.26273 14.9742 4.00862 13.1412C6.13138 10.9114 9.11209 9.76368 11.9843 10.0692C13.9072 10.274 15.394 11.8486 15.4889 13.7794L15.5318 14.6459L16.3476 14.6088C18.3333 14.5203 20.0721 15.9264 20.3975 17.8871C20.8865 20.8286 19.8858 23.942 17.7216 26.2154C15.9657 28.0605 13.661 29.1305 11.2327 29.229C11.1107 29.234 10.9886 29.2361 10.8673 29.2361L10.868 29.2354Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.3798 23.663C17.9715 23.663 16.6103 22.9035 15.9066 21.5723L14.6696 19.2347L8.83165 15.2098C7.70175 14.431 7.06078 13.1184 7.14072 11.748C7.22066 10.3775 8.01081 9.14912 9.22422 8.50744L12.0522 7.01137C12.6539 6.69303 13.3134 6.54456 13.9665 6.5567C14.3234 6.00852 14.8188 5.54814 15.4198 5.2298L18.2349 3.74015C19.4512 3.09704 20.9151 3.13702 22.0943 3.84579C23.2734 4.55457 23.995 5.82936 23.9965 7.20552L24.005 14.3068L25.237 16.6359C26.2505 18.5524 25.5189 20.9278 23.6025 21.9413L21.2113 23.2062C20.626 23.5159 19.9979 23.6623 19.3791 23.6623L19.3798 23.663Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.5009 21.3603C22.7101 21.3603 21.9321 21.1212 21.2718 20.6658C20.4146 20.0748 19.8279 19.1669 19.6394 18.1433L17.6994 7.57307C17.3083 5.44032 18.7194 3.39465 20.8521 3.00279L25.8014 2.09416C27.7479 1.73656 29.6479 2.08702 31.1497 3.08059C32.6094 4.04561 33.6265 5.58236 33.9413 7.29684C34.0434 7.85216 34.0691 8.40961 34.0241 8.95494C34.7079 9.82645 35.1747 10.875 35.3867 12.0292C35.7443 13.977 35.3103 15.9064 34.1647 17.461C33.0626 18.9577 31.371 19.9806 29.4024 20.3417L24.2104 21.2946C23.9749 21.3382 23.7379 21.3589 23.5017 21.3589L23.5009 21.3603Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.0458 21.2768C39.8209 21.2768 39.5925 21.2575 39.3634 21.2168L31.7282 19.8778C30.7025 19.6979 29.7903 19.1183 29.1929 18.2661C28.5947 17.4138 28.3599 16.3589 28.5398 15.3332L30.3963 4.74723C30.771 2.61163 32.806 1.18337 34.9409 1.55881L42.5904 2.89999C44.726 3.27472 46.1535 5.30897 45.7788 7.44458L45.3441 9.9228C45.2399 10.5159 45.0079 11.0548 44.681 11.5152L44.2699 13.8593C44.4126 14.3946 44.4447 14.9685 44.3427 15.5495L43.908 18.0277C43.5739 19.9342 41.9166 21.2761 40.0458 21.2761V21.2768Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.1835 25.9099C47.4997 25.9099 46.8152 25.7315 46.2042 25.3746L43.5168 23.8057C43.0721 23.5459 42.691 23.2069 42.3869 22.8129C41.8908 22.7415 41.4083 22.5752 40.9665 22.3175L38.6182 20.9464C36.746 19.8529 36.1143 17.4489 37.2078 15.5766L42.6267 6.29546C43.1514 5.39611 44.0122 4.74229 45.0193 4.4782C46.0264 4.21339 47.0971 4.35971 47.9964 4.88505L51.8644 7.14342C54.4061 8.62736 55.369 10.581 55.7287 11.9585C56.2141 13.8165 55.9136 15.8115 54.8822 17.5773C54.2063 18.7351 53.2619 19.6894 52.1527 20.3368C52.1385 20.3454 52.1242 20.3532 52.1092 20.3618V21.9849C52.1092 23.391 51.3576 24.6894 50.1385 25.3896C49.5332 25.7372 48.858 25.9106 48.1835 25.9106V25.9099Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.0807 36.69C19.384 36.69 18.6845 36.5051 18.0593 36.129L12.1014 32.5487C11.0086 31.892 10.302 30.745 10.2085 29.4731C10.1143 28.2011 10.6439 26.9627 11.6289 26.1526L13.1899 24.8678C13.5468 24.5737 13.9444 24.3517 14.3627 24.2018C14.5896 23.82 14.8844 23.4724 15.2413 23.179L16.2442 22.3532C16.601 22.0591 16.9986 21.8371 17.4169 21.6872C17.6439 21.3054 17.9387 20.9585 18.2955 20.6644L19.8566 19.3796C20.8416 18.5695 22.1585 18.2875 23.3883 18.6244C24.6181 18.9613 25.6081 19.8742 26.0428 21.0734L28.4104 27.6087C28.9764 29.1711 28.4975 30.9206 27.2141 31.977L25.8637 33.0883C25.5289 33.3638 25.1585 33.5765 24.7687 33.725C24.5482 34.079 24.2677 34.4017 23.9329 34.6772L22.5753 35.7949C21.8558 36.3874 20.97 36.6893 20.08 36.6893L20.0807 36.69Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.6725 32.3645C24.9937 32.3645 24.3199 32.1882 23.7204 31.8449C22.8167 31.3274 22.1565 30.4716 21.8838 29.4666L20.1686 23.144C19.6012 21.0519 20.8367 18.8949 22.9295 18.3274L27.9645 16.9613C28.9695 16.6886 30.0415 16.8264 30.9445 17.3439C31.8481 17.8613 32.5083 18.7172 32.781 19.7222L33.1829 21.2025C33.3113 21.6757 33.3477 22.1518 33.3021 22.6129L33.4926 23.316C33.7603 23.6914 33.9623 24.114 34.0851 24.5672L34.4869 26.0476C34.7596 27.0526 34.6218 28.1247 34.1043 29.0276C33.5868 29.9312 32.731 30.5915 31.7268 30.8641L26.7018 32.2274C26.3635 32.3195 26.018 32.3645 25.674 32.3645H25.6725Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.6126 31.4951C36.4805 31.4951 36.3471 31.4887 36.2129 31.4751L31.0323 30.9505C28.8753 30.7321 27.3036 28.8064 27.522 26.6493L28.1815 20.1319C28.2864 19.0962 28.7982 18.144 29.6048 17.4859C30.4113 16.8278 31.4463 16.5166 32.4827 16.6215L37.6732 17.1469C39.8303 17.3653 41.402 19.2911 41.1836 21.4481L41.0294 22.9741C40.9809 23.4509 40.8467 23.9099 40.6368 24.331L40.5633 25.0583C40.6789 25.5044 40.7161 25.972 40.669 26.4395L40.5148 27.9655C40.3099 29.9884 38.604 31.4958 36.614 31.4958L36.6126 31.4951Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.9001 34.7871C42.2456 34.7871 41.5896 34.6236 40.9957 34.2939L39.0479 33.2132C38.6903 33.0148 38.3705 32.7643 38.0964 32.4745C37.7088 32.3967 37.327 32.2589 36.9629 32.0569L35.3655 31.1704C34.4547 30.6651 33.7824 29.8192 33.4961 28.8185C33.2092 27.8178 33.332 26.7436 33.8373 25.8335L37.0158 20.1055C38.0679 18.2097 40.4568 17.5252 42.3533 18.5773L43.9508 19.4638C44.2977 19.6558 44.6039 19.8928 44.8658 20.164C45.2384 20.2447 45.6003 20.3796 45.9408 20.5687L47.8715 21.6401C49.2141 22.3853 49.9993 23.8442 49.8815 25.3753C49.763 26.9063 48.7637 28.2275 47.3226 28.7579L46.6353 29.0105L46.7994 30.4038C46.9714 31.867 46.3105 33.3032 45.0871 34.1233C44.4276 34.5651 43.6653 34.7885 42.9001 34.7885V34.7871Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.8957 44.9797C26.8843 44.9797 23.3569 42.0404 22.743 37.9555C22.4154 35.7764 22.9564 33.6001 24.2655 31.8278C25.5746 30.0555 27.496 28.8992 29.6745 28.5715C34.1727 27.8949 38.3825 31.0048 39.0584 35.503C39.386 37.6821 38.845 39.8584 37.5359 41.6307C36.2269 43.403 34.3054 44.5593 32.127 44.8869C31.7137 44.949 31.3019 44.979 30.8957 44.979V44.9797ZM30.9007 36.3317C30.8815 36.3317 30.8615 36.3331 30.8415 36.336C30.6245 36.3688 30.4746 36.5715 30.5074 36.7885C30.5403 37.0055 30.7437 37.1561 30.96 37.1225C31.177 37.0897 31.3268 36.887 31.294 36.67C31.264 36.473 31.0942 36.331 30.9007 36.331V36.3317Z"
        fill="#C8C9CA"
      />
      <path
        d="M10.9397 29.164C8.56146 29.164 6.27025 28.2239 4.45227 26.493C2.54008 24.6729 1.46656 22.2739 1.42945 19.7386C1.39376 17.2718 2.33451 14.9028 4.0804 13.0698C6.20244 10.84 9.18458 9.69155 12.0561 9.99776C13.979 10.2026 15.4658 11.7772 15.5607 13.7079L15.6035 14.5745L16.4194 14.5374C18.4051 14.4481 20.1438 15.855 20.4693 17.8157C20.9583 20.7572 19.9575 23.8706 17.7934 26.144C16.0375 27.9891 13.7327 29.0591 11.3045 29.1576C11.1824 29.1625 11.0604 29.1647 10.939 29.1647L10.9397 29.164Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.4513 23.5916C18.043 23.5916 16.6819 22.8321 15.9781 21.5009L14.7411 19.1633L8.90318 15.1383C7.77328 14.3596 7.13231 13.047 7.21226 11.6766C7.2922 10.3061 8.08234 9.07771 9.29576 8.43603L12.1237 6.93996C12.7254 6.62162 13.385 6.47244 14.0381 6.48529C14.3949 5.93711 14.8903 5.47673 15.4913 5.15839L18.3064 3.66874C19.5227 3.02563 20.9866 3.0656 22.1658 3.77438C23.3449 4.48316 24.0666 5.75795 24.068 7.13411L24.0766 14.2354L25.3085 16.5645C26.3221 18.4809 25.5905 20.8564 23.674 21.8699L21.2829 23.1347C20.6976 23.4445 20.0694 23.5908 19.4506 23.5908L19.4513 23.5916Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.5727 21.2889C22.7819 21.2889 22.0038 21.0498 21.3436 20.5944C20.4864 20.0034 19.8996 19.0955 19.7112 18.0719L17.7712 7.50166C17.38 5.36891 18.7912 3.32324 20.9239 2.93138L25.8732 2.02275C27.8197 1.66515 29.7197 2.01561 31.2215 3.00918C32.6812 3.9742 33.6983 5.51095 34.0131 7.22543C34.1151 7.78075 34.1408 8.3382 34.0959 8.88353C34.7797 9.75504 35.2465 10.8036 35.4585 11.9577C35.8161 13.9056 35.3821 15.835 34.2365 17.3896C33.1344 18.8863 31.4428 19.9092 29.4742 20.2703L24.2822 21.2232C24.0467 21.2668 23.8097 21.2875 23.5734 21.2875L23.5727 21.2889Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.1171 21.2054C39.8922 21.2054 39.6638 21.1862 39.4347 21.1455L31.7995 19.8064C30.7738 19.6266 29.8616 19.047 29.2642 18.1947C28.666 17.3425 28.4312 16.2875 28.611 15.2618L30.4676 4.67588C30.8423 2.54028 32.8773 1.11202 35.0122 1.48746L42.6617 2.82864C44.7973 3.20337 46.2248 5.23762 45.8501 7.37323L45.4154 9.85145C45.3112 10.4446 45.0792 10.9835 44.7523 11.4439L44.3412 13.7879C44.4839 14.3232 44.516 14.8971 44.414 15.4781L43.9793 17.9563C43.6452 19.8628 41.9879 21.2047 40.1171 21.2047V21.2054Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.255 25.8385C47.5712 25.8385 46.8867 25.6601 46.2757 25.3032L43.5883 23.7343C43.1437 23.4745 42.7625 23.1355 42.4584 22.7415C41.9624 22.6701 41.4799 22.5038 41.038 22.2461L38.6897 20.8749C36.8175 19.7814 36.1858 17.3775 37.2793 15.5052L42.6983 6.22405C43.2229 5.3247 44.0837 4.67088 45.0908 4.40679C46.098 4.14198 47.1686 4.2883 48.068 4.81364L51.9359 7.07201C54.4777 8.55595 55.4405 10.5095 55.8003 11.8871C56.2856 13.7451 55.9851 15.7401 54.9537 17.5059C54.2778 18.6637 53.3335 19.618 52.2243 20.2654C52.21 20.2739 52.1957 20.2818 52.1807 20.2904V21.9135C52.1807 23.3196 51.4291 24.618 50.21 25.3182C49.6047 25.6658 48.9295 25.8392 48.255 25.8392V25.8385Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.1522 36.6186C19.4556 36.6186 18.7561 36.4337 18.1308 36.0576L12.173 32.4773C11.0802 31.8206 10.3735 30.6736 10.28 29.4016C10.1858 28.1297 10.7154 26.8913 11.7004 26.0812L13.2615 24.7964C13.6183 24.5023 14.0159 24.2803 14.4342 24.1304C14.6612 23.7486 14.956 23.401 15.3128 23.1076L16.3157 22.2818C16.6726 21.9877 17.0701 21.7657 17.4884 21.6158C17.7154 21.2339 18.0102 20.8871 18.3671 20.593L19.9281 19.3082C20.9131 18.4981 22.23 18.2161 23.4598 18.553C24.6897 18.8899 25.6797 19.8028 26.1144 21.002L28.4819 27.5373C29.048 29.0997 28.569 30.8492 27.2857 31.9056L25.9352 33.0169C25.6004 33.2924 25.23 33.5051 24.8403 33.6536C24.6197 34.0076 24.3392 34.3302 24.0044 34.6058L22.6469 35.7235C21.9274 36.316 21.0416 36.6179 20.1515 36.6179L20.1522 36.6186Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.7438 32.2931C25.065 32.2931 24.3912 32.1168 23.7917 31.7735C22.888 31.256 22.2278 30.4002 21.9551 29.3952L20.2399 23.0726C19.6725 20.9806 20.908 18.8235 23.0008 18.2561L28.0357 16.8899C29.0407 16.6173 30.1128 16.755 31.0157 17.2725C31.9194 17.79 32.5796 18.6458 32.8523 19.6508L33.2541 21.1312C33.3826 21.6044 33.419 22.0805 33.3733 22.5416L33.5639 23.2446C33.8316 23.6201 34.0336 24.0426 34.1563 24.4959L34.5582 25.9763C34.8309 26.9812 34.6931 28.0533 34.1756 28.9563C33.6581 29.8599 32.8023 30.5201 31.798 30.7928L26.7731 32.1561C26.4348 32.2482 26.0893 32.2931 25.7453 32.2931H25.7438Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.6839 31.4238C36.5518 31.4238 36.4183 31.4173 36.2842 31.4038L31.1036 30.8792C28.9466 30.6607 27.3749 28.735 27.5933 26.578L28.2528 20.0605C28.3577 19.0248 28.8695 18.0726 29.6761 17.4145C30.4826 16.7565 31.5176 16.446 32.554 16.5502L37.7445 17.0755C39.9016 17.2939 41.4733 19.2197 41.2549 21.3767L41.1007 22.9027C41.0522 23.3795 40.918 23.8385 40.7081 24.2596L40.6346 24.987C40.7502 25.4331 40.7874 25.9006 40.7402 26.3681L40.5861 27.8942C40.3812 29.917 38.6753 31.4245 36.6853 31.4245L36.6839 31.4238Z"
        fill="#C8C9CA"
      />
      <path
        d="M42.9716 34.7157C42.3171 34.7157 41.6611 34.5522 41.0673 34.2225L39.1194 33.1418C38.7618 32.9434 38.442 32.6928 38.1679 32.4031C37.7804 32.3253 37.3985 32.1875 37.0345 31.9855L35.437 31.099C34.5263 30.5936 33.8539 29.7478 33.5677 28.7471C33.2807 27.7464 33.4035 26.6722 33.9089 25.7621L37.0873 20.0341C38.1394 18.1383 40.5291 17.4538 42.4249 18.5059L44.0223 19.3924C44.3692 19.5844 44.6754 19.8214 44.9374 20.0926C45.3099 20.1733 45.6718 20.3082 46.0123 20.4973L47.943 21.5687C49.2857 22.3139 50.0708 23.7728 49.953 25.3039C49.8345 26.8349 48.8353 28.1561 47.3942 28.6864L46.7068 28.9391L46.871 30.3324C47.043 31.7956 46.382 33.2317 45.1586 34.0519C44.4991 34.4937 43.7368 34.7171 42.9716 34.7171V34.7157Z"
        fill="#C8C9CA"
      />
      <path
        d="M30.9673 44.9084C26.9559 44.9084 23.4284 41.9691 22.8146 37.8842C22.4869 35.705 23.028 33.5287 24.337 31.7564C25.6461 29.9841 27.5676 28.8278 29.746 28.5002C34.2442 27.8235 38.454 30.9334 39.13 35.4316C39.4576 37.6108 38.9165 39.7871 37.6075 41.5594C36.2984 43.3317 34.3769 44.488 32.1985 44.8156C31.7852 44.8777 31.3734 44.9077 30.9673 44.9077V44.9084ZM30.9723 36.2603C30.953 36.2603 30.933 36.2618 30.913 36.2646C30.696 36.2974 30.5461 36.5001 30.579 36.7171C30.6118 36.9341 30.8145 37.084 31.0315 37.0512C31.2485 37.0184 31.3984 36.8156 31.3655 36.5987C31.3356 36.4016 31.1657 36.2596 30.9723 36.2596V36.2603Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.0108 29.0926C8.6325 29.0926 6.34129 28.1526 4.52332 26.4217C2.61112 24.6016 1.53761 22.2026 1.50049 19.6673C1.4648 17.2005 2.40555 14.8315 4.15144 12.9985C6.2742 10.7687 9.25491 9.6202 12.1271 9.92641C14.05 10.1313 15.5368 11.7058 15.6318 13.6366L15.6746 14.5031L16.4904 14.466C18.4761 14.3768 20.2149 15.7836 20.5404 17.7444C21.0293 20.6858 20.0286 23.7993 17.8644 26.0727C16.1086 27.9178 13.8038 28.9877 11.3755 29.0862C11.2535 29.0912 11.1314 29.0933 11.0101 29.0933L11.0108 29.0926Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.5228 23.5202C18.1146 23.5202 16.7534 22.7608 16.0496 21.4296L14.8127 19.092L8.97472 15.067C7.84481 14.2883 7.20385 12.9756 7.28379 11.6052C7.36373 10.2348 8.15388 9.00636 9.36729 8.36468L12.1953 6.86861C12.797 6.55027 13.4565 6.4018 14.1096 6.41394C14.4665 5.86576 14.9618 5.40538 15.5628 5.08703L18.378 3.59739C19.5942 2.95428 21.0582 2.99425 22.2373 3.70303C23.4165 4.41181 24.1381 5.6866 24.1395 7.06276L24.1481 14.1641L25.3801 16.4931C26.3936 18.4096 25.662 20.785 23.7455 21.7986L21.3544 23.0634C20.7691 23.3732 20.141 23.5195 19.5221 23.5195L19.5228 23.5202Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.644 21.2175C22.8531 21.2175 22.0751 20.9784 21.4149 20.523C20.5577 19.932 19.9709 19.0241 19.7825 18.0006L17.8425 7.43031C17.4513 5.29756 18.8624 3.25189 20.9952 2.86003L25.9445 1.9514C27.891 1.5938 29.791 1.94426 31.2928 2.93783C32.7525 3.90285 33.7696 5.4396 34.0844 7.15408C34.1864 7.7094 34.2121 8.26685 34.1672 8.81218C34.8509 9.68369 35.3178 10.7322 35.5297 11.8864C35.8873 13.8343 35.4534 15.7636 34.3078 17.3182C33.2057 18.815 31.5141 19.8378 29.5455 20.199L24.3535 21.1519C24.1179 21.1954 23.881 21.2161 23.6447 21.2161L23.644 21.2175Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.1883 21.1341C39.9635 21.1341 39.7351 21.1148 39.506 21.0741L31.8708 19.7351C30.8451 19.5552 29.9329 18.9756 29.3354 18.1234C28.7373 17.2711 28.5025 16.2162 28.6823 15.1905L30.5389 4.60453C30.9136 2.46893 32.9486 1.04067 35.0834 1.41611L42.7329 2.75729C44.8686 3.13202 46.2961 5.16627 45.9214 7.30188L45.4867 9.7801C45.3825 10.3732 45.1505 10.9121 44.8236 11.3725L44.4125 13.7166C44.5552 14.2519 44.5873 14.8258 44.4853 15.4068L44.0506 17.885C43.7165 19.7915 42.0591 21.1334 40.1883 21.1334V21.1341Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.3263 25.7672C47.6425 25.7672 46.958 25.5887 46.347 25.2318L43.6596 23.663C43.215 23.4032 42.8338 23.0641 42.5297 22.6701C42.0337 22.5987 41.5511 22.4324 41.1093 22.1747L38.761 20.8036C36.8888 19.7101 36.2571 17.3061 37.3506 15.4339L42.7696 6.1527C43.2942 5.25335 44.155 4.59953 45.1621 4.33544C46.1693 4.07063 47.2399 4.21695 48.1393 4.74229L52.0072 7.00066C54.5489 8.4846 55.5118 10.4382 55.8716 11.8158C56.3569 13.6737 56.0564 15.6687 55.025 17.4346C54.3491 18.5923 53.4048 19.5466 52.2956 20.194C52.2813 20.2026 52.267 20.2104 52.252 20.219V21.8421C52.252 23.2483 51.5004 24.5466 50.2813 25.2468C49.676 25.5944 49.0008 25.7679 48.3263 25.7679V25.7672Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.2235 36.5472C19.5269 36.5472 18.8274 36.3624 18.2021 35.9862L12.2442 32.4059C11.1515 31.7493 10.4448 30.6022 10.3513 29.3303C10.2571 28.0584 10.7867 26.82 11.7717 26.0098L13.3327 24.725C13.6896 24.431 14.0872 24.209 14.5055 24.0591C14.7325 23.6772 15.0272 23.3296 15.3841 23.0362L16.387 22.2104C16.7439 21.9163 17.1414 21.6944 17.5597 21.5445C17.7867 21.1626 18.0815 20.8157 18.4384 20.5216L19.9994 19.2368C20.9844 18.4267 22.3013 18.1448 23.5311 18.4817C24.761 18.8186 25.751 19.7315 26.1856 20.9306L28.5532 27.4659C29.1193 29.0284 28.6403 30.7778 27.3569 31.8342L26.0065 32.9456C25.6717 33.2211 25.3013 33.4338 24.9116 33.5822C24.691 33.9363 24.4105 34.2589 24.0757 34.5344L22.7181 35.6522C21.9987 36.2446 21.1129 36.5465 20.2228 36.5465L20.2235 36.5472Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.8154 32.2218C25.1366 32.2218 24.4628 32.0455 23.8632 31.7022C22.9596 31.1847 22.2993 30.3289 22.0267 29.3239L20.3115 23.0013C19.744 20.9092 20.9796 18.7522 23.0723 18.1847L28.1073 16.8186C29.1123 16.5459 30.1844 16.6837 31.0873 17.2012C31.9909 17.7186 32.6512 18.5745 32.9238 19.5795L33.3257 21.0598C33.4542 21.533 33.4906 22.0091 33.4449 22.4702L33.6355 23.1733C33.9031 23.5487 34.1051 23.9713 34.2279 24.4245L34.6297 25.9049C34.9024 26.9099 34.7646 27.982 34.2472 28.8849C33.7297 29.7885 32.8739 30.4488 31.8696 30.7214L26.8446 32.0847C26.5063 32.1768 26.1608 32.2218 25.8168 32.2218H25.8154Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.7554 31.3525C36.6234 31.3525 36.4899 31.3461 36.3557 31.3325L31.1751 30.8079C29.0181 30.5895 27.4464 28.6637 27.6648 26.5067L28.3243 19.9892C28.4293 18.9536 28.941 18.0014 29.7476 17.3433C30.5542 16.6852 31.5891 16.374 32.6255 16.4789L37.8161 17.0042C39.9731 17.2227 41.5448 19.1484 41.3264 21.3054L41.1722 22.8315C41.1237 23.3083 40.9895 23.7672 40.7797 24.1884L40.7061 24.9157C40.8218 25.3618 40.8589 25.8293 40.8118 26.2968L40.6576 27.8229C40.4527 29.8457 38.7468 31.3532 36.7568 31.3532L36.7554 31.3525Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.0429 34.6444C42.3884 34.6444 41.7324 34.4809 41.1386 34.1512L39.1907 33.0705C38.8331 32.8721 38.5133 32.6216 38.2392 32.3318C37.8516 32.254 37.4698 32.1162 37.1058 31.9142L35.5083 31.0277C34.5976 30.5224 33.9252 29.6765 33.639 28.6758C33.352 27.6751 33.4748 26.6009 33.9801 25.6908L37.1586 19.9628C38.2107 18.067 40.6004 17.3825 42.4962 18.4346L44.0936 19.3211C44.4405 19.5131 44.7467 19.7501 45.0086 20.0213C45.3812 20.102 45.7431 20.2369 46.0836 20.426L48.0143 21.4974C49.3569 22.2426 50.1421 23.7015 50.0243 25.2326C49.9058 26.7636 48.9066 28.0848 47.4654 28.6152L46.7781 28.8678L46.9422 30.2611C47.1143 31.7243 46.4533 33.1605 45.2299 33.9806C44.5704 34.4224 43.8081 34.6458 43.0429 34.6458V34.6444Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.0388 44.837C27.0274 44.837 23.4999 41.8977 22.8861 37.8128C22.5585 35.6337 23.0995 33.4574 24.4086 31.6851C25.7176 29.9128 27.6391 28.7565 29.8175 28.4288C34.3157 27.7522 38.5256 30.8621 39.2015 35.3603C39.5291 37.5394 38.9881 39.7157 37.679 41.488C36.37 43.2603 34.4485 44.4166 32.2701 44.7442C31.8568 44.8063 31.4449 44.8363 31.0388 44.8363V44.837ZM31.0438 36.189C31.0245 36.189 31.0045 36.1904 30.9845 36.1933C30.7676 36.2261 30.6177 36.4288 30.6505 36.6458C30.6833 36.8628 30.8868 37.0127 31.103 36.9798C31.32 36.947 31.4699 36.7443 31.4371 36.5273C31.4071 36.3303 31.2372 36.1883 31.0438 36.1883V36.189Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.0823 29.0212C8.70404 29.0212 6.41283 28.0812 4.59485 26.3503C2.68265 24.5302 1.60914 22.1312 1.57202 19.5959C1.53634 17.1291 2.47709 14.76 4.22297 12.9271C6.34502 10.6973 9.32716 9.54879 12.1987 9.855C14.1216 10.0599 15.6084 11.6344 15.7033 13.5652L15.7461 14.4317L16.562 14.3946C18.5477 14.3054 20.2864 15.7122 20.6119 17.6729C21.1008 20.6144 20.1001 23.7279 17.936 26.0012C16.1801 27.8463 13.8753 28.9163 11.4471 29.0148C11.325 29.0198 11.203 29.0219 11.0816 29.0219L11.0823 29.0212Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.5941 23.4489C18.1859 23.4489 16.8247 22.6894 16.1209 21.3582L14.8839 19.0206L9.046 14.9956C7.9161 14.2169 7.27513 12.9043 7.35508 11.5339C7.43502 10.1634 8.22517 8.93501 9.43858 8.29333L12.2665 6.79726C12.8683 6.47892 13.5278 6.32974 14.1809 6.34259C14.5378 5.79441 15.0331 5.33403 15.6341 5.01568L18.4492 3.52604C19.6655 2.88293 21.1295 2.9229 22.3086 3.63168C23.4878 4.34046 24.2094 5.61525 24.2108 6.99141L24.2194 14.0927L25.4513 16.4218C26.4649 18.3382 25.7333 20.7137 23.8168 21.7272L21.4257 22.992C20.8404 23.3018 20.2123 23.4481 19.5934 23.4481L19.5941 23.4489Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.7155 21.1462C22.9247 21.1462 22.1467 20.9071 21.4864 20.4517C20.6292 19.8607 20.0425 18.9528 19.854 17.9292L17.914 7.35896C17.5229 5.22621 18.934 3.18054 21.0667 2.78868L26.016 1.88005C27.9625 1.52245 29.8625 1.87291 31.3643 2.86648C32.824 3.8315 33.8411 5.36825 34.1559 7.08273C34.258 7.63805 34.2837 8.1955 34.2387 8.74083C34.9225 9.61234 35.3893 10.6609 35.6013 11.815C35.9589 13.7629 35.5249 15.6923 34.3793 17.2469C33.2772 18.7436 31.5856 19.7665 29.617 20.1276L24.425 21.0805C24.1895 21.1241 23.9525 21.1448 23.7163 21.1448L23.7155 21.1462Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.2596 21.0626C40.0348 21.0626 39.8064 21.0433 39.5773 21.0026L31.942 19.6636C30.9164 19.4837 30.0042 18.9042 29.4067 18.0519C28.8086 17.1997 28.5738 16.1447 28.7536 15.119L30.6101 4.53306C30.9849 2.39745 33.0198 0.969195 35.1547 1.34464L42.8042 2.68582C44.9398 3.06055 46.3674 5.0948 45.9927 7.23041L45.558 9.70862C45.4538 10.3018 45.2218 10.8407 44.8949 11.3011L44.4837 13.6451C44.6265 14.1804 44.6586 14.7543 44.5565 15.3353L44.1219 17.8135C43.7878 19.72 42.1304 21.0619 40.2596 21.0619V21.0626Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.3973 25.6958C47.7135 25.6958 47.029 25.5173 46.418 25.1604L43.7307 23.5916C43.286 23.3317 42.9048 22.9927 42.6008 22.5987C42.1047 22.5273 41.6222 22.361 41.1804 22.1033L38.8321 20.7322C36.9598 19.6387 36.3281 17.2347 37.4216 15.3625L42.8406 6.08129C43.3652 5.18194 44.226 4.52812 45.2332 4.26403C46.2403 3.99922 47.311 4.14554 48.2103 4.67088L52.0782 6.92925C54.62 8.41318 55.5829 10.3668 55.9426 11.7444C56.428 13.6023 56.1275 15.5973 55.0961 17.3632C54.4201 18.5209 53.4758 19.4752 52.3666 20.1226C52.3523 20.1312 52.3381 20.139 52.3231 20.1476V21.7707C52.3231 23.1769 51.5715 24.4752 50.3523 25.1754C49.7471 25.523 49.0718 25.6965 48.3973 25.6965V25.6958Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.295 36.4759C19.5984 36.4759 18.8989 36.291 18.2736 35.9149L12.3158 32.3346C11.223 31.6779 10.5164 30.5309 10.4229 29.2589C10.3286 27.987 10.8583 26.7486 11.8433 25.9385L13.4043 24.6537C13.7612 24.3596 14.1587 24.1376 14.577 23.9877C14.804 23.6059 15.0988 23.2583 15.4557 22.9649L16.4585 22.1391C16.8154 21.845 17.213 21.623 17.6312 21.4731C17.8582 21.0912 18.153 20.7444 18.5099 20.4503L20.0709 19.1655C21.0559 18.3554 22.3728 18.0734 23.6027 18.4103C24.8325 18.7472 25.8225 19.6601 26.2572 20.8593L28.6248 27.3946C29.1908 28.957 28.7118 30.7065 27.4285 31.7629L26.078 32.8742C25.7433 33.1497 25.3728 33.3624 24.9831 33.5109C24.7625 33.8649 24.482 34.1875 24.1473 34.4631L22.7897 35.5808C22.0702 36.1733 21.1844 36.4752 20.2943 36.4752L20.295 36.4759Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.8867 32.1504C25.2079 32.1504 24.5341 31.9741 23.9345 31.6308C23.0308 31.1133 22.3706 30.2575 22.0979 29.2525L20.3828 22.9299C19.8153 20.8379 21.0508 18.6808 23.1436 18.1134L28.1786 16.7472C29.1836 16.4746 30.2557 16.6123 31.1586 17.1298C32.0622 17.6473 32.7224 18.5031 32.9951 19.5081L33.397 20.9885C33.5254 21.4617 33.5618 21.9378 33.5162 22.3989L33.7067 23.1019C33.9744 23.4774 34.1764 23.8999 34.2992 24.3532L34.701 25.8336C34.9737 26.8385 34.8359 27.9106 34.3184 28.8136C33.801 29.7172 32.9451 30.3774 31.9409 30.6501L26.9159 32.0134C26.5776 32.1055 26.2321 32.1504 25.8881 32.1504H25.8867Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.8267 31.2811C36.6946 31.2811 36.5612 31.2746 36.427 31.2611L31.2464 30.7365C29.0894 30.518 27.5177 28.5923 27.7361 26.4353L28.3956 19.9178C28.5005 18.8821 29.0123 17.9299 29.8189 17.2718C30.6254 16.6138 31.6604 16.3033 32.6968 16.4075L37.8874 16.9328C40.0444 17.1512 41.6161 19.077 41.3977 21.234L41.2435 22.76C41.195 23.2368 41.0608 23.6958 40.8509 24.1169L40.7774 24.8443C40.8931 25.2904 40.9302 25.7579 40.8831 26.2254L40.7289 27.7515C40.524 29.7743 38.8181 31.2818 36.8281 31.2818L36.8267 31.2811Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.1142 34.573C42.4597 34.573 41.8037 34.4095 41.2099 34.0798L39.262 32.9991C38.9044 32.8007 38.5846 32.5501 38.3105 32.2604C37.9229 32.1826 37.5411 32.0448 37.177 31.8428L35.5796 30.9563C34.6688 30.4509 33.9965 29.6051 33.7103 28.6044C33.4233 27.6037 33.5461 26.5295 34.0514 25.6194L37.2299 19.8914C38.282 17.9956 40.671 17.3111 42.5675 18.3632L44.1649 19.2497C44.5118 19.4417 44.818 19.6787 45.0799 19.9499C45.4525 20.0306 45.8144 20.1655 46.1549 20.3546L48.0856 21.426C49.4282 22.1712 50.2134 23.6301 50.0956 25.1612C49.9771 26.6922 48.9778 28.0134 47.5367 28.5437L46.8494 28.7964L47.0135 30.1897C47.1856 31.6529 46.5246 33.089 45.3012 33.9092C44.6417 34.351 43.8794 34.5744 43.1142 34.5744V34.573Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.1101 44.7656C27.0987 44.7656 23.5712 41.8263 22.9574 37.7414C22.6298 35.5622 23.1708 33.3859 24.4798 31.6136C25.7889 29.8413 27.7104 28.685 29.8888 28.3574C34.387 27.6814 38.5968 30.7906 39.2728 35.2888C39.6004 37.468 39.0594 39.6443 37.7503 41.4166C36.4412 43.1889 34.5198 44.3452 32.3413 44.6728C31.9281 44.7349 31.5162 44.7649 31.1101 44.7649V44.7656ZM31.1151 36.1175C31.0958 36.1175 31.0758 36.1189 31.0558 36.1218C30.8388 36.1546 30.689 36.3573 30.7218 36.5743C30.7546 36.7913 30.958 36.9412 31.1743 36.9084C31.3913 36.8755 31.5412 36.6728 31.5084 36.4558C31.4784 36.2588 31.3085 36.1168 31.1151 36.1168V36.1175Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.1536 28.9499C8.77533 28.9499 6.48412 28.0098 4.66614 26.2789C2.75394 24.4588 1.68043 22.0598 1.64331 19.5245C1.60762 17.0577 2.54838 14.6887 4.29426 12.8557C6.41702 10.6259 9.39773 9.47816 12.27 9.78365C14.1929 9.9885 15.6796 11.5631 15.7746 13.4938L15.8174 14.3604L16.6332 14.3232C18.6197 14.234 20.3577 15.6409 20.6832 17.6016C21.1721 20.5431 20.1714 23.6565 18.0073 25.9299C16.2514 27.775 13.9466 28.8449 11.5184 28.9434C11.3963 28.9484 11.2742 28.9506 11.1529 28.9506L11.1536 28.9499Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.6654 23.3774C18.2571 23.3774 16.896 22.618 16.1922 21.2868L14.9552 18.9492L9.11729 14.9242C7.98739 14.1455 7.34642 12.8329 7.42637 11.4624C7.50631 10.092 8.29645 8.8636 9.50987 8.22192L12.3378 6.72585C12.9395 6.40751 13.5991 6.25904 14.2522 6.27118C14.6091 5.723 15.1044 5.26262 15.7054 4.94427L18.5205 3.45463C19.7368 2.81152 21.2007 2.85149 22.3799 3.56027C23.559 4.26905 24.2807 5.54384 24.2821 6.92L24.2907 14.0213L25.5226 16.3504C26.5362 18.2668 25.8046 20.6423 23.8881 21.6558L21.497 22.9206C20.9117 23.2304 20.2835 23.3767 19.6647 23.3767L19.6654 23.3774Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.7866 21.0748C22.9957 21.0748 22.2177 20.8357 21.5575 20.3803C20.7002 19.7893 20.1135 18.8814 19.9251 17.8578L17.985 7.28755C17.5939 5.1548 19.005 3.10913 21.1378 2.71727L26.0871 1.80864C28.0335 1.45104 29.9336 1.8015 31.4354 2.79507C32.895 3.76009 33.9122 5.29684 34.2269 7.01132C34.329 7.56664 34.3547 8.12409 34.3097 8.66941C34.9935 9.54093 35.4603 10.5895 35.6723 11.7436C36.0299 13.6915 35.5959 15.6208 34.4503 17.1754C33.3483 18.6722 31.6566 19.6951 29.6881 20.0562L24.4961 21.0091C24.2605 21.0526 24.0236 21.0733 23.7873 21.0733L23.7866 21.0748Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.3314 20.9913C40.1066 20.9913 39.8782 20.972 39.649 20.9314L32.0138 19.5923C30.9881 19.4124 30.0759 18.8329 29.4785 17.9806C28.8804 17.1284 28.6455 16.0734 28.8254 15.0477L30.6819 4.46177C31.0567 2.32616 33.0916 0.897906 35.2265 1.27335L42.876 2.61453C45.0116 2.98926 46.4392 5.02351 46.0644 7.15912L45.6297 9.63733C45.5255 10.2305 45.2936 10.7694 44.9667 11.2298L44.5555 13.5738C44.6983 14.1091 44.7304 14.683 44.6283 15.264L44.1936 17.7422C43.8596 19.6487 42.2022 20.9906 40.3314 20.9906V20.9913Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.4689 25.6244C47.7851 25.6244 47.1006 25.446 46.4896 25.0891L43.8022 23.5202C43.3575 23.2604 42.9764 22.9213 42.6723 22.5273C42.1762 22.456 41.6937 22.2897 41.2519 22.032L38.9036 20.6608C37.0314 19.5673 36.3997 17.1633 37.4932 15.2911L42.9121 6.00994C43.4368 5.11059 44.2976 4.45677 45.3047 4.19268C46.3118 3.92787 47.3825 4.07419 48.2818 4.59953L52.1498 6.8579C54.6915 8.34183 55.6544 10.2954 56.0141 11.673C56.4995 13.531 56.199 15.526 55.1676 17.2918C54.4917 18.4496 53.5473 19.4039 52.4381 20.0513C52.4239 20.0598 52.4096 20.0677 52.3946 20.0763V21.6994C52.3946 23.1055 51.643 24.4039 50.4239 25.1041C49.8186 25.4517 49.1434 25.6251 48.4689 25.6251V25.6244Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.3663 36.4045C19.6697 36.4045 18.9702 36.2196 18.3449 35.8435L12.3871 32.2632C11.2943 31.6065 10.5876 30.4595 10.4941 29.1875C10.3999 27.9156 10.9295 26.6772 11.9146 25.8671L13.4756 24.5823C13.8325 24.2882 14.23 24.0662 14.6483 23.9163C14.8753 23.5345 15.1701 23.1868 15.527 22.8935L16.5298 22.0677C16.8867 21.7736 17.2843 21.5516 17.7025 21.4017C17.9295 21.0198 18.2243 20.6729 18.5812 20.3789L20.1422 19.0941C21.1265 18.2839 22.4441 18.002 23.674 18.3389C24.9038 18.6758 25.8938 19.5887 26.3285 20.7879L28.6961 27.3232C29.2621 28.8856 28.7831 30.6351 27.4998 31.6914L26.1493 32.8028C25.8146 33.0783 25.4441 33.291 25.0544 33.4395C24.8338 33.7935 24.5533 34.1161 24.2186 34.3916L22.861 35.5094C22.1415 36.1018 21.2557 36.4038 20.3656 36.4038L20.3663 36.4045Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.9582 32.079C25.2794 32.079 24.6056 31.9027 24.006 31.5594C23.1024 31.0419 22.4421 30.1861 22.1695 29.1811L20.4543 22.8585C19.8868 20.7664 21.1224 18.6094 23.2152 18.042L28.2501 16.6758C29.2551 16.4032 30.3272 16.5409 31.2301 17.0584C32.1337 17.5759 32.794 18.4317 33.0666 19.4367L33.4685 20.9171C33.597 21.3903 33.6334 21.8664 33.5877 22.3275L33.7783 23.0305C34.0459 23.406 34.2479 23.8285 34.3707 24.2818L34.7726 25.7621C35.0452 26.7671 34.9075 27.8392 34.39 28.7421C33.8725 29.6458 33.0167 30.306 32.0124 30.5787L26.9874 31.942C26.6491 32.0341 26.3036 32.079 25.9596 32.079H25.9582Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.898 31.2097C36.7659 31.2097 36.6325 31.2032 36.4983 31.1897L31.3177 30.6651C29.1607 30.4466 27.589 28.5209 27.8074 26.3639L28.4669 19.8464C28.5718 18.8107 29.0836 17.8586 29.8902 17.2005C30.6967 16.5424 31.7317 16.2312 32.7681 16.3361L37.9586 16.8614C40.1157 17.0798 41.6874 19.0056 41.469 21.1626L41.3148 22.6887C41.2663 23.1655 41.1321 23.6244 40.9222 24.0455L40.8487 24.7729C40.9643 25.219 41.0015 25.6865 40.9544 26.154L40.8002 27.6801C40.5953 29.7029 38.8894 31.2104 36.8994 31.2104L36.898 31.2097Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.1857 34.5016C42.5312 34.5016 41.8752 34.3381 41.2814 34.0083L39.3335 32.9277C38.9759 32.7293 38.6561 32.4787 38.382 32.1889C37.9945 32.1111 37.6126 31.9734 37.2486 31.7714L35.6512 30.8849C34.7404 30.3795 34.068 29.5337 33.7818 28.533C33.4948 27.5323 33.6176 26.4581 34.123 25.548L37.3014 19.82C38.3535 17.9242 40.7425 17.2397 42.639 18.2918L44.2364 19.1783C44.5833 19.3703 44.8895 19.6073 45.1515 19.8785C45.5241 19.9592 45.8859 20.0941 46.2264 20.2832L48.1572 21.3546C49.4998 22.0998 50.2849 23.5587 50.1671 25.0898C50.0487 26.6208 49.0494 27.942 47.6083 28.4723L46.9209 28.725L47.0851 30.1183C47.2571 31.5815 46.5961 33.0176 45.3727 33.8378C44.7132 34.2796 43.9509 34.503 43.1857 34.503V34.5016Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.1814 44.6942C27.17 44.6942 23.6425 41.7549 23.0287 37.67C22.701 35.4908 23.2421 33.3145 24.5511 31.5422C25.8602 29.77 27.7817 28.6136 29.9601 28.286C34.4583 27.6094 38.6681 30.7193 39.3441 35.2175C39.6717 37.3966 39.1307 39.5729 37.8216 41.3452C36.5125 43.1175 34.5911 44.2738 32.4126 44.6014C31.9994 44.6635 31.5875 44.6935 31.1814 44.6935V44.6942ZM31.1864 36.0462C31.1671 36.0462 31.1471 36.0476 31.1271 36.0504C30.9101 36.0833 30.7602 36.286 30.7931 36.503C30.8259 36.72 31.0293 36.8698 31.2456 36.837C31.4626 36.8042 31.6125 36.6015 31.5797 36.3845C31.5497 36.1875 31.3798 36.0454 31.1864 36.0454V36.0462Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.2249 28.8785C8.84661 28.8785 6.55541 27.9384 4.73743 26.2075C2.82523 24.3874 1.75172 21.9884 1.7146 19.4531C1.67891 16.9863 2.61967 14.6173 4.36555 12.7843C6.4876 10.5545 9.46974 9.40603 12.3412 9.71224C14.2641 9.91709 15.7509 11.4917 15.8459 13.4224L15.8887 14.2889L16.7045 14.2518C18.6895 14.1626 20.429 15.5695 20.7545 17.5302C21.2434 20.4716 20.2427 23.5851 18.0785 25.8585C16.3227 27.7036 14.0179 28.7735 11.5896 28.872C11.4676 28.877 11.3455 28.8792 11.2242 28.8792L11.2249 28.8785Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.7367 23.306C18.3284 23.306 16.9673 22.5466 16.2635 21.2154L15.0265 18.8778L9.18858 14.8528C8.05868 14.0741 7.41771 12.7615 7.49766 11.391C7.5776 10.0206 8.36774 8.79219 9.58116 8.15051L12.4091 6.65444C13.0108 6.3361 13.6704 6.18692 14.3235 6.19977C14.6803 5.65159 15.1757 5.19121 15.7767 4.87286L18.5918 3.38322C19.8081 2.74011 21.272 2.78008 22.4512 3.48886C23.6303 4.19763 24.352 5.47243 24.3534 6.84858L24.362 13.9499L25.5939 16.2789C26.6075 18.1954 25.8759 20.5709 23.9594 21.5844L21.5683 22.8492C20.983 23.159 20.3548 23.3053 19.736 23.3053L19.7367 23.306Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.8581 21.0034C23.0673 21.0034 22.2892 20.7643 21.629 20.3089C20.7718 19.7179 20.185 18.81 19.9966 17.7865L18.0566 7.2162C17.6654 5.08345 19.0766 3.03778 21.2093 2.64592L26.1586 1.73729C28.1051 1.37969 30.0051 1.73015 31.5069 2.72372C32.9666 3.68874 33.9837 5.22549 34.2985 6.93997C34.4005 7.49529 34.4262 8.05274 34.3813 8.59806C35.0651 9.46958 35.5319 10.5181 35.7439 11.6723C36.1015 13.6202 35.6675 15.5495 34.5219 17.1041C33.4198 18.6009 31.7282 19.6237 29.7596 19.9849L24.5676 20.9378C24.3321 20.9813 24.0951 21.002 23.8588 21.002L23.8581 21.0034Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.4027 20.9199C40.1779 20.9199 39.9495 20.9006 39.7203 20.8599L32.0851 19.5209C31.0594 19.341 30.1472 18.7615 29.5498 17.9092C28.9517 17.057 28.7168 16.002 28.8967 14.9763L30.7532 4.39036C31.1279 2.25475 33.1629 0.826495 35.2978 1.20194L42.9473 2.54312C45.0829 2.91785 46.5105 4.9521 46.1357 7.08771L45.701 9.56592C45.5968 10.1591 45.3648 10.698 45.0379 11.1583L44.6268 13.5024C44.7696 14.0377 44.8017 14.6116 44.6996 15.1926L44.2649 17.6708C43.9309 19.5773 42.2735 20.9192 40.4027 20.9192V20.9199Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.5406 25.553C47.8568 25.553 47.1723 25.3745 46.5613 25.0177L43.874 23.4488C43.4293 23.189 43.0482 22.8499 42.7441 22.4559C42.248 22.3846 41.7655 22.2182 41.3237 21.9606L38.9754 20.5894C37.1031 19.4959 36.4715 17.0919 37.565 15.2197L42.9839 5.93853C43.5085 5.03918 44.3693 4.38536 45.3765 4.12126C46.3836 3.85645 47.4543 4.00278 48.3536 4.52811L52.2216 6.78649C54.7633 8.27042 55.7262 10.224 56.0859 11.6016C56.5713 13.4595 56.2708 15.4545 55.2394 17.2204C54.5634 18.3782 53.6191 19.3325 52.5099 19.9799C52.4956 19.9884 52.4814 19.9963 52.4664 20.0048V21.628C52.4664 23.0341 51.7148 24.3324 50.4957 25.0327C49.8904 25.3803 49.2151 25.5537 48.5406 25.5537V25.553Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.4379 36.3331C19.7412 36.3331 19.0417 36.1483 18.4165 35.7721L12.4586 32.1918C11.3658 31.5352 10.6592 30.3881 10.5657 29.1162C10.4715 27.8442 11.0011 26.6058 11.9861 25.7957L13.5471 24.5109C13.904 24.2168 14.3016 23.9949 14.7198 23.845C14.9468 23.4631 15.2416 23.1155 15.5985 22.8221L16.6013 21.9963C16.9582 21.7022 17.3558 21.4802 17.7741 21.3304C18.001 20.9485 18.2958 20.6016 18.6527 20.3075L20.2137 19.0227C21.1987 18.2126 22.5157 17.9307 23.7455 18.2676C24.9753 18.6045 25.9653 19.5174 26.4 20.7165L28.7676 27.2518C29.3336 28.8143 28.8547 30.5637 27.5713 31.6201L26.2208 32.7314C25.8861 33.007 25.5156 33.2197 25.1259 33.3681C24.9054 33.7222 24.6249 34.0448 24.2901 34.3203L22.9325 35.4381C22.213 36.0305 21.3272 36.3324 20.4372 36.3324L20.4379 36.3331Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.0295 32.0077C25.3507 32.0077 24.6769 31.8314 24.0773 31.488C23.1737 30.9706 22.5134 30.1148 22.2408 29.1098L20.5256 22.7872C19.9581 20.6951 21.1937 18.5381 23.2864 17.9706L28.3214 16.6045C29.3264 16.3318 30.3985 16.4696 31.3014 16.9871C32.2043 17.5045 32.8653 18.3604 33.1379 19.3653L33.5398 20.8457C33.6683 21.3189 33.7047 21.795 33.659 22.2561L33.8496 22.9592C34.1172 23.3346 34.3192 23.7572 34.442 24.2104L34.8438 25.6908C35.1165 26.6958 34.9787 27.7679 34.4613 28.6708C33.9438 29.5744 33.088 30.2347 32.0837 30.5073L27.0587 31.8706C26.7204 31.9627 26.3749 32.0077 26.0309 32.0077H26.0295Z"
        fill="#C8C9CA"
      />
      <path
        d="M36.9693 31.1383C36.8372 31.1383 36.7038 31.1319 36.5696 31.1183L31.389 30.5937C29.232 30.3753 27.6603 28.4495 27.8787 26.2925L28.5382 19.775C28.6431 18.7394 29.1549 17.7872 29.9615 17.1291C30.768 16.471 31.803 16.1605 32.8394 16.2647L38.0299 16.79C40.187 17.0085 41.7587 18.9342 41.5403 21.0912L41.3861 22.6173C41.3376 23.0941 41.2034 23.553 40.9935 23.9742L40.92 24.7015C41.0356 25.1476 41.0728 25.6151 41.0256 26.0826L40.8715 27.6087C40.6666 29.6315 38.9607 31.139 36.9707 31.139L36.9693 31.1383Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.2568 34.4302C42.6023 34.4302 41.9463 34.2668 41.3524 33.937L39.4046 32.8563C39.047 32.6579 38.7272 32.4074 38.4531 32.1176C38.0655 32.0398 37.6836 31.902 37.3196 31.7L35.7222 30.8135C34.8114 30.3082 34.1391 29.4624 33.8528 28.4616C33.5659 27.4609 33.6887 26.3867 34.194 25.4767L37.3724 19.7486C38.4245 17.8529 40.8135 17.1683 42.71 18.2204L44.3075 19.1069C44.6543 19.299 44.9606 19.5359 45.2225 19.8072C45.5951 19.8878 45.957 20.0227 46.2975 20.2119L48.2282 21.2832C49.5708 22.0284 50.356 23.4874 50.2382 25.0184C50.1197 26.5495 49.1204 27.8706 47.6793 28.401L46.992 28.6537L47.1561 30.0469C47.3281 31.5102 46.6672 32.9463 45.4438 33.7664C44.7843 34.2082 44.0219 34.4316 43.2568 34.4316V34.4302Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.2527 44.6229C27.2413 44.6229 23.7138 41.6836 23.1 37.5986C22.7723 35.4195 23.3134 33.2432 24.6224 31.4709C25.9315 29.6986 27.853 28.5423 30.0314 28.2147C34.5303 27.538 38.7394 30.6479 39.4154 35.1461C39.743 37.3253 39.2019 39.5015 37.8929 41.2738C36.5838 43.0461 34.6623 44.2025 32.4839 44.5301C32.0706 44.5922 31.6588 44.6221 31.2527 44.6221V44.6229ZM31.2577 35.9748C31.2384 35.9748 31.2184 35.9762 31.1984 35.9791C30.9814 36.0119 30.8315 36.2146 30.8644 36.4316C30.8972 36.6486 31.0999 36.7992 31.3169 36.7657C31.5339 36.7328 31.6838 36.5301 31.6509 36.3131C31.621 36.1161 31.4511 35.9741 31.2577 35.9741V35.9748Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.2964 28.8071C8.91815 28.8071 6.62694 27.8671 4.80896 26.1362C2.89677 24.316 1.82325 21.9171 1.78614 19.3817C1.75045 16.9149 2.6912 14.5459 4.43709 12.713C6.55984 10.4831 9.54056 9.33468 12.4128 9.64089C14.3357 9.84574 15.8225 11.4203 15.9174 13.3511L15.9602 14.2176L16.7761 14.1805C18.7611 14.0905 20.5005 15.4981 20.826 17.4588C21.3149 20.4003 20.3142 23.5138 18.1501 25.7871C16.3942 27.6322 14.0894 28.7022 11.6612 28.8007C11.5391 28.8057 11.4171 28.8078 11.2957 28.8078L11.2964 28.8071Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.808 23.2347C18.3997 23.2347 17.0386 22.4752 16.3348 21.144L15.0978 18.8064L9.25987 14.7815C8.12997 14.0028 7.489 12.6901 7.56894 11.3197C7.64889 9.94924 8.43903 8.72084 9.65245 8.07916L12.4804 6.58309C13.0821 6.26475 13.7416 6.11628 14.3947 6.12842C14.7516 5.58024 15.247 5.11985 15.848 4.80151L18.6631 3.31187C19.8794 2.66876 21.3433 2.70873 22.5225 3.41751C23.7016 4.12628 24.4233 5.40108 24.4247 6.77723L24.4332 13.8786L25.6652 16.2076C26.6788 18.1241 25.9472 20.4995 24.0307 21.5131L21.6395 22.7779C21.0542 23.0876 20.4261 23.234 19.8073 23.234L19.808 23.2347Z"
        fill="#C8C9CA"
      />
      <path
        d="M23.9292 20.932C23.1383 20.932 22.3603 20.6929 21.7001 20.2375C20.8428 19.6465 20.2561 18.7386 20.0677 17.715L18.1276 7.14479C17.7365 5.01204 19.1476 2.96637 21.2804 2.57451L26.2297 1.66587C28.1761 1.30827 30.0762 1.65874 31.5779 2.65231C33.0376 3.61733 34.0547 5.15408 34.3695 6.86856C34.4716 7.42388 34.4973 7.98133 34.4523 8.52665C35.1361 9.39817 35.6029 10.4467 35.8149 11.6009C36.1725 13.5488 35.7385 15.4781 34.5929 17.0327C33.4909 18.5295 31.7992 19.5523 29.8306 19.9135L24.6387 20.8663C24.4031 20.9099 24.1661 20.9306 23.9299 20.9306L23.9292 20.932Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.474 20.8485C40.2492 20.8485 40.0207 20.8292 39.7916 20.7885L32.1564 19.4495C31.1307 19.2696 30.2185 18.69 29.6211 17.8378C29.0229 16.9856 28.7881 15.9306 28.968 14.9049L30.8245 4.31895C31.1992 2.18334 33.2342 0.755084 35.3691 1.13053L43.0186 2.47171C45.1542 2.84644 46.5817 4.88069 46.207 7.01629L45.7723 9.49451C45.6681 10.0877 45.4361 10.6266 45.1092 11.0869L44.6981 13.431C44.8408 13.9663 44.873 14.5402 44.7709 15.1212L44.3362 17.5994C44.0022 19.5059 42.3448 20.8478 40.474 20.8478V20.8485Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.6119 25.4816C47.9281 25.4816 47.2436 25.3032 46.6326 24.9463L43.9453 23.3774C43.5006 23.1176 43.1194 22.7786 42.8154 22.3846C42.3193 22.3132 41.8368 22.1469 41.395 21.8892L39.0467 20.5181C37.1744 19.4246 36.5427 17.0206 37.6362 15.1484L43.0552 5.86718C43.5798 4.96783 44.4406 4.31401 45.4478 4.04991C46.4549 3.7851 47.5256 3.93143 48.4249 4.45676L52.2928 6.71514C54.8346 8.19907 55.7975 10.1527 56.1572 11.5302C56.6426 13.3882 56.3421 15.3832 55.3107 17.1491C54.6347 18.3068 53.6904 19.2611 52.5812 19.9085C52.5669 19.9171 52.5527 19.9249 52.5377 19.9335V21.5566C52.5377 22.9627 51.7861 24.2611 50.5669 24.9613C49.9617 25.3089 49.2864 25.4824 48.6119 25.4824V25.4816Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.5089 36.2617C19.8123 36.2617 19.1128 36.0769 18.4875 35.7007L12.5296 32.1204C11.4369 31.4637 10.7302 30.3167 10.6367 29.0448C10.5425 27.7728 11.0721 26.5344 12.0571 25.7243L13.6181 24.4395C13.975 24.1454 14.3726 23.9235 14.7909 23.7736C15.0179 23.3917 15.3126 23.0441 15.6695 22.7507L16.6724 21.9249C17.0293 21.6308 17.4268 21.4088 17.8451 21.2589C18.0721 20.8771 18.3669 20.5302 18.7238 20.2361L20.2848 18.9513C21.2698 18.1412 22.5867 17.8592 23.8165 18.1961C25.0464 18.533 26.0364 19.446 26.471 20.6451L28.8386 27.1804C29.4047 28.7428 28.9257 30.4923 27.6423 31.5487L26.2919 32.66C25.9571 32.9355 25.5867 33.1482 25.197 33.2967C24.9764 33.6507 24.6959 33.9734 24.3611 34.2489L23.0035 35.3667C22.2841 35.9591 21.3983 36.261 20.5082 36.261L20.5089 36.2617Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.1005 31.9363C25.4217 31.9363 24.7479 31.76 24.1484 31.4167C23.2447 30.8992 22.5845 30.0434 22.3118 29.0384L20.5966 22.7158C20.0292 20.6237 21.2647 18.4667 23.3575 17.8993L28.3924 16.5331C29.3974 16.2605 30.4695 16.3982 31.3724 16.9157C32.2761 17.4332 32.9363 18.289 33.209 19.294L33.6108 20.7744C33.7393 21.2476 33.7757 21.7237 33.73 22.1848L33.9206 22.8878C34.1883 23.2633 34.3903 23.6858 34.513 24.1391L34.9149 25.6194C35.1876 26.6244 35.0498 27.6965 34.5323 28.5994C34.0148 29.5031 33.159 30.1633 32.1547 30.436L27.1298 31.7993C26.7914 31.8914 26.446 31.9363 26.1019 31.9363H26.1005Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.0408 31.0669C36.9088 31.0669 36.7753 31.0605 36.6411 31.0469L31.4605 30.5223C29.3035 30.3039 27.7318 28.3781 27.9502 26.2211L28.6097 19.7037C28.7147 18.668 29.2264 17.7158 30.033 17.0577C30.8396 16.3996 31.8745 16.0884 32.9109 16.1933L38.1015 16.7187C40.2585 16.9371 41.8302 18.8628 41.6118 21.0199L41.4576 22.5459C41.4091 23.0227 41.2749 23.4817 41.0651 23.9028L40.9915 24.6301C41.1072 25.0762 41.1443 25.5437 41.0972 26.0113L40.943 27.5373C40.7381 29.5601 39.0322 31.0676 37.0422 31.0676L37.0408 31.0669Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.3283 34.3588C42.6738 34.3588 42.0178 34.1953 41.424 33.8656L39.4761 32.7849C39.1185 32.5865 38.7987 32.336 38.5246 32.0462C38.137 31.9684 37.7552 31.8306 37.3912 31.6286L35.7937 30.7421C34.883 30.2368 34.2106 29.3909 33.9244 28.3902C33.6374 27.3895 33.7602 26.3153 34.2655 25.4052L37.444 19.6772C38.4961 17.7814 40.8858 17.0969 42.7816 18.149L44.379 19.0355C44.7195 19.224 45.0264 19.4609 45.2926 19.735C45.6659 19.8157 46.0285 19.9506 46.3697 20.1397L48.3005 21.2111C49.6431 21.9563 50.4282 23.4152 50.3104 24.9463C50.1919 26.4773 49.1927 27.7985 47.7516 28.3289L47.0642 28.5815L47.2284 29.9748C47.4004 31.438 46.7394 32.8742 45.516 33.6943C44.8565 34.1361 44.0942 34.3595 43.329 34.3595L43.3283 34.3588Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.3242 44.5515C27.3128 44.5515 23.7853 41.6121 23.1715 37.5272C22.8439 35.3481 23.3849 33.1718 24.694 31.3995C26.003 29.6272 27.9245 28.4709 30.1029 28.1433C34.6011 27.4666 38.811 30.5765 39.4869 35.0747C39.8145 37.2538 39.2735 39.4301 37.9644 41.2024C36.6554 42.9747 34.7339 44.131 32.5555 44.4587C32.1422 44.5208 31.7303 44.5507 31.3242 44.5507V44.5515ZM31.3292 35.9034C31.3099 35.9034 31.2899 35.9048 31.2699 35.9077C31.053 35.9405 30.9031 36.1432 30.9359 36.3602C30.9687 36.5772 31.1722 36.7278 31.3884 36.6943C31.6054 36.6614 31.7553 36.4587 31.7225 36.2417C31.6925 36.0447 31.5226 35.9027 31.3292 35.9027V35.9034Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.368 28.7358C8.98968 28.7358 6.69847 27.7957 4.88049 26.0648C2.9683 24.2447 1.89478 21.8457 1.85767 19.3104C1.82198 16.8436 2.76273 14.4746 4.50862 12.6416C6.63066 10.4118 9.6128 9.26333 12.4843 9.56954C14.4072 9.77439 15.894 11.349 15.9889 13.2797L16.0318 14.1462L16.8476 14.1091C18.8326 14.0192 20.5721 15.4268 20.8975 17.3875C21.3865 20.3289 20.3858 23.4424 18.2216 25.7158C16.4657 27.5609 14.161 28.6308 11.7327 28.7293C11.6107 28.7343 11.4886 28.7365 11.3673 28.7365L11.368 28.7358Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.8795 23.1633C18.4713 23.1633 17.1101 22.4039 16.4063 21.0727L15.1693 18.7351L9.3314 14.7101C8.2015 13.9314 7.56054 12.6188 7.64048 11.2483C7.72042 9.87789 8.51057 8.64949 9.72398 8.00781L12.5519 6.51174C13.1537 6.1934 13.8132 6.04422 14.4663 6.05707C14.8232 5.50889 15.3185 5.0485 15.9195 4.73016L18.7346 3.24052C19.9509 2.59741 21.4149 2.63738 22.594 3.34616C23.7732 4.05493 24.4948 5.32973 24.4962 6.70588L24.5048 13.8072L25.7367 16.1362C26.7503 18.0527 26.0187 20.4282 24.1022 21.4417L21.7111 22.7065C21.1258 23.0163 20.4977 23.1626 19.8788 23.1626L19.8795 23.1633Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.0009 20.8607C23.2101 20.8607 22.4321 20.6216 21.7718 20.1662C20.9146 19.5752 20.3279 18.6672 20.1394 17.6437L18.1994 7.07344C17.8083 4.94069 19.2194 2.89502 21.3521 2.50316L26.3014 1.59452C28.2479 1.23692 30.1479 1.58739 31.6497 2.58096C33.1094 3.54598 34.1265 5.08273 34.4413 6.79721C34.5434 7.35253 34.5691 7.90998 34.5241 8.4553C35.2079 9.32682 35.6747 10.3753 35.8867 11.5295C36.2443 13.4774 35.8103 15.4067 34.6647 16.9613C33.5626 18.4581 31.871 19.4809 29.9024 19.8421L24.7104 20.795C24.4749 20.8385 24.2379 20.8592 24.0017 20.8592L24.0009 20.8607Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.5453 20.7771C40.3204 20.7771 40.092 20.7578 39.8629 20.7171L32.2277 19.3781C31.202 19.1982 30.2898 18.6186 29.6924 17.7664C29.0942 16.9141 28.8594 15.8592 29.0393 14.8335L30.8958 4.24754C31.2705 2.11193 33.3055 0.683673 35.4404 1.05912L43.0899 2.4003C45.2255 2.77503 46.653 4.80928 46.2783 6.94488L45.8436 9.4231C45.7394 10.0162 45.5074 10.5551 45.1805 11.0155L44.7694 13.3596C44.9121 13.8949 44.9443 14.4688 44.8422 15.0498L44.4075 17.528C44.0735 19.4345 42.4161 20.7764 40.5453 20.7764V20.7771Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.683 25.4102C47.9992 25.4102 47.3147 25.2318 46.7037 24.8749L44.0163 23.306C43.5716 23.0462 43.1905 22.7072 42.8864 22.3132C42.3904 22.2418 41.9078 22.0755 41.466 21.8178L39.1177 20.4467C37.2455 19.3532 36.6138 16.9492 37.7073 15.0769L43.1262 5.79577C43.6509 4.89641 44.5117 4.2426 45.5188 3.9785C46.5259 3.71369 47.5966 3.86002 48.496 4.38535L52.3639 6.64373C54.9056 8.12766 55.8685 10.0813 56.2283 11.4588C56.7136 13.3168 56.4131 15.3118 55.3817 17.0777C54.7058 18.2354 53.7615 19.1897 52.6523 19.8371C52.638 19.8457 52.6237 19.8535 52.6087 19.8621V21.4852C52.6087 22.8913 51.8571 24.1897 50.638 24.8899C50.0327 25.2375 49.3575 25.4109 48.683 25.4109V25.4102Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.5804 36.1904C19.8838 36.1904 19.1843 36.0055 18.559 35.6294L12.6012 32.0491C11.5084 31.3924 10.8018 30.2454 10.7083 28.9734C10.614 27.7015 11.1437 26.4631 12.1287 25.653L13.6897 24.3682C14.0466 24.0741 14.4441 23.8521 14.8624 23.7022C15.0894 23.3203 15.3842 22.9727 15.7411 22.6794L16.7439 21.8535C17.1008 21.5595 17.4984 21.3375 17.9166 21.1876C18.1436 20.8057 18.4384 20.4588 18.7953 20.1648L20.3563 18.88C21.3413 18.0698 22.6582 17.7879 23.8881 18.1248C25.1179 18.4617 26.1079 19.3746 26.5426 20.5737L28.9102 27.109C29.4762 28.6715 28.9972 30.421 27.7139 31.4773L26.3634 32.5887C26.0287 32.8642 25.6582 33.0769 25.2685 33.2254C25.0479 33.5794 24.7674 33.902 24.4327 34.1775L23.0751 35.2953C22.3556 35.8877 21.4698 36.1897 20.5797 36.1897L20.5804 36.1904Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.1721 31.8649C25.4933 31.8649 24.8195 31.6886 24.2199 31.3453C23.3162 30.8278 22.656 29.972 22.3833 28.967L20.6682 22.6444C20.1007 20.5523 21.3362 18.3953 23.429 17.8279L28.464 16.4617C29.469 16.189 30.5411 16.3268 31.444 16.8443C32.3476 17.3618 33.0078 18.2176 33.2805 19.2226L33.6824 20.7029C33.8108 21.1762 33.8472 21.6523 33.8016 22.1134L33.9921 22.8164C34.2598 23.1919 34.4618 23.6144 34.5846 24.0677L34.9864 25.548C35.2591 26.553 35.1213 27.6251 34.6038 28.528C34.0864 29.4317 33.2305 30.0919 32.2263 30.3646L27.2013 31.7279C26.863 31.8199 26.5175 31.8649 26.1735 31.8649H26.1721Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.1123 30.9955C36.9803 30.9955 36.8468 30.9891 36.7126 30.9756L31.5321 30.4509C29.375 30.2325 27.8033 28.3068 28.0217 26.1497L28.6813 19.6323C28.7862 18.5966 29.298 17.6444 30.1045 16.9863C30.9111 16.3282 31.9461 16.0177 32.9825 16.1219L38.173 16.6473C40.33 16.8657 41.9017 18.7915 41.6833 20.9485L41.5292 22.4745C41.4806 22.9513 41.3464 23.4103 41.1366 23.8314L41.0631 24.5587C41.1787 25.0048 41.2158 25.4724 41.1687 25.9399L41.0145 27.4659C40.8097 29.4888 39.1038 30.9963 37.1138 30.9963L37.1123 30.9955Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.3998 34.2875C42.7453 34.2875 42.0894 34.124 41.4955 33.7942L39.5476 32.7136C39.19 32.5152 38.8702 32.2646 38.5962 31.9748C38.2086 31.897 37.8267 31.7593 37.4627 31.5573L35.8653 30.6708C34.9545 30.1654 34.2821 29.3196 33.9959 28.3189C33.709 27.3182 33.8317 26.244 34.3371 25.3339L37.5155 19.6059C38.5676 17.7101 40.9573 17.0256 42.8531 18.0777L44.4505 18.9642C44.791 19.1526 45.0979 19.3896 45.3641 19.6637C45.7375 19.7443 46.1 19.8792 46.4412 20.0684L48.372 21.1398C49.7146 21.8849 50.4997 23.3439 50.382 24.8749C50.2635 26.406 49.2642 27.7272 47.8231 28.2575L47.1357 28.5102L47.2999 29.9035C47.4719 31.3667 46.811 32.8028 45.5876 33.6229C44.928 34.0648 44.1657 34.2882 43.4006 34.2882L43.3998 34.2875Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.3955 44.4801C27.3841 44.4801 23.8566 41.5408 23.2428 37.4559C22.9152 35.2767 23.4562 33.1004 24.7652 31.3281C26.0743 29.5558 27.9958 28.3995 30.1742 28.0719C34.6724 27.3953 38.8822 30.5052 39.5582 35.0033C39.8858 37.1825 39.3448 39.3588 38.0357 41.1311C36.7266 42.9034 34.8052 44.0597 32.6267 44.3873C32.2135 44.4494 31.8016 44.4794 31.3955 44.4794V44.4801ZM31.4005 35.832C31.3812 35.832 31.3612 35.8335 31.3412 35.8363C31.1242 35.8692 30.9744 36.0719 31.0072 36.2889C31.04 36.5058 31.2434 36.6564 31.4597 36.6229C31.6767 36.5901 31.8266 36.3874 31.7938 36.1704C31.7638 35.9734 31.5939 35.8313 31.4005 35.8313V35.832Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.4393 28.6643C9.06097 28.6643 6.76976 27.7243 4.95178 25.9934C3.03959 24.1733 1.96607 21.7743 1.92896 19.239C1.89327 16.7722 2.83402 14.4032 4.57991 12.5702C6.70267 10.3404 9.68338 9.19263 12.5556 9.49813C14.4785 9.70298 15.9653 11.2776 16.0602 13.2083L16.103 14.0748L16.9189 14.0377C18.9046 13.9478 20.6434 15.3553 20.9688 17.3161C21.4578 20.2575 20.4571 23.371 18.2929 25.6444C16.537 27.4895 14.2323 28.5594 11.804 28.6579C11.6819 28.6629 11.5599 28.6651 11.4385 28.6651L11.4393 28.6643Z"
        fill="#C8C9CA"
      />
      <path
        d="M19.9511 23.0919C18.5428 23.0919 17.1816 22.3325 16.4778 21.0013L15.2409 18.6637L9.40294 14.6387C8.27304 13.86 7.63207 12.5474 7.71201 11.1769C7.79195 9.80648 8.5821 8.57808 9.79551 7.93639L12.6235 6.44033C13.2252 6.12198 13.8847 5.97352 14.5378 5.98565C14.8947 5.43748 15.3901 4.97709 15.9911 4.65875L18.8062 3.16911C20.0224 2.526 21.4864 2.56597 22.6655 3.27475C23.8447 3.98352 24.5663 5.25832 24.5677 6.63447L24.5763 13.7358L25.8083 16.0648C26.8218 17.9813 26.0902 20.3567 24.1737 21.3703L21.7826 22.6351C21.1973 22.9449 20.5692 23.0912 19.9504 23.0912L19.9511 23.0919Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.0722 20.7893C23.2814 20.7893 22.5034 20.5501 21.8431 20.0948C20.9859 19.5037 20.3992 18.5958 20.2107 17.5723L18.2707 7.00203C17.8795 4.86928 19.2907 2.82361 21.4234 2.43175L26.3727 1.52311C28.3192 1.16551 30.2192 1.51598 31.721 2.50955C33.1807 3.47457 34.1978 5.01132 34.5126 6.7258C34.6146 7.28111 34.6403 7.83857 34.5954 8.38389C35.2792 9.25541 35.746 10.3039 35.958 11.4581C36.3156 13.406 35.8816 15.3353 34.736 16.8899C33.6339 18.3867 31.9423 19.4095 29.9737 19.7707L24.7817 20.7236C24.5462 20.7671 24.3092 20.7878 24.0729 20.7878L24.0722 20.7893Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.6168 20.7058C40.392 20.7058 40.1635 20.6865 39.9337 20.6458L32.2985 19.3068C31.2728 19.1269 30.3606 18.5473 29.7632 17.6951C29.165 16.8429 28.9302 15.7879 29.1101 14.7622L30.9666 4.17625C31.3413 2.04064 33.3756 0.612384 35.5112 0.987828L43.1607 2.32901C44.1864 2.50888 45.0986 3.08846 45.696 3.9407C46.2934 4.79295 46.529 5.8479 46.3491 6.87359L45.9144 9.35181C45.8102 9.94496 45.5782 10.4839 45.2513 10.9442L44.8402 13.2883C44.9829 13.8236 45.0151 14.3975 44.913 14.9785L44.4783 17.4567C44.1443 19.3632 42.4869 20.7051 40.6161 20.7051L40.6168 20.7058Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.7545 25.3389C48.0707 25.3389 47.3862 25.1604 46.7752 24.8036L44.0879 23.2347C43.6432 22.9749 43.262 22.6358 42.958 22.2418C42.4619 22.1704 41.9794 22.0041 41.5375 21.7465L39.1892 20.3753C37.317 19.2818 36.6853 16.8778 37.7788 15.0056L43.1978 5.72442C43.7224 4.82506 44.5832 4.17125 45.5903 3.90715C46.5975 3.64234 47.6681 3.78867 48.5675 4.314L52.4354 6.57238C54.9772 8.05631 55.94 10.0099 56.2998 11.3875C56.7852 13.2454 56.4847 15.2404 55.4533 17.0063C54.7773 18.164 53.833 19.1184 52.7238 19.7657C52.7095 19.7743 52.6952 19.7822 52.6802 19.7907V21.4138C52.6802 22.82 51.9286 24.1183 50.7095 24.8185C50.1042 25.1661 49.429 25.3396 48.7545 25.3396V25.3389Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.6517 36.119C19.9551 36.119 19.2556 35.9341 18.6303 35.5579L12.6725 31.9777C11.5797 31.321 10.873 30.174 10.7795 28.902C10.6853 27.6301 11.2149 26.3917 12.2 25.5815L13.761 24.2968C14.1179 24.0027 14.5154 23.7807 14.9337 23.6308C15.1607 23.2489 15.4555 22.9013 15.8124 22.608L16.8152 21.7821C17.1721 21.4881 17.5697 21.2661 17.9879 21.1162C18.2149 20.7343 18.5097 20.3874 18.8666 20.0933L20.4276 18.8086C21.4126 17.9984 22.7295 17.7165 23.9594 18.0534C25.1892 18.3903 26.1792 19.3032 26.6139 20.5023L28.9815 27.0376C29.5475 28.6001 29.0685 30.3495 27.7852 31.4059L26.4347 32.5173C26.1 32.7928 25.7295 33.0055 25.3398 33.154C25.1192 33.508 24.8387 33.8306 24.504 34.1061L23.1464 35.2239C22.4269 35.8163 21.5411 36.1182 20.651 36.1182L20.6517 36.119Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.2433 31.7935C25.5645 31.7935 24.8907 31.6172 24.2912 31.2739C23.3875 30.7564 22.7273 29.9006 22.4546 28.8956L20.7394 22.573C20.172 20.4809 21.4075 18.3239 23.5003 17.7565L28.5353 16.3903C29.5403 16.1176 30.6123 16.2554 31.5153 16.7729C32.4189 17.2904 33.0791 18.1462 33.3518 19.1512L33.7537 20.6315C33.8821 21.1048 33.9185 21.5809 33.8729 22.0419L34.0634 22.745C34.3311 23.1205 34.5331 23.543 34.6559 23.9963L35.0577 25.4766C35.3304 26.4816 35.1926 27.5537 34.6751 28.4566C34.1576 29.3603 33.3018 30.0205 32.2976 30.2932L27.2726 31.6565C26.9343 31.7485 26.5888 31.7935 26.2448 31.7935H26.2433Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.1836 30.9242C37.0516 30.9242 36.9181 30.9177 36.7839 30.9042L31.6034 30.3795C29.4463 30.1611 27.8746 28.2354 28.093 26.0783L28.7525 19.5609C28.8575 18.5252 29.3692 17.573 30.1758 16.9149C30.9824 16.2568 32.0173 15.9456 33.0537 16.0506L38.2443 16.5759C40.4013 16.7943 41.973 18.7201 41.7546 20.8771L41.6005 22.4031C41.5519 22.8799 41.4177 23.3389 41.2079 23.76L41.1344 24.4874C41.25 24.9335 41.2871 25.401 41.24 25.8685L41.0858 27.3945C40.881 29.4174 39.1751 30.9249 37.1851 30.9249L37.1836 30.9242Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.4711 34.2161C42.8166 34.2161 42.1606 34.0526 41.5668 33.7229L39.6189 32.6422C39.2613 32.4438 38.9415 32.1933 38.6674 31.9035C38.2799 31.8257 37.898 31.6879 37.534 31.4859L35.9366 30.5994C35.0258 30.0941 34.3534 29.2482 34.0672 28.2475C33.7802 27.2468 33.903 26.1726 34.4084 25.2625L37.5868 19.5345C38.6389 17.6387 41.0279 16.9542 42.9244 18.0063L44.5218 18.8928C44.8623 19.0813 45.1692 19.3182 45.4354 19.5923C45.8087 19.673 46.1713 19.8079 46.5125 19.997L48.4433 21.0684C49.7859 21.8136 50.571 23.2725 50.4533 24.8036C50.3348 26.3346 49.3355 27.6558 47.8944 28.1862L47.207 28.4388L47.3712 29.8321C47.5432 31.2953 46.8823 32.7315 45.6588 33.5516C44.9993 33.9934 44.237 34.2168 43.4718 34.2168L43.4711 34.2161Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.467 44.4087C27.4556 44.4087 23.9282 41.4694 23.3143 37.3845C22.9867 35.2053 23.5277 33.029 24.8368 31.2567C26.1458 29.4844 28.0673 28.3281 30.2457 28.0005C34.7439 27.3238 38.9538 30.4337 39.6297 34.9319C39.9573 37.1111 39.4163 39.2874 38.1072 41.0597C36.7982 42.832 34.8767 43.9883 32.6983 44.3159C32.285 44.378 31.8732 44.408 31.467 44.408V44.4087ZM31.472 35.7606C31.4527 35.7606 31.4328 35.7621 31.4128 35.7649C31.1958 35.7977 31.0459 36.0005 31.0787 36.2174C31.1116 36.4344 31.315 36.585 31.5313 36.5515C31.7482 36.5187 31.8981 36.3159 31.8653 36.099C31.8353 35.902 31.6654 35.7599 31.472 35.7599V35.7606Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.5105 28.5929C9.13226 28.5929 6.84105 27.6529 5.02307 25.922C3.11088 24.1019 2.03736 21.7029 2.00025 19.1676C1.96456 16.7008 2.90531 14.3318 4.6512 12.4988C6.77324 10.269 9.75538 9.12051 12.6269 9.42672C14.5498 9.63157 16.0366 11.2062 16.1315 13.1369L16.1743 14.0034L16.9902 13.9663C18.9759 13.8764 20.7146 15.2839 21.0401 17.2447C21.5291 20.1861 20.5283 23.2996 18.3642 25.573C16.6083 27.4181 14.3035 28.488 11.8753 28.5865C11.7532 28.5915 11.6312 28.5936 11.5098 28.5936L11.5105 28.5929Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.0226 23.0205C18.6143 23.0205 17.2532 22.2611 16.5494 20.9299L15.3124 18.5923L9.47447 14.5673C8.34457 13.7886 7.7036 12.476 7.78354 11.1055C7.86349 9.73507 8.65363 8.50666 9.86705 7.86498L12.695 6.36892C13.2967 6.05057 13.9562 5.9014 14.6093 5.91424C14.9662 5.36607 15.4616 4.90568 16.0626 4.58734L18.8777 3.0977C20.094 2.45459 21.5579 2.49456 22.7371 3.20334C23.9162 3.91211 24.6378 5.18691 24.6393 6.56306L24.6478 13.6644L25.8798 15.9934C26.8934 17.9099 26.1618 20.2853 24.2453 21.2989L21.8541 22.5637C21.2688 22.8735 20.6407 23.0198 20.0219 23.0198L20.0226 23.0205Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.1438 20.7179C23.3529 20.7179 22.5749 20.4788 21.9146 20.0234C21.0574 19.4324 20.4707 18.5245 20.2823 17.5009L18.3422 6.93068C17.9511 4.79793 19.3622 2.75226 21.495 2.36039L26.4443 1.45176C28.3914 1.09416 30.2908 1.44463 31.7925 2.4382C33.2522 3.40322 34.2693 4.93997 34.5841 6.65445C34.6862 7.20976 34.7119 7.76722 34.6669 8.31254C35.3507 9.18406 35.8175 10.2326 36.0295 11.3868C36.3871 13.3346 35.9531 15.264 34.8075 16.8186C33.7055 18.3153 32.0138 19.3382 30.0452 19.6994L24.8533 20.6522C24.6177 20.6958 24.3807 20.7165 24.1445 20.7165L24.1438 20.7179Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.6881 20.6344C40.4632 20.6344 40.2348 20.6151 40.005 20.5744L32.3698 19.2354C31.3441 19.0555 30.4319 18.4759 29.8345 17.6237C29.2363 16.7714 29.0015 15.7165 29.1814 14.6908L31.0379 4.10484C31.4126 1.96923 33.4469 0.540973 35.5825 0.916417L43.232 2.2576C44.2577 2.43747 45.1699 3.01705 45.7673 3.86929C46.3647 4.72154 46.6003 5.77649 46.4204 6.80218L45.9857 9.2804C45.8815 9.87355 45.6495 10.4124 45.3226 10.8728L44.9115 13.2169C45.0542 13.7522 45.0863 14.3261 44.9843 14.9071L44.5496 17.3853C44.2156 19.2918 42.5582 20.6337 40.6874 20.6337L40.6881 20.6344Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.8258 25.2675C48.142 25.2675 47.4575 25.089 46.8465 24.7321L44.1591 23.1633C43.7145 22.9035 43.3333 22.5644 43.0292 22.1704C42.5332 22.099 42.0507 21.9327 41.6088 21.6751L39.2605 20.3039C37.3883 19.2104 36.7566 16.8064 37.8501 14.9342L43.2691 5.65301C43.7937 4.75365 44.6545 4.09984 45.6616 3.83574C46.6688 3.57093 47.7394 3.71726 48.6388 4.24259L52.5067 6.50097C55.0485 7.9849 56.0113 9.93849 56.3711 11.3161C56.8564 13.174 56.5559 15.169 55.5245 16.9349C54.8486 18.0926 53.9043 19.0469 52.7951 19.6943C52.7808 19.7029 52.7665 19.7108 52.7515 19.7193V21.3424C52.7515 22.7486 51.9999 24.0469 50.7808 24.7471C50.1755 25.0947 49.5003 25.2682 48.8258 25.2682V25.2675Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.7235 36.0476C20.0269 36.0476 19.3274 35.8627 18.7021 35.4866L12.7442 31.9063C11.6515 31.2496 10.9448 30.1026 10.8513 28.8307C10.7571 27.5587 11.2867 26.3203 12.2717 25.5102L13.8327 24.2254C14.1896 23.9313 14.5872 23.7093 15.0055 23.5595C15.2325 23.1776 15.5272 22.83 15.8841 22.5366L16.887 21.7108C17.2439 21.4167 17.6414 21.1947 18.0597 21.0448C18.2867 20.663 18.5815 20.3161 18.9384 20.022L20.4994 18.7372C21.4844 17.9271 22.8013 17.6451 24.0311 17.982C25.261 18.3189 26.251 19.2318 26.6856 20.431L29.0532 26.9663C29.6193 28.5287 29.1403 30.2782 27.8569 31.3346L26.5065 32.4459C26.1717 32.7214 25.8013 32.9341 25.4116 33.0826C25.191 33.4366 24.9105 33.7593 24.5757 34.0348L23.2181 35.1525C22.4987 35.745 21.6129 36.0469 20.7228 36.0469L20.7235 36.0476Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.3151 31.7222C25.6363 31.7222 24.9625 31.5459 24.3629 31.2025C23.4593 30.685 22.7991 29.8292 22.5264 28.8242L20.8112 22.5016C20.2438 20.4096 21.4793 18.2526 23.5721 17.6851L28.607 16.3189C29.612 16.0463 30.6841 16.184 31.587 16.7015C32.4907 17.219 33.1509 18.0748 33.4236 19.0798L33.8254 20.5602C33.9539 21.0334 33.9903 21.5095 33.9446 21.9706L34.1352 22.6737C34.4029 23.0491 34.6049 23.4717 34.7276 23.9249L35.1295 25.4053C35.4022 26.4103 35.2644 27.4823 34.7469 28.3853C34.2294 29.2889 33.3736 29.9491 32.3693 30.2218L27.3444 31.5851C27.006 31.6772 26.6606 31.7222 26.3165 31.7222H26.3151Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.2549 30.8528C37.1229 30.8528 36.9894 30.8464 36.8552 30.8328L31.6746 30.3082C29.5176 30.0898 27.9459 28.164 28.1643 26.007L28.8238 19.4895C28.9288 18.4538 29.4405 17.5017 30.2471 16.8436C31.0537 16.1855 32.0886 15.875 33.125 15.9792L38.3156 16.5045C40.4726 16.7229 42.0443 18.6487 41.8259 20.8057L41.6717 22.3318C41.6232 22.8086 41.489 23.2675 41.2792 23.6886L41.2056 24.416C41.3213 24.8621 41.3584 25.3296 41.3113 25.7971L41.1571 27.3232C40.9523 29.346 39.2463 30.8535 37.2563 30.8535L37.2549 30.8528Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.5424 34.1447C42.8879 34.1447 42.2319 33.9812 41.6381 33.6515L39.6902 32.5708C39.3326 32.3724 39.0128 32.1219 38.7387 31.8321C38.3512 31.7543 37.9693 31.6165 37.6053 31.4145L36.0078 30.528C35.0971 30.0227 34.4247 29.1768 34.1385 28.1761C33.8515 27.1754 33.9743 26.1012 34.4797 25.1911L37.6581 19.4631C38.7102 17.5673 41.0999 16.8828 42.9957 17.9349L44.5931 18.8214C44.9336 19.0099 45.2405 19.2468 45.5067 19.5209C45.88 19.6016 46.2426 19.7365 46.5838 19.9256L48.5146 20.997C49.8572 21.7422 50.6423 23.2011 50.5245 24.7322C50.4061 26.2632 49.4068 27.5844 47.9657 28.1147L47.2783 28.3674L47.4425 29.7607C47.6145 31.2239 46.9535 32.66 45.7301 33.4802C45.0706 33.922 44.3083 34.1454 43.5431 34.1454L43.5424 34.1447Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.5383 44.3373C27.5269 44.3373 23.9994 41.398 23.3856 37.3131C23.058 35.134 23.599 32.9577 24.9081 31.1854C26.2171 29.4131 28.1386 28.2568 30.317 27.9291C34.8159 27.2525 39.0251 30.3624 39.701 34.8606C40.0286 37.0397 39.4876 39.216 38.1785 40.9883C36.8695 42.7606 34.948 43.9169 32.7696 44.2446C32.3563 44.3066 31.9444 44.3366 31.5383 44.3366V44.3373ZM31.5433 35.6893C31.524 35.6893 31.504 35.6907 31.4841 35.6936C31.2671 35.7264 31.1172 35.9291 31.15 36.1461C31.1828 36.3631 31.3856 36.513 31.6025 36.4801C31.8195 36.4473 31.9694 36.2446 31.9366 36.0276C31.9066 35.8306 31.7367 35.6886 31.5433 35.6886V35.6893Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.5818 28.5216C9.20355 28.5216 6.91234 27.5815 5.09436 25.8506C3.18217 24.0305 2.10865 21.6315 2.07154 19.0962C2.03585 16.6294 2.9766 14.2604 4.72249 12.4274C6.84525 10.1976 9.82596 9.04916 12.6982 9.35537C14.6211 9.56022 16.1079 11.1348 16.2028 13.0656L16.2456 13.9321L17.0615 13.895C19.0472 13.805 20.7859 15.2126 21.1114 17.1733C21.6003 20.1148 20.5996 23.2282 18.4355 25.5016C16.6796 27.3467 14.3748 28.4167 11.9466 28.5152C11.8245 28.5202 11.7025 28.5223 11.5811 28.5223L11.5818 28.5216Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.0936 22.9492C18.6854 22.9492 17.3242 22.1897 16.6204 20.8585L15.3835 18.5209L9.54552 14.496C8.41561 13.7172 7.77465 12.4046 7.85459 11.0342C7.93453 9.66372 8.72468 8.43531 9.93809 7.79363L12.7661 6.29757C13.3678 5.97922 14.0273 5.82933 14.6804 5.84289C15.0373 5.29472 15.5326 4.83433 16.1336 4.51599L18.9488 3.02635C20.165 2.38324 21.629 2.42321 22.8081 3.13199C23.9873 3.84076 24.7089 5.11556 24.7103 6.49171L24.7189 13.593L25.9509 15.9221C26.9644 17.8386 26.2328 20.214 24.3163 21.2275L21.9252 22.4923C21.3399 22.8021 20.7118 22.9484 20.0929 22.9484L20.0936 22.9492Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.2148 20.6466C23.4239 20.6466 22.6459 20.4074 21.9857 19.9521C21.1285 19.361 20.5417 18.4531 20.3533 17.4296L18.4133 6.85933C18.0221 4.72658 19.4332 2.68091 21.566 2.28904L26.5153 1.38041C28.4618 1.02281 30.3618 1.37328 31.8636 2.36685C33.3233 3.33187 34.3404 4.86862 34.6552 6.5831C34.7572 7.13841 34.7829 7.69587 34.738 8.24119C35.4217 9.11271 35.8886 10.1612 36.1005 11.3154C36.4581 13.2633 36.0242 15.1926 34.8786 16.7472C33.7765 18.244 32.0849 19.2668 30.1163 19.628L24.9243 20.5809C24.6888 20.6244 24.4518 20.6451 24.2155 20.6451L24.2148 20.6466Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.7599 20.563C40.535 20.563 40.3066 20.5437 40.0768 20.503L32.4416 19.164C31.4159 18.9841 30.5037 18.4045 29.9062 17.5523C29.3081 16.7 29.0733 15.6451 29.2531 14.6194L31.1097 4.03343C31.4844 1.89782 33.5186 0.469562 35.6543 0.845006L43.3037 2.18618C44.3294 2.36605 45.2416 2.94564 45.8391 3.79788C46.4365 4.65013 46.672 5.70508 46.4922 6.73077L46.0575 9.20899C45.9533 9.80214 45.7213 10.341 45.3944 10.8014L44.9833 13.1454C45.126 13.6808 45.1581 14.2546 45.0561 14.8357L44.6214 17.3139C44.2873 19.2204 42.6299 20.5623 40.7592 20.5623L40.7599 20.563Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.8971 25.1961C48.2133 25.1961 47.5288 25.0177 46.9178 24.6608L44.2304 23.0919C43.7858 22.8321 43.4046 22.4931 43.1005 22.0991C42.6045 22.0277 42.1219 21.8614 41.6801 21.6037L39.3318 20.2325C37.4596 19.139 36.8279 16.7351 37.9214 14.8628L43.3404 5.58166C43.865 4.6823 44.7258 4.02849 45.7329 3.76439C46.7401 3.49958 47.8107 3.64591 48.7101 4.17124L52.578 6.42962C55.1197 7.91355 56.0826 9.86714 56.4424 11.2447C56.9277 13.1027 56.6272 15.0977 55.5958 16.8635C54.9199 18.0213 53.9756 18.9756 52.8664 19.623C52.8521 19.6316 52.8378 19.6394 52.8228 19.648V21.2711C52.8228 22.6772 52.0712 23.9756 50.8521 24.6758C50.2468 25.0234 49.5716 25.1968 48.8971 25.1968V25.1961Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.7946 35.9762C20.0979 35.9762 19.3984 35.7913 18.7731 35.4152L12.8153 31.8349C11.7225 31.1782 11.0159 30.0312 10.9224 28.7593C10.8281 27.4873 11.3578 26.2489 12.3428 25.4388L13.9038 24.154C14.2607 23.8599 14.6583 23.6379 15.0765 23.488C15.3035 23.1062 15.5983 22.7586 15.9552 22.4652L16.958 21.6394C17.3149 21.3453 17.7125 21.1233 18.1308 20.9734C18.3577 20.5916 18.6525 20.2447 19.0094 19.9506L20.5704 18.6658C21.5554 17.8557 22.8723 17.5737 24.1022 17.9106C25.332 18.2475 26.322 19.1604 26.7567 20.3596L29.1243 26.8949C29.6903 28.4573 29.2114 30.2068 27.928 31.2632L26.5775 32.3745C26.2428 32.65 25.8723 32.8627 25.4826 33.0112C25.2621 33.3652 24.9815 33.6878 24.6468 33.9634L23.2892 35.0811C22.5697 35.6736 21.6839 35.9755 20.7938 35.9755L20.7946 35.9762Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.3862 31.6507C25.7074 31.6507 25.0336 31.4744 24.434 31.1311C23.5304 30.6136 22.8701 29.7578 22.5975 28.7528L20.8823 22.4302C20.3148 20.3382 21.5504 18.1811 23.6431 17.6137L28.6781 16.2475C29.6831 15.9749 30.7552 16.1126 31.6581 16.6301C32.5617 17.1476 33.222 18.0034 33.4946 19.0084L33.8965 20.4888C34.025 20.962 34.0614 21.4381 34.0157 21.8992L34.2063 22.6023C34.4739 22.9777 34.6759 23.4002 34.7987 23.8535L35.2005 25.3339C35.4732 26.3388 35.3354 27.4109 34.818 28.3139C34.3005 29.2175 33.4447 29.8777 32.4404 30.1504L27.4154 31.5137C27.0771 31.6058 26.7316 31.6507 26.3876 31.6507H26.3862Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.3265 30.7814C37.1944 30.7814 37.0609 30.775 36.9267 30.7614L31.7462 30.2368C29.5892 30.0184 28.0174 28.0926 28.2358 25.9356L28.8954 19.4181C29.0003 18.3824 29.5121 17.4303 30.3186 16.7722C31.1252 16.1141 32.1602 15.8029 33.1966 15.9078L38.3871 16.4331C40.5441 16.6516 42.1159 18.5773 41.8974 20.7343L41.7433 22.2604C41.6947 22.7372 41.5605 23.1961 41.3507 23.6173L41.2772 24.3446C41.3928 24.7907 41.4299 25.2582 41.3828 25.7257L41.2286 27.2518C41.0238 29.2746 39.3179 30.7821 37.3279 30.7821L37.3265 30.7814Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.614 34.0733C42.9594 34.0733 42.3035 33.9098 41.7096 33.5801L39.7617 32.4994C39.4041 32.301 39.0844 32.0504 38.8103 31.7607C38.4227 31.6829 38.0408 31.5451 37.6768 31.3431L36.0794 30.4566C35.1686 29.9512 34.4962 29.1054 34.21 28.1047C33.9231 27.104 34.0458 26.0298 34.5512 25.1197L37.7296 19.3917C38.7817 17.4959 41.1707 16.8114 43.0672 17.8635L44.6646 18.75C45.0051 18.9385 45.312 19.1754 45.5783 19.4495C45.9516 19.5302 46.3142 19.6651 46.6553 19.8542L48.5861 20.9256C49.9287 21.6708 50.7139 23.1297 50.5961 24.6608C50.4776 26.1918 49.4783 27.513 48.0372 28.0433L47.3498 28.296L47.514 29.6893C47.686 31.1525 47.0251 32.5886 45.8017 33.4088C45.1421 33.8506 44.3798 34.074 43.6147 34.074L43.614 34.0733Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.6096 44.2659C27.5982 44.2659 24.0707 41.3266 23.4569 37.2417C23.1293 35.0626 23.6703 32.8863 24.9794 31.114C26.2884 29.3417 28.2099 28.1854 30.3883 27.8577C34.8865 27.1811 39.0964 30.291 39.7723 34.7892C40.0999 36.9683 39.5589 39.1446 38.2498 40.9169C36.9408 42.6892 35.0193 43.8455 32.8409 44.1731C32.4276 44.2352 32.0157 44.2652 31.6096 44.2652V44.2659ZM31.6146 35.6179C31.5953 35.6179 31.5753 35.6193 31.5553 35.6221C31.3384 35.655 31.1885 35.8577 31.2213 36.0747C31.2541 36.2917 31.4576 36.4416 31.6738 36.4087C31.8908 36.3759 32.0407 36.1732 32.0079 35.9562C31.9779 35.7592 31.808 35.6172 31.6146 35.6172V35.6179Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.6534 28.4502C9.27508 28.4502 6.98387 27.5102 5.1659 25.7793C3.2537 23.9592 2.18019 21.5602 2.14307 19.0249C2.10738 16.5581 3.04813 14.1891 4.79402 12.3561C6.91607 10.1263 9.8982 8.97781 12.7697 9.28402C14.6926 9.48887 16.1794 11.0635 16.2743 12.9942L16.3172 13.8607L17.133 13.8236C19.1187 13.733 20.8575 15.1412 21.1829 17.102C21.6719 20.0434 20.6712 23.1569 18.507 25.4303C16.7511 27.2754 14.4464 28.3453 12.0181 28.4438C11.8961 28.4488 11.774 28.4509 11.6527 28.4509L11.6534 28.4502Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.1652 22.8777C18.7569 22.8777 17.3957 22.1183 16.692 20.7871L15.455 18.4495L9.61705 14.4245C8.48715 13.6458 7.84618 12.3332 7.92612 10.9627C8.00606 9.5923 8.79621 8.3639 10.0096 7.72222L12.8376 6.22616C13.4393 5.90781 14.0981 5.75935 14.7519 5.77148C15.1088 5.2233 15.6042 4.76292 16.2052 4.44458L19.0203 2.95494C20.2366 2.31183 21.7005 2.3518 22.8797 3.06057C24.0588 3.76935 24.7804 5.04415 24.7819 6.4203L24.7904 13.5216L26.0224 15.8507C27.0359 17.7671 26.3043 20.1426 24.3879 21.1561L21.9967 22.4209C21.4114 22.7307 20.7833 22.877 20.1645 22.877L20.1652 22.8777Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.2863 20.5752C23.4955 20.5752 22.7175 20.3361 22.0572 19.8807C21.2 19.2897 20.6133 18.3818 20.4248 17.3582L18.4848 6.78798C18.0937 4.65523 19.5048 2.60956 21.6375 2.21769L26.5868 1.30906C28.5333 0.951463 30.4333 1.30192 31.9351 2.2955C33.3948 3.26052 34.4119 4.79727 34.7267 6.51175C34.8288 7.06706 34.8545 7.62452 34.8095 8.16984C35.4933 9.04136 35.9601 10.0899 36.1721 11.2441C36.5297 13.1919 36.0957 15.1213 34.9501 16.6759C33.848 18.1726 32.1564 19.1955 30.1878 19.5567L24.9958 20.5095C24.7603 20.5531 24.5233 20.5738 24.2871 20.5738L24.2863 20.5752Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.8309 20.4917C40.6061 20.4917 40.3777 20.4724 40.1478 20.4317L32.5126 19.0927C31.4869 18.9128 30.5747 18.3332 29.9773 17.481C29.3791 16.6287 29.1443 15.5738 29.3242 14.5481L31.1807 3.96214C31.5554 1.82653 33.5897 0.398273 35.7253 0.773717L43.3748 2.11489C44.4005 2.29477 45.3127 2.87435 45.9101 3.72659C46.5075 4.57884 46.7431 5.63379 46.5632 6.65948L46.1285 9.1377C46.0243 9.73085 45.7923 10.2697 45.4654 10.7301L45.0543 13.0742C45.1971 13.6095 45.2292 14.1834 45.1271 14.7644L44.6924 17.2426C44.3584 19.1491 42.701 20.491 40.8302 20.491L40.8309 20.4917Z"
        fill="#C8C9CA"
      />
      <path
        d="M48.9686 25.1247C48.2848 25.1247 47.6003 24.9463 46.9893 24.5894L44.302 23.0205C43.8573 22.7607 43.4761 22.4217 43.1721 22.0277C42.676 21.9563 42.1935 21.79 41.7517 21.5323L39.4033 20.1611C37.5311 19.0676 36.8994 16.6637 37.9929 14.7914L43.4119 5.51024C43.9365 4.61089 44.7973 3.95708 45.8045 3.69298C46.8116 3.42817 47.8822 3.57449 48.7816 4.09983L52.6495 6.35821C55.1913 7.84214 56.1542 9.79573 56.5139 11.1733C56.9993 13.0313 56.6988 15.0263 55.6674 16.7921C54.9914 17.9499 54.0471 18.9042 52.9379 19.5516C52.9236 19.5601 52.9093 19.568 52.8944 19.5766V21.1997C52.8944 22.6058 52.1428 23.9042 50.9236 24.6044C50.3184 24.952 49.6431 25.1254 48.9686 25.1254V25.1247Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.8661 35.9049C20.1694 35.9049 19.4699 35.72 18.8447 35.3438L12.8868 31.7635C11.794 31.1069 11.0874 29.9598 10.9939 28.6879C10.8997 27.416 11.4293 26.1776 12.4143 25.3674L13.9753 24.0826C14.3322 23.7886 14.7298 23.5666 15.1481 23.4167C15.375 23.0348 15.6698 22.6872 16.0267 22.3939L17.0296 21.568C17.3864 21.2739 17.784 21.052 18.2023 20.9021C18.4293 20.5202 18.7241 20.1733 19.0809 19.8792L20.642 18.5944C21.6263 17.7843 22.9439 17.5024 24.1737 17.8393C25.4035 18.1762 26.3935 19.0891 26.8282 20.2882L29.1958 26.8235C29.7618 28.386 29.2829 30.1354 27.9995 31.1918L26.6491 32.3032C26.3143 32.5787 25.9439 32.7914 25.5541 32.9398C25.3336 33.2939 25.0531 33.6165 24.7183 33.892L23.3607 35.0098C22.6412 35.6022 21.7554 35.9041 20.8654 35.9041L20.8661 35.9049Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.4577 31.5794C25.7789 31.5794 25.1051 31.4031 24.5055 31.0598C23.6019 30.5423 22.9417 29.6865 22.669 28.6815L20.9538 22.3589C20.3863 20.2668 21.6219 18.1098 23.7147 17.5423L28.7496 16.1762C29.7546 15.9035 30.8267 16.0413 31.7296 16.5588C32.6333 17.0763 33.2935 17.9321 33.5662 18.9371L33.968 20.4174C34.0965 20.8907 34.1329 21.3667 34.0872 21.8278L34.2778 22.5309C34.5455 22.9063 34.7474 23.3289 34.8702 23.7821L35.2721 25.2625C35.5447 26.2675 35.407 27.3396 34.8895 28.2425C34.372 29.1461 33.5162 29.8064 32.5119 30.079L27.487 31.4423C27.1486 31.5344 26.8032 31.5794 26.4591 31.5794H26.4577Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.3975 30.71C37.2654 30.71 37.132 30.7036 36.9978 30.69L31.8172 30.1654C29.6602 29.947 28.0885 28.0212 28.3069 25.8642L28.9664 19.3468C29.0713 18.3111 29.5831 17.3589 30.3897 16.7008C31.1962 16.0427 32.2312 15.7322 33.2676 15.8364L38.4582 16.3618C40.6152 16.5802 42.1869 18.5059 41.9685 20.663L41.8143 22.189C41.7658 22.6658 41.6316 23.1248 41.4217 23.5459L41.3482 24.2732C41.4639 24.7193 41.501 25.1868 41.4539 25.6544L41.2997 27.1804C41.0948 29.2032 39.3889 30.7107 37.3989 30.7107L37.3975 30.71Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.6852 34.0019C43.0307 34.0019 42.3748 33.8384 41.7809 33.5087L39.833 32.428C39.4754 32.2296 39.1556 31.979 38.8816 31.6892C38.494 31.6114 38.1121 31.4737 37.7481 31.2717L36.1507 30.3852C35.2399 29.8798 34.5675 29.034 34.2813 28.0333C33.9944 27.0326 34.1171 25.9584 34.6225 25.0483L37.8009 19.3203C38.853 17.4245 41.242 16.74 43.1385 17.7921L44.7359 18.6786C45.0764 18.867 45.3833 19.104 45.6495 19.3781C46.0229 19.4588 46.3854 19.5937 46.7266 19.7828L48.6574 20.8542C50 21.5994 50.7851 23.0583 50.6674 24.5894C50.5489 26.1204 49.5496 27.4416 48.1085 27.9719L47.4211 28.2246L47.5853 29.6179C47.7573 31.0811 47.0964 32.5172 45.873 33.3373C45.2134 33.7792 44.4511 34.0026 43.686 34.0026L43.6852 34.0019Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.6809 44.1945C27.6695 44.1945 24.142 41.2552 23.5282 37.1703C23.2006 34.9912 23.7416 32.8149 25.0506 31.0426C26.3597 29.2703 28.2812 28.114 30.4596 27.7863C34.9578 27.1104 39.1676 30.2196 39.8436 34.7178C40.1712 36.8969 39.6302 39.0732 38.3211 40.8455C37.012 42.6178 35.0906 43.7741 32.9121 44.1018C32.4989 44.1638 32.087 44.1938 31.6809 44.1938V44.1945ZM31.6859 35.5465C31.6666 35.5465 31.6466 35.5479 31.6266 35.5508C31.4096 35.5836 31.2598 35.7863 31.2926 36.0033C31.3254 36.2203 31.5288 36.3702 31.7451 36.3373C31.9621 36.3045 32.112 36.1018 32.0792 35.8848C32.0492 35.6878 31.8793 35.5458 31.6859 35.5458V35.5465Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.7247 28.3788C9.34637 28.3788 7.05516 27.4388 5.23718 25.7079C3.32499 23.8878 2.25147 21.4888 2.21436 18.9535C2.17867 16.4867 3.11942 14.1176 4.86531 12.2847C6.98807 10.0549 9.96878 8.90711 12.841 9.21261C14.7639 9.41746 16.2507 10.992 16.3456 12.9228L16.3884 13.7893L17.2043 13.7522C19.1907 13.6615 20.9288 15.0698 21.2542 17.0306C21.7432 19.972 20.7425 23.0855 18.5783 25.3588C16.8224 27.2039 14.5177 28.2739 12.0894 28.3724C11.9673 28.3774 11.8453 28.3795 11.7239 28.3795L11.7247 28.3788Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.2362 22.8064C18.8279 22.8064 17.4668 22.0469 16.763 20.7158L15.526 18.3782L9.68809 14.3532C8.55819 13.5745 7.91722 12.2618 7.99717 10.8914C8.07711 9.52095 8.86726 8.29255 10.0807 7.65087L12.9086 6.1548C13.5103 5.83646 14.1706 5.68657 14.823 5.70013C15.1799 5.15195 15.6752 4.69157 16.2762 4.37323L19.0913 2.88359C20.3076 2.24048 21.7715 2.28045 22.9507 2.98922C24.1299 3.698 24.8515 4.9728 24.8529 6.34895L24.8615 13.4503L26.0934 15.7793C27.107 17.6958 26.3754 20.0712 24.4589 21.0848L22.0678 22.3496C21.4825 22.6594 20.8543 22.8057 20.2355 22.8057L20.2362 22.8064Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.3579 20.5038C23.567 20.5038 22.789 20.2647 22.1288 19.8093C21.2715 19.2183 20.6848 18.3104 20.4964 17.2868L18.5563 6.71657C18.1652 4.58382 19.5763 2.53814 21.7091 2.14628L26.6584 1.23765C28.6048 0.880052 30.5049 1.23051 32.0067 2.22408C33.4663 3.18911 34.4834 4.72586 34.7982 6.44034C34.9003 6.99565 34.926 7.55311 34.881 8.09843C35.5648 8.96995 36.0316 10.0185 36.2436 11.1726C36.6012 13.1205 36.1672 15.0499 35.0216 16.6045C33.9196 18.1012 32.2279 19.1241 30.2593 19.4852L25.0674 20.4381C24.8318 20.4817 24.5948 20.5024 24.3586 20.5024L24.3579 20.5038Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.9024 20.4203C40.6776 20.4203 40.4492 20.4011 40.2194 20.3604L32.5841 19.0213C31.5584 18.8415 30.6462 18.2619 30.0488 17.4096C29.4507 16.5574 29.2158 15.5024 29.3957 14.4767L31.2522 3.89079C31.627 1.75518 33.6612 0.326923 35.7968 0.702367L43.4463 2.04354C44.472 2.22342 45.3842 2.803 45.9816 3.65524C46.5791 4.50749 46.8146 5.56244 46.6347 6.58813L46.2001 9.06635C46.0958 9.6595 45.8639 10.1984 45.537 10.6588L45.1258 13.0028C45.2686 13.5381 45.3007 14.112 45.1986 14.693L44.764 17.1712C44.4299 19.0777 42.7725 20.4196 40.9017 20.4196L40.9024 20.4203Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.0399 25.0534C48.3561 25.0534 47.6716 24.875 47.0606 24.5181L44.3733 22.9492C43.9286 22.6894 43.5474 22.3504 43.2434 21.9564C42.7473 21.885 42.2648 21.7187 41.8229 21.461L39.4746 20.0898C37.6024 18.9963 36.9707 16.5924 38.0642 14.7201L43.4832 5.43896C44.0078 4.5396 44.8686 3.88579 45.8757 3.62169C46.8829 3.35688 47.9535 3.50321 48.8529 4.02854L52.7208 6.28692C55.2626 7.77085 56.2254 9.72444 56.5852 11.102C57.0706 12.96 56.7701 14.955 55.7387 16.7208C55.0627 17.8786 54.1184 18.8329 53.0092 19.4803C52.9949 19.4889 52.9806 19.4967 52.9656 19.5053V21.1284C52.9656 22.5345 52.214 23.8329 50.9949 24.5331C50.3896 24.8807 49.7144 25.0541 49.0399 25.0541V25.0534Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.9374 35.8335C20.2407 35.8335 19.5412 35.6486 18.916 35.2725L12.9581 31.6922C11.8653 31.0355 11.1587 29.8885 11.0652 28.6166C10.971 27.3446 11.5006 26.1062 12.4856 25.2961L14.0466 24.0113C14.4035 23.7172 14.8011 23.4952 15.2193 23.3453C15.4463 22.9635 15.7411 22.6159 16.098 22.3225L17.1008 21.4967C17.4577 21.2026 17.8553 20.9806 18.2736 20.8307C18.5006 20.4489 18.7953 20.102 19.1522 19.8079L20.7133 18.5231C21.6983 17.713 23.0152 17.431 24.245 17.7679C25.4748 18.1048 26.4648 19.0177 26.8995 20.2169L29.2671 26.7522C29.8331 28.3146 29.3542 30.0641 28.0708 31.1205L26.7204 32.2318C26.3856 32.5073 26.0152 32.72 25.6254 32.8685C25.4049 33.2225 25.1244 33.5451 24.7896 33.8207L23.432 34.9384C22.7125 35.5309 21.8267 35.8328 20.9367 35.8328L20.9374 35.8335Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.529 31.508C25.8502 31.508 25.1764 31.3317 24.5768 30.9884C23.6732 30.4709 23.0129 29.6151 22.7403 28.6101L21.0251 22.2875C20.4576 20.1955 21.6932 18.0384 23.786 17.471L28.8209 16.1048C29.8259 15.8322 30.898 15.9699 31.8009 16.4874C32.7045 17.0049 33.3648 17.8607 33.6374 18.8657L34.0393 20.3461C34.1678 20.8193 34.2042 21.2954 34.1585 21.7565L34.3491 22.4596C34.6167 22.835 34.8187 23.2575 34.9415 23.7108L35.3434 25.1912C35.616 26.1961 35.4783 27.2682 34.9608 28.1712C34.4433 29.0748 33.5875 29.735 32.5832 30.0077L27.5582 31.371C27.2199 31.4631 26.8744 31.508 26.5304 31.508H26.529Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.469 30.6386C37.337 30.6386 37.2035 30.6322 37.0693 30.6186L31.8888 30.094C29.7317 29.8756 28.16 27.9498 28.3784 25.7928L29.038 19.2754C29.1429 18.2397 29.6546 17.2875 30.4612 16.6294C31.2678 15.9713 32.3027 15.6601 33.3391 15.765L38.5297 16.2904C40.6867 16.5088 42.2584 18.4345 42.04 20.5916L41.8859 22.1176C41.8373 22.5944 41.7031 23.0534 41.4933 23.4745L41.4198 24.2018C41.5354 24.6479 41.5725 25.1155 41.5254 25.583L41.3712 27.109C41.1664 29.1319 39.4605 30.6393 37.4705 30.6393L37.469 30.6386Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.7565 33.9306C43.102 33.9306 42.446 33.7671 41.8522 33.4374L39.9043 32.3567C39.5467 32.1583 39.2269 31.9077 38.9528 31.618C38.5653 31.5402 38.1834 31.4024 37.8194 31.2004L36.222 30.3139C35.3112 29.8085 34.6388 28.9627 34.3526 27.962C34.0656 26.9613 34.1884 25.8871 34.6938 24.977L37.8722 19.249C38.9243 17.3532 41.3133 16.6687 43.2098 17.7208L44.8072 18.6073C45.1477 18.7958 45.4546 19.0327 45.7208 19.3068C46.0941 19.3875 46.4567 19.5224 46.7979 19.7115L48.7287 20.7829C50.0713 21.5281 50.8564 22.987 50.7387 24.5181C50.6202 26.0491 49.6209 27.3703 48.1798 27.9006L47.4924 28.1533L47.6566 29.5466C47.8286 31.0098 47.1677 32.4459 45.9442 33.2661C45.2847 33.7079 44.5224 33.9313 43.7572 33.9313L43.7565 33.9306Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.7527 44.1232C27.7413 44.1232 24.2138 41.1839 23.6 37.099C23.2723 34.9199 23.8134 32.7436 25.1224 30.9713C26.4315 29.199 28.353 28.0427 30.5314 27.715C35.0296 27.0384 39.2394 30.1483 39.9154 34.6465C40.243 36.8256 39.7019 39.0019 38.3929 40.7742C37.0838 42.5465 35.1623 43.7028 32.9839 44.0304C32.5706 44.0925 32.1588 44.1225 31.7527 44.1225V44.1232ZM31.7577 35.4752C31.7384 35.4752 31.7184 35.4766 31.6984 35.4794C31.4814 35.5123 31.3315 35.715 31.3644 35.932C31.3972 36.149 31.6006 36.2989 31.8169 36.266C32.0339 36.2332 32.1838 36.0305 32.1509 35.8135C32.121 35.6165 31.9511 35.4745 31.7577 35.4745V35.4752Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.7962 28.3074C9.4179 28.3074 7.1267 27.3674 5.30872 25.6365C3.39652 23.8164 2.32301 21.4174 2.28589 18.882C2.2502 16.4152 3.19096 14.0462 4.93684 12.2133C7.05889 9.98345 10.041 8.83499 12.9125 9.14119C14.8354 9.34605 16.3222 10.9206 16.4172 12.8514L16.46 13.7179L17.2758 13.6808C19.2608 13.5901 21.0003 14.9984 21.3258 16.9591C21.8147 19.9006 20.814 23.0141 18.6498 25.2874C16.894 27.1325 14.5892 28.2025 12.1609 28.301C12.0389 28.306 11.9168 28.3081 11.7955 28.3081L11.7962 28.3074Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.308 22.735C18.8997 22.735 17.5386 21.9756 16.8348 20.6444L15.5978 18.3068L9.75987 14.2818C8.62997 13.5031 7.989 12.1905 8.06894 10.82C8.14889 9.4496 8.93903 8.2212 10.1524 7.57952L12.9804 6.08345C13.5821 5.76511 14.2424 5.61665 14.8947 5.62878C15.2516 5.0806 15.747 4.62022 16.348 4.30188L19.1631 2.81224C20.3794 2.16913 21.8433 2.2091 23.0225 2.91787C24.2016 3.62665 24.9233 4.90145 24.9247 6.2776L24.9332 13.3789L26.1652 15.708C27.1788 17.6244 26.4472 19.9999 24.5307 21.0134L22.1395 22.2782C21.5542 22.588 20.9261 22.7343 20.3073 22.7343L20.308 22.735Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.4292 20.4324C23.6383 20.4324 22.8603 20.1933 22.2001 19.7379C21.3428 19.1469 20.7561 18.239 20.5677 17.2155L18.6276 6.64522C18.2365 4.51247 19.6476 2.46679 21.7804 2.07493L26.7297 1.1663C28.6761 0.808702 30.5762 1.15916 32.0779 2.15273C33.5376 3.11775 34.5547 4.65451 34.8695 6.36899C34.9716 6.9243 34.9973 7.48176 34.9523 8.02708C35.6361 8.8986 36.1029 9.94713 36.3149 11.1013C36.6725 13.0492 36.2385 14.9785 35.0929 16.5331C33.9909 18.0299 32.2992 19.0527 30.3306 19.4139L25.1387 20.3668C24.9031 20.4103 24.6661 20.431 24.4299 20.431L24.4292 20.4324Z"
        fill="#C8C9CA"
      />
      <path
        d="M40.9735 20.3489C40.7486 20.3489 40.5202 20.3296 40.2904 20.289L32.6552 18.9499C31.6295 18.7701 30.7173 18.1905 30.1199 17.3382C29.5217 16.486 29.2869 15.431 29.4668 14.4053L31.3233 3.81938C31.698 1.68377 33.7323 0.255511 35.8679 0.630956L43.5174 1.97213C44.5431 2.152 45.4553 2.73159 46.0527 3.58383C46.6501 4.43608 46.8857 5.49103 46.7058 6.51672L46.2711 8.99494C46.1669 9.58808 45.9349 10.127 45.608 10.5874L45.1969 12.9314C45.3396 13.4667 45.3717 14.0406 45.2697 14.6216L44.835 17.0998C44.501 19.0063 42.8436 20.3482 40.9728 20.3482L40.9735 20.3489Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.1112 24.9819C48.4274 24.9819 47.7429 24.8035 47.1319 24.4466L44.4445 22.8777C43.9999 22.6179 43.6187 22.2789 43.3146 21.8849C42.8186 21.8135 42.3361 21.6472 41.8942 21.3895L39.5459 20.0184C37.6737 18.9249 37.042 16.5209 38.1355 14.6487L43.5545 5.36748C44.0791 4.46813 44.9399 3.81432 45.947 3.55022C46.9542 3.28541 48.0248 3.43173 48.9242 3.95707L52.7921 6.21545C55.3339 7.69938 56.2967 9.65297 56.6565 11.0306C57.1418 12.8885 56.8413 14.8835 55.8099 16.6494C55.134 17.8071 54.1897 18.7614 53.0805 19.4088C53.0662 19.4174 53.0519 19.4252 53.0369 19.4338V21.0569C53.0369 22.463 52.2853 23.7614 51.0662 24.4616C50.4609 24.8092 49.7857 24.9827 49.1112 24.9827V24.9819Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.0087 35.7621C20.312 35.7621 19.6125 35.5772 18.9873 35.2011L13.0294 31.6208C11.9366 30.9641 11.23 29.8171 11.1365 28.5451C11.0423 27.2732 11.5719 26.0348 12.5569 25.2247L14.1179 23.9399C14.4748 23.6458 14.8724 23.4238 15.2906 23.2739C15.5176 22.8921 15.8124 22.5445 16.1693 22.2511L17.1721 21.4253C17.529 21.1312 17.9266 20.9092 18.3449 20.7593C18.5718 20.3774 18.8666 20.0305 19.2235 19.7365L20.7845 18.4517C21.7695 17.6416 23.0865 17.3596 24.3163 17.6965C25.5461 18.0334 26.5361 18.9463 26.9708 20.1455L29.3384 26.6808C29.9044 28.2432 29.4255 29.9927 28.1421 31.0491L26.7916 32.1604C26.4569 32.4359 26.0864 32.6486 25.6967 32.7971C25.4762 33.1511 25.1957 33.4737 24.8609 33.7493L23.5033 34.867C22.7838 35.4595 21.898 35.7614 21.008 35.7614L21.0087 35.7621Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.6003 31.4366C25.9215 31.4366 25.2477 31.2603 24.6481 30.917C23.7445 30.3995 23.0842 29.5437 22.8116 28.5387L21.0964 22.2161C20.5289 20.1241 21.7645 17.967 23.8572 17.3996L28.8922 16.0334C29.8972 15.7608 30.9693 15.8985 31.8722 16.416C32.7758 16.9335 33.4361 17.7893 33.7087 18.7943L34.1106 20.2747C34.2391 20.7479 34.2755 21.224 34.2298 21.6851L34.4204 22.3881C34.688 22.7636 34.89 23.1861 35.0128 23.6394L35.4146 25.1197C35.6873 26.1247 35.5496 27.1968 35.0321 28.0997C34.5146 29.0034 33.6588 29.6636 32.6545 29.9363L27.6295 31.2996C27.2912 31.3917 26.9457 31.4366 26.6017 31.4366H26.6003Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.5406 30.5673C37.4085 30.5673 37.275 30.5608 37.1409 30.5473L31.9603 30.0226C29.8033 29.8042 28.2315 27.8785 28.45 25.7215L29.1095 19.204C29.2144 18.1683 29.7262 17.2161 30.5327 16.558C31.3393 15.8999 32.3743 15.5895 33.4107 15.6937L38.6012 16.219C40.7582 16.4374 42.33 18.3632 42.1116 20.5202L41.9574 22.0462C41.9088 22.523 41.7747 22.982 41.5648 23.4031L41.4913 24.1305C41.6069 24.5766 41.644 25.0441 41.5969 25.5116L41.4428 27.0376C41.2379 29.0605 39.532 30.568 37.542 30.568L37.5406 30.5673Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.8281 33.8592C43.1735 33.8592 42.5176 33.6957 41.9237 33.366L39.9758 32.2853C39.6182 32.0869 39.2985 31.8363 39.0244 31.5465C38.6368 31.4687 38.2549 31.331 37.8909 31.129L36.2935 30.2425C35.3827 29.7371 34.7103 28.8913 34.4241 27.8906C34.1372 26.8899 34.26 25.8157 34.7653 24.9056L37.9437 19.1776C38.9958 17.2818 41.3848 16.5973 43.2813 17.6494L44.8787 18.5359C45.2192 18.7243 45.5261 18.9613 45.7924 19.2354C46.1657 19.3161 46.5283 19.451 46.8695 19.6401L48.8002 20.7115C50.1428 21.4567 50.928 22.9156 50.8102 24.4467C50.6917 25.9777 49.6924 27.2989 48.2513 27.8292L47.564 28.0819L47.7281 29.4752C47.9001 30.9384 47.2392 32.3745 46.0158 33.1946C45.3563 33.6365 44.5939 33.8599 43.8288 33.8599L43.8281 33.8592Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.8237 44.0518C27.8123 44.0518 24.2848 41.1125 23.671 37.0276C23.3434 34.8484 23.8844 32.6721 25.1935 30.8999C26.5025 29.1276 28.424 27.9712 30.6024 27.6436C35.1013 26.967 39.3105 30.0769 39.9864 34.5751C40.314 36.7542 39.773 38.9305 38.4639 40.7028C37.1549 42.4751 35.2334 43.6314 33.055 43.959C32.6417 44.0211 32.2298 44.0511 31.8237 44.0511V44.0518ZM31.8287 35.4038C31.8094 35.4038 31.7894 35.4052 31.7695 35.408C31.5525 35.4409 31.4026 35.6436 31.4354 35.8606C31.4682 36.0776 31.671 36.2282 31.8879 36.1946C32.1049 36.1618 32.2548 35.9591 32.222 35.7421C32.192 35.5451 32.0221 35.403 31.8287 35.403V35.4038Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.8675 28.2361C9.48919 28.2361 7.19798 27.2961 5.38001 25.5652C3.46781 23.7451 2.3943 21.3461 2.35718 18.8108C2.32149 16.344 3.26224 13.9749 5.00813 12.142C7.13089 9.91216 10.1116 8.7637 12.9838 9.06991C14.9067 9.27476 16.3935 10.8493 16.4884 12.7801L16.5313 13.6466L17.3471 13.6095C19.3321 13.5188 21.0716 14.9271 21.3971 16.8879C21.886 19.8293 20.8853 22.9428 18.7211 25.2161C16.9652 27.0612 14.6605 28.1312 12.2322 28.2297C12.1102 28.2347 11.9881 28.2368 11.8668 28.2368L11.8675 28.2361Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.3793 22.6636C18.971 22.6636 17.6099 21.9042 16.9061 20.573L15.6691 18.2354L9.83116 14.2104C8.70126 13.4317 8.06029 12.1191 8.14023 10.7486C8.22018 9.37819 9.01032 8.14979 10.2237 7.50811L13.0517 6.01204C13.6534 5.6937 14.3129 5.54452 14.966 5.55737C15.3229 5.00919 15.8183 4.54881 16.4193 4.23047L19.2344 2.74082C20.4507 2.09772 21.9146 2.13769 23.0938 2.84646C24.2729 3.55524 24.9945 4.83004 24.996 6.20619L25.0045 13.3075L26.2365 15.6365C27.2501 17.553 26.5184 19.9285 24.602 20.942L22.2108 22.2068C21.6255 22.5166 20.9974 22.6629 20.3786 22.6629L20.3793 22.6636Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.5005 20.361C23.7096 20.361 22.9316 20.1219 22.2713 19.6665C21.4141 19.0755 20.8274 18.1676 20.6389 17.1441L18.6989 6.57381C18.3078 4.44105 19.7189 2.39538 21.8516 2.00352L26.8009 1.09489C28.7474 0.73729 30.6475 1.08775 32.1492 2.08132C33.6089 3.04634 34.626 4.5831 34.9408 6.29758C35.0429 6.85289 35.0686 7.41035 35.0236 7.95567C35.7074 8.82719 36.1742 9.87572 36.3862 11.0299C36.7438 12.9778 36.3098 14.9071 35.1642 16.4617C34.0621 17.9585 32.3705 18.9813 30.4019 19.3425L25.2099 20.2954C24.9744 20.3389 24.7374 20.3596 24.5012 20.3596L24.5005 20.361Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.045 20.2776C40.8202 20.2776 40.5918 20.2583 40.3619 20.2176L32.7267 18.8786C31.701 18.6987 30.7888 18.1191 30.1914 17.2669C29.5933 16.4146 29.3584 15.3597 29.5383 14.334L31.3948 3.74803C31.7695 1.61242 33.8038 0.184161 35.9394 0.559606L43.5889 1.90078C44.6146 2.08065 45.5268 2.66024 46.1242 3.51248C46.7224 4.36473 46.9572 5.41968 46.7773 6.44537L46.3426 8.92359C46.2384 9.51673 46.0065 10.0556 45.6795 10.516L45.2684 12.86C45.4112 13.3954 45.4433 13.9692 45.3412 14.5503L44.9065 17.0285C44.5725 18.935 42.9151 20.2769 41.0443 20.2769L41.045 20.2776Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.1827 24.9106C48.4989 24.9106 47.8144 24.7322 47.2034 24.3753L44.5161 22.8064C44.0714 22.5466 43.6902 22.2075 43.3862 21.8135C42.8901 21.7422 42.4076 21.5759 41.9658 21.3182L39.6175 19.947C37.7452 18.8535 37.1135 16.4495 38.207 14.5773L43.626 5.29613C44.1506 4.39678 45.0114 3.74297 46.0186 3.47887C47.0257 3.21406 48.0964 3.36038 48.9957 3.88572L52.8636 6.1441C55.4054 7.62803 56.3683 9.58162 56.728 10.9592C57.2134 12.8172 56.9129 14.8121 55.8815 16.578C55.2055 17.7358 54.2612 18.6901 53.152 19.3375C53.1377 19.346 53.1235 19.3539 53.1085 19.3624V20.9856C53.1085 22.3917 52.3569 23.69 51.1377 24.3903C50.5325 24.7379 49.8572 24.9113 49.1827 24.9113V24.9106Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.0802 35.6907C20.3836 35.6907 19.6841 35.5058 19.0588 35.1297L13.1009 31.5494C12.0081 30.8927 11.3015 29.7457 11.208 28.4737C11.1138 27.2018 11.6434 25.9634 12.6284 25.1533L14.1894 23.8685C14.5463 23.5744 14.9439 23.3524 15.3622 23.2025C15.5891 22.8207 15.8839 22.473 16.2408 22.1797L17.2437 21.3538C17.6006 21.0598 17.9981 20.8378 18.4164 20.6879C18.6434 20.306 18.9382 19.9591 19.2951 19.6651L20.8561 18.3803C21.8411 17.5701 23.158 17.2882 24.3878 17.6251C25.6176 17.962 26.6076 18.8749 27.0423 20.0741L29.4099 26.6094C29.9759 28.1718 29.497 29.9213 28.2136 30.9776L26.8632 32.089C26.5284 32.3645 26.158 32.5772 25.7683 32.7257C25.5477 33.0797 25.2672 33.4023 24.9324 33.6778L23.5748 34.7956C22.8553 35.388 21.9696 35.69 21.0795 35.69L21.0802 35.6907Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.6718 31.3652C25.993 31.3652 25.3192 31.1889 24.7196 30.8456C23.816 30.3281 23.1558 29.4723 22.8831 28.4673L21.1679 22.1447C20.6005 20.0526 21.836 17.8956 23.9288 17.3282L28.9637 15.962C29.9687 15.6894 31.0408 15.8271 31.9437 16.3446C32.8474 16.8621 33.5076 17.7179 33.7803 18.7229L34.1821 20.2032C34.3106 20.6765 34.347 21.1526 34.3013 21.6137L34.4919 22.3167C34.7596 22.6922 34.9616 23.1147 35.0843 23.568L35.4862 25.0483C35.7588 26.0533 35.6211 27.1254 35.1036 28.0283C34.5861 28.932 33.7303 29.5922 32.726 29.8649L27.7011 31.2282C27.3627 31.3203 27.0173 31.3652 26.6732 31.3652H26.6718Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.6119 30.4959C37.4798 30.4959 37.3463 30.4894 37.2121 30.4759L32.0316 29.9513C29.8746 29.7328 28.3028 27.8071 28.5212 25.6501L29.1808 19.1326C29.2857 18.0969 29.7975 17.1448 30.604 16.4867C31.4106 15.8286 32.4456 15.5174 33.482 15.6223L38.6725 16.1476C40.8295 16.366 42.4013 18.2918 42.1828 20.4488L42.0287 21.9749C41.9801 22.4517 41.8459 22.9106 41.6361 23.3317L41.5626 24.0591C41.6782 24.5052 41.7153 24.9727 41.6682 25.4402L41.514 26.9663C41.3092 28.9891 39.6033 30.4966 37.6133 30.4966L37.6119 30.4959Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.8994 33.7878C43.2448 33.7878 42.5889 33.6243 41.995 33.2945L40.0471 32.2139C39.6895 32.0155 39.3698 31.7649 39.0957 31.4751C38.7081 31.3973 38.3262 31.2596 37.9622 31.0576L36.3648 30.1711C35.454 29.6657 34.7816 28.8199 34.4954 27.8192C34.2085 26.8185 34.3312 25.7443 34.8366 24.8342L38.015 19.1062C39.0671 17.2104 41.4561 16.5259 43.3526 17.578L44.95 18.4645C45.2905 18.6529 45.5974 18.8899 45.8637 19.164C46.237 19.2446 46.5996 19.3795 46.9407 19.5687L48.8715 20.6401C50.2141 21.3853 50.9993 22.8442 50.8815 24.3752C50.763 25.9063 49.7637 27.2275 48.3226 27.7578L47.6352 28.0105L47.7994 29.4038C47.9714 30.867 47.3105 32.3031 46.0871 33.1232C45.4275 33.5651 44.6652 33.7885 43.9001 33.7885L43.8994 33.7878Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.8952 43.9804C27.8838 43.9804 24.3564 41.0411 23.7425 36.9562C23.4149 34.777 23.9559 32.6007 25.265 30.8284C26.5741 29.0561 28.4955 27.8998 30.674 27.5722C35.1722 26.8956 39.382 30.0055 40.0579 34.5037C40.3856 36.6828 39.8445 38.8591 38.5355 40.6314C37.2264 42.4037 35.3049 43.56 33.1265 43.8876C32.7132 43.9497 32.3014 43.9797 31.8952 43.9797V43.9804ZM31.9002 35.3323C31.881 35.3323 31.861 35.3338 31.841 35.3366C31.624 35.3695 31.4741 35.5722 31.5069 35.7892C31.5398 36.0061 31.7432 36.1568 31.9595 36.1232C32.1765 36.0904 32.3264 35.8877 32.2935 35.6707C32.2635 35.4737 32.0937 35.3316 31.9002 35.3316V35.3323Z"
        fill="#C8C9CA"
      />
      <path
        d="M11.9388 28.1646C9.56048 28.1646 7.26927 27.2246 5.4513 25.4937C3.5391 23.6736 2.46559 21.2746 2.42847 18.7393C2.39278 16.2725 3.33353 13.9035 5.07942 12.0705C7.20147 9.84068 10.1836 8.69222 13.0551 8.99843C14.978 9.20329 16.4648 10.7779 16.5597 12.7086L16.6026 13.5751L17.4184 13.538C19.4041 13.4495 21.1429 14.8556 21.4683 16.8164C21.9573 19.7578 20.9566 22.8713 18.7924 25.1447C17.0365 26.9898 14.7318 28.0597 12.3035 28.1582C12.1815 28.1632 12.0594 28.1654 11.9381 28.1654L11.9388 28.1646Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.4508 22.5922C19.0425 22.5922 17.6814 21.8328 16.9776 20.5016L15.7406 18.164L9.90269 14.139C8.77279 13.3603 8.13182 12.0477 8.21177 10.6772C8.29171 9.30678 9.08185 8.07838 10.2953 7.4367L13.1232 5.94063C13.7249 5.62229 14.3845 5.47383 15.0376 5.48596C15.3945 4.93778 15.8898 4.4774 16.4908 4.15906L19.3059 2.66941C20.5222 2.0263 21.9861 2.06628 23.1653 2.77505C24.3444 3.48383 25.0661 4.75863 25.0675 6.13478L25.0761 13.2361L26.308 15.5651C27.3216 17.4816 26.59 19.857 24.6735 20.8706L22.2824 22.1354C21.6971 22.4452 21.0689 22.5915 20.4501 22.5915L20.4508 22.5922Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.572 20.2896C23.7811 20.2896 23.0031 20.0505 22.3429 19.5951C21.4856 19.0041 20.8989 18.0962 20.7105 17.0726L18.7704 6.50239C18.3793 4.36964 19.7904 2.32397 21.9232 1.93211L26.8725 1.02348C28.8189 0.665879 30.719 1.01634 32.2208 2.00991C33.6804 2.97493 34.6976 4.51168 35.0123 6.22617C35.1144 6.78148 35.1401 7.33894 35.0951 7.88426C35.7789 8.75577 36.2457 9.80431 36.4577 10.9585C36.8153 12.9064 36.3813 14.8357 35.2357 16.3903C34.1337 17.8871 32.442 18.9099 30.4735 19.2711L25.2815 20.224C25.0459 20.2675 24.809 20.2882 24.5727 20.2882L24.572 20.2896Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.1163 20.2062C40.8915 20.2062 40.6631 20.1869 40.4332 20.1462L32.798 18.8072C31.7723 18.6273 30.8601 18.0477 30.2627 17.1955C29.6645 16.3432 29.4297 15.2883 29.6096 14.2626L31.4661 3.67662C31.8408 1.54101 33.8751 0.11275 36.0107 0.488194L43.6602 1.82937C44.6859 2.00924 45.5981 2.58883 46.1955 3.44107C46.7937 4.29331 47.0285 5.34827 46.8486 6.37396L46.4139 8.85218C46.3097 9.44532 46.0777 9.98422 45.7508 10.4446L45.3397 12.7886C45.4825 13.324 45.5146 13.8978 45.4125 14.4788L44.9778 16.9571C44.6438 18.8636 42.9864 20.2054 41.1156 20.2054L41.1163 20.2062Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.254 24.8392C48.5702 24.8392 47.8857 24.6607 47.2747 24.3039L44.5874 22.735C44.1427 22.4752 43.7615 22.1361 43.4575 21.7421C42.9614 21.6708 42.4789 21.5044 42.0371 21.2468L39.6887 19.8756C37.8165 18.7821 37.1848 16.3781 38.2783 14.5059L43.6973 5.22472C44.2219 4.32537 45.0827 3.67155 46.0899 3.40746C47.097 3.14265 48.1676 3.28897 49.067 3.81431L52.9349 6.07268C55.4767 7.55662 56.4396 9.51021 56.7993 10.8878C57.2847 12.7457 56.9842 14.7407 55.9528 16.5066C55.2768 17.6643 54.3325 18.6187 53.2233 19.2661C53.209 19.2746 53.1947 19.2825 53.1798 19.291V20.9142C53.1798 22.3203 52.4282 23.6186 51.209 24.3188C50.6038 24.6665 49.9285 24.8399 49.254 24.8399V24.8392Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.1517 35.6194C20.4551 35.6194 19.7556 35.4345 19.1303 35.0584L13.1725 31.4781C12.0797 30.8214 11.373 29.6744 11.2795 28.4024C11.1853 27.1305 11.7149 25.8921 12.7 25.082L14.261 23.7972C14.6179 23.5031 15.0154 23.2811 15.4337 23.1312C15.6607 22.7494 15.9555 22.4018 16.3124 22.1084L17.3152 21.2826C17.6721 20.9885 18.0697 20.7665 18.4879 20.6166C18.7149 20.2347 19.0097 19.8878 19.3666 19.5938L20.9276 18.309C21.9126 17.4989 23.2295 17.2169 24.4594 17.5538C25.6892 17.8907 26.6792 18.8036 27.1139 20.0028L29.4815 26.5381C30.0475 28.1005 29.5685 29.85 28.2852 30.9064L26.9347 32.0177C26.6 32.2932 26.2295 32.5059 25.8398 32.6544C25.6192 33.0084 25.3387 33.331 25.004 33.6066L23.6464 34.7243C22.9269 35.3168 22.0411 35.6187 21.151 35.6187L21.1517 35.6194Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.7433 31.2939C26.0645 31.2939 25.3907 31.1176 24.7912 30.7742C23.8875 30.2568 23.2273 29.4009 22.9546 28.396L21.2394 22.0734C20.672 19.9813 21.9075 17.8243 24.0003 17.2568L29.0353 15.8907C30.0403 15.618 31.1123 15.7558 32.0153 16.2732C32.9189 16.7907 33.5791 17.6465 33.8518 18.6515L34.2537 20.1319C34.3821 20.6051 34.4185 21.0812 34.3729 21.5423L34.5634 22.2454C34.8311 22.6208 35.0331 23.0434 35.1559 23.4966L35.5577 24.977C35.8304 25.982 35.6926 27.0541 35.1751 27.957C34.6576 28.8606 33.8018 29.5209 32.7976 29.7935L27.7726 31.1568C27.4343 31.2489 27.0888 31.2939 26.7448 31.2939H26.7433Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.6831 30.4244C37.5511 30.4244 37.4176 30.418 37.2834 30.4044L32.1029 29.8798C29.9458 29.6614 28.3741 27.7356 28.5925 25.5786L29.2521 19.0612C29.357 18.0255 29.8688 17.0733 30.6753 16.4152C31.4819 15.7571 32.5169 15.4466 33.5533 15.5508L38.7438 16.0762C40.9008 16.2946 42.4726 18.2204 42.2541 20.3774L42.1 21.9034C42.0514 22.3802 41.9172 22.8392 41.7074 23.2603L41.6339 23.9876C41.7495 24.4337 41.7866 24.9013 41.7395 25.3688L41.5853 26.8948C41.3805 28.9177 39.6746 30.4251 37.6846 30.4251L37.6831 30.4244Z"
        fill="#C8C9CA"
      />
      <path
        d="M43.9706 33.7164C43.3161 33.7164 42.6602 33.553 42.0663 33.2232L40.1184 32.1425C39.7608 31.9441 39.441 31.6936 39.167 31.4038C38.7794 31.326 38.3975 31.1882 38.0335 30.9862L36.4361 30.0997C35.5253 29.5944 34.8529 28.7486 34.5667 27.7478C34.2798 26.7471 34.4025 25.6729 34.9079 24.7628L38.0863 19.0348C39.1384 17.139 41.5274 16.4545 43.4239 17.5066L45.0213 18.3931C45.3618 18.5816 45.6687 18.8186 45.9349 19.0926C46.3083 19.1733 46.6708 19.3082 47.012 19.4973L48.9428 20.5687C50.2854 21.3139 51.0705 22.7728 50.9528 24.3039C50.8343 25.8349 49.835 27.1561 48.3939 27.6865L47.7065 27.9391L47.8707 29.3324C48.0427 30.7957 47.3818 32.2318 46.1584 33.0519C45.4988 33.4937 44.7365 33.7171 43.9714 33.7171L43.9706 33.7164Z"
        fill="#C8C9CA"
      />
      <path
        d="M31.9665 43.909C27.9551 43.909 24.4277 40.9697 23.8138 36.8848C23.4862 34.7056 24.0272 32.5293 25.3363 30.757C26.6454 28.9847 28.5668 27.8284 30.7453 27.5008C35.2435 26.8241 39.4533 29.9341 40.1292 34.4322C40.4569 36.6114 39.9158 38.7877 38.6068 40.56C37.2977 42.3323 35.3762 43.4886 33.1978 43.8162C32.7845 43.8783 32.3727 43.9083 31.9665 43.9083V43.909ZM31.9715 35.2609C31.9523 35.2609 31.9323 35.2624 31.9123 35.2652C31.6953 35.298 31.5454 35.5008 31.5782 35.7177C31.6111 35.9347 31.8145 36.0853 32.0308 36.0518C32.2478 36.019 32.3976 35.8162 32.3648 35.5993C32.3348 35.4023 32.165 35.2602 31.9715 35.2602V35.2609Z"
        fill="#C8C9CA"
      />
      <path
        d="M12.0101 28.0934C9.63177 28.0934 7.34056 27.1533 5.52258 25.4224C3.61039 23.6023 2.53687 21.2033 2.49976 18.668C2.46407 16.2012 3.40482 13.8322 5.15071 11.9992C7.27347 9.7694 10.2542 8.62165 13.1264 8.92714C15.0493 9.132 16.5361 10.7066 16.631 12.6373L16.6738 13.5039L17.4897 13.4667C19.4754 13.3775 21.2142 14.7844 21.5396 16.7451C22.0286 19.6865 21.0279 22.8 18.8637 25.0734C17.1078 26.9185 14.8031 27.9884 12.3748 28.0869C12.2527 28.0919 12.1307 28.0941 12.0093 28.0941L12.0101 28.0934Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.5219 22.5209C19.1136 22.5209 17.7524 21.7614 17.0486 20.4302L15.8117 18.0926L9.97374 14.0677C8.84384 13.2889 8.20287 11.9763 8.28281 10.6059C8.36275 9.23543 9.1529 8.00703 10.3663 7.36535L13.1943 5.86928C13.796 5.55094 14.4555 5.40176 15.1086 5.41461C15.4655 4.86643 15.9609 4.40605 16.5619 4.08771L19.377 2.59806C20.5932 1.95495 22.0572 1.99493 23.2363 2.7037C24.4155 3.41248 25.1371 4.68727 25.1385 6.06343L25.1471 13.1647L26.3791 15.4938C27.3926 17.4103 26.661 19.7857 24.7445 20.7993L22.3534 22.0641C21.7681 22.3738 21.14 22.5202 20.5212 22.5202L20.5219 22.5209Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.6433 20.2183C23.8524 20.2183 23.0744 19.9792 22.4142 19.5238C21.5569 18.9328 20.9702 18.0248 20.7818 17.0013L18.8417 6.43104C18.4506 4.29829 19.8617 2.25262 21.9945 1.86076L26.9438 0.952129C28.8902 0.594529 30.7903 0.944991 32.2921 1.93856C33.7517 2.90358 34.7688 4.44033 35.0836 6.15482C35.1857 6.71013 35.2114 7.26759 35.1664 7.81291C35.8502 8.68442 36.317 9.73295 36.529 10.8871C36.8866 12.835 36.4526 14.7643 35.307 16.3189C34.205 17.8157 32.5133 18.8385 30.5447 19.1997L25.3528 20.1526C25.1172 20.1961 24.8802 20.2168 24.644 20.2168L24.6433 20.2183Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.1881 20.1348C40.9632 20.1348 40.7348 20.1155 40.505 20.0748L32.8698 18.7358C31.8441 18.5559 30.9319 17.9764 30.3345 17.1241C29.7363 16.2719 29.5015 15.2169 29.6814 14.1912L31.5379 3.60527C31.9126 1.46966 33.9469 0.0414 36.0825 0.416844L43.732 1.75802C44.7577 1.93789 45.6699 2.51748 46.2673 3.36972C46.8654 4.22196 47.1003 5.27692 46.9204 6.30261L46.4857 8.78083C46.3815 9.37397 46.1495 9.91287 45.8226 10.3733L45.4115 12.7173C45.5542 13.2526 45.5864 13.8265 45.4843 14.4075L45.0496 16.8857C44.7156 18.7922 43.0582 20.1341 41.1874 20.1341L41.1881 20.1348Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.3255 24.7678C48.6417 24.7678 47.9572 24.5894 47.3463 24.2325L44.6589 22.6636C44.2142 22.4038 43.8331 22.0648 43.529 21.6708C43.0329 21.5994 42.5504 21.4331 42.1086 21.1754L39.7603 19.8043C37.8881 18.7108 37.2564 16.3068 38.3499 14.4346L43.7688 5.15337C44.2934 4.25402 45.1543 3.6002 46.1614 3.33611C47.1685 3.0713 48.2392 3.21762 49.1385 3.74296L53.0065 6.00133C55.5482 7.48527 56.5111 9.43886 56.8708 10.8164C57.3562 12.6744 57.0557 14.6694 56.0243 16.4353C55.3484 17.593 54.404 18.5473 53.2948 19.1947C53.2806 19.2033 53.2663 19.2111 53.2513 19.2197V20.8428C53.2513 22.2489 52.4997 23.5473 51.2806 24.2475C50.6753 24.5951 50.0001 24.7686 49.3255 24.7686V24.7678Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.223 35.5479C20.5264 35.5479 19.8269 35.3631 19.2016 34.9869L13.2438 31.4066C12.151 30.7499 11.4443 29.6029 11.3508 28.331C11.2566 27.059 11.7862 25.8206 12.7712 25.0105L14.3323 23.7257C14.6891 23.4316 15.0867 23.2096 15.505 23.0598C15.732 22.6779 16.0268 22.3303 16.3836 22.0369L17.3865 21.2111C17.7434 20.917 18.141 20.695 18.5592 20.5451C18.7862 20.1633 19.081 19.8164 19.4379 19.5223L20.9989 18.2375C21.9839 17.4274 23.3008 17.1454 24.5306 17.4823C25.7605 17.8192 26.7505 18.7322 27.1852 19.9313L29.5527 26.4666C30.1188 28.029 29.6398 29.7785 28.3565 30.8349L27.006 31.9462C26.6712 32.2217 26.3008 32.4344 25.9111 32.5829C25.6905 32.9369 25.41 33.2596 25.0752 33.5351L23.7177 34.6528C22.9982 35.2453 22.1124 35.5472 21.2223 35.5472L21.223 35.5479Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.8144 31.2225C26.1356 31.2225 25.4618 31.0462 24.8622 30.7029C23.9586 30.1854 23.2983 29.3296 23.0257 28.3246L21.3105 22.002C20.743 19.9099 21.9786 17.7529 24.0714 17.1855L29.1063 15.8193C30.1113 15.5467 31.1834 15.6844 32.0863 16.2019C32.9892 16.7194 33.6502 17.5752 33.9228 18.5802L34.3247 20.0605C34.4532 20.5338 34.4896 21.0099 34.4439 21.471L34.6345 22.174C34.9021 22.5495 35.1041 22.972 35.2269 23.4253L35.6288 24.9056C35.9014 25.9106 35.7637 26.9827 35.2462 27.8856C34.7287 28.7893 33.8729 29.4495 32.8686 29.7222L27.8436 31.0855C27.5053 31.1776 27.1599 31.2225 26.8158 31.2225H26.8144Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.7547 30.3532C37.6226 30.3532 37.4892 30.3467 37.355 30.3332L32.1744 29.8086C30.0174 29.5901 28.4457 27.6644 28.6641 25.5074L29.3236 18.9899C29.4285 17.9542 29.9403 17.0021 30.7469 16.344C31.5534 15.6859 32.5884 15.3747 33.6248 15.4796L38.8153 16.0049C40.9724 16.2233 42.5441 18.1491 42.3257 20.3061L42.1715 21.8322C42.123 22.309 41.9888 22.7679 41.7789 23.189L41.7054 23.9164C41.821 24.3625 41.8582 24.83 41.811 25.2975L41.6569 26.8236C41.452 28.8464 39.7461 30.3539 37.7561 30.3539L37.7547 30.3532Z"
        fill="#C8C9CA"
      />
      <path
        d="M44.0422 33.6451C43.3877 33.6451 42.7317 33.4816 42.1378 33.1518L40.19 32.0712C39.8324 31.8728 39.5126 31.6222 39.2385 31.3324C38.8509 31.2546 38.469 31.1169 38.105 30.9149L36.5076 30.0284C35.5968 29.523 34.9245 28.6772 34.6382 27.6765C34.3513 26.6758 34.4741 25.6016 34.9794 24.6915L38.1578 18.9635C39.2099 17.0677 41.5989 16.3832 43.4954 17.4353L45.0929 18.3218C45.4333 18.5102 45.7402 18.7472 46.0065 19.0213C46.3798 19.1019 46.7424 19.2368 47.0836 19.426L49.0143 20.4974C50.3569 21.2425 51.1421 22.7015 51.0243 24.2325C50.9058 25.7636 49.9065 27.0848 48.4654 27.6151L47.7781 27.8678L47.9422 29.2611C48.1143 30.7243 47.4533 32.1604 46.2299 32.9805C45.5704 33.4224 44.8081 33.6458 44.0429 33.6458L44.0422 33.6451Z"
        fill="#C8C9CA"
      />
      <path
        d="M32.0381 43.8378C28.0267 43.8378 24.4992 40.8985 23.8854 36.8135C23.5577 34.6344 24.0988 32.4581 25.4078 30.6858C26.7169 28.9135 28.6384 27.7572 30.8168 27.4296C35.315 26.7529 39.5248 29.8628 40.2008 34.361C40.5284 36.5402 39.9873 38.7165 38.6783 40.4887C37.3692 42.261 35.4478 43.4174 33.2693 43.745C32.856 43.8071 32.4442 43.8371 32.0381 43.8371V43.8378ZM32.0431 35.1897C32.0238 35.1897 32.0038 35.1911 31.9838 35.194C31.7668 35.2268 31.6169 35.4295 31.6498 35.6465C31.6826 35.8635 31.886 36.0141 32.1023 35.9806C32.3193 35.9477 32.4692 35.745 32.4363 35.528C32.4064 35.331 32.2365 35.189 32.0431 35.189V35.1897Z"
        fill="#C8C9CA"
      />
      <path
        d="M12.0816 28.0219C9.7033 28.0219 7.4121 27.0818 5.59412 25.351C3.68192 23.5308 2.60841 21.1318 2.57129 18.5965C2.5356 16.1297 3.47636 13.7607 5.22224 11.9277C7.34429 9.69792 10.3264 8.54946 13.1979 8.85567C15.1208 9.06052 16.6076 10.6351 16.7026 12.5659L16.7454 13.4324L17.5612 13.3953C19.5469 13.306 21.2857 14.7129 21.6112 16.6736C22.1001 19.6151 21.0994 22.7285 18.9352 25.0019C17.1794 26.847 14.8746 27.917 12.4463 28.0155C12.3243 28.0205 12.2022 28.0226 12.0809 28.0226L12.0816 28.0219Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.5932 22.4495C19.1849 22.4495 17.8237 21.6901 17.1199 20.3589L15.883 18.0213L10.045 13.9963C8.91513 13.2176 8.27416 11.905 8.3541 10.5345C8.43404 9.16408 9.22419 7.93568 10.4376 7.294L13.2656 5.79793C13.8673 5.47959 14.5268 5.33113 15.1799 5.34326C15.5368 4.79508 16.0321 4.3347 16.6331 4.01636L19.4483 2.52671C20.6645 1.8836 22.1285 1.92358 23.3076 2.63235C24.4868 3.34113 25.2084 4.61592 25.2098 5.99208L25.2184 13.0934L26.4504 15.4224C27.4639 17.3389 26.7323 19.7143 24.8158 20.7279L22.4247 21.9927C21.8394 22.3025 21.2113 22.4488 20.5924 22.4488L20.5932 22.4495Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.7146 20.1469C23.9237 20.1469 23.1457 19.9078 22.4855 19.4524C21.6282 18.8614 21.0415 17.9535 20.8531 16.9299L18.913 6.35969C18.5219 4.22694 19.933 2.18127 22.0658 1.78941L27.0151 0.880779C28.9622 0.523179 30.8616 0.873641 32.3633 1.86721C33.823 2.83223 34.8401 4.36898 35.1549 6.08347C35.257 6.63878 35.2827 7.19624 35.2377 7.74156C35.9215 8.61307 36.3883 9.6616 36.6003 10.8158C36.9579 12.7637 36.5239 14.693 35.3783 16.2476C34.2763 17.7444 32.5846 18.7672 30.616 19.1284L25.4241 20.0813C25.1885 20.1248 24.9515 20.1455 24.7153 20.1455L24.7146 20.1469Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.2591 20.0634C41.0343 20.0634 40.8059 20.0441 40.576 20.0034L32.9408 18.6644C31.9151 18.4845 31.0029 17.9049 30.4055 17.0527C29.8074 16.2005 29.5725 15.1455 29.7524 14.1198L31.6089 3.53385C31.9837 1.39825 34.0179 -0.0300111 36.1535 0.345433L43.803 1.68661C44.8287 1.86648 45.7409 2.44606 46.3383 3.29831C46.9365 4.15055 47.1713 5.20551 46.9914 6.2312L46.5568 8.70942C46.4525 9.30256 46.2206 9.84146 45.8937 10.3018L45.4825 12.6459C45.6253 13.1812 45.6574 13.7551 45.5553 14.3361L45.1206 16.8143C44.7866 18.7208 43.1292 20.0627 41.2584 20.0627L41.2591 20.0634Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.3968 24.6965C48.713 24.6965 48.0285 24.518 47.4175 24.1612L44.7302 22.5923C44.2855 22.3325 43.9044 21.9934 43.6003 21.5994C43.1042 21.5281 42.6217 21.3617 42.1799 21.1041L39.8316 19.7329C37.9593 18.6394 37.3277 16.2354 38.4212 14.3632L43.8401 5.08202C44.3647 4.18267 45.2255 3.52885 46.2327 3.26476C47.2398 2.99995 48.3105 3.14627 49.2098 3.67161L53.0778 5.92998C55.6195 7.41392 56.5824 9.36751 56.9421 10.7451C57.4275 12.603 57.127 14.598 56.0956 16.3639C55.4196 17.5216 54.4753 18.476 53.3661 19.1234C53.3518 19.1319 53.3376 19.1398 53.3226 19.1483V20.7715C53.3226 22.1776 52.571 23.4759 51.3519 24.1761C50.7466 24.5238 50.0713 24.6972 49.3968 24.6972V24.6965Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.2943 35.4766C20.5977 35.4766 19.8982 35.2917 19.2729 34.9155L13.315 31.3353C12.2223 30.6786 11.5156 29.5316 11.4221 28.2596C11.3279 26.9877 11.8575 25.7493 12.8425 24.9391L14.4035 23.6544C14.7604 23.3603 15.158 23.1383 15.5763 22.9884C15.8033 22.6065 16.098 22.2589 16.4549 21.9656L17.4578 21.1397C17.8147 20.8457 18.2122 20.6237 18.6305 20.4738C18.8575 20.0919 19.1523 19.745 19.5092 19.451L21.0702 18.1662C22.0552 17.356 23.3721 17.0741 24.6019 17.411C25.8318 17.7479 26.8218 18.6608 27.2564 19.8599L29.624 26.3952C30.1901 27.9577 29.7111 29.7071 28.4277 30.7635L27.0773 31.8749C26.7425 32.1504 26.3721 32.3631 25.9824 32.5116C25.7618 32.8656 25.4813 33.1882 25.1465 33.4637L23.7889 34.5815C23.0695 35.1739 22.1837 35.4759 21.2936 35.4759L21.2943 35.4766Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.8859 31.1511C26.2071 31.1511 25.5333 30.9748 24.9338 30.6315C24.0301 30.114 23.3699 29.2582 23.0972 28.2532L21.382 21.9306C20.8146 19.8385 22.0501 17.6815 24.1429 17.1141L29.1778 15.7479C30.1828 15.4752 31.2549 15.613 32.1578 16.1305C33.0615 16.648 33.7217 17.5038 33.9944 18.5088L34.3962 19.9891C34.5247 20.4624 34.5611 20.9385 34.5154 21.3996L34.706 22.1026C34.9737 22.4781 35.1757 22.9006 35.2984 23.3539L35.7003 24.8342C35.973 25.8392 35.8352 26.9113 35.3177 27.8142C34.8002 28.7179 33.9444 29.3781 32.9401 29.6508L27.9152 31.0141C27.5768 31.1061 27.2314 31.1511 26.8873 31.1511H26.8859Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.826 30.2818C37.6939 30.2818 37.5604 30.2753 37.4263 30.2618L32.2457 29.7371C30.0887 29.5187 28.5169 27.593 28.7354 25.436L29.3949 18.9185C29.4998 17.8828 30.0116 16.9306 30.8181 16.2725C31.6247 15.6144 32.6597 15.3032 33.6961 15.4082L38.8866 15.9335C41.0436 16.1519 42.6154 18.0777 42.397 20.2347L42.2428 21.7607C42.1942 22.2375 42.0601 22.6965 41.8502 23.1176L41.7767 23.845C41.8923 24.2911 41.9294 24.7586 41.8823 25.2261L41.7282 26.7521C41.5233 28.775 39.8174 30.2825 37.8274 30.2825L37.826 30.2818Z"
        fill="#C8C9CA"
      />
      <path
        d="M44.1135 33.5736C43.4589 33.5736 42.803 33.4102 42.2091 33.0804L40.2612 31.9998C39.9036 31.8013 39.5839 31.5508 39.3098 31.261C38.9222 31.1832 38.5403 31.0455 38.1763 30.8435L36.5789 29.957C35.6681 29.4516 34.9957 28.6058 34.7095 27.6051C34.4226 26.6044 34.5454 25.5301 35.0507 24.6201L38.2291 18.8921C39.2812 16.9963 41.6702 16.3118 43.5667 17.3639L45.1641 18.2504C45.5046 18.4388 45.8115 18.6758 46.0778 18.9499C46.4511 19.0305 46.8137 19.1654 47.1549 19.3546L49.0856 20.426C50.4282 21.1711 51.2134 22.6301 51.0956 24.1611C50.9771 25.6922 49.9778 27.0134 48.5367 27.5437L47.8494 27.7964L48.0135 29.1897C48.1855 30.6529 47.5246 32.089 46.3012 32.9091C45.6417 33.3509 44.8793 33.5744 44.1142 33.5744L44.1135 33.5736Z"
        fill="#C8C9CA"
      />
      <path
        d="M32.1091 43.7664C28.0977 43.7664 24.5702 40.827 23.9564 36.7421C23.6288 34.563 24.1698 32.3867 25.4789 30.6144C26.7879 28.8421 28.7094 27.6858 30.8878 27.3582C35.386 26.6815 39.5959 29.7914 40.2718 34.2896C40.5994 36.4688 40.0584 38.645 38.7493 40.4173C37.4403 42.1896 35.5188 43.3459 33.3404 43.6736C32.9271 43.7357 32.5152 43.7656 32.1091 43.7656V43.7664ZM32.1141 35.1183C32.0948 35.1183 32.0748 35.1197 32.0549 35.1226C31.8379 35.1554 31.688 35.3581 31.7208 35.5751C31.7536 35.7921 31.9564 35.942 32.1733 35.9092C32.3903 35.8763 32.5402 35.6736 32.5074 35.4566C32.4774 35.2596 32.3075 35.1176 32.1141 35.1176V35.1183Z"
        fill="#C8C9CA"
      />
      <path
        d="M12.1531 27.9506C9.77484 27.9506 7.48363 27.0106 5.66565 25.2797C3.75345 23.4595 2.67994 21.0606 2.64283 18.5252C2.60714 16.0584 3.54789 13.6894 5.29378 11.8565C7.41653 9.62663 10.3972 8.47817 13.2695 8.78438C15.1924 8.98924 16.6792 10.5638 16.7741 12.4946L16.8169 13.3611L17.6328 13.324C19.6185 13.2348 21.3572 14.6416 21.6827 16.6023C22.1716 19.5438 21.1709 22.6573 19.0068 24.9306C17.2509 26.7757 14.9461 27.8457 12.5179 27.9442C12.3958 27.9492 12.2738 27.9513 12.1524 27.9513L12.1531 27.9506Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.6647 22.3781C19.2564 22.3781 17.8953 21.6187 17.1915 20.2875L15.9545 17.9499L10.1166 13.9249C8.98666 13.1462 8.34569 11.8336 8.42563 10.4631C8.50558 9.09267 9.29572 7.86427 10.5091 7.22259L13.3371 5.72652C13.9388 5.40818 14.5983 5.259 15.2514 5.27185C15.6083 4.72367 16.1037 4.26329 16.7047 3.94495L19.5198 2.4553C20.7361 1.81219 22.2 1.85216 23.3792 2.56094C24.5583 3.26972 25.2799 4.54451 25.2814 5.92067L25.2899 13.022L26.5219 15.351C27.5355 17.2675 26.8038 19.6429 24.8874 20.6565L22.4962 21.9213C21.9109 22.2311 21.2828 22.3774 20.664 22.3774L20.6647 22.3781Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.7861 20.0755C23.9952 20.0755 23.2172 19.8364 22.557 19.381C21.6997 18.79 21.113 17.8821 20.9246 16.8585L18.9846 6.28828C18.5934 4.15553 20.0045 2.10986 22.1373 1.718L27.0866 0.809368C29.033 0.451768 30.9331 0.80223 32.4349 1.7958C33.8945 2.76082 34.9117 4.29757 35.2264 6.01205C35.3285 6.56737 35.3542 7.12482 35.3092 7.67015C35.993 8.54166 36.4598 9.59019 36.6718 10.7444C37.0294 12.6922 36.5955 14.6216 35.4499 16.1762C34.3478 17.673 32.6561 18.6958 30.6876 19.057L25.4956 20.0098C25.26 20.0534 25.0231 20.0741 24.7868 20.0741L24.7861 20.0755Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.3307 19.992C41.1058 19.992 40.8774 19.9728 40.6476 19.9321L33.0124 18.5931C31.9867 18.4132 31.0745 17.8336 30.477 16.9814C29.8789 16.1291 29.6441 15.0742 29.8239 14.0485L31.6805 3.4625C32.0552 1.3269 34.0894 -0.101361 36.2251 0.274083L43.8745 1.61526C44.9002 1.79513 45.8124 2.37471 46.4099 3.22696C47.008 4.0792 47.2428 5.13416 47.063 6.15985L46.6283 8.63807C46.5241 9.23121 46.2921 9.77011 45.9652 10.2305L45.5541 12.5745C45.6968 13.1099 45.7289 13.6837 45.6269 14.2647L45.1922 16.743C44.8581 18.6494 43.2008 19.9913 41.33 19.9913L41.3307 19.992Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.4684 24.6251C48.7846 24.6251 48.1001 24.4467 47.4891 24.0898L44.8017 22.5209C44.357 22.2611 43.9759 21.9221 43.6718 21.5281C43.1758 21.4567 42.6932 21.2904 42.2514 21.0327L39.9031 19.6616C38.0309 18.5681 37.3992 16.1641 38.4927 14.2919L43.9116 5.01067C44.4363 4.11132 45.2971 3.4575 46.3042 3.19341C47.3113 2.9286 48.382 3.07492 49.2814 3.60026L53.1493 5.85863C55.691 7.34257 56.6539 9.29616 57.0137 10.6737C57.499 12.5317 57.1985 14.5267 56.1671 16.2926C55.4912 17.4503 54.5469 18.4046 53.4377 19.052C53.4234 19.0606 53.4091 19.0684 53.3941 19.077V20.7001C53.3941 22.1062 52.6425 23.4046 51.4234 24.1048C50.8181 24.4524 50.1429 24.6259 49.4684 24.6259V24.6251Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.3656 35.4052C20.669 35.4052 19.9695 35.2203 19.3442 34.8441L13.3863 31.2639C12.2935 30.6072 11.5869 29.4601 11.4934 28.1882C11.3992 26.9163 11.9288 25.6779 12.9138 24.8677L14.4748 23.5829C14.8317 23.2889 15.2293 23.0669 15.6476 22.917C15.8745 22.5351 16.1693 22.1875 16.5262 21.8942L17.5291 21.0683C17.886 20.7743 18.2835 20.5523 18.7018 20.4024C18.9288 20.0205 19.2236 19.6736 19.5805 19.3795L21.1415 18.0947C22.1258 17.2846 23.4434 17.0027 24.6732 17.3396C25.903 17.6765 26.893 18.5894 27.3277 19.7885L29.6953 26.3238C30.2613 27.8863 29.7824 29.6357 28.499 30.6921L27.1486 31.8035C26.8138 32.079 26.4434 32.2917 26.0537 32.4401C25.8331 32.7942 25.5526 33.1168 25.2178 33.3923L23.8602 34.5101C23.1407 35.1025 22.255 35.4044 21.3649 35.4044L21.3656 35.4052Z"
        fill="#C8C9CA"
      />
      <path
        d="M26.9572 31.0797C26.2784 31.0797 25.6046 30.9034 25.005 30.5601C24.1014 30.0426 23.4412 29.1868 23.1685 28.1818L21.4533 21.8592C20.8859 19.7671 22.1214 17.6101 24.2142 17.0427L29.2491 15.6765C30.2541 15.4038 31.3262 15.5416 32.2291 16.0591C33.1321 16.5766 33.793 17.4324 34.0657 18.4374L34.4675 19.9177C34.596 20.391 34.6324 20.867 34.5867 21.3281L34.7773 22.0312C35.045 22.4067 35.247 22.8292 35.3697 23.2824L35.7716 24.7628C36.0442 25.7678 35.9065 26.8399 35.389 27.7428C34.8715 28.6464 34.0157 29.3067 33.0114 29.5793L27.9865 30.9427C27.6481 31.0347 27.3027 31.0797 26.9586 31.0797H26.9572Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.897 30.2103C37.765 30.2103 37.6315 30.2039 37.4973 30.1904L32.3167 29.6657C30.1597 29.4473 28.588 27.5216 28.8064 25.3645L29.4659 18.8471C29.5709 17.8114 30.0826 16.8592 30.8892 16.2011C31.6958 15.543 32.7307 15.2318 33.7671 15.3368L38.9577 15.8621C41.1147 16.0805 42.6864 18.0063 42.468 20.1633L42.3138 21.6893C42.2653 22.1661 42.1311 22.6251 41.9213 23.0462L41.8477 23.7735C41.9634 24.2197 42.0005 24.6872 41.9534 25.1547L41.7992 26.6807C41.5943 28.7036 39.8884 30.2111 37.8984 30.2111L37.897 30.2103Z"
        fill="#C8C9CA"
      />
      <path
        d="M44.1852 33.5022C43.5307 33.5022 42.8748 33.3388 42.2809 33.009L40.333 31.9284C39.9754 31.7299 39.6556 31.4794 39.3816 31.1896C38.994 31.1118 38.6121 30.9741 38.2481 30.7721L36.6507 29.8856C35.7399 29.3802 35.0675 28.5344 34.7813 27.5337C34.4944 26.533 34.6171 25.4587 35.1225 24.5487L38.3009 18.8207C39.353 16.9249 41.742 16.2404 43.6385 17.2925L45.2359 18.179C45.5764 18.3674 45.8833 18.6044 46.1495 18.8785C46.5229 18.9591 46.8854 19.094 47.2266 19.2832L49.1574 20.3545C50.5 21.0997 51.2851 22.5587 51.1674 24.0897C51.0489 25.6208 50.0496 26.942 48.6085 27.4723L47.9211 27.725L48.0853 29.1182C48.2573 30.5815 47.5964 32.0176 46.373 32.8377C45.7134 33.2795 44.9511 33.5029 44.186 33.5029L44.1852 33.5022Z"
        fill="#C8C9CA"
      />
      <path
        d="M32.1809 43.6949C28.1695 43.6949 24.642 40.7556 24.0282 36.6707C23.7006 34.4916 24.2416 32.3153 25.5506 30.543C26.8597 28.7707 28.7812 27.6144 30.9596 27.2868C35.4578 26.6101 39.6676 29.72 40.3436 34.2182C40.6712 36.3973 40.1302 38.5736 38.8211 40.3459C37.512 42.1182 35.5906 43.2745 33.4121 43.6022C32.9989 43.6643 32.587 43.6942 32.1809 43.6942V43.6949ZM32.1859 35.0469C32.1666 35.0469 32.1466 35.0483 32.1266 35.0512C31.9096 35.084 31.7598 35.2867 31.7926 35.5037C31.8254 35.7207 32.0288 35.8706 32.2451 35.8377C32.4621 35.8049 32.612 35.6022 32.5792 35.3852C32.5492 35.1882 32.3793 35.0462 32.1859 35.0462V35.0469Z"
        fill="#C8C9CA"
      />
      <path
        d="M12.2244 27.8792C9.84613 27.8792 7.55492 26.9391 5.73694 25.2083C3.82474 23.3881 2.75123 20.9891 2.71411 18.4538C2.67843 15.987 3.61918 13.618 5.36506 11.785C7.48711 9.55522 10.4692 8.40676 13.3408 8.71297C15.2637 8.91782 16.7504 10.4924 16.8454 12.4232L16.8882 13.2897L17.704 13.2526C19.6898 13.1633 21.4285 14.5702 21.754 16.5309C22.2429 19.4724 21.2422 22.5858 19.0781 24.8592C17.3222 26.7043 15.0174 27.7743 12.5892 27.8728C12.4671 27.8778 12.345 27.8799 12.2237 27.8799L12.2244 27.8792Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.736 22.3068C19.3277 22.3068 17.9665 21.5473 17.2628 20.2161L16.0258 17.8785L10.1878 13.8536C9.05795 13.0748 8.41698 11.7622 8.49692 10.3918C8.57687 9.02132 9.36701 7.79292 10.5804 7.15124L13.4084 5.65517C14.0101 5.33683 14.6696 5.18836 15.3227 5.2005C15.6796 4.65232 16.175 4.19194 16.776 3.8736L19.5911 2.38395C20.8074 1.74084 22.2713 1.78081 23.4505 2.48959C24.6296 3.19837 25.3512 4.47316 25.3527 5.84932L25.3612 12.9506L26.5932 15.2797C27.6068 17.1962 26.8751 19.5716 24.9587 20.5851L22.5675 21.85C21.9822 22.1597 21.3541 22.3061 20.7353 22.3061L20.736 22.3068Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.8576 20.0042C24.0668 20.0042 23.2888 19.765 22.6285 19.3097C21.7713 18.7187 21.1846 17.8107 20.9961 16.7872L19.0561 6.21693C18.6649 4.08418 20.0761 2.03851 22.2088 1.64665L27.1581 0.738018C29.1046 0.380418 31.0046 0.73088 32.5064 1.72445C33.9661 2.68947 34.9832 4.22622 35.298 5.9407C35.4 6.49602 35.4257 7.05347 35.3808 7.5988C36.0646 8.47031 36.5314 9.51884 36.7434 10.673C37.101 12.6209 36.667 14.5502 35.5214 16.1048C34.4193 17.6016 32.7277 18.6244 30.7591 18.9856L25.5671 19.9385C25.3316 19.982 25.0946 20.0027 24.8583 20.0027L24.8576 20.0042Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.402 19.9206C41.1771 19.9206 40.9487 19.9014 40.7189 19.8607L33.0837 18.5216C32.058 18.3418 31.1458 17.7622 30.5483 16.9099C29.9502 16.0577 29.7154 15.0027 29.8952 13.9771L31.7518 3.39109C32.1265 1.25549 34.1607 -0.172772 36.2963 0.202672L43.9458 1.54385C44.9715 1.72372 45.8837 2.3033 46.4812 3.15555C47.0793 4.00779 47.3141 5.06275 47.1343 6.08844L46.6996 8.56666C46.5954 9.1598 46.3634 9.6987 46.0365 10.1591L45.6253 12.5031C45.7681 13.0384 45.8002 13.6123 45.6981 14.1933L45.2635 16.6715C44.9294 18.578 43.272 19.9199 41.4012 19.9199L41.402 19.9206Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.5394 24.5538C48.8556 24.5538 48.1711 24.3753 47.5601 24.0185L44.8728 22.4496C44.4281 22.1898 44.0469 21.8507 43.7429 21.4567C43.2468 21.3854 42.7643 21.219 42.3225 20.9614L39.9741 19.5902C38.1019 18.4967 37.4702 16.0927 38.5637 14.2205L43.9827 4.93932C44.5073 4.03997 45.3681 3.38615 46.3753 3.12206C47.3824 2.85725 48.453 3.00357 49.3524 3.52891L53.2203 5.78728C55.7621 7.27122 56.725 9.22481 57.0847 10.6024C57.5701 12.4603 57.2696 14.4553 56.2382 16.2212C55.5622 17.3789 54.6179 18.3333 53.5087 18.9807C53.4944 18.9892 53.4801 18.9971 53.4652 19.0056V20.6288C53.4652 22.0349 52.7136 23.3332 51.4944 24.0334C50.8892 24.3811 50.2139 24.5545 49.5394 24.5545V24.5538Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.4371 35.3338C20.7405 35.3338 20.041 35.1489 19.4157 34.7728L13.4579 31.1925C12.3651 30.5358 11.6584 29.3888 11.5649 28.1169C11.4707 26.8449 12.0003 25.6065 12.9854 24.7964L14.5464 23.5116C14.9033 23.2175 15.3008 22.9955 15.7191 22.8456C15.9461 22.4638 16.2409 22.1162 16.5978 21.8228L17.6006 20.997C17.9575 20.7029 18.3551 20.4809 18.7733 20.331C19.0003 19.9492 19.2951 19.6023 19.652 19.3082L21.213 18.0234C22.198 17.2133 23.5149 16.9313 24.7448 17.2682C25.9746 17.6051 26.9646 18.518 27.3993 19.7172L29.7669 26.2525C30.3329 27.8149 29.8539 29.5644 28.5706 30.6208L27.2201 31.7321C26.8854 32.0076 26.5149 32.2203 26.1252 32.3688C25.9046 32.7228 25.6241 33.0455 25.2894 33.321L23.9318 34.4387C23.2123 35.0312 22.3265 35.3331 21.4364 35.3331L21.4371 35.3338Z"
        fill="#C8C9CA"
      />
      <path
        d="M27.0287 31.0083C26.3499 31.0083 25.6761 30.832 25.0766 30.4887C24.1729 29.9712 23.5127 29.1154 23.24 28.1104L21.5248 21.7878C20.9574 19.6958 22.1929 17.5388 24.2857 16.9713L29.3207 15.6051C30.3257 15.3325 31.3977 15.4702 32.3007 15.9877C33.2043 16.5052 33.8645 17.361 34.1372 18.366L34.5391 19.8464C34.6675 20.3196 34.7039 20.7957 34.6583 21.2568L34.8488 21.9599C35.1165 22.3353 35.3185 22.7579 35.4413 23.2111L35.8431 24.6915C36.1158 25.6965 35.978 26.7685 35.4605 27.6715C34.943 28.5751 34.0872 29.2353 33.083 29.508L28.058 30.8713C27.7197 30.9634 27.3742 31.0083 27.0302 31.0083H27.0287Z"
        fill="#C8C9CA"
      />
      <path
        d="M37.9688 30.139C37.8367 30.139 37.7033 30.1326 37.5691 30.119L32.3885 29.5944C30.2315 29.376 28.6598 27.4502 28.8782 25.2932L29.5377 18.7757C29.6426 17.7401 30.1544 16.7879 30.961 16.1298C31.7675 15.4717 32.8025 15.1605 33.8389 15.2654L39.0294 15.7907C41.1865 16.0092 42.7582 17.9349 42.5398 20.0919L42.3856 21.618C42.3371 22.0948 42.2029 22.5537 41.993 22.9749L41.9195 23.7022C42.0351 24.1483 42.0723 24.6158 42.0252 25.0833L41.871 26.6094C41.6661 28.6322 39.9602 30.1397 37.9702 30.1397L37.9688 30.139Z"
        fill="#C8C9CA"
      />
      <path
        d="M44.2563 33.4309C43.6018 33.4309 42.9458 33.2675 42.3519 32.9377L40.4041 31.8571C40.0465 31.6586 39.7267 31.4081 39.4526 31.1183C39.065 31.0405 38.6832 30.9028 38.3191 30.7008L36.7217 29.8142C35.8109 29.3089 35.1386 28.4631 34.8523 27.4624C34.5654 26.4617 34.6882 25.3874 35.1935 24.4774L38.372 18.7494C39.4241 16.8536 41.8138 16.1698 43.7095 17.2212L45.307 18.1077C45.6474 18.2961 45.9544 18.5331 46.2206 18.8072C46.5939 18.8878 46.9565 19.0227 47.2977 19.2119L49.2284 20.2832C50.571 21.0284 51.3562 22.4874 51.2384 24.0184C51.1199 25.5495 50.1206 26.8707 48.6795 27.401L47.9922 27.6537L48.1563 29.0469C48.3284 30.5102 47.6674 31.9463 46.444 32.7664C45.7845 33.2082 45.0222 33.4316 44.257 33.4316L44.2563 33.4309Z"
        fill="#C8C9CA"
      />
      <path
        d="M32.2522 43.6236C28.2408 43.6236 24.7133 40.6843 24.0995 36.5994C23.7718 34.4202 24.3129 32.2439 25.6219 30.4717C26.931 28.6994 28.8525 27.543 31.0309 27.2154C35.5291 26.5395 39.7389 29.6487 40.4149 34.1469C40.7425 36.326 40.2015 38.5023 38.8924 40.2746C37.5833 42.0469 35.6619 43.2032 33.4834 43.5308C33.0702 43.5929 32.6583 43.6229 32.2522 43.6229V43.6236ZM32.2572 34.9756C32.2379 34.9756 32.2179 34.977 32.1979 34.9798C31.9809 35.0127 31.831 35.2154 31.8639 35.4324C31.8967 35.6494 32.1001 35.7992 32.3164 35.7664C32.5334 35.7336 32.6833 35.5309 32.6505 35.3139C32.6205 35.1169 32.4506 34.9748 32.2572 34.9748V34.9756Z"
        fill="#C8C9CA"
      />
      <path
        d="M12.2957 27.8078C9.91742 27.8078 7.62621 26.8677 5.80823 25.1368C3.89603 23.3167 2.82252 20.9177 2.7854 18.3824C2.74971 15.9156 3.69047 13.5466 5.43635 11.7136C7.55911 9.48381 10.5398 8.33607 13.412 8.64156C15.3349 8.84641 16.8217 10.421 16.9167 12.3517L16.9595 13.2183L17.7753 13.1812C19.7618 13.0919 21.4998 14.4988 21.8253 16.4595C22.3142 19.401 21.3135 22.5144 19.1493 24.7878C17.3935 26.6329 15.0887 27.7029 12.6604 27.8013C12.5384 27.8063 12.4163 27.8085 12.295 27.8085L12.2957 27.8078Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.8075 22.2354C19.3992 22.2354 18.0381 21.476 17.3343 20.1448L16.0973 17.8072L10.2594 13.7822C9.12948 13.0035 8.48851 11.6909 8.56846 10.3204C8.6484 8.94997 9.43854 7.72157 10.652 7.07989L13.4799 5.58382C14.0816 5.26548 14.7412 5.1163 15.3943 5.12915C15.7511 4.58097 16.2465 4.12059 16.8475 3.80225L19.6626 2.3126C20.8789 1.66949 22.3428 1.70946 23.522 2.41824C24.7011 3.12702 25.4228 4.40181 25.4242 5.77797L25.4328 12.8793L26.6647 15.2083C27.6783 17.1248 26.9467 19.5002 25.0302 20.5138L22.6391 21.7786C22.0538 22.0884 21.4256 22.2347 20.8068 22.2347L20.8075 22.2354Z"
        fill="#C8C9CA"
      />
      <path
        d="M24.9287 19.9327C24.1378 19.9327 23.3598 19.6936 22.6996 19.2382C21.8423 18.6472 21.2556 17.7393 21.0672 16.7158L19.1271 6.14552C18.736 4.01277 20.1471 1.9671 22.2799 1.57524L27.2292 0.666607C29.1756 0.309007 31.0757 0.659469 32.5775 1.65304C34.0371 2.61806 35.0542 4.15481 35.369 5.86929C35.4711 6.42461 35.4968 6.98206 35.4518 7.52739C36.1356 8.3989 36.6024 9.44743 36.8144 10.6016C37.172 12.5495 36.738 14.4788 35.5924 16.0334C34.4904 17.5302 32.7987 18.553 30.8301 18.9142L25.6382 19.8671C25.4026 19.9106 25.1656 19.9313 24.9294 19.9313L24.9287 19.9327Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.4732 19.8492C41.2484 19.8492 41.02 19.83 40.7902 19.7893L33.1549 18.4502C32.1292 18.2704 31.217 17.6908 30.6196 16.8385C30.0215 15.9863 29.7866 14.9313 29.9665 13.9056L31.823 3.31968C32.1978 1.18407 34.232 -0.244183 36.3676 0.131261L44.0171 1.47244C45.0428 1.65231 45.955 2.23189 46.5524 3.08414C47.1506 3.93638 47.3854 4.99134 47.2055 6.01703L46.7709 8.49524C46.6667 9.08839 46.4347 9.62729 46.1078 10.0877L45.6966 12.4317C45.8394 12.967 45.8715 13.5409 45.7694 14.1219L45.3348 16.6001C45.0007 18.5066 43.3433 19.8485 41.4725 19.8485L41.4732 19.8492Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.6109 24.4824C48.9271 24.4824 48.2426 24.3039 47.6317 23.947L44.9443 22.3782C44.4996 22.1184 44.1185 21.7793 43.8144 21.3853C43.3183 21.3139 42.8358 21.1476 42.394 20.89L40.0457 19.5188C38.1735 18.4253 37.5418 16.0213 38.6353 14.1491L44.0542 4.86791C44.5788 3.96856 45.4397 3.31474 46.4468 3.05065C47.4539 2.78584 48.5246 2.93216 49.4239 3.4575L53.2919 5.71587C55.8336 7.19981 56.7965 9.1534 57.1562 10.531C57.6416 12.3889 57.3411 14.3839 56.3097 16.1498C55.6338 17.3075 54.6894 18.2618 53.5802 18.9092C53.566 18.9178 53.5517 18.9257 53.5367 18.9342V20.5573C53.5367 21.9635 52.7851 23.2618 51.566 23.962C50.9607 24.3096 50.2855 24.4831 49.6109 24.4831V24.4824Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.5084 35.2624C20.8118 35.2624 20.1123 35.0775 19.487 34.7014L13.5292 31.1211C12.4364 30.4644 11.7297 29.3174 11.6362 28.0454C11.542 26.7735 12.0716 25.5351 13.0566 24.725L14.6177 23.4402C14.9745 23.1461 15.3721 22.9241 15.7904 22.7742C16.0174 22.3924 16.3122 22.0448 16.669 21.7514L17.6719 20.9256C18.0288 20.6315 18.4264 20.4095 18.8446 20.2596C19.0716 19.8777 19.3664 19.5309 19.7233 19.2368L21.2843 17.952C22.2693 17.1419 23.5862 16.8599 24.816 17.1968C26.0459 17.5337 27.0359 18.4466 27.4706 19.6458L29.8381 26.1811C30.4042 27.7435 29.9252 29.493 28.6419 30.5494L27.2914 31.6607C26.9566 31.9362 26.5862 32.1489 26.1965 32.2974C25.9759 32.6514 25.6954 32.974 25.3606 33.2496L24.0031 34.3673C23.2836 34.9598 22.3978 35.2617 21.5077 35.2617L21.5084 35.2624Z"
        fill="#C8C9CA"
      />
      <path
        d="M27.1 30.937C26.4212 30.937 25.7474 30.7607 25.1479 30.4174C24.2442 29.8999 23.584 29.0441 23.3113 28.0391L21.5961 21.7165C21.0287 19.6244 22.2642 17.4674 24.357 16.9L29.392 15.5338C30.3969 15.2611 31.469 15.3989 32.372 15.9164C33.2756 16.4339 33.9358 17.2897 34.2085 18.2947L34.6103 19.775C34.7388 20.2483 34.7752 20.7243 34.7295 21.1854L34.9201 21.8885C35.1878 22.264 35.3898 22.6865 35.5125 23.1397L35.9144 24.6201C36.1871 25.6251 36.0493 26.6972 35.5318 27.6001C35.0143 28.5037 34.1585 29.164 33.1542 29.4366L28.1293 30.8C27.791 30.892 27.4455 30.937 27.1015 30.937H27.1Z"
        fill="#C8C9CA"
      />
      <path
        d="M38.0401 30.0676C37.908 30.0676 37.7746 30.0612 37.6404 30.0476L32.4598 29.523C30.3028 29.3046 28.7311 27.3788 28.9495 25.2218L29.609 18.7043C29.7139 17.6686 30.2257 16.7165 31.0323 16.0584C31.8388 15.4003 32.8738 15.0891 33.9102 15.194L39.1007 15.7193C41.2578 15.9377 42.8295 17.8635 42.6111 20.0205L42.4569 21.5466C42.4084 22.0234 42.2742 22.4823 42.0643 22.9034L41.9908 23.6308C42.1064 24.0769 42.1436 24.5444 42.0964 25.0119L41.9423 26.538C41.7374 28.5608 40.0315 30.0683 38.0415 30.0683L38.0401 30.0676Z"
        fill="#C8C9CA"
      />
      <path
        d="M44.3278 33.3595C43.6733 33.3595 43.0173 33.1961 42.4235 32.8663L40.4756 31.7857C40.118 31.5872 39.7982 31.3367 39.5241 31.0469C39.1366 30.9691 38.7547 30.8313 38.3907 30.6293L36.7932 29.7428C35.8825 29.2375 35.2101 28.3917 34.9239 27.391C34.6369 26.3902 34.7597 25.316 35.2651 24.406L38.4435 18.6779C39.4956 16.7822 41.8853 16.0984 43.7811 17.1498L45.3785 18.0363C45.719 18.2247 46.0259 18.4617 46.2921 18.7358C46.6654 18.8164 47.028 18.9513 47.3692 19.1405L49.3 20.2118C50.6426 20.957 51.4277 22.416 51.3099 23.947C51.1915 25.478 50.1922 26.7992 48.7511 27.3296L48.0637 27.5822L48.2279 28.9755C48.3999 30.4388 47.7389 31.8749 46.5155 32.695C45.856 33.1368 45.0937 33.3602 44.3285 33.3602L44.3278 33.3595Z"
        fill="#C8C9CA"
      />
      <path
        d="M32.3235 43.5522C28.3121 43.5522 24.7846 40.6129 24.1708 36.528C23.8431 34.3489 24.3842 32.1726 25.6932 30.4003C27.0023 28.628 28.9238 27.4717 31.1022 27.1441C35.6004 26.4674 39.8102 29.5773 40.4862 34.0755C40.8138 36.2546 40.2727 38.4309 38.9637 40.2032C37.6546 41.9755 35.7332 43.1318 33.5547 43.4595C33.1414 43.5216 32.7296 43.5515 32.3235 43.5515V43.5522ZM32.3285 34.9042C32.3092 34.9042 32.2892 34.9056 32.2692 34.9085C32.0522 34.9413 31.9023 35.144 31.9352 35.361C31.968 35.578 32.1714 35.7279 32.3877 35.695C32.6047 35.6622 32.7546 35.4595 32.7217 35.2425C32.6918 35.0455 32.5219 34.9035 32.3285 34.9035V34.9042Z"
        fill="#C8C9CA"
      />
      <path
        d="M12.367 27.7365C9.9887 27.7365 7.6975 26.7964 5.87952 25.0655C3.96732 23.2454 2.89381 20.8464 2.85669 18.3111C2.821 15.8443 3.76176 13.4753 5.50764 11.6423C7.62969 9.41252 10.6118 8.26406 13.4833 8.57027C15.4062 8.77512 16.893 10.3497 16.988 12.2805L17.0308 13.147L17.8466 13.1099C19.8316 13.0206 21.5711 14.4275 21.8966 16.3882C22.3855 19.3297 21.3848 22.4431 19.2206 24.7165C17.4648 26.5616 15.16 27.6316 12.7317 27.7301C12.6097 27.7351 12.4876 27.7372 12.3663 27.7372L12.367 27.7365Z"
        fill="#C8C9CA"
      />
      <path
        d="M20.8788 22.1641C19.4705 22.1641 18.1094 21.4046 17.4056 20.0734L16.1686 17.7358L10.3307 13.7109C9.20077 12.9321 8.5598 11.6195 8.63975 10.2491C8.71969 8.87862 9.50983 7.65022 10.7232 7.00854L13.5512 5.51247C14.1529 5.19413 14.8124 5.04566 15.4655 5.0578C15.8224 4.50962 16.3178 4.04924 16.9188 3.7309L19.7339 2.24125C20.9502 1.59814 22.4141 1.63811 23.5933 2.34689C24.7724 3.05567 25.4941 4.33046 25.4955 5.70662L25.504 12.8079L26.736 15.137C27.7496 17.0535 27.018 19.4289 25.1015 20.4424L22.7103 21.7073C22.125 22.017 21.4969 22.1634 20.8781 22.1634L20.8788 22.1641Z"
        fill="#C8C9CA"
      />
      <path
        d="M25.0002 19.8614C24.2093 19.8614 23.4313 19.6223 22.7711 19.1669C21.9139 18.5759 21.3271 17.668 21.1387 16.6444L19.1987 6.07417C18.8075 3.94142 20.2186 1.89575 22.3514 1.50389L27.3007 0.595257C29.2472 0.237657 31.1472 0.588119 32.649 1.58169C34.1087 2.54671 35.1258 4.08346 35.4406 5.79794C35.5426 6.35326 35.5683 6.91071 35.5234 7.45604C36.2071 8.32755 36.674 9.37608 36.8859 10.5303C37.2435 12.4781 36.8096 14.4075 35.664 15.9621C34.5619 17.4588 32.8703 18.4817 30.9017 18.8428L25.7097 19.7957C25.4742 19.8393 25.2372 19.86 25.0009 19.86L25.0002 19.8614Z"
        fill="#C8C9CA"
      />
      <path
        d="M41.5445 19.7779C41.3197 19.7779 41.0913 19.7586 40.8615 19.7179L33.2262 18.3789C32.2005 18.199 31.2883 17.6194 30.6909 16.7672C30.0928 15.9149 29.8579 14.86 30.0378 13.8343L31.8943 3.24833C32.2691 1.11272 34.3033 -0.315534 36.4389 0.0599107L44.0884 1.40109C45.1141 1.58096 46.0263 2.16054 46.6237 3.01279C47.2219 3.86503 47.4567 4.91999 47.2768 5.94568L46.8422 8.42389C46.7379 9.01704 46.506 9.55594 46.1791 10.0163L45.7679 12.3603C45.9107 12.8957 45.9428 13.4696 45.8407 14.0506L45.406 16.5288C45.072 18.4353 43.4146 19.7772 41.5438 19.7772L41.5445 19.7779Z"
        fill="#C8C9CA"
      />
      <path
        d="M49.6822 24.411C48.9984 24.411 48.3139 24.2325 47.7029 23.8756L45.0156 22.3068C44.5709 22.0469 44.1898 21.7079 43.8857 21.3139C43.3896 21.2425 42.9071 21.0762 42.4653 20.8185L40.117 19.4474C38.2447 18.3539 37.6131 15.9499 38.7066 14.0777L44.1255 4.7965C44.6501 3.89715 45.5109 3.24333 46.5181 2.97924C47.5252 2.71443 48.5959 2.86075 49.4952 3.38609L53.3632 5.64446C55.9049 7.12839 56.8678 9.08199 57.2275 10.4596C57.7129 12.3175 57.4124 14.3125 56.381 16.0784C55.705 17.2361 54.7607 18.1904 53.6515 18.8378C53.6372 18.8464 53.623 18.8542 53.608 18.8628V20.4859C53.608 21.8921 52.8564 23.1904 51.6373 23.8906C51.032 24.2382 50.3567 24.4117 49.6822 24.4117V24.411Z"
        fill="#C8C9CA"
      />
      <path
        d="M21.5797 35.1911C20.8831 35.1911 20.1836 35.0062 19.5583 34.6301L13.6004 31.0498C12.5077 30.3931 11.801 29.2461 11.7075 27.9742C11.6133 26.7022 12.1429 25.4638 13.1279 24.6537L14.6889 23.3689C15.0458 23.0748 15.4434 22.8528 15.8617 22.7029C16.0887 22.3211 16.3834 21.9735 16.7403 21.6801L17.7432 20.8543C18.1001 20.5602 18.4976 20.3382 18.9159 20.1883C19.1429 19.8065 19.4377 19.4596 19.7946 19.1655L21.3556 17.8807C22.3406 17.0706 23.6575 16.7886 24.8873 17.1255C26.1172 17.4624 27.1072 18.3753 27.5418 19.5745L29.9094 26.1098C30.4755 27.6722 29.9965 29.4217 28.7131 30.4781L27.3627 31.5894C27.0279 31.8649 26.6575 32.0776 26.2678 32.2261C26.0472 32.5801 25.7667 32.9028 25.4319 33.1783L24.0743 34.296C23.3549 34.8885 22.4691 35.1904 21.579 35.1904L21.5797 35.1911Z"
        fill="#C8C9CA"
      />
      <path
        d="M27.1716 30.8656C26.4928 30.8656 25.819 30.6893 25.2194 30.346C24.3158 29.8285 23.6555 28.9727 23.3829 27.9677L21.6677 21.6451C21.1002 19.553 22.3358 17.396 24.4285 16.8285L29.4635 15.4624C30.4685 15.1897 31.5406 15.3275 32.4435 15.845C33.3471 16.3624 34.0074 17.2183 34.28 18.2233L34.6819 19.7036C34.8104 20.1768 34.8468 20.6529 34.8011 21.114L34.9917 21.8171C35.2593 22.1925 35.4613 22.6151 35.5841 23.0683L35.9859 24.5487C36.2586 25.5537 36.1208 26.6258 35.6034 27.5287C35.0859 28.4323 34.2301 29.0926 33.2258 29.3652L28.2008 30.7285C27.8625 30.8206 27.517 30.8656 27.173 30.8656H27.1716Z"
        fill="#C8C9CA"
      />
      <path
        d="M38.1114 29.9962C37.9793 29.9962 37.8458 29.9898 37.7117 29.9762L32.5311 29.4516C30.3741 29.2332 28.8023 27.3075 29.0208 25.1504L29.6803 18.633C29.7852 17.5973 30.297 16.6451 31.1035 15.987C31.9101 15.3289 32.9451 15.0177 33.9815 15.1226L39.172 15.648C41.329 15.8664 42.9008 17.7922 42.6824 19.9492L42.5282 21.4752C42.4796 21.952 42.3455 22.411 42.1356 22.8321L42.0621 23.5594C42.1777 24.0055 42.2148 24.4731 42.1677 24.9406L42.0136 26.4666C41.8087 28.4895 40.1028 29.9969 38.1128 29.9969L38.1114 29.9962Z"
        fill="#C8C9CA"
      />
      <path
        d="M44.3989 33.2881C43.7443 33.2881 43.0884 33.1247 42.4945 32.7949L40.5466 31.7142C40.189 31.5158 39.8693 31.2653 39.5952 30.9755C39.2076 30.8977 38.8257 30.7599 38.4617 30.5579L36.8643 29.6714C35.9535 29.1661 35.2811 28.3203 34.9949 27.3195C34.708 26.3188 34.8308 25.2446 35.3361 24.3346L38.5145 18.6065C39.5666 16.7107 41.9556 16.027 43.8521 17.0783L45.4495 17.9648C45.79 18.1533 46.0969 18.3903 46.3632 18.6643C46.7365 18.745 47.0991 18.8799 47.4403 19.0691L49.371 20.1404C50.7136 20.8856 51.4988 22.3446 51.381 23.8756C51.2625 25.4066 50.2632 26.7278 48.8221 27.2582L48.1348 27.5108L48.2989 28.9041C48.4709 30.3674 47.81 31.8035 46.5866 32.6236C45.9271 33.0654 45.1647 33.2888 44.3996 33.2888L44.3989 33.2881Z"
        fill="#C8C9CA"
      />
      <path
        d="M32.3947 43.4808C28.3833 43.4808 24.8559 40.5415 24.242 36.4566C23.9144 34.2775 24.4555 32.1012 25.7645 30.3289C27.0736 28.5566 28.995 27.4003 31.1735 27.0726C35.6717 26.396 39.8815 29.5059 40.5575 34.0041C40.8851 36.1832 40.344 38.3595 39.035 40.1318C37.7259 41.9041 35.8044 43.0604 33.626 43.388C33.2127 43.4501 32.8009 43.4801 32.3947 43.4801V43.4808ZM32.3997 34.8328C32.3805 34.8328 32.3605 34.8342 32.3405 34.8371C32.1235 34.8699 31.9736 35.0726 32.0065 35.2896C32.0393 35.5066 32.242 35.6572 32.459 35.6236C32.676 35.5908 32.8259 35.3881 32.793 35.1711C32.7631 34.9741 32.5932 34.8321 32.3997 34.8321V34.8328Z"
        fill="#C8C9CA"
      />
      <path
        d="M12.367 27.7365C9.9887 27.7365 7.6975 26.7964 5.87952 25.0655C3.96732 23.2454 2.89381 20.8464 2.85669 18.3111C2.821 15.8443 3.76176 13.4753 5.50764 11.6423C7.62969 9.41252 10.6118 8.26406 13.4833 8.57027C15.4062 8.77512 16.893 10.3497 16.988 12.2805L17.0308 13.147L17.8466 13.1099C19.8316 13.0206 21.5711 14.4275 21.8966 16.3882C22.3855 19.3297 21.3848 22.4431 19.2206 24.7165C17.4648 26.5616 15.16 27.6316 12.7317 27.7301C12.6097 27.7351 12.4876 27.7372 12.3663 27.7372L12.367 27.7365Z"
        fill="white"
      />
      <path
        d="M20.8788 22.1641C19.4705 22.1641 18.1094 21.4046 17.4056 20.0734L16.1686 17.7358L10.3307 13.7109C9.20077 12.9321 8.5598 11.6195 8.63975 10.2491C8.71969 8.87862 9.50983 7.65022 10.7232 7.00854L13.5512 5.51247C14.1529 5.19413 14.8124 5.04566 15.4655 5.0578C15.8224 4.50962 16.3178 4.04924 16.9188 3.7309L19.7339 2.24125C20.9502 1.59814 22.4141 1.63811 23.5933 2.34689C24.7724 3.05567 25.4941 4.33046 25.4955 5.70662L25.504 12.8079L26.736 15.137C27.7496 17.0535 27.018 19.4289 25.1015 20.4424L22.7103 21.7073C22.125 22.017 21.4969 22.1634 20.8781 22.1634L20.8788 22.1641Z"
        fill="white"
      />
      <path
        d="M25.0002 19.8614C24.2093 19.8614 23.4313 19.6223 22.7711 19.1669C21.9139 18.5759 21.3271 17.668 21.1387 16.6444L19.1987 6.07417C18.8075 3.94142 20.2186 1.89575 22.3514 1.50389L27.3007 0.595257C29.2472 0.237657 31.1472 0.588119 32.649 1.58169C34.1087 2.54671 35.1258 4.08346 35.4406 5.79794C35.5426 6.35326 35.5683 6.91071 35.5234 7.45604C36.2071 8.32755 36.674 9.37608 36.8859 10.5303C37.2435 12.4781 36.8096 14.4075 35.664 15.9621C34.5619 17.4588 32.8703 18.4817 30.9017 18.8428L25.7097 19.7957C25.4742 19.8393 25.2372 19.86 25.0009 19.86L25.0002 19.8614Z"
        fill="white"
      />
      <path
        d="M41.5445 19.7779C41.3197 19.7779 41.0913 19.7586 40.8615 19.7179L33.2262 18.3789C32.2005 18.199 31.2883 17.6194 30.6909 16.7672C30.0928 15.9149 29.8579 14.86 30.0378 13.8343L31.8943 3.24833C32.2691 1.11272 34.3033 -0.315534 36.4389 0.0599107L44.0884 1.40109C45.1141 1.58096 46.0263 2.16054 46.6237 3.01279C47.2219 3.86503 47.4567 4.91999 47.2768 5.94568L46.8422 8.42389C46.7379 9.01704 46.506 9.55594 46.1791 10.0163L45.7679 12.3603C45.9107 12.8957 45.9428 13.4696 45.8407 14.0506L45.406 16.5288C45.072 18.4353 43.4146 19.7772 41.5438 19.7772L41.5445 19.7779Z"
        fill="white"
      />
      <path
        d="M49.6822 24.411C48.9984 24.411 48.3139 24.2325 47.7029 23.8756L45.0156 22.3068C44.5709 22.0469 44.1898 21.7079 43.8857 21.3139C43.3896 21.2425 42.9071 21.0762 42.4653 20.8185L40.117 19.4474C38.2447 18.3539 37.6131 15.9499 38.7066 14.0777L44.1255 4.7965C44.6501 3.89715 45.5109 3.24333 46.5181 2.97924C47.5252 2.71443 48.5959 2.86075 49.4952 3.38609L53.3632 5.64446C55.9049 7.12839 56.8678 9.08199 57.2275 10.4596C57.7129 12.3175 57.4124 14.3125 56.381 16.0784C55.705 17.2361 54.7607 18.1904 53.6515 18.8378C53.6372 18.8464 53.623 18.8542 53.608 18.8628V20.4859C53.608 21.8921 52.8564 23.1904 51.6373 23.8906C51.032 24.2382 50.3567 24.4117 49.6822 24.4117V24.411Z"
        fill="white"
      />
      <path
        d="M21.5797 35.1911C20.8831 35.1911 20.1836 35.0062 19.5583 34.6301L13.6004 31.0498C12.5077 30.3931 11.801 29.2461 11.7075 27.9742C11.6133 26.7022 12.1429 25.4638 13.1279 24.6537L14.6889 23.3689C15.0458 23.0748 15.4434 22.8528 15.8617 22.7029C16.0887 22.3211 16.3834 21.9735 16.7403 21.6801L17.7432 20.8543C18.1001 20.5602 18.4976 20.3382 18.9159 20.1883C19.1429 19.8065 19.4377 19.4596 19.7946 19.1655L21.3556 17.8807C22.3406 17.0706 23.6575 16.7886 24.8873 17.1255C26.1172 17.4624 27.1072 18.3753 27.5418 19.5745L29.9094 26.1098C30.4755 27.6722 29.9965 29.4217 28.7131 30.4781L27.3627 31.5894C27.0279 31.8649 26.6575 32.0776 26.2678 32.2261C26.0472 32.5801 25.7667 32.9028 25.4319 33.1783L24.0743 34.296C23.3549 34.8885 22.4691 35.1904 21.579 35.1904L21.5797 35.1911Z"
        fill="white"
      />
      <path
        d="M27.1716 30.8656C26.4928 30.8656 25.819 30.6893 25.2194 30.346C24.3158 29.8285 23.6555 28.9727 23.3829 27.9677L21.6677 21.6451C21.1002 19.553 22.3358 17.396 24.4285 16.8285L29.4635 15.4624C30.4685 15.1897 31.5406 15.3275 32.4435 15.845C33.3471 16.3624 34.0074 17.2183 34.28 18.2233L34.6819 19.7036C34.8104 20.1768 34.8468 20.6529 34.8011 21.114L34.9917 21.8171C35.2593 22.1925 35.4613 22.6151 35.5841 23.0683L35.9859 24.5487C36.2586 25.5537 36.1208 26.6258 35.6034 27.5287C35.0859 28.4323 34.2301 29.0926 33.2258 29.3652L28.2008 30.7285C27.8625 30.8206 27.517 30.8656 27.173 30.8656H27.1716Z"
        fill="white"
      />
      <path
        d="M38.1114 29.9962C37.9793 29.9962 37.8458 29.9898 37.7117 29.9762L32.5311 29.4516C30.3741 29.2332 28.8023 27.3075 29.0208 25.1504L29.6803 18.633C29.7852 17.5973 30.297 16.6451 31.1035 15.987C31.9101 15.3289 32.9451 15.0177 33.9815 15.1226L39.172 15.648C41.329 15.8664 42.9008 17.7922 42.6824 19.9492L42.5282 21.4752C42.4796 21.952 42.3455 22.411 42.1356 22.8321L42.0621 23.5594C42.1777 24.0055 42.2148 24.4731 42.1677 24.9406L42.0136 26.4666C41.8087 28.4895 40.1028 29.9969 38.1128 29.9969L38.1114 29.9962Z"
        fill="white"
      />
      <path
        d="M44.3989 33.2881C43.7443 33.2881 43.0884 33.1247 42.4945 32.7949L40.5466 31.7142C40.189 31.5158 39.8693 31.2653 39.5952 30.9755C39.2076 30.8977 38.8257 30.7599 38.4617 30.5579L36.8643 29.6714C35.9535 29.1661 35.2811 28.3203 34.9949 27.3195C34.708 26.3188 34.8308 25.2446 35.3361 24.3346L38.5145 18.6065C39.5666 16.7107 41.9556 16.027 43.8521 17.0783L45.4495 17.9648C45.79 18.1533 46.0969 18.3903 46.3632 18.6643C46.7365 18.745 47.0991 18.8799 47.4403 19.0691L49.371 20.1404C50.7136 20.8856 51.4988 22.3446 51.381 23.8756C51.2625 25.4066 50.2632 26.7278 48.8221 27.2582L48.1348 27.5108L48.2989 28.9041C48.4709 30.3674 47.81 31.8035 46.5866 32.6236C45.9271 33.0654 45.1647 33.2888 44.3996 33.2888L44.3989 33.2881Z"
        fill="white"
      />
      <path
        d="M32.3947 43.4808C28.3833 43.4808 24.8559 40.5415 24.242 36.4566C23.9144 34.2775 24.4555 32.1012 25.7645 30.3289C27.0736 28.5566 28.995 27.4003 31.1735 27.0726C35.6717 26.396 39.8815 29.5059 40.5575 34.0041C40.8851 36.1832 40.344 38.3595 39.035 40.1318C37.7259 41.9041 35.8044 43.0604 33.626 43.388C33.2127 43.4501 32.8009 43.4801 32.3947 43.4801V43.4808ZM32.3997 34.8328C32.3805 34.8328 32.3605 34.8342 32.3405 34.8371C32.1235 34.8699 31.9736 35.0726 32.0065 35.2896C32.0393 35.5066 32.242 35.6572 32.459 35.6236C32.676 35.5908 32.8259 35.3881 32.793 35.1711C32.7631 34.9741 32.5932 34.8321 32.3997 34.8321V34.8328Z"
        fill="white"
      />
      <path
        d="M8.58553 22.2218C6.18369 19.9356 6.25507 16.5502 8.3507 14.3482C9.71401 12.9157 11.4992 12.3061 13.0673 12.4731L13.1908 14.9799C12.1615 14.9642 11.1087 15.2268 10.3571 16.0163C9.23435 17.1961 9.25791 18.9856 10.5113 20.1783C11.7333 21.341 13.5006 21.2775 14.624 20.0976C15.3657 19.3182 15.6476 18.2211 15.5284 17.144L18.0252 17.0313C18.2786 18.5573 17.8125 20.5031 16.3785 22.0091C14.2429 24.2525 10.9674 24.488 8.58696 22.2218H8.58553Z"
        fill="#DE3700"
      />
      <path
        d="M21.5797 13.7844L23.2664 16.9728L20.8752 18.2376L19.1886 15.0492L12.5583 10.4782L15.3863 8.98209L18.9695 11.743L18.7546 7.20052L21.5697 5.71088L21.5797 13.7844Z"
        fill="#DE3700"
      />
      <path
        d="M33.0239 11.239C33.3493 13.0128 32.2515 14.6038 30.1923 14.9821L25.0003 15.935L23.0603 5.3647L28.0096 4.45607C30.0838 4.07563 31.3436 5.21909 31.5799 6.50674C31.7505 7.43678 31.3294 8.44534 30.5292 8.91715C31.9482 8.99638 32.7969 9.99423 33.0253 11.2383L33.0239 11.239ZM26.0924 7.14485L26.4207 8.93285L28.1374 8.61808C28.9382 8.47104 29.1381 7.94642 29.0588 7.51744C28.9746 7.05991 28.5528 6.69375 27.9232 6.80938L26.0924 7.14556V7.14485ZM30.2387 11.5288C30.1416 10.9999 29.687 10.536 28.8718 10.6859L26.8119 11.0642L27.198 13.1669L29.2002 12.7994C30.101 12.6338 30.3279 12.0156 30.2387 11.5296V11.5288Z"
        fill="#DE3700"
      />
      <path
        d="M35.7615 3.92643L43.411 5.26761L42.9764 7.74583L38.0199 6.87646L37.7458 8.43748L42.3297 9.24118L41.8978 11.7051L37.314 10.9014L37.0328 12.506L41.9749 13.3725L41.5402 15.8507L33.905 14.5117L35.7615 3.92572V3.92643Z"
        fill="#DE3700"
      />
      <path
        d="M47.1583 14.8857L46.2411 14.3504L44.4445 17.4275L42.0962 16.0563L47.5152 6.77515L51.3831 9.03352C53.7193 10.3975 53.9655 12.4275 52.9898 14.0977C52.3517 15.1905 51.2168 16.0099 49.6814 15.8707V20.4853L46.9941 18.9164L47.1583 14.8857ZM47.4366 12.3033L48.8435 13.1249C49.6351 13.5867 50.2418 13.2676 50.543 12.753C50.8585 12.2127 50.7707 11.5553 50.1047 11.167L48.6101 10.294L47.4366 12.3033Z"
        fill="#DE3700"
      />
      <path
        d="M15.6226 27.685L17.1836 26.4002L20.7367 28.78L19.2357 24.7115L20.2385 23.8856L23.9501 26.1347L22.2906 22.1968L23.8516 20.912L26.2192 27.4473L24.8688 28.5587L21.4812 26.4102L22.938 30.1475L21.5804 31.2653L15.6226 27.6857V27.685Z"
        fill="#DE3700"
      />
      <path
        d="M25.4563 20.6179L30.4912 19.2518L30.8931 20.7321L27.6312 21.6172L27.8838 22.5494L30.901 21.7307L31.3 23.2025L28.2828 24.0212L28.5426 24.9791L31.7953 24.0961L32.1972 25.5765L27.1722 26.9398L25.457 20.6172L25.4563 20.6179Z"
        fill="#DE3700"
      />
      <path
        d="M33.5856 19.0284L38.7761 19.5538L38.6219 21.0798L35.2587 20.7394L35.1616 21.7008L38.2722 22.0156L38.1187 23.5323L35.0081 23.2176L34.9082 24.2054L38.2615 24.5452L38.1073 26.0712L32.9268 25.5466L33.5863 19.0292L33.5856 19.0284Z"
        fill="#DE3700"
      />
      <path
        d="M42.1942 25.4424L41.0936 25.8149L40.367 27.1247L38.7695 26.2382L41.948 20.5102L43.5454 21.3967L42.2378 23.7529L45.5354 22.5009L47.4661 23.5723L43.873 24.8935L44.3991 29.3617L42.4512 28.281L42.1942 25.4416V25.4424Z"
        fill="#DE3700"
      />
      <path
        d="M32.3997 39.5551C34.7878 39.5551 36.7238 37.6192 36.7238 35.2311C36.7238 32.843 34.7878 30.907 32.3997 30.907C30.0116 30.907 28.0757 32.843 28.0757 35.2311C28.0757 37.6192 30.0116 39.5551 32.3997 39.5551Z"
        fill="#0096FA"
      />
      <path
        d="M46.8939 46.0804L20.717 48.1544C17.8212 48.3838 15.6596 50.9172 15.889 53.8128C16.1184 56.7085 18.652 58.8698 21.5478 58.6404L47.7247 56.5664C50.6205 56.337 52.7821 53.8036 52.5527 50.908C52.3233 48.0124 49.7898 45.851 46.8939 46.0804Z"
        fill="#B52A00"
      />
      <path
        d="M46.965 46.0093L20.788 48.0832C17.8922 48.3127 15.7307 50.846 15.9601 53.7417C16.1895 56.6373 18.723 58.7987 21.6188 58.5692L47.7958 56.4953C50.6916 56.2658 52.8531 53.7325 52.6237 50.8368C52.3943 47.9412 49.8608 45.7798 46.965 46.0093Z"
        fill="#B52A00"
      />
      <path
        d="M47.0365 45.9375L20.8596 48.0115C17.9637 48.2409 15.8022 50.7743 16.0316 53.6699C16.261 56.5655 18.7945 58.7269 21.6904 58.4975L47.8673 56.4235C50.7631 56.1941 52.9247 53.6607 52.6953 50.7651C52.4658 47.8694 49.9323 45.7081 47.0365 45.9375Z"
        fill="#B52A00"
      />
      <path
        d="M47.1083 45.8664L20.9313 47.9404C18.0355 48.1698 15.874 50.7031 16.1034 53.5988C16.3328 56.4944 18.8663 58.6558 21.7621 58.4264L47.9391 56.3524C50.8349 56.123 52.9965 53.5896 52.767 50.694C52.5376 47.7983 50.0041 45.637 47.1083 45.8664Z"
        fill="#B52A00"
      />
      <path
        d="M47.1796 45.7954L21.0026 47.8694C18.1068 48.0988 15.9453 50.6322 16.1747 53.5278C16.4041 56.4234 18.9376 58.5848 21.8334 58.3554L48.0104 56.2814C50.9062 56.052 53.0677 53.5186 52.8383 50.623C52.6089 47.7274 50.0754 45.566 47.1796 45.7954Z"
        fill="#B52A00"
      />
      <path
        d="M47.2509 45.7235L21.0739 47.7975C18.1781 48.0269 16.0165 50.5603 16.246 53.4559C16.4754 56.3515 19.0089 58.5129 21.9047 58.2835L48.0817 56.2095C50.9775 55.9801 53.139 53.4467 52.9096 50.5511C52.6802 47.6555 50.1467 45.4941 47.2509 45.7235Z"
        fill="#B52A00"
      />
      <path
        d="M47.3219 45.6525L21.145 47.7264C18.2491 47.9559 16.0876 50.4892 16.317 53.3848C16.5464 56.2805 19.0799 58.4419 21.9758 58.2124L48.1527 56.1385C51.0485 55.909 53.2101 53.3757 52.9807 50.48C52.7512 47.5844 50.2177 45.423 47.3219 45.6525Z"
        fill="#B52A00"
      />
      <path
        d="M47.3932 45.5806L21.2163 47.6546C18.3204 47.884 16.1589 50.4174 16.3883 53.313C16.6177 56.2086 19.1512 58.37 22.047 58.1406L48.224 56.0666C51.1198 55.8372 53.2814 53.3038 53.0519 50.4082C52.8225 47.5126 50.289 45.3512 47.3932 45.5806Z"
        fill="#B52A00"
      />
      <path
        d="M47.4652 45.5096L21.2883 47.5836C18.3925 47.813 16.2309 50.3464 16.4603 53.242C16.6897 56.1377 19.2232 58.299 22.1191 58.0696L48.296 55.9956C51.1918 55.7662 53.3534 53.2328 53.124 50.3372C52.8946 47.4416 50.361 45.2802 47.4652 45.5096Z"
        fill="#B52A00"
      />
      <path
        d="M47.5365 45.4385L21.3596 47.5125C18.4637 47.7419 16.3022 50.2753 16.5316 53.1709C16.761 56.0665 19.2945 58.2279 22.1904 57.9985L48.3673 55.9245C51.2631 55.6951 53.4247 53.1617 53.1953 50.2661C52.9658 47.3705 50.4323 45.2091 47.5365 45.4385Z"
        fill="#B52A00"
      />
      <path
        d="M47.6076 45.3668L21.4306 47.4407C18.5348 47.6701 16.3732 50.2035 16.6027 53.0991C16.8321 55.9948 19.3656 58.1562 22.2614 57.9267L48.4383 55.8528C51.3342 55.6233 53.4957 53.09 53.2663 50.1943C53.0369 47.2987 50.5034 45.1373 47.6076 45.3668Z"
        fill="#B52A00"
      />
      <path
        d="M47.6788 45.2956L21.5019 47.3696C18.6061 47.599 16.4445 50.1324 16.6739 53.028C16.9034 55.9237 19.4369 58.085 22.3327 57.8556L48.5096 55.7817C51.4055 55.5522 53.567 53.0189 53.3376 50.1232C53.1082 47.2276 50.5747 45.0662 47.6788 45.2956Z"
        fill="#B52A00"
      />
      <path
        d="M47.7506 45.2239L21.5737 47.2978C18.6779 47.5273 16.5163 50.0606 16.7457 52.9563C16.9751 55.8519 19.5086 58.0133 22.4045 57.7838L48.5814 55.7099C51.4772 55.4804 53.6388 52.9471 53.4094 50.0514C53.18 47.1558 50.6464 44.9944 47.7506 45.2239Z"
        fill="#B52A00"
      />
      <path
        d="M47.8221 45.1528L21.6452 47.2267C18.7494 47.4562 16.5878 49.9895 16.8173 52.8852C17.0467 55.7808 19.5802 57.9422 22.476 57.7127L48.6529 55.6388C51.5488 55.4093 53.7103 52.876 53.4809 49.9803C53.2515 47.0847 50.718 44.9233 47.8221 45.1528Z"
        fill="#B52A00"
      />
      <path
        d="M47.8932 45.0817L21.7163 47.1556C18.8204 47.3851 16.6589 49.9184 16.8883 52.814C17.1177 55.7097 19.6512 57.8711 22.547 57.6416L48.724 55.5677C51.6198 55.3382 53.7814 52.8049 53.5519 49.9092C53.3225 47.0136 50.789 44.8522 47.8932 45.0817Z"
        fill="#B52A00"
      />
      <path
        d="M47.9645 45.0099L21.7875 47.0838C18.8917 47.3133 16.7302 49.8466 16.9596 52.7423C17.189 55.6379 19.7225 57.7993 22.6183 57.5698L48.7953 55.4959C51.6911 55.2665 53.8527 52.7331 53.6232 49.8375C53.3938 46.9418 50.8603 44.7805 47.9645 45.0099Z"
        fill="#B52A00"
      />
      <path
        d="M48.0355 44.9388L21.8586 47.0127C18.9628 47.2422 16.8012 49.7755 17.0306 52.6712C17.26 55.5668 19.7936 57.7282 22.6894 57.4987L48.8663 55.4248C51.7621 55.1953 53.9237 52.662 53.6943 49.7664C53.4649 46.8707 50.9314 44.7093 48.0355 44.9388Z"
        fill="#B52A00"
      />
      <path
        d="M48.1076 44.867L21.9306 46.941C19.0348 47.1704 16.8732 49.7038 17.1027 52.5994C17.3321 55.495 19.8656 57.6564 22.7614 57.427L48.9383 55.353C51.8342 55.1236 53.9957 52.5902 53.7663 49.6946C53.5369 46.7989 51.0034 44.6376 48.1076 44.867Z"
        fill="#B52A00"
      />
      <path
        d="M48.1791 44.796L22.0021 46.8699C19.1063 47.0993 16.9448 49.6327 17.1742 52.5283C17.4036 55.424 19.9371 57.5853 22.8329 57.3559L49.0099 55.282C51.9057 55.0525 54.0673 52.5192 53.8378 49.6235C53.6084 46.7279 51.0749 44.5665 48.1791 44.796Z"
        fill="#B52A00"
      />
      <path
        d="M48.2501 44.7248L22.0732 46.7987C19.1774 47.0282 17.0158 49.5615 17.2452 52.4572C17.4746 55.3528 20.0082 57.5142 22.904 57.2848L49.0809 55.2108C51.9767 54.9814 54.1383 52.448 53.9089 49.5524C53.6795 46.6567 51.146 44.4954 48.2501 44.7248Z"
        fill="#B52A00"
      />
      <path
        d="M48.3214 44.653L22.1445 46.727C19.2487 46.9564 17.0871 49.4898 17.3165 52.3854C17.5459 55.281 20.0794 57.4424 22.9753 57.213L49.1522 55.139C52.048 54.9096 54.2096 52.3762 53.9802 49.4806C53.7508 46.585 51.2172 44.4236 48.3214 44.653Z"
        fill="#B52A00"
      />
      <path
        d="M48.3934 44.582L22.2165 46.656C19.3207 46.8854 17.1591 49.4188 17.3885 52.3144C17.618 55.21 20.1515 57.3714 23.0473 57.142L49.2242 55.068C52.1201 54.8386 54.2816 52.3052 54.0522 49.4096C53.8228 46.514 51.2893 44.3526 48.3934 44.582Z"
        fill="#B52A00"
      />
      <path
        d="M48.4645 44.5102L22.2875 46.5841C19.3917 46.8136 17.2302 49.3469 17.4596 52.2426C17.689 55.1382 20.2225 57.2996 23.1183 57.0702L49.2953 54.9962C52.1911 54.7668 54.3527 52.2334 54.1232 49.3378C53.8938 46.4421 51.3603 44.2808 48.4645 44.5102Z"
        fill="#B52A00"
      />
      <path
        d="M48.5358 44.4391L22.3588 46.5131C19.463 46.7425 17.3015 49.2759 17.5309 52.1715C17.7603 55.0672 20.2938 57.2285 23.1896 56.9991L49.3666 54.9251C52.2624 54.6957 54.4239 52.1623 54.1945 49.2667C53.9651 46.3711 51.4316 44.2097 48.5358 44.4391Z"
        fill="#B52A00"
      />
      <path
        d="M48.6071 44.3673L22.4301 46.4413C19.5343 46.6707 17.3727 49.2041 17.6022 52.0997C17.8316 54.9953 20.3651 57.1567 23.2609 56.9273L49.4379 54.8533C52.3337 54.6239 54.4952 52.0905 54.2658 49.1949C54.0364 46.2993 51.5029 44.1379 48.6071 44.3673Z"
        fill="#B52A00"
      />
      <path
        d="M48.6781 44.2963L22.5012 46.3702C19.6053 46.5997 17.4438 49.133 17.6732 52.0286C17.9026 54.9243 20.4361 57.0857 23.332 56.8562L49.5089 54.7823C52.4047 54.5528 54.5663 52.0195 54.3369 49.1238C54.1074 46.2282 51.5739 44.0668 48.6781 44.2963Z"
        fill="#B52A00"
      />
      <path
        d="M48.7501 44.2252L22.5732 46.2991C19.6774 46.5285 17.5158 49.0619 17.7452 51.9575C17.9746 54.8532 20.5082 57.0145 23.404 56.7851L49.5809 54.7112C52.4767 54.4817 54.6383 51.9484 54.4089 49.0527C54.1795 46.1571 51.646 43.9957 48.7501 44.2252Z"
        fill="#B52A00"
      />
      <path
        d="M48.8212 44.1534L22.6442 46.2273C19.7484 46.4568 17.5869 48.9901 17.8163 51.8858C18.0457 54.7814 20.5792 56.9428 23.475 56.7133L49.652 54.6394C52.5478 54.4099 54.7093 51.8766 54.4799 48.981C54.2505 46.0853 51.717 43.9239 48.8212 44.1534Z"
        fill="#B52A00"
      />
      <path
        d="M48.8925 44.0823L22.7155 46.1562C19.8197 46.3857 17.6581 48.919 17.8876 51.8147C18.117 54.7103 20.6505 56.8717 23.5463 56.6422L49.7233 54.5683C52.6191 54.3388 54.7806 51.8055 54.5512 48.9098C54.3218 46.0142 51.7883 43.8528 48.8925 44.0823Z"
        fill="#B52A00"
      />
      <path
        d="M48.9635 44.0105L22.7866 46.0845C19.8907 46.3139 17.7292 48.8472 17.9586 51.7429C18.188 54.6385 20.7215 56.7999 23.6174 56.5705L49.7943 54.4965C52.6901 54.2671 54.8517 51.7337 54.6223 48.8381C54.3928 45.9424 51.8593 43.7811 48.9635 44.0105Z"
        fill="#B52A00"
      />
      <path
        d="M49.035 43.9394L22.8581 46.0133C19.9623 46.2428 17.8007 48.7761 18.0301 51.6718C18.2596 54.5674 20.7931 56.7288 23.6889 56.4994L49.8658 54.4254C52.7617 54.196 54.9232 51.6626 54.6938 48.767C54.4644 45.8713 51.9309 43.71 49.035 43.9394Z"
        fill="#B52A00"
      />
      <path
        d="M49.035 43.9394L22.8581 46.0133C19.9623 46.2428 17.8007 48.7761 18.0301 51.6718C18.2596 54.5674 20.7931 56.7288 23.6889 56.4994L49.8658 54.4254C52.7617 54.196 54.9232 51.6626 54.6938 48.767C54.4644 45.8713 51.9309 43.71 49.035 43.9394Z"
        fill="#DE3700"
      />
      <path
        d="M22.7996 52.7855L23.2678 52.1167C23.599 52.5014 24.0551 52.6863 24.5126 52.6499C24.8902 52.6199 25.1429 52.3972 25.1207 52.1174C25.1029 51.8919 24.9152 51.772 24.6754 51.6806C24.3784 51.5692 23.998 51.4893 23.6668 51.3373C23.2528 51.1431 22.9095 50.8334 22.8645 50.2723C22.8053 49.5286 23.382 48.9062 24.2963 48.8341C24.8203 48.7927 25.3713 48.9326 25.826 49.3259L25.397 50.0225C25.1065 49.7634 24.6868 49.6556 24.3763 49.6799C24.0165 49.7084 23.811 49.9026 23.8317 50.1588C23.8495 50.3844 24.0422 50.4979 24.2328 50.575C24.4791 50.6656 24.9437 50.8005 25.2606 50.929C25.7167 51.1074 26.0722 51.4165 26.1179 51.9897C26.1814 52.7884 25.5626 53.4136 24.5747 53.4922C23.8859 53.5471 23.2293 53.3109 22.801 52.7862L22.7996 52.7855Z"
        fill="white"
      />
      <path
        d="M29.0121 52.0847L27.4397 52.2096L27.1699 53.2124L26.2249 53.2874L27.5867 48.6464L28.3241 48.5879L30.3754 52.9576L29.4247 53.0333L29.0121 52.0847ZM27.6317 51.3845L28.6924 51.3002L28.0493 49.8427L27.6317 51.3845Z"
        fill="white"
      />
      <path
        d="M33.6389 48.166L32.1493 52.817L31.4298 52.8741L29.2014 48.5179L30.2435 48.4351L31.6789 51.4558L32.6147 48.2474L33.6389 48.166Z"
        fill="white"
      />
      <path
        d="M34.9624 48.0611L35.3192 52.5657L34.4113 52.6378L34.0544 48.1332L34.9624 48.0611Z"
        fill="white"
      />
      <path
        d="M39.6794 47.6871L40.0363 52.1917L39.4204 52.2403L36.8672 49.6643L37.0863 52.4251L36.1962 52.4958L35.8394 47.9912L36.4125 47.9455L39.0106 50.5422L38.7901 47.7571L39.6802 47.6864L39.6794 47.6871Z"
        fill="white"
      />
      <path
        d="M40.4804 49.8933C40.3719 48.5222 41.3498 47.475 42.6788 47.3701C43.4347 47.3102 44.1299 47.5743 44.5532 47.9576L44.0128 48.6506C43.6774 48.3644 43.2227 48.2038 42.7473 48.2416C41.9065 48.308 41.3398 48.979 41.4061 49.8141C41.4704 50.6249 42.1128 51.2238 42.9665 51.156C43.4483 51.1174 43.8829 50.8747 44.1349 50.5785L44.0864 49.9625L42.843 50.061L42.7809 49.2745L44.8472 49.1103L44.9943 50.9633C44.4911 51.58 43.8037 51.9597 43.0114 52.0225C41.646 52.131 40.5867 51.2274 40.4811 49.8926L40.4804 49.8933Z"
        fill="white"
      />
      <path
        d="M45.2849 51.0025L45.7531 50.3337C46.0843 50.7184 46.5404 50.9033 46.998 50.8669C47.3756 50.8369 47.6282 50.6142 47.6061 50.3344C47.5883 50.1089 47.4005 49.9889 47.1607 49.8976C46.8638 49.7862 46.4833 49.7063 46.1521 49.5543C45.7382 49.3601 45.3948 49.0503 45.3499 48.4893C45.2906 47.7456 45.8673 47.1231 46.7817 47.0511C47.3056 47.0097 47.8566 47.1496 48.3113 47.5428L47.8823 48.2395C47.5918 47.9804 47.1721 47.8726 46.8616 47.8969C46.5019 47.9254 46.2963 48.1196 46.317 48.3758C46.3349 48.6014 46.5276 48.7149 46.7182 48.7919C46.9644 48.8826 47.4291 49.0175 47.746 49.146C48.2021 49.3244 48.5576 49.6335 48.6032 50.2066C48.6668 51.0054 48.0479 51.6306 47.0601 51.7091C46.3713 51.7641 45.7146 51.5278 45.2871 51.0032L45.2849 51.0025Z"
        fill="white"
      />
      <path
        d="M49.2375 51.0325C49.2161 50.7584 49.4245 50.5271 49.705 50.505C49.9791 50.4836 50.2154 50.6792 50.2375 50.9533C50.2611 51.2459 50.0576 51.4707 49.7835 51.4922C49.5095 51.5136 49.2611 51.3251 49.2382 51.0325H49.2375ZM48.9556 46.8655L49.8635 46.7934L50.0676 50.1381L49.2689 50.2017L48.9556 46.8655Z"
        fill="white"
      />
    </svg>
  );
};
