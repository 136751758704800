import React from 'react';

export const WifiRebrandV2 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="white" />
      <rect width="24" height="24" rx="12" fill="#DE3700" fillOpacity="0.8" />
      <g clipPath="url(#clip0_7119_29046)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0623 13.647C11.0796 13.647 10.2823 14.4443 10.2823 15.4269C10.2823 16.4104 11.0796 17.2068 12.0623 17.2068C13.0458 17.2068 13.8422 16.4095 13.8422 15.4269C13.8422 14.4434 13.0449 13.647 12.0623 13.647Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.24338 10.6685C9.87391 7.97375 14.1289 7.97375 16.7585 10.6685C17.1165 11.0346 17.7031 11.0418 18.0701 10.6847C18.4362 10.3268 18.4435 9.7401 18.0864 9.37309C14.7282 5.9326 9.27458 5.9326 5.91636 9.37309C5.55839 9.73919 5.56562 10.3268 5.93263 10.6838C6.29874 11.0418 6.88631 11.0346 7.24338 10.6685ZM9.59278 13.0748C10.9252 11.7089 13.0766 11.7089 14.4091 13.0748C14.7671 13.4409 15.3537 13.4482 15.7207 13.0911C16.0868 12.7331 16.0941 12.1464 15.737 11.7794C13.6769 9.66778 10.3259 9.66778 8.26576 11.7794C7.90779 12.1455 7.91502 12.7331 8.28203 13.0911C8.64813 13.4491 9.23481 13.4418 9.59278 13.0748Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7119_29046">
          <rect
            width="13.3"
            height="10.909"
            fill="white"
            transform="translate(5.35001 6.54541)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
