import React from 'react';

export const SatelliteRebrandV2 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="white" />
      <rect width="24" height="24" rx="12" fill="#DE3700" fillOpacity="0.8" />
      <g clipPath="url(#clip0_7119_29032)">
        <path
          d="M11.6256 14.179L9.84128 12.3934C9.52598 12.0781 9.13011 12.2241 8.89422 12.3105L7.37961 12.8664L4.58162 15.6644C4.28501 15.961 4.28501 16.4445 4.58162 16.7422L7.36677 19.5274L7.37377 19.5204C7.50573 19.615 7.66105 19.6617 7.81636 19.6617C8.01138 19.6617 8.2064 19.5869 8.35587 19.4386L11.141 16.6535L11.7109 15.1237C11.7576 14.9987 11.8207 14.8283 11.8207 14.6484C11.8207 14.4651 11.7553 14.3074 11.6256 14.179ZM10.3236 16.455L7.84789 18.9307C7.83037 18.9482 7.80235 18.9482 7.786 18.9307L6.69296 17.8376C7.95182 16.5951 8.79496 15.7543 9.20484 15.3351L10.3236 16.455ZM8.69803 14.8283C8.21807 15.3176 7.08533 16.441 6.18498 17.3296L5.08844 16.2331C5.08143 16.2261 5.07676 16.2144 5.07676 16.2027C5.07676 16.191 5.08143 16.1794 5.08844 16.1712L7.56529 13.6943L8.69803 14.8283ZM11.1013 14.6694C11.0943 14.7185 11.0511 14.8353 11.0359 14.8773L10.6903 15.8057L8.21107 13.3253L9.1523 12.9797C9.192 12.9656 9.30294 12.9236 9.35082 12.9178L11.1013 14.6694Z"
          fill="white"
        />
        <path
          d="M19.4182 7.29859L16.7218 4.6022C16.5781 4.45856 16.3866 4.37915 16.1834 4.37915C15.9802 4.37915 15.7887 4.45856 15.6451 4.6022L12.8459 7.40019L12.2866 8.92531C12.2013 9.15769 12.0589 9.54656 12.3742 9.86186L14.1585 11.6474C14.3057 11.7945 14.4691 11.8412 14.6256 11.8412C14.8078 11.8412 14.9806 11.777 15.1079 11.7291L16.633 11.1616L19.4182 8.37645C19.5618 8.23282 19.6401 8.0413 19.6401 7.83811C19.6412 7.63258 19.5618 7.44223 19.4182 7.29859ZM13.6762 7.58587L16.1519 5.10901C16.1683 5.09266 16.1975 5.09266 16.2138 5.10901L17.315 6.21022C16.8561 6.67734 15.763 7.76337 14.802 8.7116L13.6762 7.58587ZM18.9114 7.86847L16.4345 10.3442L15.3111 9.22075C16.2091 8.33441 17.3162 7.23787 17.823 6.71821L18.9114 7.80658C18.9277 7.82293 18.9277 7.85095 18.9114 7.86847ZM15.7852 10.7108L14.8522 11.0577C14.8113 11.0728 14.6969 11.1161 14.649 11.1219L12.8985 9.37023C12.9055 9.32235 12.9429 9.21842 12.9604 9.17171L13.306 8.22931L15.7852 10.7108Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9385 13.7938C11.6057 13.461 10.118 11.9732 10.118 11.9732C9.93581 11.791 9.84239 11.54 9.8599 11.2831L9.89377 10.7903C9.90895 10.5766 10 10.3745 10.1518 10.2227C10.3037 10.0709 10.5057 9.97866 10.7194 9.96464L11.2122 9.93078C11.4691 9.91326 11.7202 10.0078 11.9023 10.1889L13.7591 12.0456C13.9413 12.2278 14.0347 12.4789 14.0172 12.7358L13.9833 13.2286C13.9681 13.4423 13.8771 13.6443 13.7252 13.7961C13.5734 13.9479 13.3714 14.0402 13.1577 14.0542L12.7069 14.0846C12.7069 14.0846 12.2702 14.1266 11.9385 13.7938Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3268 13.1711C16.3478 14.9065 14.9605 16.2938 13.2252 16.2728C12.9893 16.2704 12.7954 16.4584 12.7931 16.6955C12.7908 16.9314 12.9788 17.1252 13.2158 17.1287C15.4323 17.1556 17.2096 15.3782 17.1828 13.1618C17.1804 12.9259 16.9866 12.7367 16.7495 12.7391C16.5136 12.7414 16.3245 12.9341 16.3268 13.1711ZM14.7771 13.1513C14.7877 14.0306 14.087 14.7325 13.2065 14.7219C12.9706 14.7196 12.7767 14.9076 12.7732 15.1447C12.7709 15.3806 12.9589 15.5744 13.196 15.5768C14.5564 15.5931 15.6483 14.5012 15.632 13.1408C15.6296 12.9049 15.4358 12.7157 15.1999 12.718C14.9628 12.7215 14.7736 12.9154 14.7771 13.1513Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7119_29032">
          <rect
            width="15.8"
            height="15.8"
            fill="white"
            transform="translate(4.10001 4.1001)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
