import React from 'react';

export const Unlimited = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="HN_x5F_Unlimited_x5F_Data_x5F_Icon_x5F_CMYK_xA0_Image"
      viewBox="0 0 268.32 140.4"
      width="40"
      height="40"
      {...props}
    >
      <path
        fill="#d65b1e"
        d="M268.32,72.6c-.14,1.11-.33,2.22-.42,3.34-1.38,16.04-7.67,29.9-18.59,41.69-11.45,12.36-25.57,19.74-42.3,21.82-21.68,2.7-40.3-4.09-56.24-18.73-.73-.68-1.47-1.36-2.14-2.09-2.5-2.75-2.35-7.02.29-9.51,2.72-2.56,6.86-2.55,9.51.09,7.75,7.71,16.74,13.36,27.47,15.76,17.88,4,34.05.02,48.12-11.61,10.37-8.57,17.17-19.55,19.48-32.88,3.25-18.75-1.43-35.34-14.69-49.25-7.71-8.09-17.02-13.49-27.91-16.12-14.04-3.39-26.98-.85-38.99,7.03-6.49,4.26-12.13,9.48-17.47,15.07-4.6,4.82-9.28,9.58-13.91,14.38-8.62,8.94-17.2,17.92-25.84,26.85-6.3,6.52-12.52,13.13-19.03,19.43-7.06,6.84-15.7,10.35-25.56,10.36-19.73.03-34.55-13.7-37.72-30.53-2.49-13.22.71-24.95,10.09-34.73,6.12-6.39,13.77-9.88,22.53-11.14,9.91-1.42,18.07,2.32,25.56,8.21,2.16,1.7,4.24,3.53,6.22,5.43,2.77,2.65,2.8,6.88.2,9.55-2.7,2.76-6.75,2.78-9.7.19-2.49-2.18-5.07-4.27-7.69-6.28-6.08-4.66-12.72-4.8-19.46-1.82-9.17,4.07-14.19,11.25-14.87,21.26-.84,12.33,7.1,22.96,19.18,25.61,8.92,1.96,16.61-.59,22.96-7.04,7.53-7.66,14.97-15.42,22.43-23.15,10.24-10.6,20.45-21.23,30.69-31.82,6.19-6.4,12.26-12.93,19.5-18.21,7.98-5.82,16.57-10.35,26.3-12.44,2.37-.51,4.81-.74,7.21-1.12.21-.03.4-.15.6-.23,2.56,0,5.12,0,7.68,0,.21.06.42.18.64.19,4.52.22,8.97.97,13.34,2.12,13.33,3.52,24.58,10.52,33.92,20.57,10.73,11.53,16.67,25.22,18.12,40.88.13,1.43.3,2.86.46,4.29v4.55Z"
      />
      <path
        fill="#0086ca"
        d="M73.92,0c1.32.2,2.64.4,3.97.59,11.27,1.63,21.25,6.29,30.2,13.14,3.86,2.95,7.45,6.28,11.02,9.59,2.95,2.73,2.93,7.13.18,9.82-2.7,2.64-7,2.57-9.8-.23-5.14-5.14-10.74-9.66-17.11-13.21-12.13-6.75-24.95-7.91-38.01-3.76-21.89,6.95-35.5,21.94-39.78,44.42-3.42,17.99,1.69,34.08,14.18,47.6,8.83,9.55,19.71,15.56,32.61,17.51,15.85,2.4,30.21-1.39,42.64-11.5,4.53-3.69,8.51-8.08,12.62-12.27,7.41-7.56,14.73-15.21,22.08-22.82,10.47-10.83,20.93-21.66,31.4-32.48,5-5.17,10.4-9.81,17-12.88,8.27-3.85,16.66-3.28,24.81-.07,14.25,5.6,22.51,16.26,24.36,31.41,1.47,12.08-2.29,22.63-10.74,31.53-6.31,6.65-13.94,10.61-23.02,11.6-10.72,1.18-20.23-1.81-28.44-8.82-.97-.83-1.91-1.71-2.74-2.67-2.51-2.91-2.27-7.09.48-9.58,2.72-2.46,6.84-2.35,9.53.37,5.12,5.18,11.27,7.72,18.57,7.3,11.77-.67,20.53-10.65,22.51-19.84,2.46-11.41-2.97-22.89-13.46-27.85-4.16-1.97-8.57-2.98-13.15-2.03-3.8.79-7.06,2.89-9.86,5.46-4.38,4.02-8.59,8.23-12.75,12.48-7.27,7.41-14.46,14.91-21.68,22.38-9.55,9.88-19.14,19.72-28.61,29.68-6.37,6.7-13.14,12.83-21.42,17.12-8.08,4.18-16.61,6.72-25.69,7.51-29.87,2.57-55.62-14.34-67.68-37.07C3.57,93.85.92,84.72.21,75.04c-.02-.26-.14-.51-.21-.76,0-3.19,0-6.39,0-9.58.08-.24.22-.48.24-.73.49-5.34,1.52-10.57,3.21-15.65,3.74-11.23,10-20.86,18.56-29.04C32.84,8.94,45.71,2.84,60.5.7,62.09.47,63.69.23,65.28,0c2.88,0,5.76,0,8.64,0Z"
      />
    </svg>
  );
};
