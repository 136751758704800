import React from 'react';
import styles from './SuggestionList.module.scss';
import { v4 as uuid } from 'uuid';

export default function SuggestionItems(props) {
  const { items, handleKeyDown, onSuggestionClick } = props;

  const formatAddress = (item) => {
    let secondaryAddress = item.secondary;

    if (item.secondary && item.entries > 1) {
      secondaryAddress = `${item.secondary} (${item.entries} entries)`;
    }

    if (secondaryAddress) {
      item.displayAddress = `${item.street_line}, ${secondaryAddress} - ${item.city}, ${item.state} ${item.zipcode}`;
    } else {
      item.displayAddress = `${item.street_line} - ${item.city}, ${item.state} ${item.zipcode}`;
    }
  };

  const renderSuggestions = () => {
    if (items) {
      let idx = 0;
      return items.map((item) => {
        formatAddress(item);
        const isActive = idx === props.activeIdx ? 'active' : '';
        idx += 1;
        return (
          <li
            className={`${styles.listItemWrap} ${isActive}`}
            key={uuid()}
            onKeyDown={(e) => handleKeyDown(e, item)}
            tabIndex="0"
          >
            <div
              className={styles.listContent}
              onClick={() => onSuggestionClick(item)}
            >
              <span className={styles.listItem} data-private="lipsum">
                {item.displayAddress}
              </span>
            </div>
          </li>
        );
      });
    }
    return [];
  };

  return renderSuggestions();
}
