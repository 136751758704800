import React from 'react';

export const Ribbon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="122"
      height="140"
      viewBox="0 0 122 140"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1571_2726)">
        <path
          d="M120.915 119.197L99.6531 78.2512C105.884 70.0632 109.592 59.845 109.592 48.7547C109.592 21.8278 87.764 0 60.837 0C33.9101 0 12.0757 21.8278 12.0757 48.7614C12.0757 59.7385 15.7037 69.8702 21.828 78.0182L0.446187 119.197C-0.286065 120.602 -0.112987 122.306 0.878882 123.538C1.87075 124.769 3.50168 125.302 5.02609 124.896L22.1142 120.309L27.9656 137.304C28.4781 138.795 29.8228 139.847 31.3938 139.98C31.507 139.993 31.6268 139.993 31.74 139.993C33.1845 139.993 34.5292 139.208 35.2348 137.93L57.6484 97.3896C58.7002 97.4561 59.7586 97.5027 60.8304 97.5027C61.8023 97.5027 62.7609 97.4695 63.7194 97.4095L86.1197 137.923C86.8253 139.201 88.17 139.987 89.6146 139.987C89.7277 139.987 89.8475 139.987 89.9607 139.973C91.5317 139.834 92.8764 138.788 93.389 137.297L99.2403 120.302L116.328 124.889C117.853 125.302 119.484 124.769 120.476 123.531C121.468 122.299 121.634 120.595 120.908 119.191L120.915 119.197ZM32.5921 126.214L28.4515 114.191C27.7659 112.208 25.6623 111.089 23.6386 111.635L11.7096 114.837L27.5329 84.3688C33.544 89.9938 40.973 94.1077 49.2275 96.1248L32.5854 126.22L32.5921 126.214ZM20.8694 48.7614C20.8694 26.6873 38.763 8.79369 60.837 8.79369C82.9111 8.79369 100.805 26.6873 100.805 48.7614C100.805 70.8354 82.9111 88.729 60.837 88.729C38.763 88.729 20.8694 70.8354 20.8694 48.7614ZM97.7226 111.629C95.6923 111.083 93.5954 112.201 92.9097 114.185L88.7691 126.207L72.1603 96.178C80.4348 94.2076 87.8838 90.1403 93.9215 84.5552L109.638 114.83L97.7093 111.629H97.7226Z"
          fill="#0095F9"
        />
        <path
          d="M37.4248 46.6576L44.541 55.0785L44.055 65.7761C43.9885 67.2006 44.6342 68.5586 45.7725 69.4107C46.5114 69.9566 47.4101 70.2495 48.3287 70.2561C48.8147 70.2561 49.294 70.1762 49.7533 70.0098L60.6838 66.1888L71.6143 70.0098C73.8377 70.802 76.2808 69.637 77.0729 67.4136C77.2593 66.8877 77.3392 66.3352 77.3126 65.7761L76.8266 55.0785L83.9361 46.6576C85.4606 44.8603 85.2409 42.1642 83.4435 40.6332C82.9509 40.2138 82.3718 39.9142 81.7527 39.7545L70.5692 36.8255L64.1853 27.7855C62.8207 25.8483 60.1446 25.389 58.2075 26.7537C57.8081 27.0332 57.4619 27.3861 57.1757 27.7855L50.7917 36.8255L39.6083 39.7545C37.325 40.3469 35.9536 42.6835 36.5461 44.9668C36.7059 45.5859 37.0054 46.165 37.4248 46.651V46.6576ZM54.4796 44.7271C55.4582 44.4675 56.3103 43.8751 56.9027 43.0496L60.6838 37.6908L64.4649 43.0496C65.0507 43.8751 65.9094 44.4675 66.888 44.7271L73.0056 46.3248L69.2046 50.8315C68.5056 51.6569 68.1395 52.7153 68.1994 53.7937L68.4657 59.8315L62.0884 57.6081C61.1764 57.2819 60.1779 57.2819 59.2659 57.6081L52.8953 59.8315L53.1682 53.7937C53.2148 52.7153 52.8554 51.6569 52.1631 50.8315L48.362 46.3248L54.4796 44.7271Z"
          fill="#DD3700"
        />
      </g>
      <defs>
        <clipPath id="clip0_1571_2726">
          <rect width="121.361" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
