import React, { useContext } from 'react';
import Button from '~/components/shared/Button';
import { PhoneRebrand } from '~/components/icons/PhoneRebrand';
import styles from '../Nav.module.scss';
import PhoneNumber from '~/components/shared/Fuse/PhoneNumber';

function HeaderCTA({ metadata }) {
  const { callCTA, callCTACopy, mobileCallCTACopy } = metadata;

  return (
    <>
      {callCTA ? (
        <div className={styles.rightNav}>
          <Button
            className={styles.btnMobile}
            fuse
            tracking={{
              position: '',
              location: 'MASTHEAD',
              elementType: 'BUTTON',
              actionOutcome: 'click to call',
              htmlId: 'MASTHEAD_C2C',
            }}
          >
            <PhoneRebrand color="#fff" />
            {mobileCallCTACopy}
          </Button>
          <Button
            className={styles.phoneNumber}
            fuse
            tracking={{
              position: '',
              location: 'MASTHEAD',
              elementType: 'BUTTON',
              actionOutcome: 'click to call',
              htmlId: 'MASTHEAD_C2C',
            }}
          >
            <PhoneRebrand color="#ffffff" />
            {/* CallCTA_Copy */}
            {callCTACopy}{' '}
            <span>
              <PhoneNumber />
            </span>
          </Button>
        </div>
      ) : (
        <div className={styles.rightNav}>
          <Button
            className={styles.btnDesktop}
            href="/order-online"
            tracking={{
              position: '',
              location: 'MASTHEAD',
              elementType: 'BUTTON',
              actionOutcome: 'redirect to cart',
              htmlId: 'MASTHEAD_ORDER_ONLINE',
            }}
          >
            ORDER ONLINE
          </Button>
        </div>
      )}
    </>
  );
}

HeaderCTA.propTypes = {};

export default HeaderCTA;
