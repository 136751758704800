import React from 'react';

export const ContractRebrand = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
    >
      <path
        d="M26.2248 28.6033C26.2248 28.5662 26.2248 28.5322 26.2248 28.4951C26.2248 27.8488 26.2248 27.2025 26.2248 26.5562C26.2248 26.5191 26.2248 26.448 26.2619 26.414C28.3461 22.8177 30.4303 19.2615 32.5114 15.6683C32.5114 15.6312 32.5485 15.6312 32.5485 15.5972C33.1237 15.9219 33.7329 16.2806 34.308 16.6022C34.2709 16.6393 34.2709 16.6733 34.2369 16.7104C32.1898 20.2665 30.1056 23.8598 28.0585 27.4159C28.0214 27.487 27.9503 27.5241 27.9163 27.5581C27.3782 27.8828 26.8371 28.2044 26.299 28.5291C26.2959 28.5662 26.2619 28.6033 26.2248 28.6033Z"
        fill="#DE3700"
      />
      <path
        d="M35.4951 14.7716C35.2787 15.1674 35.0282 15.5632 34.8117 15.9559C34.2366 15.6312 33.6274 15.2725 33.0151 14.9509C33.2316 14.5551 33.482 14.1593 33.6985 13.7666C34.3448 14.0882 34.92 14.4098 35.4951 14.7716Z"
        fill="#DE3700"
      />
      <path
        d="M29.3513 33.7767C29.3513 34.6394 28.6339 35.3568 27.7711 35.3568H8.72888C7.86614 35.3568 7.14873 34.6394 7.14873 33.7767V7.2264C7.14873 6.36366 7.86614 5.64625 8.72888 5.64625H27.7711C28.6339 5.64625 29.3513 6.36366 29.3513 7.2264V18.5379C29.9976 17.4247 30.681 16.2744 31.3273 15.1612V7.2264C31.3273 5.25044 29.7471 3.67029 27.7711 3.67029H8.72888C6.75292 3.67029 5.17276 5.25044 5.17276 7.2264V33.7767C5.17276 35.7526 6.75292 37.3328 8.72888 37.3328H27.7711C29.7471 37.3328 31.3273 35.7526 31.3273 33.7767V24.2958C30.681 25.4461 29.9976 26.5593 29.3513 27.7096V33.7767Z"
        fill="#0096FA"
      />
      <path
        d="M15.8779 10.8907H10.0954C9.73665 10.8907 9.44907 10.6032 9.44907 10.2445C9.44907 9.88576 9.73665 9.59818 10.0954 9.59818H15.881C16.2397 9.59818 16.5273 9.88576 16.5273 10.2445C16.5242 10.6032 16.2366 10.8907 15.8779 10.8907Z"
        fill="#DE3700"
      />
      <path
        d="M23.2066 14.7716H10.0954C9.73665 14.7716 9.44907 14.484 9.44907 14.1253C9.44907 13.7666 9.73665 13.479 10.0954 13.479H23.2097C23.5684 13.479 23.856 13.7666 23.856 14.1253C23.856 14.484 23.5653 14.7716 23.2066 14.7716Z"
        fill="#DE3700"
      />
      <path
        d="M25.4362 18.6864H10.0954C9.73665 18.6864 9.44907 18.3988 9.44907 18.0401C9.44907 17.6814 9.73665 17.3938 10.0954 17.3938H25.4733C25.832 17.3938 26.1196 17.6814 26.1196 18.0401C26.1165 18.3617 25.7949 18.6864 25.4362 18.6864Z"
        fill="#DE3700"
      />
      <path
        d="M23.2066 22.5672H10.0954C9.73665 22.5672 9.44907 22.2796 9.44907 21.9209C9.44907 21.5622 9.73665 21.2746 10.0954 21.2746H23.2097C23.5684 21.2746 23.856 21.5622 23.856 21.9209C23.8529 22.2796 23.5653 22.5672 23.2066 22.5672Z"
        fill="#DE3700"
      />
      <path
        d="M23.2066 26.4449H10.0954C9.73665 26.4449 9.44907 26.1573 9.44907 25.7986C9.44907 25.4399 9.73665 25.1523 10.0954 25.1523H23.2097C23.5684 25.1523 23.856 25.4399 23.856 25.7986C23.8529 26.1604 23.5653 26.4449 23.2066 26.4449Z"
        fill="#DE3700"
      />
      <path
        d="M17.7828 30.3257H10.0954C9.73665 30.3257 9.44907 30.0381 9.44907 29.6794C9.44907 29.3207 9.73665 29.0331 10.0954 29.0331H17.7828C18.1415 29.0331 18.429 29.3207 18.429 29.6794C18.429 30.0381 18.1415 30.3257 17.7828 30.3257Z"
        fill="#DE3700"
      />
    </svg>
  );
};
