import React from 'react';

export const UnlimitedRebrand = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        d="M35.8653 13.4723C34.1143 11.7228 31.8125 10.7552 29.3708 10.7552C27.1603 10.7552 24.9955 11.8141 22.9234 13.8405L22.8777 13.8862L20.0222 16.8345L16.7515 20.2423L13.896 23.1905C13.2509 23.8355 12.1921 24.5719 10.6723 24.5719C9.42928 24.5719 8.27767 24.111 7.40149 23.2362C6.52671 22.3614 6.06578 21.2098 6.06578 19.9654C6.06578 18.7211 6.52671 17.5708 7.40149 16.6947C8.27628 15.8199 9.4279 15.359 10.6723 15.359C11.8696 15.359 13.0668 16.3265 13.896 17.1099L14.4233 17.5819L17.6013 14.2655L17.1667 13.8848L17.121 13.8391C15.0476 11.7685 12.8828 10.7552 10.6723 10.7552C8.2306 10.7552 5.88307 11.7228 4.17779 13.4723C2.42683 15.2233 1.46069 17.5252 1.46069 19.9668C1.46069 22.4085 2.42822 24.756 4.17779 26.4613C5.92875 28.2122 8.2306 29.1784 10.6723 29.1784C13.0668 29.1784 15.3701 28.2108 17.1197 26.4142L17.1653 26.3685L19.8368 23.6044L23.383 19.9197L26.1928 17.0186C26.9762 16.2351 28.1735 15.2676 29.3708 15.2676C30.6138 15.2676 31.7654 15.7285 32.6416 16.6033C33.5164 17.4781 33.9773 18.6297 33.9773 19.8741C33.9773 21.1184 33.5164 22.2687 32.6416 23.1448C31.7654 24.1124 30.5681 24.5733 29.3708 24.5733C27.8967 24.5733 26.7921 23.8369 26.1928 23.1919L25.4163 22.3711L22.1926 25.6875L22.8777 26.4156L22.9234 26.4613C24.6744 28.2122 26.9762 29.2254 29.3708 29.2254C31.8125 29.2254 34.16 28.2579 35.8653 26.5083C37.6162 24.7574 38.5824 22.4555 38.5824 20.0139C38.5824 17.5252 37.6149 15.1762 35.8653 13.4723Z"
        fill="#DE3700"
      />
    </svg>
  );
};
