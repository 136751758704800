import React from 'react';

export const CreditCardBrand = (props) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.536 18.7934H6.46631V24.5931H49.536V18.7934Z"
        fill="#DE3700"
      />
      <path
        d="M44.7505 26.6764C45.3694 26.6764 45.8782 27.0718 45.8782 27.6321V30.7297C45.8782 31.235 45.3728 31.6854 44.7505 31.6854H41.4846C40.8657 31.6854 40.3569 31.29 40.3569 30.7297V27.6321C40.3569 27.1268 40.8623 26.6764 41.4846 26.6764H44.7505Z"
        fill="#DE3700"
      />
      <path
        d="M47.9033 37.8804C47.9033 38.9496 47.0576 39.7953 45.9884 39.7953H9.9561C8.88692 39.7953 8.0412 38.9496 8.0412 37.8804V18.1196C8.0412 17.0504 8.88692 16.2047 9.9561 16.2047H46.0434C47.1126 16.2047 47.9583 17.0504 47.9583 18.1196L47.9033 37.8804ZM45.9884 13.3891H9.9561C7.36738 13.3891 5.22559 15.5274 5.22559 18.1196V37.8804C5.22559 40.4691 7.36394 42.6109 9.9561 42.6109H46.0434C48.6322 42.6109 50.774 40.4726 50.774 37.8804V18.1196C50.719 15.5309 48.6356 13.3891 45.9884 13.3891Z"
        fill="#DE3700"
      />
    </svg>
  );
};
