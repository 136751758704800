export const getOfferEndDiff = (currentDate, offerEndDate) => {
  // converting the date to milliseconds
  const date1 = currentDate.getTime();
  const date2 = offerEndDate.getTime();
  const msDifference = Math.abs(date1 - date2);

  // converting the milliseconds between dates to hours
  const hoursBetween = msDifference / (1000 * 60 * 60);

  return hoursBetween;
};
