import React from 'react';

export const WhiteHughesnetLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="40"
      viewBox="0 0 140 40"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_1836_2823)">
        <path
          d="M1.14463 15.7485L3.79995 14.0522C4.07322 13.8769 4.43413 14.0728 4.43413 14.4028V21.0231C5.07863 19.8372 6.70276 18.7029 8.89405 18.7029C11.704 18.7029 14.3852 20.5848 14.3852 24.5807V32.8045C14.3852 33.088 14.1531 33.3201 13.8696 33.3201H10.3377C10.0541 33.3201 9.82212 33.088 9.82212 32.8045V25.7923C9.82212 23.9104 8.86827 22.8534 7.32148 22.8534C5.69735 22.8534 4.53725 24.0651 4.53725 25.8955V32.8045C4.53725 33.088 4.30523 33.3201 4.02166 33.3201H0.515597C0.232019 33.3201 0 33.088 0 32.8045V17.8367C0 16.9911 0.433101 16.2074 1.14463 15.7485Z"
          fill="white"
        />
        <path
          d="M112.792 24.8023H119.443C119.082 23.2039 117.896 22.1727 116.195 22.1727C114.494 22.1727 113.256 23.1524 112.792 24.8023ZM122.753 27.6896H112.818C113.256 29.2364 114.468 30.3449 116.659 30.3449C117.994 30.3449 119.51 29.8861 120.629 29.2519C120.877 29.1127 121.191 29.19 121.336 29.4375L122.429 31.3297C122.563 31.5669 122.496 31.8659 122.269 32.0155C120.892 32.9281 118.866 33.6963 116.479 33.6963C111.194 33.6963 108.745 30.1387 108.745 26.2975C108.745 22.0954 111.709 18.8987 116.195 18.8987C120.242 18.8987 123.336 21.5024 123.336 25.9881C123.336 26.4006 123.31 26.8182 123.264 27.2307C123.238 27.4885 123.011 27.6896 122.753 27.6896Z"
          fill="white"
        />
        <path
          d="M135.159 32.1857C135.241 32.428 135.143 32.691 134.916 32.8095C133.808 33.3973 132.328 33.7479 130.972 33.7479C127.672 33.7479 125.919 31.8402 125.919 28.0506V22.611H124.192C123.908 22.611 123.676 22.379 123.676 22.0954V19.7753C123.676 19.4917 123.908 19.2597 124.192 19.2597H125.945V17.8933C125.945 16.9807 126.445 16.1455 127.249 15.7124L129.358 14.5832C129.631 14.4337 129.967 14.6348 129.967 14.9441V19.2545H133.988C134.272 19.2545 134.504 19.4865 134.504 19.7701V22.0903C134.504 22.3739 134.272 22.6059 133.988 22.6059H129.967V27.6587C129.967 29.2829 130.585 30.0563 131.848 30.0563C132.534 30.0563 133.117 29.8603 133.663 29.6128C133.937 29.4839 134.261 29.6232 134.365 29.9119L135.164 32.1805L135.159 32.1857Z"
          fill="white"
        />
        <path
          d="M42.279 26.0501C42.279 24.1939 40.9127 22.8276 39.0565 22.8276C37.2004 22.8276 35.834 24.1681 35.834 26.0501C35.834 27.932 37.2004 29.3499 39.0565 29.3499C40.9127 29.3499 42.279 27.932 42.279 26.0501ZM45.95 17.9861C46.2233 17.8108 46.5842 18.0067 46.5842 18.3316V31.8763C46.5842 36.4909 43.0524 39.2751 38.4893 39.2751C36.1124 39.2751 33.8077 38.5172 32.1269 37.3932C31.9103 37.2488 31.8433 36.9704 31.9567 36.7384L33.1581 34.3151C33.2921 34.047 33.6221 33.949 33.8799 34.0985C35.0864 34.8101 36.628 35.4288 38.3089 35.4288C40.99 35.4288 42.2532 33.9335 42.2532 32.1547V31.8454C41.3509 32.6961 40.0619 33.2633 38.2831 33.2633C34.0037 33.2633 31.2452 30.2212 31.2452 25.9933C31.2452 21.7654 34.4161 18.6461 38.4893 18.6461C40.2166 18.6461 41.5314 19.2906 42.4852 20.1929L43.8412 19.3267L45.95 17.981V17.9861Z"
          fill="white"
        />
        <path
          d="M138.175 31.8764H138.407C138.556 31.8764 138.649 31.8712 138.685 31.8558C138.721 31.8455 138.752 31.8248 138.773 31.7939C138.794 31.763 138.804 31.7269 138.804 31.6805C138.804 31.6289 138.788 31.5877 138.763 31.5567C138.737 31.5258 138.696 31.5052 138.644 31.5C138.618 31.5 138.541 31.4949 138.417 31.4949H138.175V31.8764ZM137.866 32.7478V31.2371H138.52C138.685 31.2371 138.804 31.2525 138.881 31.2783C138.959 31.3041 139.015 31.3556 139.062 31.4227C139.108 31.4897 139.129 31.5722 139.129 31.6598C139.129 31.7733 139.093 31.8661 139.026 31.9383C138.959 32.0105 138.855 32.0569 138.721 32.0775C138.788 32.1136 138.845 32.16 138.886 32.2064C138.928 32.2528 138.989 32.3353 139.062 32.4487L139.247 32.7426H138.876L138.654 32.4126C138.572 32.294 138.52 32.2218 138.489 32.1909C138.458 32.16 138.428 32.1394 138.397 32.129C138.366 32.1187 138.314 32.1136 138.242 32.1136H138.18V32.7426H137.871L137.866 32.7478Z"
          fill="white"
        />
        <path
          d="M138.531 30.8452C137.86 30.8452 137.314 31.3814 137.314 32.0362C137.314 32.691 137.86 33.2272 138.531 33.2272C139.201 33.2272 139.747 32.691 139.747 32.0362C139.747 31.3814 139.201 30.8452 138.531 30.8452ZM138.531 33.4799C137.721 33.4799 137.061 32.8302 137.061 32.0362C137.061 31.2422 137.721 30.5925 138.531 30.5925C139.34 30.5925 140 31.2422 140 32.0362C140 32.8302 139.34 33.4799 138.531 33.4799Z"
          fill="white"
        />
        <path
          d="M68.6258 24.4776H74.6583C74.2716 23.1371 73.2147 22.2863 71.6936 22.2863C70.1726 22.2863 69.0899 23.0597 68.6258 24.4776ZM78.3861 27.597H68.6774C69.1414 28.9375 70.2758 29.8398 72.1577 29.8398C73.4673 29.8398 74.9367 29.4067 76.0247 28.7932C76.2721 28.6539 76.5867 28.7364 76.7259 28.9839L77.9994 31.1752C78.1386 31.4175 78.0613 31.7217 77.8292 31.8713C76.401 32.7839 74.3902 33.5521 71.9514 33.5521C66.6408 33.5521 64.0886 30.0203 64.0886 26.1275C64.0886 21.8738 67.1306 18.6514 71.6936 18.6514C75.7926 18.6514 78.9893 21.2551 78.9893 25.7666C78.9893 26.1997 78.9584 26.6637 78.8965 27.1484C78.8656 27.4062 78.6439 27.597 78.3861 27.597Z"
          fill="white"
        />
        <path
          d="M79.8041 30.8091L81.2839 28.5817C81.4489 28.3342 81.7892 28.2775 82.0212 28.458C83.0988 29.2726 84.4445 29.8913 85.8469 29.8913C86.6719 29.8913 87.3679 29.6077 87.3679 28.989C87.3679 28.7055 87.2132 28.4477 86.5945 28.1899C85.8985 27.9321 83.9134 27.3391 83.0627 26.9524C81.387 26.179 80.4074 24.9674 80.4074 23.0854C80.4074 20.4043 82.7018 18.574 86.2852 18.574C88.0227 18.574 89.6469 18.9916 91.204 20.1259C91.4257 20.2909 91.4875 20.5951 91.3329 20.8271L89.915 22.9926C89.7654 23.2247 89.4561 23.2917 89.2189 23.1525C88.0743 22.4719 86.9658 22.2038 86.2078 22.2038C85.4499 22.2038 84.9188 22.5389 84.9188 23.003C84.9188 23.4412 85.2024 23.7763 86.2078 24.0341C87.0586 24.2662 88.4249 24.6786 89.5592 25.2716C91.2865 26.1481 91.9825 27.2566 91.9825 28.8808C91.9825 31.6134 89.6881 33.6242 85.8727 33.6242C83.5628 33.6242 81.4798 32.8405 79.8918 31.4794C79.6958 31.3092 79.6597 31.0205 79.8041 30.8039"
          fill="white"
        />
        <path
          d="M16.0454 27.0451V19.5432C16.0454 19.2596 16.2775 19.0276 16.561 19.0276H20.0929C20.3765 19.0276 20.6085 19.2596 20.6085 19.5432V27.0967C20.6085 27.803 20.8147 28.3754 21.2272 28.8085C21.6397 29.2467 22.1914 29.4684 22.8771 29.4684C23.5628 29.4684 24.1403 29.2519 24.5528 28.8239C24.9653 28.396 25.1715 27.8185 25.1715 27.0967V19.5432C25.1715 19.2596 25.4035 19.0276 25.6871 19.0276H29.2189C29.5025 19.0276 29.7345 19.2596 29.7345 19.5432V27.0451C29.7345 29.123 29.1313 30.7419 27.9196 31.8917C26.708 33.0415 25.0271 33.619 22.8823 33.619C20.7374 33.619 19.0565 33.0415 17.8552 31.8917C16.6538 30.7419 16.0506 29.123 16.0506 27.0451"
          fill="white"
        />
        <path
          d="M103.759 33.3147C103.475 33.3147 103.243 33.0827 103.243 32.7991V25.5086C103.243 23.936 101.964 22.6625 100.392 22.6625C99.8453 22.6625 99.3039 22.812 98.7832 23.1111C98.1026 23.5029 97.5457 24.4723 97.5457 25.2766V32.7991C97.5457 33.0827 97.3137 33.3147 97.0301 33.3147H93.9366C93.653 33.3147 93.421 33.0827 93.421 32.7991V24.7558H93.4313V24.6372C93.5808 21.4251 96.6434 18.9038 100.412 18.9038C104.181 18.9038 107.141 21.3529 107.383 24.4774V24.5805L107.399 24.5857V32.7991C107.399 33.0827 107.167 33.3147 106.883 33.3147H103.764H103.759Z"
          fill="white"
        />
        <path
          d="M105.837 8.07132L100.077 12.1652C99.9743 12.2373 99.8557 12.2992 99.732 12.3405C99.6082 12.3869 99.4742 12.4178 99.3401 12.4333C99.2061 12.4487 99.072 12.4487 98.9483 12.4333C98.8245 12.4178 98.7059 12.3869 98.6028 12.3353L93.5912 9.90685C93.5912 9.90685 93.5861 9.90685 93.5809 9.90685C93.5809 9.90685 93.5757 9.90685 93.5706 9.90169C93.5654 9.89654 93.5654 9.90169 93.5603 9.89654C93.5603 9.89654 93.5551 9.89654 93.55 9.89654C93.4726 9.85013 93.4159 9.79857 93.3798 9.7367C93.3437 9.67483 93.3334 9.6078 93.3489 9.54077C93.3592 9.47375 93.3953 9.40156 93.4468 9.33453C93.5036 9.26751 93.5757 9.20048 93.6737 9.13345L99.402 5.4624L105.831 8.08163L105.837 8.07132Z"
          fill="white"
        />
        <path
          d="M102.176 1.65718L94.5088 6.41614L90.2706 4.54968C90.2706 4.54968 90.2603 4.54452 90.2552 4.54452C90.25 4.54452 90.2448 4.53936 90.2397 4.53421C90.2345 4.53421 90.2294 4.52905 90.2242 4.5239C90.2191 4.5239 90.2139 4.51874 90.2088 4.51358C90.0644 4.4414 89.9664 4.34859 89.9149 4.24547C89.8581 4.14235 89.8478 4.03408 89.8788 3.91549C89.9097 3.80206 89.9767 3.68347 90.085 3.56489C90.1933 3.4463 90.3428 3.33287 90.5284 3.22975L95.4678 0.414589C95.6431 0.316626 95.8391 0.23413 96.0505 0.167103C96.2619 0.105231 96.4784 0.0588272 96.7001 0.0278914C96.9167 0.00211157 97.1384 -0.00820036 97.3395 0.00726754C97.5457 0.0227354 97.7365 0.0588272 97.8963 0.115543L102.181 1.64687L102.176 1.65718Z"
          fill="white"
        />
        <path
          d="M112.725 2.38924C112.813 2.42533 112.885 2.46658 112.936 2.51298C112.988 2.55939 113.019 2.6161 113.034 2.66766C113.045 2.72438 113.034 2.78109 113.008 2.83781C112.978 2.89452 112.926 2.95124 112.849 3.00796L107.93 6.49855L101.521 4.03399L106.46 0.868229C106.538 0.816669 106.631 0.775422 106.734 0.744486C106.837 0.71355 106.945 0.68777 107.053 0.677458C107.162 0.66199 107.275 0.66199 107.383 0.667146C107.492 0.677458 107.59 0.692926 107.682 0.723862L112.694 2.37377H112.704C112.704 2.37377 112.709 2.37377 112.715 2.37377C112.715 2.37377 112.72 2.37377 112.725 2.37377C112.725 2.37377 112.73 2.37377 112.735 2.37377"
          fill="white"
        />
        <path
          d="M118.685 7.5556C118.907 7.64841 119.092 7.75668 119.232 7.88558C119.371 8.00932 119.464 8.15369 119.51 8.29806C119.557 8.44758 119.551 8.5971 119.495 8.74663C119.438 8.89615 119.33 9.04567 119.165 9.18488L114.261 13.2993C114.065 13.4592 113.828 13.5932 113.565 13.6964C113.302 13.7995 113.014 13.8665 112.715 13.9026C112.416 13.9387 112.111 13.9387 111.823 13.9026C111.529 13.8665 111.25 13.7943 110.998 13.6809L105.109 11.0874L112.771 5.44165L118.582 7.51435C118.582 7.51435 118.603 7.51951 118.608 7.52466C118.618 7.52466 118.628 7.52982 118.634 7.53497C118.644 7.53497 118.654 7.54013 118.659 7.54529C118.67 7.54529 118.675 7.55044 118.685 7.5556Z"
          fill="white"
        />
        <path
          d="M49.5591 15.7485L52.2144 14.0522C52.4877 13.8769 52.8486 14.0728 52.8486 14.4028V21.0231C53.4931 19.8372 55.1172 18.7029 57.3085 18.7029C60.1185 18.7029 62.7996 20.5848 62.7996 24.5807V32.8045C62.7996 33.088 62.5676 33.3201 62.284 33.3201H58.7522C58.4686 33.3201 58.2366 33.088 58.2366 32.8045V25.7923C58.2366 23.9104 57.2828 22.8534 55.736 22.8534C54.1118 22.8534 52.9517 24.0651 52.9517 25.8955V32.8045C52.9517 33.088 52.7197 33.3201 52.4361 33.3201H48.9301C48.6465 33.3201 48.4145 33.088 48.4145 32.8045V17.8367C48.4145 16.9911 48.8476 16.2074 49.5591 15.7485Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1836_2823">
          <rect width="140" height="39.2751" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
