import React from 'react';

export const DollarRebrand = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
    >
      <path
        d="M36.8797 8.78906H3.78334C3.26787 8.78906 2.85083 9.20869 2.85083 9.72158V30.2783C2.85083 30.7834 3.27823 31.2108 3.78334 31.2108H36.8797C37.3952 31.2108 37.8123 30.7912 37.8123 30.2783V9.72158C37.8148 9.21646 37.3874 8.78906 36.8797 8.78906ZM35.968 15.8373V24.1419H35.9498V29.3432H4.71585V10.6541H35.9472V15.5783L35.968 15.8373Z"
        fill="#0096FA"
      />
      <path
        d="M34.6081 12.2445H30.0232C29.658 12.2445 29.3497 12.5528 29.3497 12.918C29.3497 13.2833 29.658 13.5915 30.0232 13.5915H34.6081C34.9733 13.5915 35.2815 13.2833 35.2815 12.918C35.2815 12.5528 34.9733 12.2445 34.6081 12.2445Z"
        fill="#0096FA"
      />
      <path
        d="M12.2019 26.3851H7.61702C7.25179 26.3851 6.94354 26.6933 6.94354 27.0585C6.94354 27.4238 7.25179 27.732 7.61702 27.732H12.2019C12.5878 27.732 12.8754 27.4238 12.8754 27.0585C12.8754 26.6933 12.5697 26.3851 12.2019 26.3851Z"
        fill="#0096FA"
      />
      <path
        d="M24.0369 21.8831C24.0369 22.5643 23.8271 23.1472 23.4075 23.6264C22.9878 24.1082 22.4309 24.4216 21.7315 24.5693V25.8877H20.1618V24.6185C18.9988 24.4294 18.1206 23.8258 17.5223 22.8052L18.7553 21.6344C18.9806 22.0282 19.2785 22.3442 19.6515 22.5773C20.0245 22.8104 20.4105 22.9296 20.8094 22.9296C21.1746 22.9296 21.4647 22.8415 21.6823 22.6654C21.8973 22.4892 22.0061 22.2639 22.0061 21.9945C22.0061 21.6241 21.7393 21.3029 21.2083 21.0335C21.1332 20.992 20.9519 20.9065 20.6591 20.7744C19.9857 20.4532 19.5272 20.2123 19.2759 20.0466C18.4211 19.4715 17.9937 18.7385 17.9937 17.8526C17.9937 17.2024 18.1932 16.6533 18.5921 16.1999C18.991 15.7492 19.5298 15.4513 20.211 15.3115V14.1147H21.7937V15.3115C22.5578 15.4591 23.2417 15.8451 23.8478 16.4693L22.8272 17.7749C22.2133 17.2076 21.5994 16.9252 20.9829 16.9252C20.6591 16.9252 20.4001 17.0055 20.2058 17.1661C20.0116 17.3267 19.9131 17.534 19.9131 17.7878C19.9131 18.0106 20.0116 18.2074 20.211 18.3784C20.3198 18.4613 20.527 18.596 20.8327 18.7851C20.9648 18.8602 21.2523 19.0027 21.6927 19.2177C22.2082 19.4637 22.5682 19.6528 22.7754 19.7849C23.6173 20.3056 24.0369 21.0024 24.0369 21.8831Z"
        fill="#DE3700"
      />
    </svg>
  );
};
