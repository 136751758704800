import React from 'react';

export const ThumbsUpRebrandV2 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="white" />
      <rect width="24" height="24" rx="12" fill="#DE3700" fillOpacity="0.8" />
      <g clipPath="url(#clip0_7119_29057)">
        <path
          d="M10.7294 17.1413H15.1945C15.1974 17.1413 15.2012 17.1413 15.2041 17.1413C15.7074 17.1413 16.2059 16.9767 16.6081 16.6765C17.0142 16.3743 17.3163 15.9413 17.4588 15.4563L18.2507 12.8167C18.4625 12.1209 18.3374 11.3607 17.9159 10.7708L17.8812 10.7246C17.4395 10.1251 16.7303 9.76714 15.9864 9.77291H14.2322L14.3274 9.42648C14.5526 8.60564 14.3448 7.73956 13.7712 7.11022C13.5316 6.84462 13.2266 6.64735 12.8869 6.53765C12.5472 6.42794 12.1844 6.41062 11.836 6.48568C11.7619 6.50204 11.6936 6.54053 11.6407 6.59731C11.5878 6.65408 11.5531 6.72433 11.5406 6.80035C11.5358 6.82826 11.0643 9.74116 9.36676 11.0383L9.29266 11.0951H6.11225C6.01121 11.0951 5.91113 11.1365 5.83991 11.2077C5.76678 11.2808 5.72733 11.3771 5.72733 11.48V16.0981C5.72733 16.2011 5.76774 16.2983 5.83991 16.3705C5.91113 16.4426 6.01121 16.4831 6.11225 16.4831H9.37927L9.46106 16.5745C9.78344 16.9344 10.2453 17.1403 10.7275 17.1403C10.7284 17.1413 10.7284 17.1413 10.7294 17.1413ZM15.2002 16.3695C15.1983 16.3695 15.1964 16.3695 15.1945 16.3695H10.7303C10.4811 16.3695 10.2463 16.2723 10.0702 16.0962C9.8941 15.9201 9.79691 15.6853 9.79691 15.4361V11.659L9.89891 11.5772C11.3607 10.4013 11.9919 8.29096 12.195 7.43836L12.2585 7.17373L12.5241 7.23532C12.7906 7.2969 13.0293 7.4374 13.2121 7.64045C13.6115 8.08695 13.7472 8.71823 13.5672 9.28791L13.3372 10.052C13.3035 10.1665 13.3266 10.2935 13.3988 10.3907C13.471 10.4879 13.5865 10.5456 13.7077 10.5456H15.9884C16.4897 10.5456 16.967 10.7862 17.2663 11.1885C17.5656 11.5907 17.658 12.1171 17.5136 12.5973L16.7216 15.2359C16.6254 15.5621 16.4214 15.8537 16.1481 16.0577C15.8748 16.2588 15.539 16.3695 15.2002 16.3695ZM9.02514 15.7103H6.49717V11.863H9.02514V15.7103Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7119_29057">
          <rect
            width="13.3"
            height="11.2715"
            fill="white"
            transform="translate(5.35001 6.18921)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
