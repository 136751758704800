import React from 'react';

export const MapRebrand = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
    >
      <path
        d="M18.78 31.0088L18.9105 31.0349C19.7573 31.2147 20.5519 31.4061 21.364 31.6874L21.4394 31.7106L21.509 31.7338L21.5467 31.7454L21.5728 31.7541L22.06 30.2809L22.031 30.2722L21.9933 30.2577C21.0798 29.9387 20.1546 29.7066 19.2063 29.5065L19.0671 29.4804L19.0497 29.4775L18.7626 31.0059L18.78 31.0088Z"
        fill="#DE3700"
      />
      <path
        d="M15.0736 29.0947L14.9779 29.1005L14.917 29.1034L15.0127 30.6579L15.0736 30.6521C15.9726 30.597 16.8398 30.655 17.7301 30.7652L17.8461 30.7797L17.8635 30.7826L18.0665 29.2397L18.0404 29.2368L17.9157 29.2223C16.9616 29.1005 16.0335 29.0425 15.0736 29.0947Z"
        fill="#DE3700"
      />
      <path
        d="M38.7818 35.7533L34.6347 21.7749C34.5215 21.395 34.3069 21.0238 33.9676 20.7396C33.6283 20.4554 33.2049 20.2755 32.6887 20.2755H20.7403L19.6208 22.6855H27.7759C28.2747 23.3264 28.7272 23.9877 29.1274 24.7098L30.4295 23.9268C30.1917 23.5033 29.9365 23.0886 29.6639 22.6855H32.303L36.1021 35.4981H10.8393C10.7697 34.7615 10.7929 33.9988 10.9901 33.2941L10.9872 33.2999L9.5517 32.8445L9.5488 32.8503C9.30229 33.7117 9.25879 34.6078 9.32839 35.4981H5.25665L9.05868 22.6855H15.175L14.0526 20.2755H8.66716C8.15095 20.2755 7.72753 20.4524 7.38822 20.7396C7.05181 21.0238 6.8343 21.395 6.7212 21.7749L2.57406 35.7533C2.53055 35.8983 2.51025 36.0405 2.51025 36.1797C2.51025 36.6437 2.71906 37.0439 3.04967 37.3484C3.29038 37.5717 3.60359 37.7428 3.9313 37.8298C4.12561 37.882 4.32282 37.911 4.52002 37.911H36.8387C37.0359 37.911 37.2331 37.882 37.4246 37.8298C37.7523 37.7399 38.0655 37.5688 38.3062 37.3455C38.6368 37.041 38.8456 36.6408 38.8456 36.1768C38.8485 36.0405 38.8253 35.8983 38.7818 35.7533Z"
        fill="#0096FA"
      />
      <path
        d="M10.361 31.2089L11.5355 32.1775C12.0691 31.4757 12.8347 31.0842 13.6584 30.8696L13.2959 29.3586C12.1445 29.6632 11.1034 30.2258 10.361 31.2089Z"
        fill="#DE3700"
      />
      <path
        d="M29.9482 33.1491L29.3276 31.757C28.5359 32.1485 27.6746 32.1572 26.8422 31.9774L26.4913 33.4913C27.663 33.7378 28.8462 33.6972 29.9482 33.1491Z"
        fill="#DE3700"
      />
      <path
        d="M31.9087 28.8888L31.9232 28.7931L31.9348 28.7322L30.3948 28.4915L30.3861 28.5524C30.244 29.4427 30.0033 30.278 29.7017 31.1219L29.664 31.2321L29.6582 31.2495L31.1228 31.7802L31.1315 31.757L31.1721 31.641C31.494 30.7333 31.7521 29.8372 31.9087 28.8888Z"
        fill="#DE3700"
      />
      <path
        d="M30.9861 24.6576L29.71 25.487C30.2204 26.2062 30.3654 27.056 30.319 27.9057L31.8706 28.013C31.9257 26.8211 31.7024 25.661 30.9861 24.6576Z"
        fill="#DE3700"
      />
      <path
        d="M22.9966 30.6173L22.463 32.0731C23.4693 32.4675 24.4988 32.8881 25.5893 32.9403L25.6531 31.3858C24.7598 31.3365 23.8289 30.9421 22.9966 30.6173Z"
        fill="#DE3700"
      />
      <path
        d="M24.725 7.81384C24.029 4.60924 21.2565 2.24276 17.9823 2.06006H17.9736C16.8339 2.06006 16.6338 2.09776 16.3814 2.14706L16.3118 2.16156C14.16 2.56757 12.4837 3.71891 11.3237 5.58947C10.7611 6.4972 10.4217 7.52093 10.3086 8.63167C10.2535 9.17399 10.2622 9.6496 10.3347 10.0904C10.4333 10.6936 10.6132 11.3201 10.9003 12.0625C11.3991 13.3559 12.0429 14.6784 12.9854 16.3459C14.015 18.1672 15.1953 20.0203 16.6947 22.1867C16.8861 22.4622 17.2167 22.6246 17.5879 22.6246C17.9504 22.6217 18.281 22.4564 18.4666 22.1838L18.7972 21.6966C19.1539 21.1746 19.5223 20.6323 19.8761 20.0928C21.2594 17.9787 22.3035 16.2125 23.1619 14.5305C23.7912 13.295 24.2088 12.3206 24.5133 11.3665C24.6699 10.8763 24.8352 10.2905 24.8758 9.6467C24.9164 9.05798 24.8642 8.45766 24.725 7.81384ZM17.3211 4.56864L17.382 4.56284H17.585C19.937 4.56284 22.0047 6.22459 22.5065 8.51277C22.6022 8.95068 22.637 9.35379 22.6109 9.74531C22.5848 10.1658 22.4688 10.5718 22.3557 10.9169C22.1207 11.642 21.7959 12.396 21.3029 13.3559C20.6127 14.6987 19.7717 16.1139 18.6551 17.8105C18.368 18.2455 18.0693 18.6834 17.7793 19.1068L17.7503 19.1474L17.5676 19.3794C16.3901 17.6858 15.4621 16.227 14.6501 14.8002C13.899 13.4806 13.3885 12.4424 12.997 11.4303C12.7853 10.8879 12.6548 10.4355 12.5852 10.0092C12.5388 9.7308 12.5359 9.435 12.5707 9.07248C12.649 8.31266 12.881 7.61374 13.2638 6.99892C14.0672 5.71128 15.2301 4.91665 16.7179 4.63824C16.9093 4.60344 17.1094 4.58604 17.3211 4.56864Z"
        fill="#0096FA"
      />
    </svg>
  );
};
