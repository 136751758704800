import React from 'react';
import classNames from 'classnames';
import styles from './Typography.module.scss';

const Typography = ({ children, variant, className }) => {
  switch (variant) {
    case 'p': {
      return (
        <p className={classNames([styles.paragraph], className)}>{children}</p>
      );
    }
    case 'pbolder': {
      return (
        <p className={classNames([styles.paragraphbolder], className)}>
          {children}
        </p>
      );
    }
    case 'p1': {
      return (
        <p className={classNames([styles.paragraph1], className)}>{children}</p>
      );
    }
    case 'legal': {
      return (
        <p className={classNames([styles.legal], className)}>{children}</p>
      );
    }
    case 'h1': {
      return (
        <h1 className={classNames([styles.headline1], className)}>
          {children}
        </h1>
      );
    }
    case 'h1bolder': {
      return (
        <h1 className={classNames([styles.headline1bolder], className)}>
          {children}
        </h1>
      );
    }
    case 'h2': {
      return (
        <h2 className={classNames([styles.headline2], className)}>
          {children}
        </h2>
      );
    }
    case 'h2small': {
      return (
        <h2 className={classNames([styles.headline2small], className)}>
          {children}
        </h2>
      );
    }
    case 'h2smallerlighter': {
      return (
        <h2 className={classNames([styles.headline2smallerlighter], className)}>
          {children}
        </h2>
      );
    }
    case 'h2smaller': {
      return (
        <h2 className={classNames([styles.headline2smaller], className)}>
          {children}
        </h2>
      );
    }
    case 'h3': {
      return (
        <h3 className={classNames([styles.headline3], className)}>
          {children}
        </h3>
      );
    }
    case 'h3small': {
      return (
        <h3 className={classNames([styles.headline3small], className)}>
          {children}
        </h3>
      );
    }
    case 'h4': {
      return (
        <h4 className={classNames([styles.headline4], className)}>
          {children}
        </h4>
      );
    }
    case 'ul': {
      return (
        <ul className={classNames([styles.list], className)}>{children}</ul>
      );
    }
    case 'disclaimer': {
      return (
        <p className={classNames([styles.disclaimer], className)}>{children}</p>
      );
    }
    case 'disclaimer-new': {
      return (
        <p className={classNames([styles.disclaimerNew], className)}>
          {children}
        </p>
      );
    }
  }
};

export default Typography;
